import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
const CustomUnauthorizedRouteGuard = ({ children }) => {
  const location = useLocation();
  const [user] = useState(JSON.parse(localStorage.getItem("loggedinUser")));
  // useEffect(() => {
  //   setToken(JSON.parse(localStorage.getItem("loggedinUser")));
  // }, []);
  // check user is already logged-in or not
  if (user) {
    // Redirect the user to their current location or a default route
    return (
      <Navigate
        to={
          (location.state?.from || user?.superAdmin || user?.companyAdmin || user?.isFarmer)
            ? "/dashboard"
            : "/products"
        }
      />
    );
  }
  return children;
};
export default CustomUnauthorizedRouteGuard;
