import axios from "axios";
import { toast } from "react-toastify";
const getUserFromLocalStorage = () => {
  const user = JSON.parse(localStorage.getItem("loggedinUser"));
  return user;
};

// Create a custom Axios instance for uploading images with "multipart/form-data" content type
const axiosInstanceWithFormData = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL, // server baseURL
  timeout: 100000, // Set the request timeout in milliseconds
  headers: {
    "Content-Type": "multipart/form-data"
  }
});

// Create a custom Axios instance for other requests with "application/json" content type
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL, // server baseURL
  timeout: 100000, // Set the request timeout in milliseconds
  headers: {
    "Content-Type": "application/json"
  }
});

// Interceptor for handling request errors
axiosInstanceWithFormData.interceptors.request.use(
  (config) => {
    const user = getUserFromLocalStorage();
    if (user && user.token) {
      config.headers["Authorization"] = `Bearer ${user.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor for handling request errors for the other Axios instance
axiosInstance.interceptors.request.use(
  (config) => {
    const user = getUserFromLocalStorage();
    if (user && user.token) {
      config.headers["Authorization"] = `Bearer ${user.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Interceptor for handling errors for both Axios instances
const errorInterceptor = (error) => {
  if(error?.response?.status === 401){
    toast.error(error?.response?.data?.message);
    localStorage.removeItem("loggedinUser")
    window.location.reload()
    return
  }
  if (error?.response?.status < 200 || error?.response?.status > 299) {
    toast.error(error?.response?.data?.message);
    return;
  }
};

axiosInstanceWithFormData.interceptors.response.use(
  (response) => response,
  errorInterceptor
);

axiosInstance.interceptors.response.use(
  (response) => response,
  errorInterceptor
);

export { axiosInstanceWithFormData, axiosInstance };
