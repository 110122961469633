import React, { useState, useEffect } from "react";
import breadcrumbLogo from "../../../assets/images/icons/breadcrumb-icon.svg";
import uploadProductLogo from "../../../assets/images/icons/upload-product.svg";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../axiosConfig/axiosInstance";
import ImageUploadProgress from "../ImageUploadProgress";
import { DatePicker, Space } from "antd";

const AddSubProduct = (props) => {
  const [product, setProduct] = useState({
    name: "",
    brand: "",
    description: "",
    nutritionImage: "",
    country: "",
    city: "",
    quantity: 1,
    sku: 1,
    title: "",
    journeyDescription: "",
    media: []
  });
  const [isLoading, setIsLoading] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [editorToolBar] = useState({
    toolbar: ["bold", "italic", "bulletedList", "numberedList"]
  });
  const [manufactureDate, setManufactureDate] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [settings, setSettings] = useState(null);
  useEffect(() => {
    imageHandling();
    getCompanySettings();
  }, []);

  const getCompanySettings = async () => {
    const companySettings = await axiosInstance.get("/company/settings");
    setSettings(companySettings?.data?.company);
  };

  const handleManufacturingDate = (date) => {
    if (expiryDate && date && date.isAfter(expiryDate)) {
      toast.error("Manufacture date must be less than expiry date");
    } else {
      setManufactureDate(date);
    }
  };

  const handleExpiryDate = (date) => {
    if (manufactureDate && date && date.isBefore(manufactureDate)) {
      toast.error("Expiry date must be greater than manufacture date");
    } else {
      setExpiryDate(date);
    }
  };

  const disabledManufactureDate = (current) => {
    return expiryDate && current && current.isAfter(expiryDate, "day");
  };

  const disabledExpiryDate = (current) => {
    return (
      manufactureDate && current && current.isBefore(manufactureDate, "day")
    );
  };


  const imageHandling = async () => {
    if (props?.product?.pictures && props?.product?.pictures.length > 0) {
      // Create an array of preview image objects
      const previewImageObjects = props?.product?.pictures.map(
        async (picture) => {
          const imgUrl = `${process.env.REACT_APP_SERVER_URL}/uploads/${props?.product?.company}/${picture}`;
          const result = await fetch(imgUrl);
          // data is fetched successfully
          if (result?.ok) {
            const blob = await result.blob();
            const sizeInKBs = (blob.size / 1024).toFixed(2);
            return {
              image: imgUrl,
              size: sizeInKBs,
              name: picture
            };
          } else {
            return {
              image: imgUrl,
              size: 0,
              name: picture
            };
          }
        }
      );
      // Wait for all promises to resolve
      const imageSizes = await Promise.all(previewImageObjects);
      setPreviewImages(imageSizes);
    } else {
      setPreviewImages([]); // or set a default value
    }
  };

  // handle form submission
  const handleFormSubmission = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosInstance.post("/product/subproduct", {
        productId: props?.product?._id,
        sku: product.sku,
        quantity: product?.quantity,
        manufacturing: manufactureDate,
        expiry: expiryDate,
        title: product?.title,
        journeyDescription: product?.journeyDescription
      });
      if (response) {
        toast.success(response?.data?.message);
        props.updateParentState(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };
  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setProduct({ ...product, description: data });
  };
  // handle input fields
  const handleInputFields = (event) => {
    const { name, value } = event.target;
    if (name === "quantity" || name === "sku") {
      if (parseInt(value) <= 1) {
        setProduct({ ...product, [name]: 1 });
      } else {
        setProduct({ ...product, [name]: value });
      }
    } else {
      setProduct({ ...product, [name]: value });
    }
  };

  return (
    <>
      <section className="section-main">
        <div className="content-sec">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-between">
              <div className="breadcrumb-bar">
                <ul className="nav align-items-center">
                  <li className="nav-item">
                    <Link to={"/products"}>Products</Link>
                  </li>
                  <li className="nav-item">
                    <img src={breadcrumbLogo} alt="icon" />
                  </li>
                  <li className="nav-item">Add New Stock</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="form-section">
                <div className="edit-profile-images">
                  <div className="upload-img">
                    <label>Uploaded Product Pictures</label>
                    <div className="image-section">
                      {previewImages?.length ? (
                        <>
                          {previewImages?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="image-show"
                                style={{
                                  display:
                                    previewImages.length > 0 ? "block" : "none"
                                }}
                              >
                                <div className="img-details">
                                  <ul className="nav">
                                    <li className="nav-item">
                                      <div className="uploaded-image">
                                        <img
                                          className=""
                                          id="uploaded-image-preview"
                                          src={item?.image}
                                          alt="preview image"
                                        />
                                      </div>
                                    </li>
                                    <li className="nav-item">
                                      <div
                                        className="product-name"
                                        id="product-name"
                                      >
                                        {item?.name}
                                      </div>
                                      <div
                                        className="product-size"
                                        id="product-size"
                                      >
                                        {item?.size} KB
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                                <div>
                                  <ImageUploadProgress
                                    newImages={previewImages}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ):<span>Product has no picture</span>}
                      <input id="upload-img-pu" type="file" disabled={true} />
                    </div>
                  </div>
                </div>

                <div className="form-area">
                  <form onSubmit={handleFormSubmission}>
                    <div className="form-group">
                      <label>Product Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={props?.product?.name}
                        required
                        disabled={true}
                      />
                    </div>
                    <div className="form-group">
                      <label>Brand</label>
                      <input
                        type="text"
                        className="form-control"
                        name="brand"
                        value={props?.product?.brand}
                        required
                        disabled={true}
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label>Quantity</label>
                      {settings?.standard === "erc1155" ? (
                        <span className="required-asterisk">*</span>
                      ) : null}
                      <input
                        type="number"
                        className="form-control"
                        name="quantity"
                        value={product.quantity}
                        onChange={handleInputFields}
                        required
                        disabled={
                          settings?.standard === "erc721" ? true : false
                        }
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label>SKU</label>
                      <span className="required-asterisk">*</span>
                      <input
                        type="text"
                        className="form-control"
                        name="sku"
                        value={product.sku}
                        onChange={handleInputFields}
                        required
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label>Manufacture Date</label>
                      <span className="required-asterisk">*</span>
                      <br />
                      <Space direction="vertical">
                        <DatePicker
                          onChange={handleManufacturingDate}
                          disabledDate={disabledManufactureDate}
                          value={manufactureDate}
                          placeholder="Select Manufacture Date"
                          required className = "ant-datepicker"
                        />
                      </Space>
                    </div>

                    <div className="form-group mt-3">
                      <label>Expiry Date</label>
                      <span className="required-asterisk">*</span>
                      <br />
                      <Space direction="vertical">
                        <DatePicker
                          onChange={handleExpiryDate}
                          disabledDate={disabledExpiryDate}
                          value={expiryDate}
                          placeholder="Select Expiry Date"
                          required
                          className = "ant-datepicker"
                        />
                      </Space>
                    </div>

                    <div className="form-group mt-3">
                      <label>Product Description</label>
                      <span className="required-asterisk">*</span>
                      {settings && (
                        <CKEditor
                          editor={ClassicEditor}
                          data={props?.product?.description || ""}
                          id="editor"
                          className="form-control"
                          config={editorToolBar}
                          onChange={handleEditorChange}
                          autofocus
                          disabled={true}
                          style={{color:"red"}}
                          required
                        />
                      )}
                    </div>

                    <div className="form-group mt-3">
                      <label>Product Journey Title</label>
                      <span className="required-asterisk">*</span>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={product.title}
                        onChange={handleInputFields}
                        required
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label>Product Journey Summary</label>
                      <span className="required-asterisk">*</span>
                      <textarea
                        type="text"
                        className="form-control"
                        name="journeyDescription"
                        value={product.journeyDescription}
                        onChange={handleInputFields}
                        rows="10"
                        cols="40"
                        required
                      />
                    </div>

                    <div
                      className={`form-group ${
                        props?.product?.nutritionImage ? "mt-4 mt-lg-5" : ""
                      }  row`}
                    >
                      <div className="col-8 pe-0">
                        {isLoading ? (
                          <button className="btn btn-primary w-100" disabled>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-primary w-100"
                          >
                            Add Product
                          </button>
                        )}
                      </div>
                      <div className="col-4">
                        <button
                          className="btn btn-light me-3 w-100"
                          onClick={() => props.updateParentState(false)}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AddSubProduct;
