import React, { useState, useEffect } from "react";
import userPlaceholder from "../../../assets/images/icons/userplaceholder.png";
import DOMPurify from "dompurify";
import Pulse from "../../Common/Pulse";
import { useNavigate } from "react-router-dom";
import FullImage from "../../Modals/FullimageModal";

const CompanyFarmerProfileSection = ({
  reviewPayload,
  farmer,
  farmerProfile,
  tipAmount,
  specificReviewStatus,
}) => {
  const navigate = useNavigate();
  const [fullPicture, setFullPicture] = useState(null);
  const [farmPictures, setFarmPictures] = useState([]);

  function getMonthNameAndYear(dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long" }; // Define options for month name and year
    return date.toLocaleString("en-US", options);
  }
  useEffect(() => {
    handleFarmPictures();
  }, []);

  const handleFarmPictures = () => {
    try {
      if (["Approved", "Rejected"]?.includes(specificReviewStatus?.text)) {
        if (
          reviewPayload?.removedPictures?.length &&
          farmerProfile?.farmPictures?.length > 0
        ) {
          let newFarmPictures = farmerProfile?.farmPictures?.filter((pic) => {
            return !reviewPayload?.removedPictures?.includes(pic);
          });
          setFarmPictures([...newFarmPictures]);
        } else if (
          reviewPayload?.farmPictures?.length > 0 &&
          reviewPayload?.removedPictures?.length === 0
        ) {
          setFarmPictures([...reviewPayload?.farmPictures]);
        } else if (
          reviewPayload?.farmPictures?.length === 0 &&
          reviewPayload?.removedPictures?.length > 0
        ) {
          setFarmPictures([...reviewPayload?.removedPictures]);
        } else if (farmerProfile?.farmPictures?.length > 0) {
          setFarmPictures([...farmerProfile?.farmPictures]);
        } else {
          setFarmPictures([]);
        }
      } else {
        if (reviewPayload?.removedPictures?.length) {
          if (farmerProfile?.farmPictures?.length > 0) {
            let newFarmPictures = farmerProfile?.farmPictures?.filter((pic) => {
              return !reviewPayload?.removedPictures?.includes(pic);
            });
            setFarmPictures([
              ...newFarmPictures,
              ...reviewPayload?.farmPictures
            ]);
          } else if (reviewPayload?.farmPictures?.length > 0) {
            setFarmPictures([...reviewPayload?.farmPictures]);
          } else {
            setFarmPictures([]);
          }
        } else {
          if (
            farmerProfile?.farmPictures?.length > 0 &&
            reviewPayload?.farmPictures?.length > 0
          ) {
            setFarmPictures([
              ...farmerProfile?.farmPictures,
              ...reviewPayload?.farmPictures
            ]);
          } else if (farmerProfile?.farmPictures?.length > 0) {
            setFarmPictures([...farmerProfile?.farmPictures]);
          } else if (reviewPayload?.farmPictures?.length > 0) {
            setFarmPictures([...reviewPayload?.farmPictures]);
          } else {
            setFarmPictures([]);
          }
        }
      }
    } catch (error) {}
  };

  // reset full picture state
  const resetFullPicture = (newState) => {
    setFullPicture(newState);
  };
  return (
    <>
      {/* show full picture */}
      {fullPicture && (
        <FullImage image={fullPicture} resetFullPicture={resetFullPicture} />
      )}
      <div className="farmer-profile">
        <div className="f-porfile-header">
          <button
            className="btn btn-primary"
            onClick={() => {
              navigate(`/edit-farmer/${farmer?._id}`);
            }}
          >
            Edit Profile
          </button>
          <div className="farmer-img">
            {reviewPayload?.pfp && reviewPayload?.pfp !== "remove" ? (
              <>
                <img
                  src={`${process.env.REACT_APP_SERVER_URL}/uploads/pfp/${reviewPayload?.pfp}`}
                  alt="farmer-img"
                />
                <Pulse />
              </>
            ) : reviewPayload?.pfp === "remove" ? (
              <>
                <img src={userPlaceholder} alt="farmer-img" />
                <Pulse />
              </>
            ) : farmerProfile?.pfp ? (
              <img
                src={`${process.env.REACT_APP_SERVER_URL}/uploads/pfp/${farmerProfile?.pfp}`}
                alt="farmer-img"
              />
            ) : (
              <img src={userPlaceholder} alt="farmer-img" />
            )}
          </div>
          <div className="farmer-name mb-3">
            <span>{reviewPayload?.firstName || farmerProfile?.firstName}</span>
            <span className="mx-1">
              {reviewPayload?.lastName || farmerProfile?.lastName}
            </span>
            {(reviewPayload?.firstName || reviewPayload?.lastName) && <Pulse />}
          </div>
          <div className="farmer-email mb-2">{farmerProfile?.email}</div>
          <div className="farmer-info">
            Joined Since {getMonthNameAndYear(farmerProfile?.createdAt)}
          </div>
        </div>
        <div className="profile-body">
          <div className="profile-info">
            <div className="row justify-content-center">
              <div className="col-md-12 col-xxl-9">
                <div className="row">
                  <div className="col-info col-md-3">
                    <div className="info-div">
                      <div className="title">Total Tip:</div>
                      <div className="description">{tipAmount} USD</div>
                    </div>
                  </div>
                  <div className="col-info  col-md-3">
                    <div className="info-div">
                      <div className="title">
                        Chain:
                        {reviewPayload?.network && <Pulse />}
                      </div>
                      <div className="description">
                        {reviewPayload?.network ||
                          farmerProfile?.farmerBlockchainNetwork?.name}
                      </div>
                    </div>
                  </div>
                  <div className="col-info  col-md-6">
                    <div className="info-div">
                      <div className="title">
                        Wallet Address:
                        {reviewPayload?.walletAddress && <Pulse />}
                      </div>
                      <div className="description">
                        {reviewPayload?.walletAddress ||
                          farmerProfile?.walletAddress}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(farmerProfile?.farmerDescription ||
            reviewPayload?.farmerDescription) && (
            <>
              <div className="farmer-profile-heading">
                About Me
                {reviewPayload?.farmerDescription && <Pulse />}
              </div>
              <div className="farmer-profile-pra">
                <p
                  className="product-details-pra"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      reviewPayload?.farmerDescription ||
                        farmerProfile?.farmerDescription
                    )
                  }}
                ></p>
              </div>
            </>
          )}

          <div className="farmer-profile-heading">
            Photos
            {reviewPayload?.farmPictures?.length > 0 ||
            reviewPayload?.removedPictures?.length > 0 ? (
              <Pulse />
            ) : null}
          </div>
          {farmPictures?.length > 0 ? (
            <>
              <div className="farmer-photoGallery">
                <div className="row">
                  {farmPictures?.map((item, index) => {
                    return (
                      <div className="col-md-6 col-lg-4 mb-2" key={index}>
                        <div className="farmer-photo">
                          <img
                            src={`${process.env.REACT_APP_SERVER_URL}/uploads/${farmerProfile?.company}/farmPictures/${item}`}
                            alt="farme-photo"
                            onClick={() => {
                              setFullPicture(
                                `${process.env.REACT_APP_SERVER_URL}/uploads/${farmerProfile?.company}/farmPictures/${item}`
                              );
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default CompanyFarmerProfileSection;
