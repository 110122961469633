import React, { useState, useEffect } from "react";
import userPlaceholder from "../../../assets/images/icons/userplaceholder.png";
import DOMPurify from "dompurify";
import Pulse from "../../Common/Pulse";
import { useNavigate } from "react-router-dom";
import FullImage from "../../Modals/FullimageModal";

const Viewprofile = ({
  reviewPayload,
  farmerProfile,
  tipAmount,
  profileStatus
}) => {

  const navigate = useNavigate();
  const [fullPicture, setFullPicture] = useState(null);
  const [farmPictures, setFarmPictures] = useState([]);

  useEffect(() => {
    handleFarmPictures();
  }, []);

  const handleFarmPictures = () => {
    if (
      reviewPayload?.reviewPayload?.removedPictures?.length &&
      !['Approved','Rejected'].includes(profileStatus?.text)
    ) {
      if (farmerProfile?.farmPictures?.length > 0) {
        let newFarmPictures = farmerProfile?.farmPictures?.filter((pic) => {
          return !reviewPayload?.reviewPayload?.removedPictures?.includes(pic);
        });
        if (reviewPayload?.reviewPayload?.farmPictures?.length > 0) {
          setFarmPictures([
            ...newFarmPictures,
            ...reviewPayload?.reviewPayload?.farmPictures
          ]);
        } else {
          setFarmPictures([...newFarmPictures]);
        }
      } else if (reviewPayload?.reviewPayload?.farmPictures?.length > 0) {
        setFarmPictures([...reviewPayload?.reviewPayload?.farmPictures]);
      } else {
        setFarmPictures([]);
      }
    } else {
      if (
        farmerProfile?.farmPictures?.length > 0 &&
        reviewPayload?.reviewPayload?.farmPictures?.length > 0 &&
        !['Approved','Rejected'].includes(profileStatus?.text)
      ) {
        setFarmPictures([
          ...farmerProfile?.farmPictures,
          ...reviewPayload?.reviewPayload?.farmPictures
        ]);
      } else if (farmerProfile?.farmPictures?.length > 0) {
        setFarmPictures([...farmerProfile?.farmPictures]);
      } else if (reviewPayload?.reviewPayload?.farmPictures?.length > 0) {
        setFarmPictures([...reviewPayload?.reviewPayload?.farmPictures]);
      } else {
        setFarmPictures([]);
      }
    }
  };

  function getMonthNameAndYear(dateString) {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "long" }; // Define options for month name and year
    return date.toLocaleString("en-US", options);
  }

  // reset full picture state
  const resetFullPicture = (newState) => {
    setFullPicture(newState);
  };

  return (
    <>
      {/* show full picture */}
      {fullPicture && (
        <FullImage image={fullPicture} resetFullPicture={resetFullPicture} />
      )}
      <div className="farmer-profile">
        <div className="f-porfile-header">
          <button
            className="btn btn-primary"
            onClick={() => {
              navigate(`/farmer/create/review-request`);
            }}
          >
            Edit Profile
          </button>
          <div className="farmer-img">
            {reviewPayload?.reviewPayload?.pfp &&
            reviewPayload?.reviewPayload?.pfp !== "remove" &&
            !["approved","rejected"].includes(reviewPayload?.reviewStatus) ? (
              <>
                <img
                  src={`${process.env.REACT_APP_SERVER_URL}/uploads/pfp/${reviewPayload?.reviewPayload?.pfp}`}
                  alt="farmer-img"
                />
                <Pulse />
              </>
            ) : reviewPayload?.reviewPayload?.pfp === "remove" &&
            !["approved","rejected"].includes(reviewPayload?.reviewStatus) ? (
              <>
                <img src={userPlaceholder} alt="farmer-img" />
                <Pulse />
              </>
            ) : farmerProfile?.pfp ? (
              <img
                src={`${process.env.REACT_APP_SERVER_URL}/uploads/pfp/${farmerProfile?.pfp}`}
                alt="farmer-img"
              />
            ) : (
              <img src={userPlaceholder} alt="farmer-img" />
            )}
          </div>

          <div className="farmer-name mb-3">
            <span>
              {reviewPayload?.reviewPayload?.firstName &&
               !["approved","rejected"].includes(reviewPayload?.reviewStatus)
                ? reviewPayload?.reviewPayload?.firstName
                : farmerProfile?.firstName}
            </span>
            <span className="mx-1">
              {reviewPayload?.reviewPayload?.lastName &&
               !["approved","rejected"].includes(reviewPayload?.reviewStatus)
                ? reviewPayload?.reviewPayload?.lastName
                : farmerProfile?.lastName}
            </span>
            {(reviewPayload?.reviewPayload?.firstName ||
              reviewPayload?.reviewPayload?.lastName) &&
               !["approved","rejected"].includes(reviewPayload?.reviewStatus) && <Pulse />}
          </div>
          <div className="farmer-email mb-2">{farmerProfile?.email}</div>
          <div className="farmer-info">
            Joined Since {getMonthNameAndYear(farmerProfile?.createdAt)}
          </div>
        </div>
        <div className="profile-body">
          <div className="profile-info">
            <div className="row justify-content-center">
              <div className="col-md-12 col-xxl-9">
                <div className="row">
                  <div className="col-info col-md-3">
                    <div className="info-div">
                      <div className="title">Total Tip:</div>
                      <div className="description">{tipAmount} USD</div>
                    </div>
                  </div>
                  <div className="col-info  col-md-3">
                    <div className="info-div">
                      <div className="title">
                        Chain:
                        {reviewPayload?.reviewPayload?.network &&
                          !["approved","rejected"].includes(reviewPayload?.reviewStatus) && (
                            <Pulse />
                          )}
                      </div>
                      <div className="description">
                        {reviewPayload?.reviewPayload?.network &&
                        !["approved","rejected"].includes(reviewPayload?.reviewStatus)
                          ? reviewPayload?.reviewPayload?.network
                          : farmerProfile?.farmerBlockchainNetwork?.name}
                      </div>
                    </div>
                  </div>
                  <div className="col-info  col-md-6">
                    <div className="info-div">
                      <div className="title">
                        Wallet Address:
                        {reviewPayload?.reviewPayload?.walletAddress &&
                          !["approved","rejected"].includes(reviewPayload?.reviewStatus) && (
                            <Pulse />
                          )}
                      </div>
                      <div className="description">
                        {reviewPayload?.reviewPayload?.walletAddress &&
                        !["approved","rejected"].includes(reviewPayload?.reviewStatus)
                          ? reviewPayload?.reviewPayload?.walletAddress
                          : farmerProfile?.walletAddress}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {(farmerProfile?.farmerDescription ||
            reviewPayload?.reviewPayload?.farmerDescription) && (
            <>
              <div className="farmer-profile-heading">
                About Me
                {reviewPayload?.reviewPayload?.farmerDescription &&
                  !["approved","rejected"].includes(reviewPayload?.reviewStatus) && <Pulse />}
              </div>
              <div className="farmer-profile-pra">
                <p
                  className="product-details-pra"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      reviewPayload?.reviewPayload?.farmerDescription &&
                        !["approved","rejected"].includes(reviewPayload?.reviewStatus)
                        ? reviewPayload?.reviewPayload?.farmerDescription
                        : farmerProfile?.farmerDescription
                    )
                  }}
                ></p>
              </div>
            </>
          )}

          <div className="farmer-profile-heading">
            Photos
            {(reviewPayload?.reviewPayload?.farmPictures?.length > 0 ||
              reviewPayload?.reviewPayload?.removedPictures?.length > 0) &&
            !["approved","rejected"].includes(reviewPayload?.reviewStatus) ? (
              <Pulse />
            ) : null}
          </div>
          {reviewPayload?.reviewPayload.farmPictures?.length ||
          farmerProfile?.farmPictures?.length ? (
            <>
              <div className="farmer-photoGallery">
                <div className="row">
                  {farmPictures?.map((item, index) => {
                    return (
                      <div className="col-md-6 col-lg-4 mb-2" key={index}>
                        <div className="farmer-photo">
                          <img
                            src={`${process.env.REACT_APP_SERVER_URL}/uploads/${farmerProfile?.company}/farmPictures/${item}`}
                            alt="farme-photo"
                            onClick={() => {
                              setFullPicture(
                                `${process.env.REACT_APP_SERVER_URL}/uploads/${farmerProfile?.company}/farmPictures/${item}`
                              );
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default Viewprofile;
