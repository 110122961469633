import React from "react";

const Progressbar = ({ step1 }) => {
  return (
    <div className="indication-nav">
      <ul className="nav justify-content-center align-items-center gap-2">
        <li className={`nav-item ${step1 ? "active" : null}`}>
          <div className="item-name d-flex align-items-center gap-2">
            {step1 ? (
              <div className="item-numb">
                <svg
                  width="14"
                  height="10"
                  viewBox="0 0 14 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 4.375L5.07506 8.45006L12.5131 1.012"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            ) : (
              <div className="item-numb">1</div>
            )}
            <div className="item-title">Complete Profile</div>
          </div>
        </li>
        <li className="nav-item">
          <div className="link-img">
            <svg
              width="161"
              height="2"
              viewBox="0 0 161 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="0.25" y="0.5" width="160" height="1" fill="#A2A1A1" />
            </svg>
          </div>
        </li>
        <li className="nav-item">
          <div className="item-name d-flex align-items-center gap-2">
            <div className={`${step1 ? "item-numb": "number"} `}>2</div>
            <div className={`${step1 ? "item-title":"item-title-text"}`}>Link Wallet</div>
          </div>
        </li>
      </ul>
    </div>
  );
};
export default Progressbar;
