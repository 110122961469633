import React, { useState, useEffect,  } from "react";
import { Pagination, PaginationItem } from "@mui/material";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader/Loader";
import { axiosInstance } from "../../../axiosConfig/axiosInstance";
import productPlaceholder from "../../../assets/images/products/product.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";
import searchIcon from "../../../assets/images/reporting/report-search.svg"

const TipHistory = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage] = useState(10);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [totalCount, setTotalCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [user] = useState(JSON.parse(localStorage.getItem("loggedinUser")));
  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        `/user/tip-history?page=${currentPage}&limit=${perPage}&searchInput=${searchQuery}`
      );
      setData(response?.data?.history);
      setTotalPages(response?.data?.totalPages);
      setTotalCount(response?.data?.totalCount);
      setCurrentPage(response?.data?.currentPage);
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePagination = async (event, page) => {
    if (page < 1 || page > totalPages) {
      return; // Prevent clicking on disabled pages
    }
    // Calculate the new startPage based on the clicked page
    setCurrentPage(page);
  };

  // handle pagination mouse over page number
  const handleItemMouseEnter = (event, page) => {
    if (page !== currentPage) {
      event.target.style.color = "white"; // Change color on hover for other pages
    }
  };

  // handle pagination mouse leave page number
  const handleItemMouseLeave = (event, page) => {
    if (page !== currentPage) {
      event.target.style.color = "#9FA1A1"; // Restore default color on mouse leave
    }
  };

  // get searched data from api
  const fetchedData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        `/user/tip-history?page=${currentPage}&limit=${perPage}&searchInput=${searchQuery}`
      );
      setData(response?.data?.history);
      setTotalPages(response?.data?.totalPages);
      setTotalCount(response?.data?.totalCount);
      setCurrentPage(response?.data?.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  // handle report searching
  const handleReportingSearching = (e) => {
    fetchedData()
  };

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    // Array of month names
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    // Get components of the date
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Pad single digit numbers with leading zero
    const padZero = (num) => (num < 10 ? "0" : "") + num;

    // Format the date string
    const formattedDate = `${padZero(day)}-${month}-${year} ${padZero(
      hours
    )}:${padZero(minutes)}:${padZero(seconds)}`;

    return formattedDate;
  }

  return (
    <>
      <section className="section-main">
        <div className="content-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 d-md-flex align-items-center justify-content-between">
                <div className="title-main mb-3 mb-md-0">Tips History</div>
                <div  className = "search-input position-relative">
                  <input
                    className="form-control report-searching mb-0"
                    type="search"
                    name="search"
                    id="searching"
                    placeholder="Name"
                    onChange={(event)=>setSearchQuery(event.target.value)}
                  />
                  <button className = "search-product" onClick={handleReportingSearching}>
                    <img src = {searchIcon} alt = "serach-icon"/>
                  </button>
                </div>
              </div>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="products-table pt-30">
                    <div className="products-table table-responsive">
                      {data?.length > 0 ? (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Product</th>
                              {user?.companyAdmin && <th>Farmer</th>}
                              <th>Tip</th>
                              <th>Coin</th>
                              <th>From</th>
                              <th>Transaction Hash</th>
                              <th>Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.length > 0 &&
                              data.map((item, index) => (
                                <tr key={index}>
                                  <td>
                                    <ul className="nav align-items-center">
                                      <li className="nav-item">
                                        <div className="product-img">
                                          {item?.picture ? (
                                            <img
                                              src={`${process.env.REACT_APP_SERVER_URL}/uploads/${user?.companyid}/${item?.picture}`}
                                            />
                                          ) : (
                                            <img src={productPlaceholder} />
                                          )}
                                        </div>
                                      </li>
                                      <li className="nav-item">
                                        {item.productName?.length > 20
                                          ? item.productName.substring(0, 20) +
                                            "..."
                                          : item.productName}
                                      </li>
                                    </ul>
                                  </td>
                                  {user?.companyAdmin && (
                                    <td>{item?.farmerName}</td>
                                  )}
                                  <td>{item?.amount}</td>
                                  <td>{item?.currency}</td>
                                  <td className="pl-1">
                                    {item?.fromWallet?.substring(0, 5)}****
                                    {item?.fromWallet?.substring(
                                      item?.fromWallet?.length - 11
                                    )}
                                  </td>
                                  <td>
                                    <a
                                      href={`${item?.explorerUrl}/tx/${item?.txHash}`}
                                      target="_blank"
                                      style={{
                                        color: "inherit",
                                        textDecoration: "underline"
                                      }}
                                    >
                                      {item?.txHash?.substring(0, 5)}****
                                      {item?.txHash?.substring(
                                        item?.txHash?.length - 11
                                      )}
                                      <i className="bi bi-box-arrow-up-right mx-2"></i>
                                    </a>
                                  </td>
                                  <td>{formatDate(item?.createdAt)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      ) : (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ minHeight: "70vh" }}
                        >
                          <div>
                            <p>No Data Found</p>
                          </div>
                        </div>
                      )}
                    </div>
                    {totalCount > perPage ? (
                      <div className="table-footer row">
                        <div className="col-12">
                          <ul className="nav align-items-center justify-content-end">
                            <Pagination
                              count={totalPages}
                              page={currentPage}
                              shape="rounded"
                              disabled={totalPages <= 1}
                              onChange={handlePagination}
                              siblingCount={isMobile ? 0 : 2}
                              boundaryCount={isMobile ? 0 : 2}
                              renderItem={(item) => (
                                <PaginationItem
                                  {...item}
                                  onMouseEnter={(e) =>
                                    handleItemMouseEnter(e, item.page)
                                  }
                                  onMouseLeave={(e) =>
                                    handleItemMouseLeave(e, item.page)
                                  }
                                  style={{
                                    background:
                                      item.page === currentPage
                                        ? "#202D46"
                                        : "",
                                    color:
                                      item.page === currentPage
                                        ? "white"
                                        : "#9FA1A1"
                                  }}
                                />
                              )}
                            />
                          </ul>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default TipHistory;
