import React,{useEffect} from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "./components/Context/AuthContext";
import { WalletConnectProvider } from "./components/Context/WalletConnectContext";
import { HeaderProvider } from "./components/Context/HeaderContext";
import { NotFoundProvider } from "./components/Context/NotFoundContext";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ForgetPassword from "./pages/ForgetPassword";
import NewPassword from "./pages/CreateNewPassword";
import Dashboard from "./components/Dashboard";
import AddProduct from "./pages/Products/AddProduct";
import AddUser from "./pages/Users/AddUser";
import Products from "./pages/Products/Products";
import Reporting from "./pages/Products/Reporting/ReportListing";
import ReportDetails from "./pages/Products/Reporting/ReportDetails";
import UsersListing from "./pages/Users/UsersListing";
import ShowingHeader from "./components/showingHeader";
import ProtectedRoute from "./components/ProtectedRoutes/ProtectedRoute";
import CustomUnauthorizedRouteGuard from "./components/CustomUnauthorizedRouteGuard/CustomUnauthorizedRouteGuard";
import CompaniesListing from "./pages/Companies/UpdateCompanyStatus";
import EmailVerification from "./pages/VerifyEmail";
import EditUser from "./pages/Users/EditUser";
import EditProduct from "./pages/Products/EditProduct";
import EditProfile from "./pages/EditProfile";
import VerifyProduct from "./pages/Products/VerifyProduct";
import VerifyReview from "./pages/Products/VerifyProduct/VerifyReview";
import ProductDetails from "./pages/Products/ProductDetails";
import ManageAccess from "./pages/Users/ManageAccess";
import NotFound from "./pages/404";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TipsHistory from "./pages/Farmer/History";
import FarmerListing from "./pages/Farmer/Listing";
import AddFarmer from "./pages/Farmer/Operations/AddFarmer";
import EditFarmer from "./pages/Farmer/Operations/EditFarmer";
import ViewProfile from "./pages/Farmer/Operations/ViewProfile";
import CreateReviewRequest from "./pages/Farmer/Operations/CreateReviewRequest";
import Roadmap from "./pages/Farmer/Operations/Roadmap";
import Welcome from "./pages/Farmer/Operations/Welcome";
import NetworkStatus from "./components/NetworkStatus";
import Test from "./test";
import { GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

const App = () => {

  useEffect(() => {
    // Global error handling for synchronous errors
    window.onerror = function (message, source, lineno, colno, error) {
      console.error("Global error caught:");
      // Handle or log the error
    };

    // Global handler for unhandled promise rejections
    window.addEventListener('unhandledrejection', function (event) {
      console.error("Unhandled promise rejection:", event.reason);
      // Handle or log the rejection
    });

    return () => {
      // Cleanup the event listeners on component unmount
      window.onerror = null;
      window.removeEventListener('unhandledrejection', () => {});
    };
  }, []);
  // Define ProtectedRoutes without wrapping them directly in Routes
  const ProtectedDashboard = () => (
    <ProtectedRoute allowedRoles={["superAdmin", "companyAdmin"]}>
      <Dashboard />
    </ProtectedRoute>
  );


  const ProtectedEditFarmer = () => (
    <ProtectedRoute allowedRoles={["companyAdmin"]}>
      <EditFarmer />
    </ProtectedRoute>
  );

  const ProtectedCreateReviewRequest = () => (
    <ProtectedRoute allowedRoles={["companyFarmer"]}>
      <CreateReviewRequest />
    </ProtectedRoute>
  );

  const ProtectedWelcomScreen = () => (
    <ProtectedRoute allowedRoles={["companyFarmer"]}>
      <Welcome />
    </ProtectedRoute>
  );

  const ProtectedFarmerRoadmapGuide = () => (
    <ProtectedRoute allowedRoles={["companyFarmer"]}>
      <Roadmap />
    </ProtectedRoute>
  );

  const ProtectedFarmerTipsHistory = () => (
    <ProtectedRoute allowedRoles={["companyAdmin","companyFarmer"]}>
      <TipsHistory />
    </ProtectedRoute>
  );

  const ProtectedFarmerViewProfile = () => (
    <ProtectedRoute allowedRoles={["companyAdmin","companyFarmer"]}>
      <ViewProfile />
    </ProtectedRoute>
  );

  const ProtectedAddFarmer = () => (
    <ProtectedRoute allowedRoles={["companyAdmin"]}>
      <AddFarmer />
    </ProtectedRoute>
  );

  const ProtectedFarmers = () => (
    <ProtectedRoute allowedRoles={["companyAdmin"]}>
      <FarmerListing />
    </ProtectedRoute>
  );

  const ProtectedCompaniesListing = () => (
    <ProtectedRoute allowedRoles={["superAdmin"]}>
      <CompaniesListing />
    </ProtectedRoute>
  );

  const ProtectedUsersListing = () => (
    <ProtectedRoute allowedRoles={["companyAdmin", "companyUser"]}>
      <UsersListing />
    </ProtectedRoute>
  );

  const ProtectedEditUser = () => (
    <ProtectedRoute allowedRoles={["companyAdmin", "companyUser"]}>
      <EditUser />
    </ProtectedRoute>
  );

  const ProtectedEditProfile = () => (
    <ProtectedRoute
      allowedRoles={["superAdmin", "companyAdmin", "companyUser"]}
    >
      <EditProfile />
    </ProtectedRoute>
  );

  const ProtectedAddProduct = () => (
    <ProtectedRoute allowedRoles={["companyAdmin", "companyUser"]}>
      <AddProduct />
    </ProtectedRoute>
  );

  const ProtectedProducts = () => (
    <ProtectedRoute
      allowedRoles={["superAdmin", "companyAdmin", "companyUser"]}
    >
      <Products />
    </ProtectedRoute>
  );

  const ProtectedEditProduct = () => (
    <ProtectedRoute allowedRoles={["companyAdmin", "companyUser"]}>
      <EditProduct />
    </ProtectedRoute>
  );

  const ProtectedAddUser = () => (
    <ProtectedRoute allowedRoles={["companyAdmin"]}>
      <AddUser />
    </ProtectedRoute>
  );

  const ProtectedManageUserAccess = () => (
    <ProtectedRoute allowedRoles={["companyAdmin"]}>
      <ManageAccess />
    </ProtectedRoute>
  );

  const ProtectedReporting = () => (
    <ProtectedRoute
      allowedRoles={["companyAdmin", "companyUser", "superAdmin"]}
    >
      <Reporting />
    </ProtectedRoute>
  );

  const ProtectedReportingDetails = () => (
    <ProtectedRoute
      allowedRoles={["companyAdmin", "companyUser", "superAdmin"]}
    >
      <ReportDetails />
    </ProtectedRoute>
  );

  const ProtectedProductDetails = () => (
    <ProtectedRoute
      allowedRoles={["companyAdmin", "companyUser", "superAdmin"]}
    >
      <ProductDetails />
    </ProtectedRoute>
  );

  return (
    <NetworkStatus>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AuthProvider>
        <WalletConnectProvider>
          <NotFoundProvider>
            <HeaderProvider>
              <ShowingHeader />
              <Routes>
                <Route
                  path="/product/getProduct/scan/:productId"
                  element={<VerifyProduct />}
                />
                {/* Un-authorized components */}
                <Route path="/" element={<Navigate to={"/login"} />} />

                <Route
                  path="/login"
                  element={
                    <CustomUnauthorizedRouteGuard>
                       <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}>
                      <Login />
                       </GoogleReCaptchaProvider>
                    </CustomUnauthorizedRouteGuard>
                  }
                />
                <Route
                  path="/signup"
                  element={
                    <CustomUnauthorizedRouteGuard>
                      <Signup />
                    </CustomUnauthorizedRouteGuard>
                  }
                />
                <Route
                  path="/forget-password"
                  element={
                    <CustomUnauthorizedRouteGuard>
                      <ForgetPassword />
                    </CustomUnauthorizedRouteGuard>
                  }
                />
                <Route
                  path="/reset-password"
                  element={
                    <CustomUnauthorizedRouteGuard>
                      <NewPassword />
                    </CustomUnauthorizedRouteGuard>
                  }
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/test" element={<Test />} />

                <Route path="/verify-email" element={<EmailVerification />} />
                <Route path="/verify-review" element={<VerifyReview />} />
                {/* Protected routes */}
                <Route path="/dashboard" element={<ProtectedDashboard />} />

                <Route path="/tips-history" element={<ProtectedFarmerTipsHistory />} />
                <Route path="/farmers" element={<ProtectedFarmers />} />
                <Route path="/edit-farmer/:id" element={<ProtectedEditFarmer />} />
                <Route path="/add-farmer" element={<ProtectedAddFarmer />} />
                <Route path="/farmer/profile" element={<ProtectedFarmerViewProfile />} />
                <Route path="/farmer/create/review-request" element={<ProtectedCreateReviewRequest />} />
                <Route path="/farmer/roadmap" element={<ProtectedFarmerRoadmapGuide />} />
                <Route path="/welcome" element={<ProtectedWelcomScreen />} />

                {/* Companies routes */}
                <Route
                  path="/companies"
                  element={<ProtectedCompaniesListing />}
                />

                {/* Product routes */}
                <Route path="/products" element={<ProtectedProducts />} />
                <Route path="/add-product" element={<ProtectedAddProduct />} />
                <Route
                  path="/edit-product/:id"
                  element={<ProtectedEditProduct />}
                />
                <Route
                  path="/products-reporting"
                  element={<ProtectedReporting />}
                />
                <Route
                  path="/reporting-details/:productId"
                  element={<ProtectedReportingDetails />}
                />
                <Route
                  path="/product-details/:productId"
                  element={<ProtectedProductDetails />}
                />

                {/* User routes */}
                <Route path="/users" element={<ProtectedUsersListing />} />
                <Route path="/edit-user/:id" element={<ProtectedEditUser />} />
                <Route path="/add-user" element={<ProtectedAddUser />} />
                <Route
                  path="/edit-profile"
                  element={<ProtectedEditProfile />}
                />
                <Route
                  path="/manage-access/:id"
                  element={<ProtectedManageUserAccess />}
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </HeaderProvider>
          </NotFoundProvider>
        </WalletConnectProvider>
      </AuthProvider>
    </NetworkStatus>
  );
};
export default App;
