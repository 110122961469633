import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import breadcrumbImg from "../../assets/images/icons/breadcrumb-icon.svg";
import uploadUserImg from "../../assets/images/icons/upload-user.svg";
import {
  axiosInstance,
  axiosInstanceWithFormData
} from "../../axiosConfig/axiosInstance";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import countriesListing from "countries-cities";
import ImageUploadProgress from "../Products/ImageUploadProgress";
import { checkWalletChecksum } from "../../utils/utills";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { Upload } from "antd";
import DraggableUploadListItem from "../../components/HOCs/DragAndDrop";
import dragAndDropLogo from "../../assets/images/icons/dragdrop.svg";
import uploadProductLogo from "../../assets/images/icons/upload-product.svg";
import { v4 as uuidv4 } from "uuid";

const EditUser = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [selectedRole, setSelectedRole] = useState("");
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    country: "",
    city: "",
    isViewer: false,
    isEditor: false,
    walletAddress: "",
  });
  const [file, setFile] = useState(null);
  const [currentPfp, setCurrentPfp] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [editorToolBar] = useState({
    toolbar: ["bold", "italic", "bulletedList", "numberedList"]
  });
  const [previewImages, setPreviewImages] = useState([]);


  const { id } = useParams();
  useEffect(() => {
    fetchData();
  }, [id]);

  // function that is called when component is mount
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        `user/getuserdetails?userId=${id}`
      );
      setData({ ...response?.data, farmPictures: [] });
      if (response?.data?.isEditor) {
        setSelectedRole("editor");
      } else {
        setSelectedRole("viewer");
      }
     
      setCurrentPfp(response?.data?.profilePic);
      const filteredCountries = [];
      countriesListing.getCountries().forEach((country, index) => {
        if (index == 0 && response.data.country) {
          filteredCountries.push(response.data.country);
        } else if (country !== response.data.country) {
          filteredCountries.push(country);
        }
      });
      setCountries(filteredCountries);
      const selectedFilteredCities = [];
      const selectedCountryCities = countriesListing.getCities(
        response.data.country ? response.data.country : filteredCountries[0]
      );
      selectedCountryCities.forEach((city, index) => {
        if (index === 0 && response.data.city) {
          selectedFilteredCities.push(response.data.city);
        } else if (city !== response.data.city) {
          selectedFilteredCities.push(city);
        }
      });
      setCities(selectedFilteredCities);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  // countries input changing
  const onCountryChange = async (e) => {
    setIsButtonDisabled(false);
    setCities(countriesListing.getCities(countries[e.target.value]));
    setData({
      ...data,
      country: countries[e.target.value],
      city: countriesListing.getCities(countries[e.target.value])[0]
    });
  };

  // cities input
  const onCityChange = async (e) => {
    setIsButtonDisabled(false);
    setData({ ...data, city: cities[e.target.value] });
  };

  // handle input field changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setIsButtonDisabled(false);
    setData({ ...data, [name]: value });
  };

  // handle role change
  const handleRoleChange = (event) => {
    const role = event.target.value;
    setIsButtonDisabled(false);
    setSelectedRole(role);
    setData({
      ...data,
      isViewer: role === "viewer",
      isEditor: role === "editor",
    });
  };

 

  // handle Image changes
  const handleImageChange = (event) => {
    if (event.target.files.length > 0) {
      setIsButtonDisabled(false);
      const file = event.target.files[0];
      if (file) setFile(file);
      const { type, size, name } = event.target.files[0];

      if (
        type === "image/jpeg" ||
        type === "image/jpg" ||
        type === "image/png"
      ) {
        if (size <= 1 * 1024 * 1024) {
          // Update the product state with the new media item added to the existing media array
          const reader = new FileReader();
          reader.onload = (e) => {
            const newPreviewImage = {
              image: e.target.result,
              size: `${(size / 1024).toFixed(2)} KB`,
              name: name,
              dbImage: 0
            };
            setNewImages((previous) => [newPreviewImage]);
            setPreviewImage(newPreviewImage);
          };
          reader.readAsDataURL(event.target.files[0]);
          event.target.value = null;
        } else {
          event.target.value = null;
          toast.warn("File size must be less than 1MB");
        }
      } else {
        event.target.value = null;
        toast.warn("Only JPG and PNG files supported");
      }
    }
  };

  const handleDeleteImage = () => {
    setIsButtonDisabled(false);
    if (previewImage != null) setPreviewImage(null);
    else {
      setFile("remove");
      setCurrentPfp(null);
    }
  };

  // handle form submission
  const handleFormSubmission = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData()
      if (data?.isEditor || data?.isFarmer) {
        const isValid = await checkWalletChecksum(data?.walletAddress);
        if (!isValid) {
          toast.error("Error: invalid ethereum address.");
          return;
        }else{
          formData.append("walletAddress", data?.walletAddress)
        }
      }
      formData.append("firstName", data?.firstName);
      formData.append("lastName", data?.lastName);
      formData.append("email", data?.email);
      formData.append("country", data?.country);
      formData.append("city", data?.city);
      formData.append("isEditor",data?.isEditor);
      formData.append("isViewer", data?.isViewer);
      formData.append("userId",id)
      setButtonLoading(true);
      const response = await axiosInstanceWithFormData.patch("/user/edituser", 
        formData
      );
      if (file) {
        const formData = new FormData();
        formData.append("pfp", file);
        formData.append("userId", id);
        await axiosInstanceWithFormData.post("/user/updatepfp", formData);
      }
      toast.success(response?.data?.message);
      setButtonLoading(false);
      navigate("/users");
      return;
    } catch (error) {
      toast.error("Something went wrong");
      setIsLoading(false);
      return;
    }
  };

  return (
      <section className="section-main">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="content-sec">
            <div className="row">
              <div className="col-12 d-flex align-items-center justify-content-between">
                <div className="breadcrumb-bar">
                  <ul className="nav align-items-center">
                    <li className="nav-item">
                      <Link to={"/users"}>Users</Link>
                    </li>
                    <li className="nav-item">
                      <img src={breadcrumbImg} alt="icon" />
                    </li>
                    <li className="nav-item">Edit User</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-section">
                  <div className="edit-profile-images">
                    <div className="upload-img">
                      <label>Upload Profile Picture</label>
                      <div className="image-section">
                        <div className="img-placeholder">
                          <div className="user-img-ic">
                            <img src={uploadUserImg} alt="icon" />
                          </div>
                          <div className="img-dimensions mt-1">(Optional)</div>
                          <div className="title mt-2">Upload Picture</div>
                        </div>
                        {previewImage != null || currentPfp != null ? (
                          <>
                            <div
                              className="image-show"
                              style={{
                                display:
                                  previewImage != null || currentPfp != null
                                    ? "block"
                                    : "none"
                              }}
                            >
                              <div className="img-details">
                                <ul className="nav">
                                  <li className="nav-item">
                                    {
                                      <div className="uploaded-image">
                                        <img
                                          className=""
                                          id="uploaded-image-preview"
                                          src={
                                            previewImage?.image ||
                                            `${process.env.REACT_APP_SERVER_URL}/uploads/pfp/${currentPfp}`
                                          }
                                          alt="Uploaded Image"
                                        />
                                      </div>
                                    }
                                  </li>
                                  <li className="nav-item">
                                    <div
                                      className="product-name"
                                      id="product-name"
                                    >
                                      {previewImage?.name ||
                                        "Current profile picture"}
                                    </div>
                                    <div
                                      className="product-size"
                                      id="product-size"
                                    >
                                      {previewImage?.size}{" "}
                                      {previewImage?.size && "KB"}
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <div>
                                <ImageUploadProgress newImages={newImages} />
                              </div>
                              <div
                                className="close-sec"
                                onClick={() => handleDeleteImage()}
                              ></div>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <input
                          id="upload-img-pu"
                          type="file"
                          onChange={handleImageChange}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                    
                  </div>
                  <div className="form-area">
                    <form onSubmit={handleFormSubmission}>
                      <div className="form-group">
                        <label>First Name</label>
                        <span className="required-asterisk">*</span>
                        <div className="input-area">
                          <input
                            type="text"
                            name="firstName"
                            required
                            value={data?.firstName}
                            className="form-control"
                            onChange={handleInputChange}
                          />
                          <span className="focus-bg"></span>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Last Name</label>
                        <span className="required-asterisk">*</span>
                        <input
                          type="text"
                          name="lastName"
                          required
                          value={data?.lastName}
                          className="form-control"
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="form-group">
                        <label>Email</label>
                        <span className="required-asterisk">*</span>
                        <input
                          type="email"
                          name="email"
                          required
                          value={data?.email}
                          readOnly
                          disabled
                          className="form-control"
                        />
                      </div>

                      <div className="form-group">
                        <label>Country</label>
                        <span className="required-asterisk">*</span>
                        <select
                          name=""
                          required
                          className="form-select select-options"
                          onChange={onCountryChange}
                        >
                          {countries.map((item, index) => (
                            <option key={index} value={index}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group mt-3">
                        <label>City</label>
                        <span className="required-asterisk">*</span>
                        <select
                          name=""
                          required
                          className="form-select select-options"
                          onChange={onCityChange}
                        >
                          {cities.map((item, index) => (
                            <option key={index} value={index}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group mt-3 ">
                        <label>Designation</label>
                        <span className="required-asterisk">*</span>
                        <select
                          name="" // Change 'role' to the appropriate field name
                          onChange={handleRoleChange}
                          required
                          value={selectedRole}
                          className="form-select select-options"
                        >
                          <option value="" disabled>
                            Select a role
                          </option>
                          <option value="editor">Editor</option>
                          <option value="viewer">Viewer</option>
                        </select>
                      </div>
                      {(data?.isEditor) && (
                        <div className="form-group mt-3">
                          <label>Wallet Address</label>
                          <span className="required-asterisk">*</span>
                          <input
                            type="text"
                            name="walletAddress"
                            required
                            value={data?.walletAddress}
                            onChange={handleInputChange}
                            className="form-control"
                          />
                        </div>
                      )}
                     
                      <div className="form-group mt-4 mt-lg-5 text-end">
                        <button
                          className="btn btn-light me-3"
                          onClick={() => navigate("/users")}
                        >
                          Cancel
                        </button>
                        {buttonLoading ? (
                          <button className="btn btn-primary w-25" disabled>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </button>
                        ) : (
                          <button
                            className="btn btn-primary"
                            disabled={isButtonDisabled}
                          >
                            Update User
                          </button>
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
  );
};

export default EditUser;
