import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "./index";
import Navbar from "../Navbar";
import FarmerNavbar from "../Navbar/FarmerNavbar";
const Menu = () => {
  const location = useLocation();
  const [activeMenu, setActiveMenu] = useState(false);
  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  return (
    <>
      {location?.pathname?.split("/")?.includes("welcome") ? <FarmerNavbar/>: (
        <>
          <Navbar onMenuClick={handleMenuClick} />
          {location.pathname.split("/")?.slice(0, 4)?.join("/") !==
            "/product/getProduct/scan" && (
            <Sidebar activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
          )}
        </>
      )}
    </>
  );
};

export default Menu;
