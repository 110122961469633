import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstance } from "../../axiosConfig/axiosInstance";
import { checkWalletChecksum } from "../../utils/utills";
import EmailPopup from "../../components/EmailPopup/index";
import Form from "react-bootstrap/Form";
const Signup = () => {
  const [inputFields, setInputFields] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    companyName: "",
    walletAddress: "",
    chainId: "",
    networkName: "",
    explorerUrl: ""
  });
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isConfirmPasswordShown, setIsConfirmPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [networks, setNetworks] = useState([]);

  // fetch blockchain networks
  useEffect(() => {
    fetchNetworks();
  }, []);

  const fetchNetworks = async () => {
    try {
      const networks = await axiosInstance.get("/general/networks");
      setNetworks(networks?.data?.networks);
    } catch (error) {
      toast.error(error?.message);
    }
  };
  // handle input field changes
  const handleInputFields = (event) => {
    let { name, value } = event.target;
    setInputFields({ ...inputFields, [name]: value });
  };

  // network selection change
  const handleNetworkSelectionChange = (event) => {
    const blockchainNetwork = JSON.parse(event.target.value);
    setInputFields({
      ...inputFields,
      chainId: blockchainNetwork?.chainId,
      networkName: blockchainNetwork?.name,
      explorerUrl: blockchainNetwork?.explorerUrl
    });
  };
  // handle form submission
  const handleFormSubmission = async (event) => {
    event.preventDefault();
    if (inputFields.password === inputFields.confirmPassword) {
      try {
        const isValid = await checkWalletChecksum(inputFields.walletAddress);
        if (!isValid) {
          toast.error("Error: invalid ethereum address.");
          return;
        }
        if (
          !inputFields?.chainId ||
          !inputFields?.networkName ||
          !inputFields?.explorerUrl
        ) {
          toast.error("Please select a blockchain network");
          return;
        }
        setIsLoading(true);
        const response = await axiosInstance.post(
          "/company/register",
          inputFields
        );
        if (response) {
          setIsEmailSent(true);
        }
      } catch (error) {
        toast.error("Something went wrong");
      } finally {
        setIsLoading(false);
      }
    } else {
      toast.error("password does not match");
      return;
    }
  };

  return (
    <>
      {isEmailSent ? (
        <EmailPopup
          text="We have sent a link on your email. Kindly follow the instructions to verify your email"
          purpose="signup"
        />
      ) : (
        <section className="section-main ms-0">
          <div className="login-signup-section d-flex align-items-center justify-content-center py-4">
            <div className="login-signup-form">
              <form onSubmit={handleFormSubmission} autoComplete="off">
                <div className="form-title">Register Your Company</div>

                <div className="form-group form-floating">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    id="name"
                    required
                    name="firstName"
                    onChange={handleInputFields}
                  />
                  <label>First Name</label>
                </div>

                <div className="form-group form-floating">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    id="name"
                    required
                    name="lastName"
                    onChange={handleInputFields}
                  />
                  <label>Last Name</label>
                </div>

                <div className="form-group form-floating">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    id="email"
                    required
                    name="email"
                    onChange={handleInputFields}
                    autoComplete="off"
                  />
                  <label>Email</label>
                </div>

                <div className="form-group form-floating">
                  <input
                    type={isPasswordShown ? "text" : "password"}
                    id="password"
                    className="form-control"
                    placeholder="Password"
                    required
                    name="password"
                    onChange={handleInputFields}
                    autoComplete="new-password"
                  />
                  <label>Password</label>
                  <div
                    id="passwordbtn"
                    className={`show-hide-btn ${
                      isPasswordShown ? "active" : ""
                    }`}
                    data-target="password"
                    onClick={() => setIsPasswordShown(!isPasswordShown)}
                  >
                    <span>password-icon-show</span>
                  </div>
                </div>

                <div className="form-group form-floating">
                  <input
                    type={`${isConfirmPasswordShown ? "text" : "password"}`}
                    id="resetpassword"
                    className="form-control"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    required
                    onChange={handleInputFields}
                  />
                  <label>Confirm Password</label>
                  <div
                    id="passwordbtn"
                    className={`show-hide-btn ${
                      isConfirmPasswordShown ? "active" : ""
                    }`}
                    data-target="resetpassword"
                    onClick={() =>
                      setIsConfirmPasswordShown(!isConfirmPasswordShown)
                    }
                  >
                    <span>password-icon-show</span>
                  </div>
                </div>

                <div className="form-group form-floating">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Company Name"
                    id="name"
                    required
                    name="companyName"
                    onChange={handleInputFields}
                  />
                  <label>Company Name</label>
                </div>
                <div className="form-group">
                  <Form.Select
                    onChange={handleNetworkSelectionChange}
                    required
                    className="select-options signup-select"
                    style={{ paddingTop: "10px" }}
                  >
                    <option value="Select blockchain network" disabled selected>
                      Select an option
                    </option>
                    {networks &&
                      networks?.map((item) => {
                        return (
                          <option value={JSON.stringify(item)} key={item?._id}>
                            {item?.name}
                          </option>
                        );
                      })}
                  </Form.Select>
                </div>

                <div className="form-group form-floating mt-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Wallet Address"
                    id="wallet"
                    required
                    name="walletAddress"
                    onChange={handleInputFields}
                  />
                  <label>Wallet Address</label>
                </div>

                <div className="form-group">
                  <div className="note">
                    By submitting this form, you consent to allow us to store
                    and process the personal information submitted above to
                    provide you the content requested. Please review our
                    <Link to={"/privacy-policy"} target="_blank">
                      {" "}
                      Privacy Policy.
                    </Link>
                  </div>
                </div>

                <div className="form-group">
                  {isLoading ? (
                    <button type="submit" className="btn btn-primary" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary">
                      Register
                    </button>
                  )}
                </div>
              </form>

              <div className="form-footer">
                <Link to={"/login"}> Sign in</Link> If you already have an
                account
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Signup;
