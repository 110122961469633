import React, {useContext,useEffect} from "react";
import {useNavigate,Link} from "react-router-dom"
import { notfoundContext } from "../../components/Context/NotFoundContext";
const NotFound = () => {
    const navigate = useNavigate()
  const { update404State } = useContext(notfoundContext);
  useEffect(()=>{
    update404State(true)
  },[update404State])

  return (
    <>
      <section className="section-main ms-0">
        <section className="min-vh-100 d-flex justify-content-center align-items-center error-404">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="body-404 text-center">
                  <div className="text-top">Oops!</div>
                  <h2>
                    4<span>0</span>4
                  </h2>
                  <p className="mb-5">
                    The page you are looking for does not exist. It might have
                    been moved or deleted.
                  </p>
                  <Link className="btn btn-primary w-100" to={""} role="button" onClick={()=> {
                    navigate(-1)
                    update404State(false)
                    }}>
                    Back to Track
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};
export default NotFound;
