import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const ApproveReviewRequestModal = ({ updateApproveModalStatus, show, handleApproveModalClose,userInfo }) => {
  const handleButtonClick = () => {
    updateApproveModalStatus(false);
  };
  const handleCloseButton = () => {
    handleApproveModalClose(false);
  };
  return (
    <Modal
      show={show}
      onHide={handleCloseButton}
      backdrop="static"
      keyboard={false}
      centered
      className="modal fade deleteModal"
    >
      <Modal.Body className="Modal-body text-center">
        <div className="Modal-title mb-3">
          Farmer Approval
        </div>
        <div>
            <p>You are going to approve "{userInfo?.email}". Please make sure you have properly checked all the details.</p>
        </div>
        <ul className="nav justify-content-center align-items-center">
          <li className="nav-item">
            <Button
              variant="approve"
              onClick={handleButtonClick}
              className="btn btn-approve"
            >
              Yes, Approve
            </Button>
          </li>
          <li className="nav-item">
            <Button
              variant="cancel"
              onClick={handleCloseButton}
              className="btn btn-cancel"
            >
              No
            </Button>
          </li>
        </ul>
      </Modal.Body>
    </Modal>
  );
};

export default ApproveReviewRequestModal;
