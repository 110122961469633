import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  axiosInstance,
  axiosInstanceWithFormData
} from "../../../axiosConfig/axiosInstance";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader/Loader";
import userPlaceholder from "../../../assets/images/icons/userplaceholder.png";
import DeleteRequestModal from "../../../components/Modals/deleteReviewRequest";
import RejectReviewRequestModal from "../../../components/Modals/rejectReviewRequestModal";
import ApproveReviewRequestModal from "../../../components/Modals/approveReviewRequestModal";
import eventEmitter from "../../../components/Events/eventEmitter";

const FarmerListing = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("approved");
  const [isUpdating, setIsUpdating] = useState(false);
  const [user] = useState(JSON.parse(localStorage.getItem("loggedinUser")));
  const [show, setShow] = useState(false);
  const [reviewToUpdate, setReviewToUpdate] = useState(null);
  const [reviewListing, setReviewListing] = useState([]);
  const [isRejectModal, setIsRejectModal] = useState(false);
  const [isApproveModal, setIsApproveModal] = useState(false);
  const [reviewStats, setReviewStats] = useState({
    totalApprovedRequests: 0,
    totalRejectedRequests: 0,
    totalDeletedRequests: 0,
    totalInReviewRequests: 0
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [showFarmerDetails, setShowFarmerDetails] = useState(false);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);
  const dropdownRef = useRef(null);

  // Handle clicks outside the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveIndex(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleButtonClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  // function that is called when component is mount
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(`/farmer/review-requests`);
      setData(response?.data?.reviewRequests);
      if (response?.data?.reviewRequests?.length) {
        eventEmitter.emit("reviewRequest", { message: "fetchRequests" });
        // filterOut approved requests
        const reviews = specificReviewRequest(
          response?.data?.reviewRequests,
          activeTab
        );
        setReviewListing(reviews);
        const totalCount = statusTotalCount(response?.data?.reviewRequests);
        setReviewStats({
          totalApprovedRequests: totalCount?.approved,
          totalDeletedRequests: totalCount?.deleted,
          totalInReviewRequests: totalCount?.inReview,
          totalRejectedRequests: totalCount?.rejected
        });
      }
    } catch (error) {
      toast.error("Something went wrong");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
      setShowFarmerDetails(false);
    }
  };

  // search review requests by farmer name
  const searchReviewRequests = (event) => {
    try {
      const text = event.target.value?.toLowerCase().replace(/\s+/g, ""); // Remove all spaces from the search query
      setSearchQuery(text);

      const searchedResults = data?.filter((obj) => {
        const fullName = `${obj?.farmer?.firstName} ${obj?.farmer?.lastName}`
          .toLocaleLowerCase()
          .replace(/\s+/g, ""); // Remove all spaces from the full name

        const firstName = obj.farmer.firstName
          .toLowerCase()
          .replace(/\s+/g, ""); // Remove all spaces from the first name
        const lastName = obj.farmer.lastName.toLowerCase().replace(/\s+/g, ""); // Remove all spaces from the last name

        return (
          obj?.reviewStatus === activeTab &&
          (firstName.includes(text) ||
            lastName.includes(text) ||
            fullName.includes(text))
        );
      });

      setReviewListing(searchedResults);
    } catch (error) {}
  };

  // filter out listing review on specific status
  const specificReviewRequest = (reviews, status) => {
    if (reviews?.length) {
      const filteredReviews = reviews?.filter((review) => {
        return review?.reviewStatus == status;
      });
      return filteredReviews;
    } else {
      return [];
    }
  };

  // total count of specific status
  const statusTotalCount = (reviews) => {
    let approved = 0;
    let rejected = 0;
    let deleted = 0;
    let inReview = 0;
    if (reviews?.length) {
      reviews?.forEach((request) => {
        if (request?.reviewStatus === "pending") {
          inReview += 1;
        } else if (request?.reviewStatus === "approved") {
          approved += 1;
        } else if (request?.reviewStatus === "rejected") {
          rejected += 1;
        } else if (request?.reviewStatus === "deleted") {
          deleted += 1;
        } else {
          console.log("no review request match");
        }
      });
      return { approved, rejected, deleted, inReview };
    } else {
      return { approved, rejected, deleted, inReview };
    }
  };

  // update local states after data is updated into database
  const updateObjectInArray = (array, id, key, newValue) => {
    // Find index of the object with the specified id
    const index = array.findIndex((obj) => obj._id === id);

    // Check if the object is found
    if (index !== -1) {
      // Create a new updated array with the updated object
      return [
        ...array.slice(0, index),
        {
          ...array[index],
          [key]: newValue
        },
        ...array.slice(index + 1)
      ];
    }

    // If the object with the specified id is not found, return the original array
    return array;
  };

  // delete modal callback
  const updateModalStatus = (newState) => {
    setShow(newState);
    handleFarmer(reviewToUpdate, "deleted");
  };

  // reject modal callback
  const updateRejectModalStatus = (newState) => {
    handleFarmer(reviewToUpdate, "rejected", newState);
  };

  // approve modal callback
  const updateApproveModalStatus = (newState) => {
    setIsApproveModal(newState);
    handleFarmer(reviewToUpdate, "approved");
  };
  //

  const handleClose = (newState) => setShow(newState);
  const handleRejectModalClose = (newState) => setIsRejectModal(newState);
  const handleApproveModalClose = (newState) => setIsApproveModal(newState);

  // handle Edit user
  const handleFarmer = async (reviewRequest, status, reason) => {
    try {
      setIsUpdating(true);
      const formData = new FormData();
      formData.append("status", status);
      formData.append("declineReason", reason);
      const response = await axiosInstanceWithFormData.patch(
        `/farmer/update/review-request/${reviewRequest?._id}`,
        formData
      );
      if (response?.data?.success) {
        eventEmitter.emit("reviewRequest", { message: "fetchRequests" });
        // update current listing review array
        const newListingArray = reviewListing?.filter((review) => {
          return review?._id !== reviewRequest?._id;
        });
        setReviewListing(newListingArray);

        // update status in data array
        const newDataArray = updateObjectInArray(
          data,
          reviewRequest?._id,
          "reviewStatus",
          status
        );
        setData(newDataArray);
        const totalCount = statusTotalCount(newDataArray);
        setReviewStats({
          totalApprovedRequests: totalCount?.approved,
          totalDeletedRequests: totalCount?.deleted,
          totalInReviewRequests: totalCount?.inReview,
          totalRejectedRequests: totalCount?.rejected
        });
        toast.success(response?.data?.message);
        if (status === "approved") {
          fetchData();
        }
      } else {
        toast.error(response?.data?.message);
      }
      return;
    } catch (error) {
      console.log(error);
      toast.error(error?.message);
      return;
    } finally {
      setIsDropdownActive(false);
      setShow(false);
      setIsApproveModal(false);
      setIsRejectModal(false);
      setIsUpdating(false);
      setReviewToUpdate(null);
    }
  };

  const handleFarmerClick = (farmer) => {
    navigate(`/farmer/profile`, {
      state: {
        reviewPayload: farmer?.reviewPayload,
        farmer: farmer?.farmer,
        requestId: farmer?._id,
        reviewRequestStautsProp: farmer?.reviewStatus
      }
    });
  };

  // handle active tab logic
  const handleTabs = async (tab) => {
    setActiveTab(tab);
    setSearchQuery("");
    const reviews = specificReviewRequest(data, tab);
    setReviewListing(reviews);
  };

  const shortEmail = (email) => {
    return email?.substring(0, 20) + "..";
  };

  const shortName = (name) => {
    return name?.length > 20 ? name?.substring(0, 20) + ".." : name;
  };


  return (
    <>
      {show && (
        <DeleteRequestModal
          updateModalStatus={updateModalStatus}
          show={show}
          handleClose={handleClose}
        />
      )}
      {isRejectModal && (
        <RejectReviewRequestModal
          updateRejectModalStatus={updateRejectModalStatus}
          show={isRejectModal}
          handleRejectModalClose={handleRejectModalClose}
          userInfo={reviewToUpdate?.farmer}
        />
      )}
      {isApproveModal && (
        <ApproveReviewRequestModal
          updateApproveModalStatus={updateApproveModalStatus}
          show={isApproveModal}
          handleApproveModalClose={handleApproveModalClose}
          userInfo={reviewToUpdate?.farmer}
        />
      )}
      <section className="section-main">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="content-sec">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-7 mb-3 mb-xl-0">
                  <div className="nav nav-tabs products-tab farmer-tab">
                    <button
                      className={`nav-link ${
                        activeTab === "approved" ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      onClick={() => handleTabs("approved")}
                    >
                      Approved ({reviewStats?.totalApprovedRequests})
                    </button>
                    <button
                      className={`nav-link ${
                        activeTab === "rejected" ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      onClick={() => handleTabs("rejected")}
                    >
                      Rejected ({reviewStats?.totalRejectedRequests})
                    </button>

                    <button
                      className={`nav-link ${
                        activeTab === "pending" ? "active" : ""
                      }`}
                      data-bs-toggle="tab"
                      onClick={() => handleTabs("pending")}
                    >
                      In-review ({reviewStats?.totalInReviewRequests})
                    </button>
                  </div>
                </div>
                {user?.companyAdmin && (
                  <div className="col-xl-5">
                    <div className="d-flex justify-content-xl-end align-items-center gap-2">
                      <input
                        type="text"
                        className="form-control mb-0 review-request-search-bar"
                        placeholder="Search by name..."
                        onChange={searchReviewRequests}
                      />
                      <Link
                        to={"/add-farmer"}
                        className="btn btn-primary"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Add Farmer
                      </Link>
                    </div>
                  </div>
                )}
              </div>
              {reviewListing?.length > 0 ? (
                <div className="row py-3 py-lg-5">
                  {reviewListing?.map((item, index) => {
                    return (
                      <div className="col-12" key={index}>
                        <div
                          className="usercard"
                          style={{
                            color: isUpdating ? "grey" : "",
                            cursor: isUpdating ? "not-allowed" : ""
                          }}
                        >
                          <div
                            className="usercard-img"
                            onClick={() => handleFarmerClick(item)}
                          >
                            {
                            item?.reviewPayload?.pfp &&
                            item?.reviewPayload?.pfp !== "remove" ? (
                              <Link>
                                <img
                                  src={`${process.env.REACT_APP_SERVER_URL}/uploads/pfp/${item?.reviewPayload?.pfp}`}
                                  alt="user-img1"
                                />
                              </Link>
                            ) :(
                              <img src={userPlaceholder} />
                            )}
                          </div>
                          <div
                            className="usercard-content"
                            onClick={() => handleFarmerClick(item)}
                          >
                            <Link style={{ color: "rgba(32, 45, 70, 1)" }}>
                              <div className="user-name">
                                {shortName(
                                  `${item?.farmer?.firstName} ${item?.farmer?.lastName}`
                                )}
                              </div>
                            </Link>
                            {item?.farmer?.email?.length > 20 ? (
                              <div className="user-email">
                                <Link to={"#"}>
                                  {shortEmail(item?.farmer?.email)}
                                </Link>
                              </div>
                            ) : (
                              <div className="user-email">
                                <Link to={"#"}>{item?.farmer?.email}</Link>
                              </div>
                            )}
                            <Link>
                              <div className="user-post">Farmer</div>
                            </Link>
                          </div>

                          {activeTab === "pending" && (
                            <div className="dropdown mx-3" ref={dropdownRef}>
                              <button
                                className={`btn btn-secondary dropdown-toggle option-btn farmer-dropdown`}
                                type="button"
                                data-bs-toggle="dropdown"
                                aria-expanded={activeIndex === index}
                                style={{
                                  backgroundColor:
                                    activeIndex === index
                                      ? "rgba(241, 246, 255, 1)"
                                      : ""
                                }}
                              >
                                <i
                                  className="bi bi-three-dots-vertical"
                                  onClick={() => handleButtonClick(index)}
                                ></i>
                              </button>
                              <ul className="dropdown-menu">
                                <li
                                  onClick={() => {
                                    setIsApproveModal(true);
                                    setReviewToUpdate(item);
                                  }}
                                >
                                  <Link className="dropdown-item">
                                    <i class="fa-regular fa-circle-check"></i>
                                    Approved
                                  </Link>
                                </li>
                                <li
                                  onClick={() => {
                                    setIsRejectModal(true);
                                    setReviewToUpdate(item);
                                  }}
                                >
                                  <Link className="dropdown-item">
                                    <i class="fa-regular fa-circle-xmark"></i>
                                    Reject
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <>
                  <div className="d-flex justify-content-center align-items-center remove-scroll-bar">
                    <div>
                      <p>No Data Found</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </section>
    </>
  );
};
export default FarmerListing;
