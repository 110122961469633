import React, { useState,useEffect} from "react";
import { useSearchParams,useNavigate} from "react-router-dom";
import {toast} from "react-toastify"
import {axiosInstance} from "../../axiosConfig/axiosInstance";
const NewPassword = () => {
  const [inputFields, setInputFields] = useState({password:"",repassword:""})
  const [isPasswordShown, setIsPasswordShown] = useState(false)
  const [isRepasswordShown, setIsRepasswordShown]= useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams] =useSearchParams()
  const [userId, setUserId] = useState("")
  const navigate = useNavigate()
  const handleInputFields = (event)=>{
    let {name, value} = event.target;
    setInputFields({...inputFields, [name]:value})
  }
  useEffect(()=>{
    const verifyToken = async()=>{
      const token = await axiosInstance.post("/user/verifytoken",{token:searchParams.get("token")})
      if (token){
        setUserId(token?.data?.userId)
      }else{
        navigate("/login")
      }
    }
    verifyToken()
  },[searchParams.get("token")])
  const handleFormSubmission = async(event)=>{
    event.preventDefault()
    if (inputFields.password === inputFields.repassword){
      try{
        setIsLoading(true)
        const response = await axiosInstance.post("/user/resetpassword", {userId:userId,token:searchParams.get("token"),password:inputFields.password})
        if (response){
          toast.success(response?.data?.message)
          setIsLoading(false)
          navigate("/login")
        }else{
          setIsLoading(false)
        }
      }catch(error){
        setIsLoading(false)
        toast.error("Something went wrong")
        return
      }   
    }else{
      toast.error("Password does not match")
    }
  }
  return (
    <>
      <section className="section-main ms-0">
        <div className="login-signup-section d-flex align-items-center justify-content-center py-4">
          <div className="login-signup-form">
            <form onSubmit={handleFormSubmission}>
              <div className="form-title">Create new password</div>

              <div className="form-group">
                <div className="title-description">
                  New password must be different from previous used password.
                </div>
              </div>

              <div className="form-group form-floating">
                <input
                  type={isPasswordShown?"text":"password"}
                  id="password"
                  className="form-control"
                  placeholder="Password"
                  name="password"
                  required
                  onChange={handleInputFields}
                />
                <label>Password</label>
                <div
                  id="passwordbtn"
                  className={`show-hide-btn ${isPasswordShown ? "active":""}`}
                  data-target="password"
                  onClick={()=> setIsPasswordShown(!isPasswordShown)}
                >
                  <span>password-icon-show</span>
                </div>
              </div>

              <div className="form-group form-floating">
                <input
                  type={isRepasswordShown ? "text":"password"}
                  id="resetpassword"
                  className="form-control"
                  placeholder="Confirm Password"
                  name="repassword"
                  required
                  onChange={handleInputFields}
                />
                <label>Password</label>
                <div
                  id="passwordbtn"
                  className={`show-hide-btn ${isRepasswordShown ? "active":""}`}
                  data-target="resetpassword"
                  onClick={()=> setIsRepasswordShown(!isRepasswordShown)}
                >
                  <span>password-icon-show</span>
                </div>
              </div>

              <div className="form-group">
                <button type="submit" className="btn btn-primary">
                {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>:"Set a new password"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};
export default NewPassword;
