import React, { useState, useEffect, useContext, useMemo } from "react";
import Menu from "../Sidebar/Menu";
import LoginSignupHeader from "../Header";
import { authContext } from "../Context/AuthContext";
import { notfoundContext } from "../Context/NotFoundContext";
import { HeaderContext } from "../Context/HeaderContext";
import { useLocation } from "react-router-dom";
const ShowingHeader = (props) => {
  const [token, setToken] = useState(
    JSON.parse(localStorage.getItem("loggedinUser"))
  );
  const [headerDisplay, setHeaderDisplay]=useState(false)
  const { state } = useContext(authContext);
  const {is404}= useContext(notfoundContext)
  const location = useLocation()
  const { companyLogo } = useContext(HeaderContext);
  const [isLogoAvailable, setIsLogoAvailable] = useState(null);


  useEffect(() => {
    if (!token && state?.user) {
      setToken(state.user);
    }
  }, [token, state]);

  useEffect(()=>{
    setHeaderDisplay(is404)
  },[is404])
  
  useEffect(() => {
    setIsLogoAvailable(companyLogo);
  }, [companyLogo]);
  
    return <>{headerDisplay ? null : token &&  location.pathname.split("/")?.slice(0,4)?.join("/") !== "/product/getProduct/scan"? <Menu /> : isLogoAvailable ? null :<LoginSignupHeader  />}</>;
};
export default ShowingHeader;
