import { createContext, useState } from "react";

const notfoundContext = createContext({
    is404:false, 
    update404State:()=>{}
});
const NotFoundProvider = ({ children }) => {
  const [is404, set404] = useState(false);
  const update404State = newState => {
    set404(newState);
  };
  return (
    <notfoundContext.Provider value={{ is404, update404State }}>
      {children}
    </notfoundContext.Provider>
  );
};
export { notfoundContext, NotFoundProvider };
