import React from "react";

const DropdownProperty = ({selectedNetwork})=>{
    return(
    <div className = "d-flex align-items-center gap-2">
        <div>
            <img style = {{width:"17px"}} src = {selectedNetwork?.icon} alt = "network-icon"/>
        </div>
        <div>
            {selectedNetwork?.name}
        </div>
    </div>
    )

}
export default DropdownProperty