import React, { useState, useEffect, useContext } from "react";
import { authContext } from "../../../components/Context/AuthContext";
import Loader from "../../../components/Loader/Loader";
import {
  axiosInstance,
  axiosInstanceWithFormData
} from "../../../axiosConfig/axiosInstance";
import { toast } from "react-toastify";

import errorIcon from "../../../assets/images/farmer/error-icon.svg";
import { Link } from "react-router-dom";
import DeclineModal from "../../../components/Modals/declineModal";
import approvedIcon from "../../../assets/images/farmer/approved-status.svg";
import declinedIcon from "../../../assets/images/farmer/rejected-status.svg";
import reviewIcon from "../../../assets/images/farmer/review-status.svg";
import { useLocation, useNavigate } from "react-router-dom";
import RejectReviewRequestModal from "../../../components/Modals/rejectReviewRequestModal";
import CompanyFarmerProfileSection from "../../../components/FarmerComponents/Company/CompanyFarmerProfileSection";
import Viewprofile from "../../../components/FarmerComponents/Farmer/Viewprofile";
import eventEmitter from "../../../components/Events/eventEmitter";
import ApproveReviewRequestModal from "../../../components/Modals/approveReviewRequestModal";
import DeleteRequestModal from "../../../components/Modals/deleteReviewRequest"
const ViewProfile = () => {
  const { state } = useContext(authContext);
  const [user] = useState(
    JSON.parse(localStorage.getItem("loggedinUser")) || state.user
  );
  const [farmerProfile, setFarmerProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [reviewStatus, setReviewStatus] = useState(null);
  const [tipAmount, setTipAmount] = useState(0);
  const [isDeclineModalOpen, setDeclineModalOpen] = useState(false);
  const [profileStatus, setProfileStatus] = useState(null);
  const location = useLocation();
  const { reviewPayload, farmer, requestId, reviewRequestStautsProp } =
    location.state || {};

  const [buttonLoading, setButtonLoading] = useState(false);
  const [requestStatus, setRequestStatus] = useState("");
  const [declineReason, setDeclineReason] = useState("");
  const [isRejectedModalOpen, setIsRejectModalOpen] = useState(false);
  const [isApprovedModalOpen, setIsApprovedModalOpen]= useState(false)
  const [isDeletedModalOpen, setIsDeletedModalOpen]= useState(false)
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(true);
  const [specificReviewStatus, setSpecificReviewStatus]= useState("")
  const [buttonClass, setButtonClass]= useState("secondary")
  const navigate = useNavigate();

  useEffect(() => {
    fetchFarmerProfile();
    window.scrollTo(0, 0);
  }, []);

  const fetchFarmerProfile = async () => {
    try {
      setIsLoading(true);
      const userId = farmer ? farmer?._id : user?.userId;
      let response;
      if(user?.companyAdmin){
        response = await axiosInstance.get(`/farmer/profile/${userId}?reviewId=${requestId}`);
      }else{
        response = await axiosInstance.get(`/farmer/profile/${userId}`);
      }
      if (user?.isFarmer) {
        if (!response?.data?.isProfileCompleted) {
          navigate("/welcome");
          return;
        }
      }
      setFarmerProfile(response?.data?.user);
      setReviewStatus(response?.data?.reviewRequestStatus);
      setTipAmount(response?.data?.totalTip);
      
      if (response?.data?.reviewRequestStatus) {
        if (
          reviewRequestStautsProp === "approved" ||
          response?.data?.reviewRequestStatus?.reviewStatus === "approved"
        ) {
          setProfileStatus({
            className: "approved",
            imageClassName: "approved-icon",
            icon: approvedIcon,
            text: "Approved",
            textClassName: "approved"
          });
        } else if (
          response?.data?.reviewRequestStatus?.reviewStatus === "pending"
        ) {
          setProfileStatus({
            className: "underReview",
            imageClassName: "review-icon",
            icon: reviewIcon,
            text: "Under Review",
            textClassName: "review"
          });
        } else if (
          response?.data?.reviewRequestStatus?.reviewStatus === "rejected"
        ) {
          setProfileStatus({
            className: "rejected",
            imageClassName: "rejected-icon",
            icon: declinedIcon,
            text: "Rejected",
            textClassName: "rejected"
          });
        } else if (
          response?.data?.reviewRequestStatus?.reviewStatus === "deleted"
        ) {
          setProfileStatus({
            className: "rejected",
            imageClassName: "rejected-icon",
            icon: declinedIcon,
            text: "Deleted",
            textClassName: "rejected"
          });
        } else {
          setProfileStatus({
            className: "approved",
            imageClassName: "approved-icon",
            icon: approvedIcon,
            text: "Approved",
            textClassName: "approved"
          });
        }
      }

      // for admin 
      if(response?.data?.reviewRequestById){
        if(response?.data?.reviewRequestById?.reviewStatus === "approved"){
          setSpecificReviewStatus({
            className: "approved",
            imageClassName: "approved-icon",
            icon: approvedIcon,
            text: "Approved",
            textClassName: "approved"
          });
        }else if (response?.data?.reviewRequestById?.reviewStatus === "rejected"){
          setSpecificReviewStatus({
            className: "rejected",
            imageClassName: "rejected-icon",
            icon: declinedIcon,
            text: "Rejected",
            textClassName: "rejected"
          });
        }else if (response?.data?.reviewRequestById?.reviewStatus === "deleted"){
          setSpecificReviewStatus({
            className: "rejected",
            imageClassName: "rejected-icon",
            icon: declinedIcon,
            text: "Deleted",
            textClassName: "rejected"
          });
        }else if (response?.data?.reviewRequestById?.reviewStatus === "pending"){
          setSpecificReviewStatus({
            className: "underReview",
            imageClassName: "review-icon",
            icon: reviewIcon,
            text: "Under Review",
            textClassName: "review"
          });
        }else{
          setSpecificReviewStatus({
            className: "underReview",
            imageClassName: "review-icon",
            icon: reviewIcon,
            text: "Under Review",
            textClassName: "review"
          });
        }
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveReviewRequest = (event) => {
    event.preventDefault();
    if (requestStatus === "rejected") {
      setIsRejectModalOpen(true);
    }else if(requestStatus === "approved"){
      setIsApprovedModalOpen(true)
    } else if (requestStatus === "deleted"){
      setIsDeletedModalOpen(true)
    }else {
      updateReviewRequestStatus();
    }
  };

  //   update review request status
  const updateReviewRequestStatus = async (reason) => {
    try {
      setButtonLoading(true);
      const formData = new FormData();
      formData.append("status", requestStatus);
      formData.append("declineReason", reason);
      const response = await axiosInstanceWithFormData.patch(
        `/farmer/update/review-request/${requestId}`,
        formData
      );
      toast.success(response?.data?.message);
      eventEmitter.emit("reviewRequest", { message: "fetchRequests" });
      fetchFarmerProfile();
      return;
    } catch (error) {
      toast.error(error?.message);
      return;
    } finally {
      setButtonLoading(false);
      setDeclineReason("");
      setIsRejectModalOpen(false);
    }
  };

  const handleSelectChange = (event) => {
    setButtonClass("primary")
    setRequestStatus(event.target.value);
    setIsSaveButtonEnabled(false);
  };

  const updateRejectModalStatus = (newState) => {
    setDeclineReason(newState);
    updateReviewRequestStatus(newState);
  };

  const handleRejectModalClose = (newState) => setIsRejectModalOpen(newState);

  const updateDeclineReasonModalStatus = (newState) => {
    setDeclineModalOpen(newState);
  };

  const updateApproveModalStatus = (newState)=>{
    setIsApprovedModalOpen(newState)
    updateReviewRequestStatus()

  }
  
  const handleApproveModalClose = ()=> setIsApprovedModalOpen(false)

  const updateModalStatus = (newState)=>{
    setIsDeletedModalOpen(newState)
    updateReviewRequestStatus()
  }
  const handleClose = ()=> setIsDeletedModalOpen(false)

  return (
    <>
      {user ? (
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {isDeclineModalOpen && (
                <DeclineModal
                  show={isDeclineModalOpen}
                  updateDeclineReasonModalStatus={
                    updateDeclineReasonModalStatus
                  }
                  reason={reviewStatus?.declineReason}
                />
              )}

              {isRejectedModalOpen && (
                <RejectReviewRequestModal
                  show={isRejectedModalOpen}
                  userInfo={farmerProfile}
                  updateRejectModalStatus={updateRejectModalStatus}
                  handleRejectModalClose={handleRejectModalClose}
                />
              )}

              {isApprovedModalOpen && (
                <ApproveReviewRequestModal
                updateApproveModalStatus={updateApproveModalStatus}
                show={isApprovedModalOpen}
                handleApproveModalClose={handleApproveModalClose}
                userInfo={farmerProfile}
                />
              )}

              {isDeletedModalOpen && (
                <DeleteRequestModal
                updateModalStatus={updateModalStatus}
                show={isDeletedModalOpen}
                handleClose={handleClose}
                />
              )}

              <section className="section-main">
                {reviewStatus?.reviewStatus === "rejected" &&
                  user?.isFarmer && (
                    <div className="error-sec">
                      <img src={errorIcon} alt="error-icon" />
                      Your profile has been Rejected{" "}
                      <Link onClick={() => setDeclineModalOpen(true)}>
                        Show Details
                      </Link>
                    </div>
                  )}
                <div className="content-sec">
                  <div className="container-fluid">
                    {user?.companyAdmin && (
                      <div className="row">
                        <div className="col-lg-4 mb-3 mb-lg-0">
                          <div
                            className={`title-main d-flex align-items-center gap-2 gap-md-3 ${specificReviewStatus?.className}`}
                          >
                            <span>Profile</span>
                            <div className="profile-status-badge d-flex align-items-center gap-2">
                              <img
                                className={`${specificReviewStatus?.imageClassName}`}
                                src={specificReviewStatus?.icon}
                                alt="status-icon"
                              />
                              <span
                                className={`${specificReviewStatus?.textClassName}`}
                              >
                                {specificReviewStatus?.text}
                              </span>
                            </div>
                          </div>
                        </div>
                        {!["rejected","approved"].includes(specificReviewStatus?.textClassName) && (
                            <div className="col-lg-8">
                              <div className="profile-status-select d-flex align-items-center gap-2 justify-content-lg-end">
                                <span className="input-title">
                                  Change Status
                                </span>
                                <select
                                  className="details-select wide form-select mb-0 signup-select"
                                  id="my-select-background"
                                  onChange={handleSelectChange}
                                  style={{
                                    width: "164px",
                                    height: "46px",
                                    border: "1px solid rgba(206, 212, 218, 1)",
                                    borderRadius: "5px",
                                    // padding: "10px 16px",
                                    fontSize: "14pxs",
                                    backgroundColor: "#fff",
                                    outline: "none"
                                  }}
                                >
                                  <option value="Select" disabled selected>
                                    Select
                                  </option>
                                  <option value="approved">Approve</option>
                                  <option value="rejected">Reject</option>
                                </select>
                                {buttonLoading ? (
                                  <button className="btn btn-primary">
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  </button>
                                ) : (
                                  <button
                                    className={`btn btn-${buttonClass}`}
                                    disabled={isSaveButtonEnabled}
                                    onClick={handleSaveReviewRequest}
                                    style={{ width: "65px", height: "43px" }}
                                  >
                                    Save
                                  </button>
                                )}
                              </div>
                            </div>
                          )}
                      </div>
                    )}
                    {user?.isFarmer && (
                      <div className="row">
                        <div className="col-12 d-flex align-items-center justify-content-between profile-top">
                          <div className="title-main">Profile</div>
                          <div
                            className={`profile-status d-flex align-items-center gap-2 gap-md-3 ${profileStatus?.className}`}
                          >
                            <span>Profile Status</span>
                            <div className="profile-status-badge d-flex align-items-center gap-2">
                              <img
                                className={profileStatus?.imageClassName}
                                src={profileStatus?.icon}
                                alt="status-icon"
                              />
                              <span
                                className={`${profileStatus?.textClassName}`}
                              >
                                {profileStatus?.text}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-12">
                        {user?.companyAdmin && (
                          <CompanyFarmerProfileSection
                            reviewPayload={location?.state?.reviewPayload}
                            farmer={location?.state?.farmer}
                            specificReviewStatus={specificReviewStatus}
                            farmerProfile={farmerProfile}
                            tipAmount={tipAmount}
                            latestReview = {reviewStatus}
                          />
                        )}
                        {user?.isFarmer && (
                          <Viewprofile
                            tipAmount={tipAmount}
                            farmerProfile={farmerProfile}
                            reviewPayload={reviewStatus}
                            profileStatus={profileStatus}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      ) : (
        <p>Profile loading failed</p>
      )}
    </>
  );
};

export default ViewProfile;
