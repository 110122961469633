import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "react-toastify/dist/ReactToastify.css";
import "react-circular-progressbar/dist/styles.css";
import "./assets/css/main-style.css";
import "./assets/css/components-style/dashboard.css";
import "./assets/css/components-style/add-form.css";
import "./assets/css/components-style/product-table.css";
import "./assets/css/components-style/progress-round.css";
import "./assets/css/components-style/reporting.css";
import "./assets/css/components-style/usercard.css";
import "./assets/css/components-style/popup-css.css";
import "./assets/css/components-style/privacy-policy.css";
import "./assets/css/components-style/qr-modal.css";
import "./assets/css/components-style/rewards.css";
import "./assets/css/components-style/verify-user.css";
import "./assets/css/components-style/new-style.css";
import "./assets/css/components-style/farmer.css";
import "antd/dist/reset.css";
import ErrorBoundary from "./components/ErrorBoundry/ErrorBoundry"

const root = ReactDOM.createRoot(document.getElementById("root"));
const isLocal = process.env.REACT_APP_ENVIRONMENT === "local";
  root.render(
    isLocal ? (
      <ErrorBoundary>
        <React.StrictMode>
          <Router>
            <App />
          </Router>
        </React.StrictMode>
      </ErrorBoundary>
    ) : (
      <ErrorBoundary>
        <Router>
          <App />
        </Router>
      </ErrorBoundary>
    )
  );


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
