import React, { useContext, useEffect, useState } from "react";
import scanLogo from "../../assets/images/icons/scan.svg";
import { authContext } from "../Context/AuthContext";
import { axiosInstance } from "../../axiosConfig/axiosInstance";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import dashboardUserLogo from "../../assets/images/icons/dashboard-user.svg";
import dashboardCompanyLogo from "../../assets/images/icons/Company.svg";
import dashboardProductLogo from "../../assets/images/icons/Products.svg";
import Donut from "../Charts/Donut";
import ComparisonChart from "../Charts/ComparisonChart";

const Dashboard = () => {
  const [token, setToken] = useState(
    JSON.parse(localStorage.getItem("loggedinUser"))
  );
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const { state } = useContext(authContext);
  const [chartData, setChartData] = useState([
    ["Month", "Total Visitors", "Total Feedback"],
    ["Jan", 0, 0],
    ["Feb", 0, 0],
    ["Mar", 0, 0],
    ["Apr", 0, 0],
    ["May", 0, 0],
    ["Jun", 0, 0],
    ["Jul", 0, 0],
    ["Aug", 0, 0],
    ["Sep", 0, 0],
    ["Oct", 0, 0],
    ["Nov", 0, 0],
    ["Dec", 0, 0]
  ]);
  const [filter, setFilter] = useState("all");
  const [selectedFilter, setSelectedFilter] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!token && state?.user) {
      setToken(state.user);
    }
    fetchData();
  }, [token, state, filter]);

  // function that is called when component is mount
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/product/dashboard/stats?filter=${filter}`
      );
      if(response){
        setData(response?.data);
        setChartData(response?.data?.chartData);
        setFilter(filter);
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };


  const handleSelectChange = (event) => {
    setFilter(event.target.value);
    if (event.target.value === "thisMonth") {
      setSelectedFilter("This Month");
    } else if (event.target.value === "lastMonth") {
      setSelectedFilter("Last Month");
    } else {
      setSelectedFilter("All");
    }
  };

  return (
    <>
      <section className="section-main">
        {loading ? (
          <Loader />
        ) : (
          <div className="content-sec">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 d-flex align-items-center justify-content-between">
                  <div className="title-main">
                    Dashboard
                  </div>
                  <div className="col-lg-8">
                    <div className="profile-status-select d-flex align-items-center gap-2 justify-content-lg-end">
                      <select
                        className="details-select wide form-select mb-0 select-background"
                        id="my-select-background"
                        onChange={handleSelectChange}
                        style={{
                          width: "164px",
                          height: "42px",
                          border: "1px solid rgba(206, 212, 218, 1)",
                          borderRadius: "5px",
                          // padding: "10px 16px",
                          fontSize: "14pxs",
                          backgroundColor: "#fff",
                          outline: "none"
                        }}
                      >
                        <option value="Select" disabled selected>
                          {selectedFilter || "Select"}
                        </option>
                        <option value="all">All</option>
                        <option value="thisMonth">This Month</option>
                        <option value="lastMonth">Last Month</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12 dashboard-content">
                  <div className="row mb-3">
                    <div className="col-md-12 col-lg-12 col-xl-4 mb-3 mb-xl-0 mt-3">
                      <div className="total-sec h-100">
                        <div className="total-result mb-3 h-100">
                          <div className="cards-icon">
                            <img className="mb-3" src={scanLogo} alt="icon" />
                          </div>
                          <div className="scan-result-number">
                            {data?.totalScans}
                          </div>
                          <div className="reporting-title">Total Scans</div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-12 col-xl-4  mb-3 mb-xl-0 mt-3">
                      <div className="total-sec h-100">
                        <div className="total-result mb-3 h-100">
                          <div className="cards-icon">
                            <img
                              className="mb-3"
                              src={
                                token?.superAdmin
                                  ? dashboardCompanyLogo
                                  : dashboardUserLogo
                              }
                              alt="icon"
                            />
                          </div>
                          <div className="scan-result-number">
                            {data?.totalVisitors}
                          </div>
                          <div className="reporting-title">
                          Visitors with Location
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-12 col-xl-4 mb-3 mb-xl-0 mt-3">
                      <div className="total-sec h-100">
                        <div className="total-result mb-3 h-100">
                          <div className="cards-icon">
                            <img
                              className="mb-3"
                              src={dashboardProductLogo}
                              alt="icon"
                            />
                          </div>
                          <div className="scan-result-number">
                            {data?.totalProducts}
                          </div>
                          <div className="reporting-title">Total Products</div>
                        </div>
                      </div>
                    </div>

                    {/* charts */}
                    <div className="col-md-12 col-lg-12 col-xl-6 mb-3 mb-xl-0 mt-3">
                      <div className="total-sec graph-sec_total h-100">
                        <div className="total-result mb-3 h-100">
                          <Donut
                            localMinted={data?.totalLocalMinted || 0}
                            blockchainMinted={data?.totalMinted || 0}
                            totalDeployed={data?.totalDeployed || 0}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-12 col-xl-6 mb-3 mb-xl-0 mt-3">
                      <div className="total-sec graph-sec_total h-100">
                        <div className="total-result mb-3 h-100">
                          <ComparisonChart data={chartData} totalVisitors={data?.totalVisitors} totalFeedbacks = {data?.totalFeedbacks} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Dashboard;
