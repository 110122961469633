import React, { useState, useEffect } from "react";
import breadcrumbLogo from "../../assets/images/icons/breadcrumb-icon.svg";
import uploadProductLogo from "../../assets/images/icons/upload-product.svg";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstanceWithFormData } from "../../axiosConfig/axiosInstance";
import Loader from "../../components/Loader/Loader";
import ImageUploadProgress from "./ImageUploadProgress";
import { v4 as uuidv4 } from "uuid";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { MdClose } from "react-icons/md";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { Upload } from "antd";
import DraggableUploadListItem from "../../components/HOCs/DragAndDrop";
import dragAndDropLogo from "../../assets/images/icons/dragdrop.svg";
import { Country, City } from "country-state-city";
import { Dropdown, DropdownButton } from "react-bootstrap";
import userPlaceholder from "../../assets/images/icons/userplaceholder.png";


const EditProduct = () => {
  const [product, setProduct] = useState({
    name: "",
    brand: "",
    serialNum: "",
    SKU: "",
    description: "",
    nutritionImage: "",
    media: [],
    retainedImages: [],
    retainNutritionImage: ""
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [previewImages, setPreviewImages] = useState([]);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isData, setIsData] = useState(true);
  const [nutrition, setNutrition] = useState(null);
  const [newImages, setNewImages] = useState([]);
  const { id } = useParams();
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [editorToolBar] = useState({
    toolbar: ["bold", "italic", "bulletedList", "numberedList"]
  });
  const [isLocationAvailable, setIsLocationAvailable] = useState(false);
  const [activeLocation, setActiveLocation] = useState({
    label: "",
    country: "",
    city: "",
    countryCode: ""
  });
  const [isLocationEdit, setIsLocationEdit] = useState(false);
  const [placeholderCountry, setPlaceholderCountry] =
    useState("Select Country");
  const [cityPlaceholder, setCityPlaceholder] = useState("Select City");
  const [locations, setLocations] = useState([]);
  const [selectedFarmer, setSelectedFarmer] = useState(null);
  const [farmers, setFarmers] = useState([]);

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    // Load all countries when the component mounts
    const loadCountries = () => {
      const countries = Country.getAllCountries();
      setCountries(countries);
    };
    loadCountries();
  }, []);
  const fetchData = async () => {
    try {
      setIsLoading(true);
      let response = await axiosInstanceWithFormData(
        `/product/getproduct?productId=${id}`
      );
      if (response) {
        setCountries(Country.getAllCountries());
        setProduct({ ...response?.data?.product, media: [] });
        setLocations(response?.data?.product?.locations);
        if (response?.data?.farmers?.length) {
          const productFarmer = response?.data?.farmers?.filter((item) => {
            return item?.productId?.includes(id);
          });
          setSelectedFarmer(productFarmer[0]);
          setFarmers(response?.data?.farmers);
        }
        const { pictures } = response?.data?.product;
        if (pictures && pictures.length > 0) {
          setProduct({
            ...response?.data?.product,
            retainedImages: pictures,
            retainNutritionImage: "1",
            media: pictures
          });
          // Create an array of preview image objects
          const previewImageObjects = pictures.map(async (picture) => {
            const imgUrl = `${process.env.REACT_APP_SERVER_URL}/uploads/${response?.data?.product?.company}/${picture}`;
            const result = await fetch(imgUrl);
            // data is fetched successfully
            if (result?.ok) {
              const blob = await result.blob();
              const sizeInKBs = (blob.size / 1024).toFixed(2);
              return {
                uid: uuidv4(),
                image: imgUrl,
                size: sizeInKBs,
                name: picture
              };
            } else {
              return {
                uid: uuidv4(),
                image: imgUrl,
                size: 0,
                name: picture
              };
            }
          });
          // Wait for all promises to resolve
          const imageSizes = await Promise.all(previewImageObjects);
          setPreviewImages(imageSizes);
        } else {
          setPreviewImages([]); // or set a default value
        }
        return;
      }
    } catch (error) {
      toast.error("Something went wrong");
      return;
    } finally {
      setIsLoading(false);
    }
  };

  // countries input changing
  const onCountryChange = async (e) => {
    setIsData(false);
    const countryCities = City.getCitiesOfCountry(e.target.value);
    setCities(countryCities);
    setCityPlaceholder(countryCities[0]?.name);
    const country = Country.getCountryByCode(e.target.value);
    setPlaceholderCountry(country?.name);
    setActiveLocation({
      ...activeLocation,
      country: country?.name,
      city: countryCities[0]?.name,
      countryCode: country?.isoCode,
      lng: countryCities[0]?.longitude,
      ltd: countryCities[0]?.latitude
    });
  };

  // cities input
  const onCityChange = async (e) => {
    setIsData(false);
    const filteredCities = JSON.parse(e.target.value);
    setCityPlaceholder(filteredCities?.name);
    setActiveLocation({
      ...activeLocation,
      city: filteredCities?.name,
      lng: filteredCities?.longitude,
      ltd: filteredCities?.latitude
    });
  };

  // handle input fields
  const handleInputFields = (event) => {
    setIsData(false);
    const { name, value } = event.target;
    setProduct({ ...product, [name]: value });
  };

  // handle nutrition image
  const handleNutritionImage = (event) => {
    if (event.target.files?.length > 0) {
      setIsData(false);
      const { type, size, name } = event.target.files[0];
      if (
        type === "image/jpeg" ||
        type === "image/jpg" ||
        type === "image/png"
      ) {
        if (size <= 5 * 1024 * 1024) {
          setProduct({ ...product, nutritionImage: event.target.files[0] });
          const reader = new FileReader();
          reader.onload = (e) => {
            const newPreviewImage = {
              image: e.target.result,
              size: `${(size / 1024).toFixed(2)} KB`,
              name: name
            };
            setNutrition(newPreviewImage);
          };
          reader.readAsDataURL(event.target.files[0]);
        } else {
          toast.warn("File size must be less than 5MB");
        }
      } else {
        toast.warn("Only JPG and PNG files supported");
      }
    }
  };
  // handle upload image logic here
  const handleImageChange = (event) => {
    if (event.target?.files?.length > 0) {
      setIsData(false);
      const { type, size, name } = event.target.files[0];
      if (!(previewImages.length < 5)) {
        toast.warn("You can add only 5 pictures of the product");
        return;
      }

      if (
        type === "image/jpeg" ||
        type === "image/jpg" ||
        type === "image/png"
      ) {
        if (size <= 1 * 1024 * 1024) {
          // Update the product state with the new media item added to the existing media array
          setProduct((prevProduct) => ({
            ...prevProduct,
            media: [...(prevProduct?.media || []), event.target.files[0]],
            retainedImages: [
              ...(prevProduct?.retainedImages || []),
              event.target.files[0]
            ]
          }));
          // setNewFiles([...newFiles, event.target.files[0]])
          const reader = new FileReader();
          reader.onload = (e) => {
            const newPreviewImage = {
              uid: uuidv4(),
              image: e.target.result,
              size: `${(size / 1024).toFixed(2)} KB`,
              name: name,
              dbImage: 0
            };
            setNewImages((previous) => [...previous, newPreviewImage]);
            setPreviewImages((previous) => [...previous, newPreviewImage]);
          };

          reader.readAsDataURL(event.target.files[0]);
        } else {
          toast.warn("File size must be less than 1MB");
        }
      } else {
        toast.warn("Only JPG and PNG files supported");
      }
    }
  };

  const handleEditorChange = (event, editor) => {
    setIsData(false);
    const data = editor.getData();
    setProduct({ ...product, description: data });
  };

  // delete, added images
  const handleDeleteImage = (item) => {
    const filteredPreviewImages = previewImages?.filter((img) => {
      return img?.uid != item?.uid;
    });
    setIsData(false);
    setPreviewImages(filteredPreviewImages);
    const retainedImages = product?.retainedImages?.filter((img) => {
      return img !== item.name;
    });
    setProduct({
      ...product,
      retainedImages: retainedImages,
      media: retainedImages
    });
  };

  // add location button
  const handleAddLocationButton = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLocationAvailable(true);
    setIsLocationEdit(false);
    setActiveLocation({
      label: "",
      country: "",
      city: "",
      uid: "",
      countryCode: ""
    });
    setCityPlaceholder("Select City");
    setPlaceholderCountry("Select Country");
  };

  const handleCanelButtonClick = (event) => {
    event.stopPropagation();
    setIsLocationAvailable(false);
  };

  // handle save location
  const handleSaveLocationClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (
      !activeLocation?.label ||
      !activeLocation?.country ||
      !activeLocation?.city
    ) {
      toast.warn("All fields are required to add location");
      return;
    }
    const existingLocationIndex = locations.findIndex(
      (loc) => loc.uid === activeLocation.uid
    );
    if (existingLocationIndex !== -1) {
      // Update existing location
      let updatedLocations = [...locations];
      updatedLocations[existingLocationIndex] = activeLocation;
      setLocations(updatedLocations);
    } else {
      // Add new location
      setLocations([...locations, activeLocation]);
    }
    setIsLocationAvailable(false);
    setIsLocationEdit(false);
    setCityPlaceholder("Select City");
    setPlaceholderCountry("Select Country");
    setActiveLocation({ label: "", country: "", city: "", uid: "" });
  };

  const handleLocationLabelOnChange = (event) => {
    setIsData(false);
    const existingLocationIndex = locations.findIndex(
      (loc) => loc.uid === activeLocation.uid
    );
    if (existingLocationIndex !== -1) {
      setActiveLocation({
        ...activeLocation,
        label: event.target.value
      });
    } else {
      setActiveLocation({
        ...activeLocation,
        label: event.target.value,
        uid: uuidv4()
      });
    }
  };

  const handleFarmerSelection = (eventKey) => {
    setIsData(false);
    setSelectedFarmer(JSON.parse(eventKey));
  };

  const handleEditLocationClick = (location) => {
    setIsLocationAvailable(true);
    setIsLocationEdit(true);
    setActiveLocation(location);
    setCityPlaceholder(location?.city);
    setPlaceholderCountry(location?.country);
    setCities(City.getCitiesOfCountry(location?.countryCode));
  };

  const handleDeleteSavedLocationClick = (location) => {
    setIsData(false);
    const filteredLocations = locations?.filter(
      (item) => location?.uid != item?.uid
    );
    setLocations(filteredLocations);
  };

  // handle form submission
  const handleFormSubmission = async (event) => {
    event.preventDefault();
    try {
      if (!product?.media?.length) {
        return toast.warn("At least 1 picture of product is required");
      }

      if (locations?.length === 0) {
        toast.warn("Product tracking location is required");
        return;
      }

      let newRetainedImages = [];
      let uploadMedia = [];
      let allUploadedFiles = [];
      const formData = new FormData();
      if (product.media?.length > 0) {
        for (let i = 0; i < previewImages?.length; i++) {
          if (previewImages[i]?.image?.startsWith("data:image/")) {
            const newFile = product?.media?.find((item) => {
              if (item instanceof File) {
                if (item?.name === previewImages[i]?.name) {
                  allUploadedFiles.push(item?.name);
                  return item;
                }
              }
            });
            uploadMedia.push(newFile);
          } else {
            uploadMedia.push(previewImages[i]?.name);
            allUploadedFiles.push(previewImages[i]?.name);
            newRetainedImages.push(previewImages[i]?.name);
          }
        }
        for (let i = 0; i < uploadMedia?.length; i++) {
          formData.append("media", uploadMedia[i]);
        }
      }

      formData.append("name", product.name);
      formData.append("uploaded", allUploadedFiles);
      formData.append("brand", product.brand);
      formData.append("description", product.description);
      formData.append("productId", id);
      formData.append(
        "retainedImages",
        newRetainedImages ? newRetainedImages : []
      );
      formData.append("retainNutritionImage", product.retainNutritionImage);
      formData.append("nutritionImage", product.nutritionImage);
      formData.append("locations", JSON.stringify(locations));
      if (selectedFarmer) {
        formData.append("farmerId", selectedFarmer?._id);
      }
      setIsButtonLoading(true);
      const response = await axiosInstanceWithFormData.patch(
        "/product/edit",
        formData
      );
      setProduct({
        name: "",
        brand: "",
        description: "",
        nutritionImage: "",
        media: []
      });
      toast.success(response?.data?.message);
      if (response) {
        navigate("/products");
      } else {
        setProduct(product);
      }
      setIsButtonLoading(false);
      return;
    } catch (error) {
      toast.error("Something went wrong");
      setIsButtonLoading(false);
      return;
    }
  };

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10
    }
  });

  const onDragEnd = ({ active, over }) => {
    setIsData(false);
    if (active.id !== over?.id) {
      setPreviewImages((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };
  return (
    <>
      <section className="section-main">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="content-sec">
            <div className="row">
              <div className="col-12 d-flex align-items-center justify-content-between">
                <div className="breadcrumb-bar">
                  <ul className="nav align-items-center">
                    <li className="nav-item">
                      <Link to={"/products"}>Products</Link>
                    </li>
                    <li className="nav-item">
                      <img src={breadcrumbLogo} alt="icon" />
                    </li>
                    <li className="nav-item">Edit Product</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-section">
                  <div className="edit-profile-images">
                    <div className="upload-img">
                      <label>Upload Product Pictures</label>
                      <span className="required-asterisk">*</span>
                      <div className="image-section">
                        <div className="img-placeholder">
                          <div className="user-img-ic">
                            <img src={uploadProductLogo} alt="icon" />
                          </div>
                          <div className="title">
                            Drop files here or browse to upload
                          </div>
                          <div className="img-dimensions mt-2">
                            Up to 1 MB (JPG or PNG)
                          </div>
                          <input
                            id="upload-img-pu"
                            type="file"
                            onChange={handleImageChange}
                            style={{ cursor: "pointer", width: "100%" }}
                          />
                        </div>
                        <DndContext
                          sensors={[sensor]}
                          onDragEnd={onDragEnd}
                          className="w-100"
                        >
                          <SortableContext
                            items={previewImages.map((i) => i.uid)}
                            strategy={verticalListSortingStrategy}
                            className="sortable-btn w-100"
                          >
                            <Upload
                              fileList={previewImages}
                              onChange={handleImageChange}
                              showUploadList={false}
                              className="w-100"
                            ></Upload>
                            {previewImages.length > 0 &&
                              previewImages.map((file) => (
                                <DraggableUploadListItem
                                  key={file.uid}
                                  file={file}
                                  className="w-100"
                                  originNode={
                                    <div
                                      className="image-show"
                                      style={{ display: "block" }}
                                    >
                                      <div className="dragdrop-icon">
                                        <img
                                          src={dragAndDropLogo}
                                          alt="dragdrop-icon"
                                        />
                                      </div>
                                      <div className="img-details">
                                        <ul className="nav">
                                          <li className="nav-item">
                                            <div className="uploaded-image">
                                              <img
                                                className=""
                                                id="uploaded-image-preview"
                                                src={file.image}
                                                alt=""
                                              />
                                            </div>
                                          </li>
                                          <li className="nav-item">
                                            <div
                                              className="product-name"
                                              id="product-name"
                                            >
                                              {file.name?.length > 15 ? `${file?.name?.substring(0,15)}...` : file?.name}
                                            </div>
                                            <div
                                              className="product-size"
                                              id="product-size"
                                            >
                                              {file.size} KB
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                      <div>
                                        {/* Include your ImageUploadProgress component here */}
                                        <ImageUploadProgress
                                          newImages={newImages}
                                        />
                                      </div>
                                      <div
                                        className="close-sec"
                                        onClick={() => handleDeleteImage(file)}
                                      ></div>
                                    </div>
                                  }
                                />
                              ))}
                          </SortableContext>
                        </DndContext>
                      </div>
                    </div>
                  </div>
                  <div className="form-area">
                    <form onSubmit={handleFormSubmission}>
                      <div className="form-group">
                        <label>Product Name</label>
                        <span className="required-asterisk">*</span>
                        <input
                          type="text"
                          className="form-control"
                          value={product?.name}
                          required
                          name="name"
                          onChange={handleInputFields}
                        />
                      </div>
                      <div className="form-group">
                        <label>Brand</label>
                        <span className="required-asterisk">*</span>
                        <input
                          type="text"
                          className="form-control"
                          value={product?.brand}
                          required
                          name="brand"
                          onChange={handleInputFields}
                        />
                      </div>

                      <div className="form-group mt-3">
                        <label>Product Description</label>
                        <span className="required-asterisk">*</span>
                        {product?.description.length > 0 && (
                          <CKEditor
                            editor={ClassicEditor}
                            data={product?.description || ""}
                            id="editor"
                            className="form-control"
                            config={editorToolBar}
                            onChange={handleEditorChange}
                            autofocus
                            required
                          />
                        )}
                      </div>

                      <div className="form-group mt-3">
                        <div className="add-location-btn">
                          <button
                            className="btn btn-light"
                            onClick={handleAddLocationButton}
                          >
                            <i className="bi bi-plus me-1 "></i> Add Location
                            <span className="required-asterisk">*</span>
                          </button>
                        </div>

                        {isLocationAvailable && (
                          <div className="form-group location-col mt-3">
                            <div className="row mb-3">
                              <div className="col-md-4 mb-3 mb-md-0">
                                <label>Label</label>
                                <OverlayTrigger
                                  key={"tope"}
                                  placement={"top"}
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      To display the product journey, name each
                                      entry to specify its purpose, such as
                                      'Production,' 'Harvesting,' etc, and
                                      select the country and city.
                                    </Tooltip>
                                  }
                                >
                                  <span>
                                    <AiOutlineQuestionCircle
                                      style={{
                                        marginLeft: "10px",
                                        marginBottom: "2px"
                                      }}
                                    />
                                  </span>
                                </OverlayTrigger>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Ex: Manufactured"
                                  onChange={handleLocationLabelOnChange}
                                  value={activeLocation?.label}
                                  required
                                />
                              </div>
                              <div className="col-md-4 mb-3 mb-md-0">
                                <label>Country</label>
                                <select
                                  id="select-country"
                                  className="form-select select-country select-options"
                                  onChange={onCountryChange}
                                  value={placeholderCountry}
                                  required
                                >
                                  <option value={placeholderCountry}>
                                    {placeholderCountry}
                                  </option>
                                  {countries?.map((country, index) => {
                                    return (
                                      <option
                                        value={country?.isoCode}
                                        key={index}
                                      >
                                        {country?.name}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>
                              <div className="col-md-4 mb-3 mb-md-0">
                                <label>City</label>
                                <select
                                  id="select-country"
                                  className="form-select select-country select-options"
                                  onChange={onCityChange}
                                  value={cityPlaceholder}
                                  required
                                >
                                  <option value={cityPlaceholder}>
                                    {cityPlaceholder}
                                  </option>

                                  {activeLocation?.country &&
                                    cities.map((item, index) => {
                                      return (
                                        <option
                                          key={index}
                                          value={JSON.stringify(item)}
                                        >
                                          {item?.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 text-end">
                                <button
                                  className="btn btn-light me-2"
                                  onClick={handleCanelButtonClick}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="btn btn-secondary"
                                  onClick={handleSaveLocationClick}
                                >
                                  {isLocationEdit ? "Update" : "Save"}
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      {locations?.length ? (
                        <div className="from-group d-location">
                          <div className="row">
                            <div className="col-12">
                              <div className="table-responsive-sm products-table add-product-table">
                                <table className="table">
                                  <tr>
                                    <th>Label</th>
                                    <th>Country</th>
                                    <th>City</th>
                                    <th></th>
                                  </tr>
                                  {locations?.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{item?.label}</td>
                                        <td>{item?.country}</td>
                                        <td>{item?.city}</td>
                                        <td>
                                          <div className="dropdown">
                                            <button
                                              className="btn btn-secondary dropdown-toggle option-button"
                                              type="button"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <i className="bi bi-three-dots-vertical"></i>
                                            </button>
                                            <ul className="dropdown-menu">
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  onClick={() =>
                                                    handleEditLocationClick(
                                                      item
                                                    )
                                                  }
                                                >
                                                  <i className="bi bi-pencil"></i>
                                                  Edit
                                                </Link>
                                              </li>
                                              <li>
                                                <Link
                                                  className="dropdown-item"
                                                  onClick={() =>
                                                    handleDeleteSavedLocationClick(
                                                      item
                                                    )
                                                  }
                                                >
                                                  <i className="bi bi-trash"></i>
                                                  Delete
                                                </Link>
                                              </li>
                                            </ul>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="form-group mt-3">
                        <label>Specification Image</label>
                        <input
                          type="file"
                          className="form-control h-auto"
                          name="nutritionImage"
                          id="fileInput"
                          onChange={handleNutritionImage}
                        />
                        {nutrition || product?.nutritionImage ? (
                          <div style={{ position: "relative" }}>
                            <img
                              src={
                                nutrition
                                  ? nutrition?.image
                                  : `${process.env.REACT_APP_SERVER_URL}/uploads/${product?.company}/${product?.nutritionImage}`
                              }
                              alt=""
                              style={{
                                maxWidth: "200px",
                                maxHeight: "200px",
                                width: "auto",
                                height: "auto"
                              }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                top: "3px",
                                left: "177px",
                                width: "20px",
                                height: "20px",
                                borderRadius: "50%",
                                background: "#fff",
                                cursor: "pointer"
                              }}
                            >
                              <MdClose
                                style={{
                                  marginBottom: "5px",
                                  marginLeft: "2px"
                                }}
                                onClick={() => {
                                  setIsData(false);
                                  setNutrition(null);
                                  setProduct({
                                    ...product,
                                    nutritionImage: null,
                                    retainNutritionImage: ""
                                  });
                                  document.getElementById("fileInput").value =
                                    "";
                                }}
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>

                      {farmers?.length > 0 ? (
                        <div className="form-group add-farmer mt-3">
                          <label>Choose Farmer</label>
                          <DropdownButton
                            variant="farmer"
                            id="dropdown-basic-button"
                            title={
                              selectedFarmer
                                ? `${selectedFarmer?.firstName}-${selectedFarmer?.email}`
                                : ""
                            }
                            onSelect={handleFarmerSelection}
                          >
                            {farmers?.map((item, index) => {
                              return (
                                <Dropdown.Item
                                  key={index}
                                  eventKey={JSON.stringify(item)}
                                >
                                  <div className="user-icon-img">
                                    {item?.pfp ? (
                                      <img
                                        src={`${process.env.REACT_APP_SERVER_URL}/uploads/pfp/${item?.pfp}`}
                                        alt="profile-picture"
                                      />
                                    ) : (
                                     item?.firstName ? (
                                      item?.firstName.substring(0, 2)
                                     ) : (
                                      <img
                                      src={userPlaceholder}
                                      alt="profile-picture"
                                    />
                                     )
                                    )}
                                  </div>
                                  <div className="user-name-email">
                                    {item?.firstName}-{item?.email}
                                  </div>
                                </Dropdown.Item>
                              );
                            })}
                          </DropdownButton>
                        </div>
                      ) : null}

                      <div className="form-group mt-4 mt-lg-5 row">
                        <div className="col-8 pe-0">
                          {isButtonLoading ? (
                            <button className="btn btn-primary w-100" disabled>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-primary w-100"
                              disabled={isData}
                            >
                              Update Product
                            </button>
                          )}
                        </div>
                        <div className="col-4">
                          <button
                            className="btn btn-light me-3 w-100"
                            onClick={() => navigate("/products")}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};
export default EditProduct;
