import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import breadCrumb from "../../assets/images/icons/breadcrumb-icon.svg";
import Loader from "../../components/Loader/Loader";
import { toast } from "react-toastify";
import { axiosInstance } from "../../axiosConfig/axiosInstance";
import accessLogo from "../../assets/images/icons/access-granted.svg";
import revokeLogo from "../../assets/images/icons/access-revoked.svg";
import { BrowserProvider, Contract } from "ethers";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import contractABI from "../../contracts/abi.json";
import erc1155ABI from "../../contracts/abi1155.json";
import { encryptData } from "../../utils/encryption";
import { walletContext } from "../../components/Context/WalletConnectContext";
import accessLock from "../../assets/images/icons/accessLock.svg";
import userPlaceholder from "../../assets/images/icons/userplaceholder.png";
import productPlaceholder from "../../assets/images/products/product.png";

const ManageAccess = () => {
  const [user] = useState(JSON.parse(localStorage.getItem("loggedinUser")));
  const [deployedProducts, setDeployedProducts] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { walletConnect } = useContext(walletContext);
  const { walletProvider } = useWeb3ModalProvider(
    JSON.parse(sessionStorage.getItem("walletConfig"))
  );
  const { id } = useParams();
  const [connectedWallet, setConnectedWallet] = useState();

  useEffect(() => {
    setConnectedWallet(walletConnect);
  }, [walletConnect]);
  useEffect(() => {
    fetchDeployedProducts();
  }, [id]);

  const fetchDeployedProducts = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        `/company/deployed/products?userId=${id}`
      );
      setDeployedProducts(response?.data?.deployedProducts);
      setUserInfo(response?.data?.userInfo);
    } catch (error) {
      toast.error("Something went wrong");
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const handleMintingRevoke = async (product) => {
    try {
      if (!userInfo?.walletAddress) {
        toast.error("User wallet address not found in profile");
        return;
      }
      if (!connectedWallet?.isConnected) {
        toast.warn("Connect wallet for transaction");
        return;
      }
      if (connectedWallet?.address !== user?.walletAddress) {
        toast.warn(
          "Wallet mismatch! Your profile wallet is different from connected wallet"
        );
        return;
      }

      const loadingProduct = deployedProducts?.map((item) => {
        if (item?._id == product?._id) {
          return { ...item, isLoading: true };
        } else {
          return { ...item, isLoading: false };
        }
      });
      setDeployedProducts(loadingProduct);
      let result, payload;
      try {
        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();
        const contract = new Contract(
          product?.contractAddress,
          product?.standard === "erc721" ? contractABI : erc1155ABI,
          signer
        );
        const role = await contract.MINTER_ROLE();
        // revoke product minting role
        result = await contract.revokeRole(role, userInfo?.walletAddress);
        payload = {
          productId: product?._id,
          initiatedBy: user?.userId,
          user: id,
          accessType: "revoke",
          txHash: result?.hash,
          walletAddress: userInfo?.walletAddress,
          contractAddress: product?.contractAddress
        };
      } catch (error) {
        const loadingProduct = deployedProducts?.map((item) => {
          return { ...item, isLoading: false };
        });
        setDeployedProducts(loadingProduct);
        if (error?.info?.error?.message) {
          toast.error(error?.info?.error?.message);
        } else if (error?.error?.message) {
          toast.error(error?.error?.message);
        } else {
          toast.error(
            `Transaction failed. Please try again after some time or contact ${process.env.REACT_APP_SUPPORT_EMAIL}`
          );
        }
        return;
      }
      const encryptedData = encryptData(
        JSON.stringify(payload),
        process.env.REACT_APP_ENCRYPTION_KEY
      );
      const response = await axiosInstance.post("/product/minting/access", {
        encryptedData: encryptedData
      });
      if (response) {
        const updatedFilteredAccess = deployedProducts?.map((obj) => {
          if (product?._id == obj?._id) {
            return { ...obj, accessType: "revoke", isLoading: false };
          } else {
            return { ...obj };
          }
        });
        setDeployedProducts(updatedFilteredAccess);
      }
      toast.success(response?.data?.message);
      return;
    } catch (error) {
      toast.error(error?.message);
      return;
    }
  };

  const handleMintingAccess = async (product) => {
    try {
      if (!userInfo?.walletAddress) {
        toast.error("User wallet address not found in profile");
        return;
      }
      if (!connectedWallet?.isConnected) {
        toast.warn("Connect wallet for transaction");
        return;
      }
      if (connectedWallet?.address !== user?.walletAddress) {
        toast.warn(
          "Wallet mismatch! Your profile wallet is different from connected wallet"
        );
        return;
      }
      const loadingProduct = deployedProducts?.map((item) => {
        if (item?._id == product?._id) {
          return { ...item, isLoading: true };
        } else {
          return { ...item, isLoading: false };
        }
      });
      setDeployedProducts(loadingProduct);
      let payload;
      try {
        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();
        const contract = new Contract(
          product?.contractAddress,
          product?.standard === "erc721" ? contractABI : erc1155ABI,
          signer
        );
        const role = await contract.MINTER_ROLE();
        // grant product minting role
        const result = await contract.grantRole(role, userInfo?.walletAddress);
        payload = {
          productId: product?._id,
          initiatedBy: user?.userId,
          user: id,
          accessType: "access",
          txHash: result?.hash,
          walletAddress: userInfo?.walletAddress,
          contractAddress: product?.contractAddress
        };
      } catch (error) {
        const loadingProduct = deployedProducts?.map((item) => {
          return { ...item, isLoading: false };
        });
        setDeployedProducts(loadingProduct);
        console.log("minting access error", error?.message);
        if (error?.info?.error?.message) {
          toast.error(error?.info?.error?.message);
        } else if (error?.error?.message) {
          toast.error(error?.error?.message);
        } else {
          toast.error(
            `Transaction failed. Please try again after some time or contact ${process.env.REACT_APP_SUPPORT_EMAIL}`
          );
        }
        return;
      }
      const encryptedData = encryptData(
        JSON.stringify(payload),
        process.env.REACT_APP_ENCRYPTION_KEY
      );
      const response = await axiosInstance.post("/product/minting/access", {
        encryptedData: encryptedData
      });
      if (response) {
        const updatedFilteredAccess = deployedProducts?.map((obj) => {
          if (product?._id == obj?._id) {
            return { ...obj, accessType: "access", isLoading: false };
          } else {
            return { ...obj };
          }
        });
        setDeployedProducts(updatedFilteredAccess);
      }
      toast.success(response?.data?.message);
    } catch (error) {
      toast.error(error?.message);
      return;
    }
  };

  return (
    <>
      <section className="section-main">
        <div className="content-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 d-flex align-items-center justify-content-between">
                <div className="breadcrumb-bar">
                  <ul className="nav align-items-center">
                    <li className="nav-item">
                      <Link to={"/users"}>Users</Link>
                    </li>
                    <li className="nav-item">
                      <img src={breadCrumb} alt="icon" />
                    </li>
                    <li className="nav-item">
                      <span className="product-name">
                        {userInfo?.firstName}
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="report-header mt-4">
              <div className="row f-row">
                <div className="start-col">
                  <ul className="nav">
                    <li className="nav-item">
                      <div className="usercard-img">
                        {userInfo?.pfp ? (
                          <img
                            src={`${process.env.REACT_APP_SERVER_URL}/uploads/pfp/${userInfo?.pfp}`}
                            alt="user-img"
                            style={{
                              borderRadius: "50%",
                              width: "100px",
                              height: "100px",
                              overflow: "hidden"
                            }}
                          />
                        ) : (
                          <img
                            src={userPlaceholder}
                            style={{
                              borderRadius: "50%",
                              width: "100px",
                              height: "100px",
                              overflow: "hidden"
                            }}
                          />
                        )}
                      </div>
                    </li>
                    <li className="nav-item">
                      <div className="product-details">
                        <div className="name">{userInfo?.firstName}</div>
                        <div className="mb-2" style={{ color: "#3071F1" }}>
                          {userInfo?.email}
                        </div>
                        <div style={{ color: "grey" }}>Editor</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {deployedProducts?.length > 0 ? (
              <>
                {isLoading ? (
                  <Loader />
                ) : (
                  <div className="row">
                    <div className="col-12">
                      <div className="products-table pt-30">
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <b className="mb-3">Product Name</b>
                              <tr>
                                <th></th>
                              </tr>
                            </thead>
                            {deployedProducts?.map((product, index) => {
                              return (
                                <tbody key={index}>
                                  <tr>
                                    <td>
                                      <ul className="nav align-items-center">
                                        <li className="nav-item">
                                          <div className="product-img">
                                            {product?.pictures?.length ? (
                                              <img
                                                src={`${process.env.REACT_APP_SERVER_URL}/uploads/${product?.company}/${product?.pictures[0]}`}
                                              />
                                            ) : (
                                              <img src={productPlaceholder} />
                                            )}
                                          </div>
                                        </li>
                                        <li className="nav-item">
                                          {product?.name}
                                        </li>
                                      </ul>
                                    </td>

                                    <td>
                                      <div className="d-flex align-items-center justify-content-end">
                                        <div className="me-5">
                                          {product?.isLoading ? (
                                            <span
                                              className="spinner-border text-primary spinner-border-sm "
                                              role="status"
                                              aria-hidden="true"
                                            ></span>
                                          ) : product?.accessType ===
                                            "access" ? (
                                            <button
                                              className="btn btn-access-granted"
                                              style={{ maxWidth: "185px" }}
                                            >
                                              <img
                                                src={accessLogo}
                                                alt=""
                                                className="me-2"
                                              />
                                              Access Granted
                                            </button>
                                          ) : product?.accessType ===
                                            "revoke" ? (
                                            <button
                                              className="btn btn-access-revoked"
                                              style={{
                                                color: "#EF2929",
                                                background: "#FFE5E5",
                                                borderColor: "#EF2929",
                                                maxWidth: "185px",
                                                borderRadius: "4px"
                                              }}
                                            >
                                              <img
                                                src={revokeLogo}
                                                alt=""
                                                className="me-2"
                                              />
                                              Access Revoked
                                            </button>
                                          ) : null}
                                        </div>
                                        <div className="dropdown">
                                          <button
                                            className="btn btn-secondary dropdown-toggle option-btn"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="bi bi-three-dots-vertical"></i>
                                          </button>

                                          <ul
                                            className="dropdown-menu"
                                            style={{ width: "150px" }}
                                          >
                                            <>
                                              {product?.accessType ===
                                              "access" ? (
                                                <li
                                                  className="dropdown-item grant-access-hover"
                                                  onClick={() =>
                                                    handleMintingRevoke(product)
                                                  }
                                                  style={{
                                                    padding: "15px",
                                                    background: "#FFF",
                                                    color: "#EF2929",
                                                    fontWeight: 600,
                                                    fontSize: "14px"
                                                  }}
                                                >
                                                  <img
                                                    src={revokeLogo}
                                                    alt=""
                                                    className="me-2"
                                                  />
                                                  Revoke Access
                                                </li>
                                              ) : product?.accessType ===
                                                "revoke" ? (
                                                <li
                                                  className="dropdown-item grant-access-hover"
                                                  onClick={() =>
                                                    handleMintingAccess(product)
                                                  }
                                                  style={{
                                                    padding: "15px",
                                                    background: "#FFF",
                                                    fontWeight: 600,
                                                    fontSize: "14px"
                                                  }}
                                                >
                                                  <img
                                                    src={accessLock}
                                                    alt=""
                                                    className="me-2 "
                                                    style={{
                                                      color: "#3071F133"
                                                    }}
                                                  />
                                                  Grant Access
                                                </li>
                                              ) : (
                                                <li
                                                  className="dropdown-item grant-access-hover"
                                                  onClick={() =>
                                                    handleMintingAccess(product)
                                                  }
                                                  style={{
                                                    padding: "15px",
                                                    background: "#FFF",
                                                    fontWeight: 600,
                                                    fontSize: "14px"
                                                  }}
                                                >
                                                  <img
                                                    src={accessLock}
                                                    alt=""
                                                    className="me-2"
                                                  />
                                                  Grant access
                                                </li>
                                              )}
                                            </>
                                          </ul>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div
                  style={{ height: "60vh" }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <div>
                    <p>No Deployed Product Found</p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};
export default ManageAccess;
