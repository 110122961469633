import React, { useContext, useState, useEffect } from "react";
import dropdown from "../../assets/images/icons/Vector-dropdown.svg";
import { Link, useNavigate,useLocation } from "react-router-dom";
import { authContext } from "../Context/AuthContext";
import { walletContext } from "../Context/WalletConnectContext";
import connectWalletLogo from "../../assets/images/icons/connect-icon.svg";
import disconnectWalletLogo from "../../assets/images/icons/disconnect-icon.svg";
import {
  createWeb3Modal,
  defaultConfig,
  useWeb3ModalAccount,
  useWeb3Modal,
  useDisconnect,
  useWeb3ModalEvents,
  useSwitchNetwork
} from "@web3modal/ethers/react";
import userPlaceholder from "../../assets/images/icons/userplaceholder.png";


const Navbar = ({ onMenuClick }) => {
  const { state } = useContext(authContext);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("loggedinUser")) || state?.user
  );

 
  const [isSwitch, setIsSwitch] = useState(false);
  const chains = [
    {
      chainId: Number(user?.networkSetting?.chainId),
      name: user?.networkSetting?.name,
      currency: user?.networkSetting?.currency,
      explorerUrl: user?.networkSetting?.explorerUrl,
      rpcUrl: user?.networkSetting?.rpcUrl
    }
  ];
  const { updateWallet } = useContext(walletContext);

  const navigate = useNavigate();

  const projectId = process.env.REACT_APP_METAMASK_PROJECT_ID;
  const { address, chainId, isConnected } = useWeb3ModalAccount();


  const ethersConfig = defaultConfig({
    metadata: {
      name: "Scan and Trace",
      description:
        "Scan and trace connection established",
      url: "https://web3modal.com",
      icons: [
        "https://drive.google.com/file/d/19BBUCil8jHTf872DXutrg2V4DQsMEjJr/view?usp=sharing"
      ]
    },
    defaultChain: chains[0]
  });


  createWeb3Modal({
    ethersConfig,
    chains,
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    allWallets: "ONLY_MOBILE",
    featuredWalletIds: []
  });
  const events = useWeb3ModalEvents();

  const { open, close } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { switchNetwork } = useSwitchNetwork();

  useEffect(() => {
    const fetchData = async () => {
      if (isConnected) {
        try {
          if (chains[0]?.chainId !== chainId) {
            setIsSwitch(true);
            await switchNetwork(chains[0]?.chainId);
            setIsSwitch(false);
            close();
          }
        } catch (error) {
          console.log("error in switch", error);
          setIsSwitch(false);
          close();
          disconnect();
        }
        await signerFunction();
      } else {
        sessionStorage.removeItem("walletConfig");
        updateWallet({});
      }
    };
    fetchData();
  }, [isConnected, address, chainId]);

  useEffect(() => {
    if (isSwitch && events?.data?.event === "MODAL_CLOSE") {
      disconnect();
    }
  }, [events?.data?.event]);

  useEffect(() => {
    if (state?.user) {
      setUser(state.user);
    }
  }, [state]);


  // change title dynamically
  useEffect (()=>{
    document.title = user?.companyName
  },[user])

  // change favicon dynamically
  useEffect(()=>{
    let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = `${process.env.REACT_APP_SERVER_URL}/uploads/companyLogos/${user?.companyLogo}`;
    document.getElementsByTagName('head')[0].appendChild(link);
  },[user])

  

  const handleButtonClick = () => {
    onMenuClick(true);
  };
  const signerFunction = async () => {
    sessionStorage.setItem(
      "walletConfig",
      JSON.stringify({
        address: address,
        chainId: Number(chainId),
        isConnected: isConnected,
        pendingSession: false
      })
    );
    updateWallet({
      address: address,
      chainId: Number(chainId),
      isConnected: isConnected,
      pendingSession: false
    });
  };

  return (
    <>
      {user && (
        <section className="header-top">
          <div className="container-fluid px-md-0">
            <div className="row">
              <div className="col-12">
                <div className="d-flex justify-content-between align-items-center">
                  <div
                    className="menu-btn-mobile d-flex d-md-none align-items-center"
                    id="open-menu"
                    onClick={handleButtonClick}
                  >
                    <div className="menu-btn me-2">
                      <i className="bi bi-list"></i>
                    </div>
                    <div className="menu-title">Menu</div>
                  </div>
                  
                  
                  <div className="connect-wallet d-none d-md-flex wallet-header">
                    {!isConnected ? (
                      <>
                        <button
                          className="btn btn-connect"
                          onClick={() => open()}
                        >
                          <img src={connectWalletLogo} alt="icon" /> Connect
                          Wallet
                        </button>

                        <div
                          id="gb-widget-3951"
                          style={{
                            bottom: "14px",
                            right: "16px",
                            opacity: 1,
                            transition: "opacity 0.5s ease 0s",
                            boxSizing: "border-box",
                            direction: "ltr",
                            textAlign: "right",
                            position: "fixed",
                            zIndex: "16000160"
                          }}
                        >
                          <div
                            className="q8c6tt-2 eiGYSu"
                            style={{
                              display: "flex",
                              margin: "7px",
                              position: "relative",
                              WebkitBoxPack: "end",
                              justifyContent: "flex-end",
                              maxHeight: "50px"
                            }}
                          >
                            <a
                              size="50"
                              href="https://wa.me/971523531533"
                              target="_blank"
                              color="#4dc247"
                              id="getbutton-whatsapp"
                              className="q8c6tt-0 jWcIXO"
                              style={{
                                flexShrink: 0,
                                width: "50px",
                                height: "50px",
                                order: 2,
                                padding: "5px",
                                boxSizing: "border-box",
                                borderRadius: "50%",
                                cursor: "pointer",
                                overflow: "hidden",
                                boxShadow: "rgba(0, 0, 0, 0.4) 2px 2px 6px",
                                transition: "all 0.5s ease 0s",
                                position: "relative",
                                zIndex: 200,
                                display: "block",
                                border: "0px",
                                backgroundColor: "rgb(77, 194, 71)"
                              }}
                            >
                              <svg
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                                xlink="http://www.w3.org/1999/xlink"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  fill: "rgb(255, 255, 255)",
                                  stroke: "none"
                                }}
                              >
                                <path d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z"></path>
                              </svg>
                            </a>
                          </div>
                        </div>
                      </>
                    ) : (
                      <div className="wallet-connected">
                        <div className="wallet-details">
                          <div className="c-status">
                            <span></span> Wallet Connected
                          </div>
                          {address?.substring(0, 12)}.....
                          {address?.substring(address?.length - 12)}
                        </div>
                        <button
                          className="btn btn-disconnect"
                          onClick={() => open()}
                        >
                          <img
                            src={disconnectWalletLogo}
                            alt="disconnect-icon"
                          />
                          Disconnect
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="dropdown admin-dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle admin-btn px-0"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <span className="admin-img">
                        {user.profilePic ? (
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              borderRadius: "50%"
                            }}
                            src={`${process.env.REACT_APP_SERVER_URL}/uploads/pfp/${user?.profilePic}`}
                          />
                        ) : user?.firstName ?
                          user?.firstName?.charAt(0):
                        <img src={userPlaceholder} alt="user-placeholder" style={{height:"30px", width:"30px"}} />
                        }
                      </span>
                      {user?.firstName}
                      <img
                        className="dd-icon"
                        src={dropdown}
                        alt="icon-dropdown"
                      />
                    </button>
                    <ul className="dropdown-menu">
                      <li onClick={() => {
                        if(user?.isFarmer){
                          navigate("/farmer/create/review-request")
                        }else{
                          navigate("/edit-profile")
                        }
                        }}>
                        <Link className="dropdown-item" to={"#"}>
                          Edit Profile
                        </Link>
                      </li>

                      <li
                      onClick={() => {
                        localStorage.removeItem("loggedinUser");
                        sessionStorage.removeItem("walletConfig");
                        disconnect();
                        window.location.href="/login"
                      }}
                      >
                        <Link className="dropdown-item"
                        >
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) }
    </>
  );
};
export default Navbar;
