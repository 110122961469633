import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";

const ComparisonChart = ({ data, totalVisitors, totalFeedbacks }) => {
  
  // Calculate maximum value from data for dynamic y-axis scaling
  const getMaxValue = (data) => {
    let maxValue = 0;
    for (let i = 1; i < data.length; i++) {
      const totalVisitors = data[i][1];
      const totalFeedbacks = data[i][2];
      if (totalVisitors > maxValue) maxValue = totalVisitors;
      if (totalFeedbacks > maxValue) maxValue = totalFeedbacks;
    }
    return maxValue;
  };

  const maxValue = getMaxValue(data);

  // Handle case where maxValue is 0
  const yAxisTicks = maxValue > 0
    ? Array.from({ length: 6 }, (_, i) => i * Math.ceil(maxValue / 5))
    : [0, 1, 2, 3, 4, 5]; // Default ticks when maxValue is 0


  const options = {
    hAxis: {
      slantedText: true, // Optional: to prevent overlap of month names
    },
    vAxis: {
      ticks: yAxisTicks, // Adjust this list as needed
    },
    legend: { position: "none" }, // Hide default legend
    series: {
      0: { color: "#14cca1" }, // Color for Total Scans
      1: { color: "#3071f1" } // Color for Feedback
    }
  };

  return (
    <div className="chart-container">
      <Chart
        chartType="ColumnChart"
        width="100%" // Adjust width as needed
        height="300px" // Adjust height as needed
        data={data}
        options={options}
        className="chart"
        style={{margin:"auto"}}
      />
      <div className="custom-legend comparison">
        <div className="s-item">
          <div className="s-numb color-box" style={{ color: "#1b9e77" }}>
            {totalVisitors}
          </div>
          <div className="s-name">Total Visitors</div>
          {/* Assuming the first value is Total Visitors */}
        </div>
        <div className="s-item">
          <div className="s-numb color-box" style={{ color: "#3071f1" }}>
            {totalFeedbacks}
          </div>
          <div className="s-name">Feedback</div>
          {/* Assuming the second value is Feedback */}
        </div>
      </div>
    </div>
  );
};

export default ComparisonChart;
