import React, { useState, useEffect } from "react";

const NetworkStatus = ({ children }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [effectiveType, setEffectiveType] = useState(null);

  useEffect(() => {
    const updateNetworkStatus = () => {
      setIsOnline(navigator.onLine);
      const connection =
        navigator.connection ||
        navigator.mozConnection ||
        navigator.webkitConnection;
      if (connection) {
        setEffectiveType(connection.effectiveType);
      }
    };

    updateNetworkStatus();

    window.addEventListener("online", updateNetworkStatus);
    window.addEventListener("offline", updateNetworkStatus);

    const connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;
    if (connection) {
      connection.addEventListener("change", updateNetworkStatus);
    }

    return () => {
      window.removeEventListener("online", updateNetworkStatus);
      window.removeEventListener("offline", updateNetworkStatus);

      if (connection) {
        connection.removeEventListener("change", updateNetworkStatus);
      }
    };
  }, []);

  if (!isOnline) {
    return <div style={{display:"flex", justifyContent:"center", alignItems:"center", minHeight:"100vh"}}>
        <p>
        Oops! You are offline. Please check your internet connection.
            </p> 
    </div>;
  }

//   if (effectiveType && effectiveType !== "4g") {
//     return <div>Your connection is slow: {effectiveType}</div>;
//   }

  return <>{children}</>;
};

export default NetworkStatus;
