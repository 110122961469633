import React from "react";
import { Chart } from "react-google-charts";

const options = {
  pieHole: 0.5,
  is3D: false,
  legend: { position: "none" }, // Hides the default legend
  chartArea: { width: "90%", height: "80%" }, // Adjust the chart area to make room for custom legend
  slices: [
    { color: "#1b97ce" }, // Custom color for 'Work'
    { color: "#42cbce" }, // Custom color for 'Watch TV'
    { color: "#0c60de" } // Custom color for 'Sleep'
  ]
};

const Donut = ({ localMinted, blockchainMinted, totalDeployed }) => {
  const sampleData = [
    ["Products", "Stats"],
    ["Locally Minted", localMinted],
    ["Minted On Blockchain", blockchainMinted],
    ["Contract Deployed", totalDeployed]
  ];

  // Check if there is data
  const hasData = sampleData.slice(1).some(item => item[1] > 0);

  return (
    <div className="w-100">
      {hasData ? (
        <Chart
          chartType="PieChart"
          width="100%"
          height="300px"
          style={{ margin: "auto" }}
          data={sampleData}
          options={options}
        />
      ) : (
        <div className="empty-donut">
          <Chart
            chartType="PieChart"
            width="100%"
            height="300px"
            style={{ margin: "auto" }}
            data={[
              ["Products", "Stats"],
              ["No Data", 1]
            ]}
            options={{
              ...options,
              slices: [{ color: "#ccc" }], // Gray color for empty donut
              pieHole: 0.5,
              chartArea: { width: "90%", height: "80%" },
              legend: { position: "none" }
            }}
          />
          <div className="no-data-message">No data available</div>
        </div>
      )}
      {hasData && (
        <div className="custom-legend">
          {sampleData.slice(1).map((item, index) => (
            <div className="s-item" key={index}>
              <div
                className="s-numb"
                style={{
                  color: options.slices[index].color
                }}
              >
                {item[1]}
              </div>
              <div className="s-name">{item[0]}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Donut;
