import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "../../../axiosConfig/axiosInstance";
import { toast } from "react-toastify";
import { Pagination, PaginationItem } from "@mui/material";
import Loader from "../../../components/Loader/Loader";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const CompaniesListing = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage] = useState(15);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("loggedinUser"));
  const [activeTab, setActiveTab] = useState("Approved Companies");
  const [totalCount, setTotalCount]= useState(0)
  const [totalApproveCompanies, setTotalApproveCompanies]=useState(0)
  const [totalDisapproveCompanies, setTotalDisapproveCompanies]= useState(0)
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  
  // get all companies listing
  useEffect(() => {
    fetchData();
  }, [currentPage,activeTab]);

  // function that is called when component is mount
  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/company/getall?page=${currentPage}&perPage=${perPage}&approvedFilter=${
          activeTab== "Approved Companies" ? 1 : 0
        }`
      );
      setData(response.data?.users);
      setTotalPages(response?.data?.totalPages);
      // setCurrentPage();
      setTotalCount(response?.data?.totalCount)
      setTotalApproveCompanies(response?.data?.totalApproveCompanies)
      setTotalDisapproveCompanies(response?.data?.totalDisapproveCompanies)
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };
   // handle active tab logic
   const handleTabs = async (tab) => {
    setActiveTab(tab);
    setCurrentPage(1)
  };
 
  // handle pagination
  const handlePagination = async (event, page) => {
    if (page < 1 || page > totalPages) {
      return; // Prevent clicking on disabled pages
    }
    // Calculate the new startPage based on the clicked page
    setCurrentPage(page);
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/company/getall?page=${page}&perPage=${perPage}&approvedFilter=${
          activeTab== "Approved Companies" ? 1 : 0
        }`
      );
      setData(response.data?.users);
      setTotalPages(response?.data?.totalPages);
      setTotalCount(response?.data?.totalCount)
      setTotalApproveCompanies(response?.data?.totalApproveCompanies)
      setTotalDisapproveCompanies(response?.data?.totalDisapproveCompanies)
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  // checkbox logic i.e. if company is approved make it un-approved and vice versa
  const handleCheckboxChange = async (index) => {
    if (!user?.superAdmin) {
      toast.error("Unauthorized access");
      navigate("/login");
      return;
    }
    // Create a copy of the data to modify
    const updatedData = [...data];
    updatedData[index].isApproved = !updatedData[index].isApproved; // Toggle the isApproved value
    setIsUpdating(true);
    try {
      // update isApproved into Backend as well
      const response = await axiosInstance.patch("/user/editapproval", {
        _id: updatedData[index]?._id
      });
      // Update the state with the modified data
      if (activeTab === "Disapproved Companies") {
        const filterData = updatedData.filter(
          (item) => item._id !== updatedData[index]?._id
        );
        setData(filterData);
      } else if (activeTab === "Approved Companies") {
        const filterData = updatedData.filter(
          (item) => item._id !== updatedData[index]?._id
        );
        setData(filterData);
      }
      toast.success(response?.data?.message);
      setTotalCount(response?.data?.totalCount)
      setTotalApproveCompanies(response?.data?.totalApproveCompanies)
      setTotalDisapproveCompanies(response?.data?.totalDisapproveCompanies)
      setCurrentPage(1)
      setIsUpdating(false);
    } catch (error) {
      setIsUpdating(false);
      toast.error("Something went wrong");
    }
  };

  const handleItemMouseEnter = (event, page) => {
    if (page !== currentPage) {
      event.target.style.color = "white"; // Change color on hover for other pages
      // event.target.style.background = "#202D46";
    }
  };

  const handleItemMouseLeave = (event, page) => {
    if (page !== currentPage) {
      event.target.style.color = ""; // Restore default color on mouse leave
      // event.target.style.background = "";
    }
  };

  return (
    <>
      {loading ? (
        <>
          <Loader />
        </>
      ) : (
        <section className="section-main">
          <div className="content-sec">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 d-flex align-items-center justify-content-between">
                  <div className="title-main">
                     <div className="nav nav-tabs products-tab">
                      <button
                        className={`nav-link ${
                          activeTab === "Approved Companies" ? "active" : ""
                        }`}
                        data-bs-toggle="tab"
                        onClick={() => handleTabs("Approved Companies")}
                      >
                        Approved Companies ({totalApproveCompanies})
                      </button>
                      <button
                        className={`nav-link ${
                          activeTab === "Disapproved Companies" ? "active" : ""
                        }`}
                        data-bs-toggle="tab"
                        onClick={() => handleTabs("Disapproved Companies")}
                      >
                        Disapproved Companies ({totalDisapproveCompanies})
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="products-table pt-30">
                    <div className="table-responsive">
                      {data?.length > 0 ? (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Company</th>
                              <th>Approval</th>
                              <th></th>
                            </tr>
                          </thead>
                            <tbody>
                              {data?.map((item, index) => {
                                return (
                                  // <>
                                    <tr key={index}>
                                      <td>{`${item?.firstName} ${item?.lastName}`}</td>
                                      <td>
                                        <ul className="nav align-items-center">
                                          <li className="nav-item">
                                            {item?.email}
                                          </li>
                                        </ul>
                                      </td>
                                      <td>{item?.companyName}</td>
                                      <td>
                                        <div className="form-check form-switch">
                                          <input
                                            className="form-check-input checkbox-company"
                                            type="checkbox"
                                            id="flexSwitchCheckDefault"
                                            checked={item?.isApproved}
                                            onChange={() =>
                                              handleCheckboxChange(index)
                                            }
                                            disabled={isUpdating}
                                          />
                                        </div>
                                      </td>
                                    </tr>
                                  // {/* </> */}
                                );
                              })}
                            </tbody>
                        </table>
                      ) : (
                          <div className="d-flex justify-content-center align-items-center remove-scroll-bar">
                            <div>
                              <p>No Data Found</p>
                            </div>
                          </div>
                      )}
                     
                    </div>
                    {
                      totalCount > perPage ? (
                        <div className="table-footer row">
                        <div className="col-12">
                          <ul className="nav align-items-center justify-content-end">
                            <Pagination
                              count={totalPages}
                              page={currentPage}
                              shape="rounded"
                              onChange={handlePagination}
                              siblingCount={isMobile ? 0 : 2}
                              boundaryCount={isMobile ? 0 : 2}
                              renderItem={(item) => (
                                <PaginationItem
                                  {...item}
                                  onMouseEnter={(e) =>
                                    handleItemMouseEnter(e, item.page)
                                  }
                                  onMouseLeave={(e) =>
                                    handleItemMouseLeave(e, item.page)
                                  }
                                  style={{
                                    background:
                                      item.page === currentPage
                                        ? "#202D46"
                                        : "",
                                    color:
                                      item.page === currentPage
                                        ? "white"
                                        : "#9FA1A1"
                                  }}
                                />
                              )}
                            />
                          </ul>
                        </div>
                      </div>
                      ):null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
export default CompaniesListing;
