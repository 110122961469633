import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import userPlaceholder from "../../assets/images/icons/userplaceholder.png";
import rewardIcon from "../../assets/images/verify/reward-icon.svg";
import cancelButton from "../../assets/images/verify/cancel-farmer.svg";
import DOMPurify from "dompurify";

const FarmerModal = ({
  farmer,
  companyId,
  updateSupportModalStatus,
  closeFarmerModal
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const thumbContainerRef = useRef(null);

  useEffect(() => {
    if(farmer?.farmPictures?.length > 0){
      scrollThumbnails(activeIndex);
    }
  }, [activeIndex]);

  const handleNextSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === farmer?.farmPictures?.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePreviousSlide = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? farmer?.farmPictures?.length - 1 : prevIndex - 1
    );
  };

  const scrollThumbnails = (index) => {
    try{
      if (thumbContainerRef.current) {
        const thumbContainer = thumbContainerRef.current;
        const thumbWidth = thumbContainer.children[0].offsetWidth;
        const containerScrollPosition =
          thumbWidth * index - thumbContainer.clientWidth / 2 + thumbWidth / 2;
  
        const maxScrollLeft =
          thumbContainer.scrollWidth - thumbContainer.clientWidth;
        const scrollPosition = Math.max(
          0,
          Math.min(containerScrollPosition, maxScrollLeft)
        );
  
        thumbContainer.scrollTo({
          left: scrollPosition,
          behavior: "smooth"
        });
      }
    }catch(error){
    }
  };

  const handleThumbClick = (index) => {
    setActiveIndex(index);
  };

  const openModal = () => {
    updateSupportModalStatus(false);
  };

  const handleClose = () => {
    closeFarmerModal(false);
  };

  return (
    <Modal className="farmer-modal" centered show={true}>
      <Modal.Header>
        Farmer Details
        <div className="btnClose-rm" onClick={handleClose}>
          <img src={cancelButton} alt="delete-icon" />
        </div>
      </Modal.Header>
      <Modal.Body className="position-relative">
        <div className="modal-body p-0">
          <div className="farmer-profile  p-0">
            <div className="f-porfile-header text-center ps-0">
              <div className="farmer-img mx-auto">
                {farmer?.pfp ? (
                  <img
                    src={`${process.env.REACT_APP_SERVER_URL}/uploads/pfp/${farmer?.pfp}`}
                    alt="farmer-img"
                  />
                ) : (
                  <img src={userPlaceholder} alt="farmer-img" />
                )}
              </div>
              <div className="farmer-name mb-3">
                <span>
                  {farmer?.firstName} {farmer?.lastName}
                </span>
              </div>
            </div>
          </div>
          <div className="modal-body-inner">
            <section className="main-slider product-slider">
              <div className="carousel slide">
                <div className="carousel-inner">
                  {farmer?.farmPictures?.length > 0 ? (
                    farmer?.farmPictures?.map((picture, index) => (
                      <div
                        className={`carousel-item ${
                          index === activeIndex ? "active" : ""
                        }`}
                        key={index}
                      >
                        <img
                          src={`${process.env.REACT_APP_SERVER_URL}/uploads/${companyId}/farmPictures/${picture}`}
                          alt={`Slide ${index + 1}`}
                        />
                      </div>
                    ))
                  ) : (
                    <div className="carousel-item active">
                      <p>No farm images</p>
                    </div>
                  )}
                </div>
                {farmer?.farmPictures?.length > 1 ? (
                  <>
                    <button
                      className={`carousel-control-prev shadow-sm ${
                        activeIndex === 0 ? "active" : ""
                      }`}
                      type="button"
                      onClick={handlePreviousSlide}
                      disabled={activeIndex === 0}
                    >
                      <i className="bi bi-chevron-left"></i>
                    </button>
                    <button
                      className={`carousel-control-next shadow-sm ${
                        activeIndex === farmer?.farmPictures?.length - 1
                          ? "active"
                          : ""
                      }`}
                      type="button"
                      onClick={handleNextSlide}
                      disabled={
                        activeIndex === farmer?.farmPictures?.length - 1
                      }
                    >
                      <i className="bi bi-chevron-right"></i>
                    </button>
                  </>
                ) : null}
              </div>
            </section>
            <section className="thumb-slider thumb-product_carousel my-3">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <ul
                      className="nav align-items-center flex-nowrap"
                      ref={thumbContainerRef}
                    >
                      {farmer?.farmPictures?.map((picture, index) => (
                        <li
                          className="nav-item"
                          key={index}
                          onClick={() => handleThumbClick(index)}
                        >
                          <div
                            className={`thumb-img ${
                              index === activeIndex ? "active" : ""
                            }`}
                          >
                            <div className="overlay"></div>
                            <img
                              src={`${process.env.REACT_APP_SERVER_URL}/uploads/${companyId}/farmPictures/${picture}`}
                              className="img-fluid"
                              alt={`Thumbnail ${index + 1}`}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </section>
            {
             farmer?.farmerDescription && (
            <div className="farmer-profile-pra">
                <p
                  className="product-details-pra"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                     farmer?.farmerDescription
                    )
                  }}
                ></p>
              </div>
             ) 
            }
            <div className="reward-frame farmer-profile-modal">
              <div className="container px-3">
                <div className="row">
                  <div className="col-12 text-center">
                    <div className="reward-img-icon">
                      <img src={rewardIcon} alt="reward-icon" />
                    </div>
                    <div className="reward-heading">
                      Reward Farmer with a Tip!
                    </div>
                    <div className="reward-pra">
                      Farmers will keep 100% of the tip
                    </div>
                    <button
                      className="btn btn-primary btn-primary-farmer"
                      onClick={openModal}
                    >
                      Support the Farmer
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FarmerModal;
