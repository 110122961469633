import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../../axiosConfig/axiosInstance";
import { toast } from "react-toastify";
import uploadUserImg from "../../../assets/images/icons/upload-user.svg";
import ImageUploadProgress from "../../Products/ImageUploadProgress";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import uploadProductLogo from "../../../assets/images/icons/upload-product.svg";
import { v4 as uuidv4 } from "uuid";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { Upload } from "antd";
import DraggableUploadListItem from "../../../components/HOCs/DragAndDrop";
import dragAndDropLogo from "../../../assets/images/icons/dragdrop.svg";
import WalletConnect from "./WalletConnect";
import Progressbar from "./Progressbar";

const CompleteProfile = ({ updateStartSession }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [newImages, setNewImages] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [file, setFile] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    walletAddress: "",
    farmerDescription: "",
    chainId: "",
    networkName: "",
    explorerUrl: "",
    farmPictures: [],
    retainedImages: []
  });
  const [newRequestData, setNewRequestData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    walletAddress: "",
    farmerDescription: "",
    chainId: "",
    farmPictures: [],
    retainedImages: [],
    removedPictures: []
  });
  const [editorToolBar] = useState({
    toolbar: ["bold", "italic", "bulletedList", "numberedList"]
  });
  const [profilePic, setProfilePic] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);
  const [step1, setStep1] = useState(false);

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  // function that is called when component is mount
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(`user/get/profile`);
      setData({ ...response?.data?.user, farmPictures: [] });
      if (response?.data?.user?.farmerBlockchainNetwork?.chainId) {
        setNewRequestData({
          ...newRequestData,
          chainId: response?.data?.user?.farmerBlockchainNetwork?.chainId
        });
      }
      if (response?.data?.user?.pfp) {
        setProfilePic({
          image: `${process.env.REACT_APP_SERVER_URL}/uploads/pfp/${response?.data?.user?.pfp}`
        });
      }
      const { farmPictures } = response?.data?.user;
      if (farmPictures && farmPictures?.length > 0) {
        setData({
          ...response?.data?.user,
          retainedImages: farmPictures,
          farmPictures: farmPictures
        });

        // Create an array of preview image objects
        const previewImageObjects = farmPictures.map(async (picture) => {
          const imgUrl = `${process.env.REACT_APP_SERVER_URL}/uploads/${response?.data?.user?.company}/farmPictures/${picture}`;
          const result = await fetch(imgUrl);
          // data is fetched successfully
          if (result?.ok) {
            const blob = await result.blob();
            const sizeInKBs = (blob.size / 1024).toFixed(2);
            return {
              uid: uuidv4(),
              image: imgUrl,
              size: sizeInKBs,
              name: picture
            };
          } else {
            return {
              uid: uuidv4(),
              image: imgUrl,
              size: 0,
              name: picture
            };
          }
        });
        // Wait for all promises to resolve
        const imageSizes = await Promise.all(previewImageObjects);
        setPreviewImages(imageSizes);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditorChange = (event, editor) => {
    setIsButtonDisabled(false);
    const description = editor.getData();
    setData({ ...data, farmerDescription: description });
    setNewRequestData({ ...newRequestData, farmerDescription: description });
  };

  // handle farmer pictures change
  const handleFarmerImages = async (event) => {
    setIsButtonDisabled(false);
    if (event.target.files.length > 0) {
      const { type, size, name } = event.target.files[0];
      if (previewImages.length >= 10) {
        toast.warn("You can add only 10 pictures of a farm");
        return;
      }

      if (
        type === "image/jpeg" ||
        type === "image/jpg" ||
        type === "image/png"
      ) {
        if (size <= 1 * 1024 * 1024) {
          // Update the product state with the new media item added to the existing media array
          setData((prevUser) => ({
            ...prevUser,
            farmPictures: [...prevUser.farmPictures, event.target.files[0]]
          }));
          setNewRequestData((prevUser) => ({
            ...prevUser,
            farmPictures: [...prevUser.farmPictures, event.target.files[0]]
          }));
          const reader = new FileReader();
          reader.onload = (e) => {
            const newPreviewImage = {
              uid: uuidv4(),
              image: e.target.result,
              size: `${(size / 1024).toFixed(2)} KB`,
              name: name
            };
            setNewImages((previous) => [...previous, newPreviewImage]);
            setPreviewImages((previous) => [...previous, newPreviewImage]);
          };

          reader.readAsDataURL(event.target.files[0]);
        } else {
          toast.warn("File size must be less than 1MB");
        }
      } else {
        toast.warn("Only JPG and PNG files supported");
      }
    }
  };

  // delete, added images
  const handleFarmImageDelete = (index, item) => {
    setIsButtonDisabled(false);
    const filteredPreviewImages = previewImages?.filter((img) => {
      return img?.uid != item?.uid;
    });
    setPreviewImages(filteredPreviewImages);
    setNewImages(filteredPreviewImages);
    const filterRemoveProduct = [...data?.farmPictures];
    filterRemoveProduct.splice(index, 1);
    setData({ ...data, farmPictures: filterRemoveProduct });
    if (!item?.image?.startsWith("data:image/")) {
      setNewRequestData({
        ...newRequestData,
        removedPictures: [...newRequestData?.removedPictures, item?.name]
      });
    }
    if (newRequestData?.farmPictures?.length) {
      const filteredNewAddedImages = newRequestData?.farmPictures?.filter(
        (file) => {
          return file?.name !== item?.name;
        }
      );
      setNewRequestData({
        ...newRequestData,
        farmPictures: filteredNewAddedImages
      });
    }
  };

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10
    }
  });

  const onDragEnd = ({ active, over }) => {
    setIsButtonDisabled(false);
    if (active.id !== over?.id) {
      setPreviewImages((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  // handle input field changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setIsButtonDisabled(false);
    setData({ ...data, [name]: value });
    setNewRequestData({ ...newRequestData, [name]: value });
  };

  // handle Image changes
  const handleImageChange = (event) => {
    if (event.target.files.length > 0) {
      setIsButtonDisabled(false);
      const file = event.target.files[0];
      if (file) setFile(file);
      const { type, size, name } = event.target.files[0];

      if (
        type === "image/jpeg" ||
        type === "image/jpg" ||
        type === "image/png"
      ) {
        if (size <= 1 * 1024 * 1024) {
          // Update the product state with the new media item added to the existing media array
          const reader = new FileReader();
          reader.onload = (e) => {
            const newPreviewImage = {
              image: e.target.result,
              size: `${(size / 1024).toFixed(2)} KB`,
              name: name,
              dbImage: 0
            };
            setNewImages((previous) => [newPreviewImage]);
            setProfilePic(newPreviewImage);
          };
          reader.readAsDataURL(event.target.files[0]);
          event.target.value = null;
        } else {
          event.target.value = null;
          toast.warn("File size must be less than 1MB");
        }
      } else {
        event.target.value = null;
        toast.warn("Only JPG and PNG files supported");
      }
    }
  };

  const handleDeleteImage = () => {
    setIsButtonDisabled(false);
    setProfilePic(null);
    setFile("remove");
  };

  // handle form submission
  const handleFormSubmission = async (event) => {
    event.preventDefault();
    if (!data?.farmerDescription) {
      toast.warn("Please add farm description as well");
      return;
    }
    if (!file || file === "remove") {
      if (!profilePic) {
        return toast.warn("Profile picture is required");
      }
    }
    setStep1(true);
  };
  const updateParentState = () => {
    setStep1(false);
  };

  return (
    <>
      {step1 ? (
        <WalletConnect
          updateParentState={updateParentState}
          data={newRequestData}
          pfp={file}
        />
      ) : (
        <section className="section-main ms-0 link-wallet">
          <div className="content-sec">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-12 col-lg-10 col-xl-9 col-xxl-8">
                  <Progressbar step1={step1} />
                  <div className="wallet-sec why_connect">
                    <div className="title-main">
                      Why do you need to complete your profile?
                    </div>
                    <div className="wallet-description">
                      Make sure to fill out all your information accurately.
                      Customers will view this information, so it should be
                      authentic and represent you and your farm well. Build
                      trust and credibility with those who view your profile.
                    </div>
                  </div>

                  <div className="wallet-sec">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-section user add-farmer-form">
                          <div className="edit-profile-images">
                            <div className="upload-img">
                              <div className="image-section">
                                <div className="img-placeholder">
                                  <div className="user-img-ic">
                                    <img src={uploadUserImg} alt="icon" />
                                  </div>
                                  <div className="optional-txt">(required)</div>

                                  <div className="title">
                                    {profilePic
                                      ? "Uploaded Picture"
                                      : "Upload your Picture"}
                                    <span className="required-asterisk">*</span>
                                  </div>
                                </div>
                                {profilePic ? (
                                  <div
                                    className="image-show"
                                    style={{
                                      display:
                                        profilePic != null ? "block" : "none"
                                    }}
                                  >
                                    <div className="img-details">
                                      <div className="uploaded-image">
                                        <img
                                          id="uploaded-image-preview"
                                          src={profilePic?.image}
                                          alt="Uploaded Image"
                                        />
                                      </div>
                                    </div>
                                    <div
                                      className="close-sec"
                                      onClick={() => handleDeleteImage()}
                                    ></div>
                                  </div>
                                ) : null}
                                <input
                                  id="upload-img-pu"
                                  type="file"
                                  onChange={handleImageChange}
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                              <div className="img-dimensions">
                                <div className="title">Picture Dimensions</div>
                                300 X 300 (JPG or PNG)
                              </div>
                            </div>
                          </div>
                          <div className="form-area">
                            <form onSubmit={handleFormSubmission}>
                              <div style={{ display: "flex", gap: "2%" }}>
                                <div
                                  className="form-group"
                                  style={{ width: "50%" }}
                                >
                                  <label>First Name</label>
                                  <div className="input-area">
                                    <input
                                      type="text"
                                      name="firstName"
                                      value={data?.firstName}
                                      className="form-control"
                                      onChange={handleInputChange}
                                    />
                                    <span className="focus-bg"></span>
                                  </div>
                                </div>
                                <div
                                  className="form-group"
                                  style={{ width: "50%" }}
                                >
                                  <label>Last Name</label>
                                  <input
                                    type="text"
                                    name="lastName"
                                    value={data?.lastName}
                                    className="form-control"
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </div>

                              <div className="form-group">
                                <label>Email</label>
                                <input
                                  type="email"
                                  name="email"
                                  required
                                  value={data?.email}
                                  readOnly
                                  disabled
                                  className="form-control"
                                />
                              </div>

                              <div className="form-group mt-3">
                                <label>Farmer and Farm description</label>
                                <span className="required-asterisk">*</span>
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={data?.farmerDescription || ""}
                                  id="editor"
                                  config={editorToolBar}
                                  className="form-control"
                                  onChange={handleEditorChange}
                                  autofocus
                                />
                              </div>

                              <div className="form-group mt-3">
                                <div className="">
                                  <div className="upload-farmer-product">
                                    <label>Upload Farm Pictures</label>
                                    <div className="image-section ">
                                      <div className="img-placeholder">
                                        <div className="user-img-ic">
                                          <img
                                            src={uploadProductLogo}
                                            alt="icon"
                                          />
                                        </div>
                                        <div className="title">
                                          Drop files here or browse to upload
                                        </div>
                                        <div className="img-dimensions mt-2">
                                          Up to 1 MB (JPG or PNG)
                                        </div>
                                        <input
                                          id="upload-img-pu"
                                          type="file"
                                          onChange={handleFarmerImages}
                                          style={{ cursor: "pointer" }}
                                        />
                                      </div>
                                      <DndContext
                                        sensors={[sensor]}
                                        onDragEnd={onDragEnd}
                                      >
                                        <SortableContext
                                          items={previewImages.map(
                                            (i) => i.uid
                                          )}
                                          strategy={verticalListSortingStrategy}
                                        >
                                          <Upload
                                            fileList={previewImages}
                                            onChange={handleFarmerImages}
                                            showUploadList={false}
                                          ></Upload>
                                          {previewImages?.length > 0 && (
                                            <>
                                              {previewImages?.map(
                                                (item, index) => {
                                                  return (
                                                    <DraggableUploadListItem
                                                      key={item?.uid}
                                                      file={item}
                                                      originNode={
                                                        <div
                                                          key={index}
                                                          className="image-show"
                                                          style={{
                                                            display:
                                                              previewImages.length >
                                                              0
                                                                ? "block"
                                                                : "none"
                                                          }}
                                                        >
                                                          <div className="dragdrop-icon">
                                                            <img
                                                              src={
                                                                dragAndDropLogo
                                                              }
                                                              alt="dragdrop-icon"
                                                            />
                                                          </div>
                                                          <div className="img-details">
                                                            <ul className="nav">
                                                              <li className="nav-item">
                                                                <div className="uploaded-image">
                                                                  <img
                                                                    className=""
                                                                    id="uploaded-image-preview"
                                                                    src={
                                                                      item?.image
                                                                    }
                                                                    alt="preview image"
                                                                  />
                                                                </div>
                                                              </li>
                                                              <li className="nav-item">
                                                                <div
                                                                  className="product-name"
                                                                  id="product-name"
                                                                >
                                                                  {item?.name}
                                                                </div>
                                                                <div
                                                                  className="product-size"
                                                                  id="product-size"
                                                                >
                                                                  {item?.size}
                                                                </div>
                                                              </li>
                                                            </ul>
                                                          </div>
                                                          <div>
                                                            <ImageUploadProgress
                                                              newImages={
                                                                newImages
                                                              }
                                                            />
                                                          </div>
                                                          <div
                                                            className="close-sec"
                                                            onClick={() =>
                                                              handleFarmImageDelete(
                                                                index,
                                                                item
                                                              )
                                                            }
                                                          ></div>
                                                        </div>
                                                      }
                                                    />
                                                  );
                                                }
                                              )}
                                            </>
                                          )}
                                        </SortableContext>
                                      </DndContext>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="form-group mt-4 mt-lg-5 text-end">
                                <button
                                  className="btn btn-light me-3"
                                  onClick={() => updateStartSession(false)}
                                >
                                  Cancel
                                </button>
                                {buttonLoading ? (
                                  <button
                                    className="btn btn-primary w-25"
                                    disabled
                                  >
                                    <span
                                      className="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"
                                    ></span>
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-primary"
                                    disabled={isButtonDisabled}
                                  >
                                    Complete Profile
                                  </button>
                                )}
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default CompleteProfile;
