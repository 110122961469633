import React, { useEffect, useState, useContext } from "react";
import "../../assets/css/components-style/sidebar.css";
import dashboardLogo from "../../assets/images/icons/Dashboard.svg";
import logo from "../../assets/images/icons/logo.svg";
import menuLogo from "../../assets/images/icons/menu.svg";
import reportingLogo from "../../assets/images/icons/reporting.svg";
import userLogo from "../../assets/images/icons/users.svg";
import { Link, useLocation } from "react-router-dom";
import connectIcon from "../../assets/images/icons/connect-icon.svg";
import disconnectIcon from "../../assets/images/icons/disconnect-icon.svg";
import { authContext } from "../Context/AuthContext";
import { walletContext } from "../Context/WalletConnectContext";
import { useWeb3ModalAccount, useWeb3Modal } from "@web3modal/ethers/react";
import tipHistoryLogo from "../../assets/images/icons/tip-history.svg";
import farmerLogo from "../../assets/images/logo/farmer-logo.svg";
import roadmapIcon from "../../assets/images/farmer/how-it-works.svg";
import { axiosInstance } from "../../axiosConfig/axiosInstance";
import eventEmitter from "../Events/eventEmitter";

const Sidebar = ({ activeMenu, setActiveMenu }) => {
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();
  const { state } = useContext(authContext);
  const user = JSON.parse(localStorage.getItem("loggedinUser")) || state?.user;
  const { updateWallet } = useContext(walletContext);
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [totalPendingRequests, setTotalPendingRequests] = useState(0);
  
  useEffect(() => {
    signerFunction();
  }, [isConnected, address, chainId]);
  const { open } = useWeb3Modal();
  useEffect(() => {
    const pathname = location.pathname;
    const splitedPath = pathname.split("/");
    if (pathname == "/products-reporting") {
      setActiveTab("Reporting");
    } else if (splitedPath.includes("reporting-details")) {
      setActiveTab("Reporting");
    } else if (
      splitedPath.includes("edit-product") ||
      splitedPath.includes("product-details") ||
      splitedPath.includes("add-product")
    ) {
      setActiveTab("Products");
    } else if (
      splitedPath.includes("edit-user") ||
      splitedPath.includes("add-user") ||
      splitedPath.includes("manage-access")
    ) {
      setActiveTab("Users");
    } else if (splitedPath.includes("tips-history")) {
      setActiveTab("tips");
    } else if (splitedPath.includes("farmers")) {
      setActiveTab("farmers");
    } else if (splitedPath.includes("profile")) {
      setActiveTab("profile");
    } else if (splitedPath.includes("roadmap")) {
      setActiveTab("works");
    } else {
      setActiveTab(pathname.charAt(1).toUpperCase() + pathname?.slice(2));
    }
    setActiveMenu(false);

  }, [location?.pathname]);

  const handleCustomEvent =()=>{
      fetchFarmerPendingRequests();
  }

  // fetch faremrs total pending review requests for only company Admin
  useEffect(() => {
    if (user?.companyAdmin) {
      fetchFarmerPendingRequests();
    }
    eventEmitter.on("reviewRequest", handleCustomEvent);
    // Clean up the event listener on unmount
    return () => {
      eventEmitter.off("reviewRequest", handleCustomEvent);
    };
  }, []);

  const fetchFarmerPendingRequests = async () => {
    try {
      const response = await axiosInstance.get("/farmer/pending/requests");
      setTotalPendingRequests(response?.data?.pendingRequests);
    } catch (error) {
      console.error("error while fetching famer total pending requests");
    }
  };

  const handleWalletConnection = () => {
    setActiveMenu(false);
    open();
  };

  const signerFunction = async () => {
    if (isConnected) {
      sessionStorage.setItem(
        "walletConfig",
        JSON.stringify({
          address: address,
          chainId: chainId,
          isConnected: isConnected,
          pendingSession: false
        })
      );
      updateWallet({
        address: address,
        chainId: chainId,
        isConnected: isConnected
      });
      return;
    } else {
      sessionStorage.removeItem("walletConfig");
      updateWallet({});
      return;
    }
  };

  return (
    <>
      {user && (
        <div className={`side-bar ${activeMenu ? "open" : ""}`}>
          <div className="sidebar-overlay"></div>
          <div className="side-bar-body">
            <div
              className="side-bar-close d-md-none"
              onClick={() => setActiveMenu(false)}
            >
              <i className="bi bi-x"></i>
            </div>
            <div className="side-bar-header">
              {user?.companyLogo ? (
                <img
                  src={`${process.env.REACT_APP_SERVER_URL}/uploads/companyLogos/${user?.companyLogo}`}
                  alt="compny logo"
                />
              ) : (
                <img src={logo} alt="logo" style={{ width: "150px" }} />
              )}
            </div>
            {user?.isViewer ? null : (
              <div className="connect-wallet d-md-none pb-3">
                {!isConnected ? (
                  <button
                    className="btn btn-connect btn-csb"
                    onClick={handleWalletConnection}
                  >
                    <img src={connectIcon} alt="icon" /> Connect Wallet
                  </button>
                ) : (
                  <div className="wallet-details mob ">
                    <div className="status">
                      <span></span> Wallet Connected
                    </div>
                    <div className="wallet-add">
                      {address?.substring(0, 7)}......
                      {address?.substring(address?.length - 6)}
                    </div>
                    <button
                      className="btn btn-disconnect btn-dcs"
                      onClick={handleWalletConnection}
                    >
                      <img src={disconnectIcon} alt="disconnect-icon" />{" "}
                      Disconnect
                    </button>
                  </div>
                )}
              </div>
            )}

            <ul className="nav flex-column">
              {user?.isFarmer && (
                <>
                  <li
                    className={`nav-item ${
                      activeTab === "profile" ? "active" : ""
                    }`}
                  >
                    <Link
                      to={"/farmer/profile"}
                      className="nav-link"
                      onClick={() => setActiveTab("profile")}
                    >
                      <img src={dashboardLogo} alt="icon" /> Profile
                    </Link>
                  </li>
                </>
              )}
              {user?.superAdmin || user?.companyAdmin ? (
                <>
                  <li
                    className={`nav-item  ${
                      activeTab === "Dashboard" ? "active" : ""
                    }`}
                  >
                    <Link
                      className="nav-link"
                      to={"/dashboard"}
                      onClick={() => setActiveTab("Dashboard")}
                    >
                      <img src={dashboardLogo} alt="icon" /> Dashboard
                    </Link>
                  </li>

                  {user?.superAdmin && (
                    <li
                      className={`nav-item  ${
                        activeTab === "Companies" ? "active" : ""
                      }`}
                    >
                      <Link
                        className="nav-link"
                        to={"/companies"}
                        onClick={() => setActiveTab("Companies")}
                      >
                        <img src={dashboardLogo} alt="icon" /> Companies
                      </Link>
                    </li>
                  )}
                </>
              ) : null}
              {!user?.isFarmer && (
                <>
                  <li
                    className={`nav-item ${
                      activeTab === "Products" ? "active" : ""
                    }`}
                  >
                    <Link
                      to={"/products"}
                      className="nav-link"
                      onClick={() => setActiveTab("Products")}
                    >
                      <img src={menuLogo} alt="icon" /> Products
                    </Link>
                  </li>

                  <li
                    className={`nav-item ${
                      activeTab === "Reporting" ? "active" : ""
                    }`}
                  >
                    <Link
                      to={"/products-reporting"}
                      className="nav-link"
                      onClick={() => setActiveTab("Reporting")}
                    >
                      <img src={reportingLogo} alt="icon" /> Reporting
                    </Link>
                  </li>
                </>
              )}
              {user?.isFarmer || user?.companyAdmin ? (
                <>
                  <li
                    className={`nav-item ${
                      activeTab === "tips" ? "active" : ""
                    }`}
                  >
                    <Link
                      to={"/tips-history"}
                      className="nav-link"
                      onClick={() => setActiveTab("tips")}
                    >
                      <img src={tipHistoryLogo} alt="icon" /> Tips History
                    </Link>
                  </li>
                </>
              ) : null}

              {user?.isFarmer ? (
                <>
                  <li
                    className={`nav-item ${
                      activeTab === "works" ? "active" : ""
                    }`}
                  >
                    <Link
                      to={"/farmer/roadmap"}
                      className="nav-link"
                      onClick={() => setActiveTab("works")}
                    >
                      <img src={roadmapIcon} alt="icon" /> How it works
                    </Link>
                  </li>
                </>
              ) : null}

              {user?.companyAdmin ? (
                <>
                  <li
                    className={`nav-item ${
                      activeTab === "farmers" ? "active" : ""
                    }`}
                  >
                    <Link
                      to={"/farmers"}
                      className="nav-link"
                      onClick={() => setActiveTab("farmers")}
                    >
                      <img src={farmerLogo} alt="icon" /> Farmers
                      <span
                        className="mx-2"
                        style={{
                          height: "24px",
                          width: "24px",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "rgba(234, 67, 53, 1)",
                          color: "#fff"
                        }}
                      >
                        {totalPendingRequests}
                      </span>
                    </Link>
                  </li>
                </>
              ) : null}

              {user?.superAdmin || user?.isFarmer ? null : (
                <>
                  <li
                    className={`nav-item ${
                      activeTab === "Users" ? "active" : ""
                    }`}
                  >
                    <Link
                      to={"/users"}
                      className="nav-link"
                      onClick={() => setActiveTab("Users")}
                    >
                      <img src={userLogo} alt="icon" /> Company Users
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
