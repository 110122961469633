import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

const ImageUploadProgress = ({ newImages }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (newImages.length > 0) {
      // Set up the interval
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress >= 100) {
            clearInterval(timer); // Stop the interval if progress reaches 100%
            return 100;
          }
          const diff = Math.random() * 60;
          return Math.min(oldProgress + diff, 100);
        });
      }, 500);

      // Cleanup function to clear the interval
      return () => clearInterval(timer);
    } else {
      // Immediately set progress to 100% if no new images
      setProgress(100);
    }
  }, [newImages]);

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          backgroundColor: "#ccc",
          "& .MuiLinearProgress-bar": {
            backgroundColor: progress === 100 ? "green" : "#0d6efd"
          }
        }}
      />
    </Box>
  );
};

export default ImageUploadProgress;
