import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import {
  axiosInstance,
  axiosInstanceWithFormData
} from "../../../axiosConfig/axiosInstance";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader/Loader";
import uploadUserImg from "../../../assets/images/icons/upload-user.svg";
import ImageUploadProgress from "../../Products/ImageUploadProgress";
import { checkWalletChecksum } from "../../../utils/utills";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Form from "react-bootstrap/Form";
import uploadProductLogo from "../../../assets/images/icons/upload-product.svg";
import { v4 as uuidv4 } from "uuid";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { Upload } from "antd";
import DraggableUploadListItem from "../../../components/HOCs/DragAndDrop";
import dragAndDropLogo from "../../../assets/images/icons/dragdrop.svg";

const EditFarmer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPfp, setCurrentPfp] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [newImages, setNewImages] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [file, setFile] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();
  const [networks, setNetworks] = useState([]);
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    walletAddress: "",
    farmerDescription: "",
    chainId: "",
    networkName: "",
    explorerUrl: "",
    farmPictures: [],
    retainedImages: []
  });
  const [editorToolBar] = useState({
    toolbar: ["bold", "italic", "bulletedList", "numberedList"]
  });
  const [profilePic, setProfilePic] = useState(null);
  const [previewImages, setPreviewImages] = useState([]);
  const [alreadySelectedChain, setAlreadySelectedChain] = useState(null);

  const { id } = useParams();
  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchNetworks = async () => {
    try {
      const networks = await axiosInstance.get("/general/networks");
      return networks?.data?.networks;
    } catch (error) {
      toast.error(error?.message);
    }
  };

  // network selection change
  const handleNetworkSelectionChange = (event) => {
    setIsButtonDisabled(false);
    const blockchainNetwork = JSON.parse(event.target.value);
    setData({
      ...data,
      chainId: blockchainNetwork?.chainId,
      networkName: blockchainNetwork?.name,
      explorerUrl: blockchainNetwork?.explorerUrl
    });
  };

  // function that is called when component is mount
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        `user/getuserdetails?userId=${id}`
      );
      setData({ ...response?.data, farmPictures: [] });
      const networks = await fetchNetworks();
      if (response?.data?.blockchain?.chainId) {
        const selectedNetwork = networks?.filter((item) => {
          return item?.chainId == response?.data?.blockchain?.chainId;
        });
        setAlreadySelectedChain(selectedNetwork[0]);
      }
      setNetworks(networks);
      if (response?.data?.profilePic) {
        setProfilePic({
          image: `${process.env.REACT_APP_SERVER_URL}/uploads/pfp/${response?.data?.profilePic}`
        });
      }
      const { farmPictures } = response?.data;
      if (farmPictures && farmPictures?.length > 0) {
        setData({
          ...response?.data,
          retainedImages: farmPictures,
          farmPictures: farmPictures
        });

        // Create an array of preview image objects
        const previewImageObjects = farmPictures.map(async (picture) => {
          const imgUrl = `${process.env.REACT_APP_SERVER_URL}/uploads/${response?.data?.company}/farmPictures/${picture}`;
          const result = await fetch(imgUrl);
          // data is fetched successfully
          if (result?.ok) {
            const blob = await result.blob();
            const sizeInKBs = (blob.size / 1024).toFixed(2);
            return {
              uid: uuidv4(),
              image: imgUrl,
              size: sizeInKBs,
              name: picture
            };
          } else {
            return {
              uid: uuidv4(),
              image: imgUrl,
              size: 0,
              name: picture
            };
          }
        });
        // Wait for all promises to resolve
        const imageSizes = await Promise.all(previewImageObjects);
        setPreviewImages(imageSizes);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditorChange = (event, editor) => {
    setIsButtonDisabled(false);
    const description = editor.getData();
    setData({ ...data, farmerDescription: description });
  };

  // handle farmer pictures change
  const handleFarmerImages = async (event) => {
    if (event.target.files.length > 0) {
      const { type, size, name } = event.target.files[0];
      if (previewImages.length >= 10) {
        toast.warn("You can add only 10 pictures of a farm");
        return;
      }

      if (
        type === "image/jpeg" ||
        type === "image/jpg" ||
        type === "image/png"
      ) {
        if (size <= 1 * 1024 * 1024) {
          setIsButtonDisabled(false);
          // Update the product state with the new media item added to the existing media array
          setData((prevUser) => ({
            ...prevUser,
            farmPictures: [...prevUser.farmPictures, event.target.files[0]]
          }));
          const reader = new FileReader();
          reader.onload = (e) => {
            const newPreviewImage = {
              uid: uuidv4(),
              image: e.target.result,
              size: `${(size / 1024).toFixed(2)} KB`,
              name: name
            };
            setNewImages((previous) => [...previous, newPreviewImage]);
            setPreviewImages((previous) => [...previous, newPreviewImage]);
          };

          reader.readAsDataURL(event.target.files[0]);
        } else {
          toast.warn("File size must be less than 1MB");
        }
      } else {
        toast.warn("Only JPG and PNG files supported");
      }
    }
  };

  // delete, added images
  const handleFarmImageDelete = (index, item) => {
    setIsButtonDisabled(false);
    const filteredPreviewImages = previewImages?.filter((img) => {
      return img?.uid != item?.uid;
    });
    setPreviewImages(filteredPreviewImages);
    setNewImages(filteredPreviewImages);
    const filterRemoveProduct = [...data?.farmPictures];
    filterRemoveProduct.splice(index, 1);
    setData({ ...data, farmPictures: filterRemoveProduct });
  };

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10
    }
  });

  const onDragEnd = ({ active, over }) => {
    setIsButtonDisabled(false);
    if (active.id !== over?.id) {
      setPreviewImages((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  // handle input field changes
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setIsButtonDisabled(false);
    setData({ ...data, [name]: value });
  };

  // handle Image changes
  const handleImageChange = (event) => {
    if (event.target.files.length > 0) {
      setIsButtonDisabled(false);
      const file = event.target.files[0];
      if (file) setFile(file);
      const { type, size, name } = event.target.files[0];

      if (
        type === "image/jpeg" ||
        type === "image/jpg" ||
        type === "image/png"
      ) {
        if (size <= 1 * 1024 * 1024) {
          // Update the product state with the new media item added to the existing media array
          const reader = new FileReader();
          reader.onload = (e) => {
            const newPreviewImage = {
              image: e.target.result,
              size: `${(size / 1024).toFixed(2)} KB`,
              name: name,
              dbImage: 0
            };
            setNewImages((previous) => [newPreviewImage]);
            setProfilePic(newPreviewImage);
          };
          reader.readAsDataURL(event.target.files[0]);
          event.target.value = null;
        } else {
          event.target.value = null;
          toast.warn("File size must be less than 1MB");
        }
      } else {
        event.target.value = null;
        toast.warn("Only JPG and PNG files supported");
      }
    }
  };

  const handleDeleteImage = () => {
    setIsButtonDisabled(false);
    setProfilePic(null);
    setFile("remove");
  };

  // handle form submission
  const handleFormSubmission = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      if (data?.walletAddress) {
        const isValid = await checkWalletChecksum(data?.walletAddress);
        if (!isValid) {
          toast.error("Error: invalid ethereum address.");
          return;
        } else {
          formData.append("walletAddress", data?.walletAddress);
        }
      }
      let newRetainedImages = [];
      let uploadMedia = [];
      let allUploadedFiles = [];
      if (data?.farmPictures?.length > 0) {
        for (let i = 0; i < previewImages?.length; i++) {
          if (previewImages[i]?.image?.startsWith("data:image/")) {
            const newFile = data?.farmPictures?.find((item) => {
              if (item instanceof File) {
                if (item?.name === previewImages[i]?.name) {
                  allUploadedFiles.push(item?.name);
                  return item;
                }
              }
            });
            uploadMedia.push(newFile);
          } else {
            uploadMedia.push(previewImages[i]?.name);
            allUploadedFiles.push(previewImages[i]?.name);
            newRetainedImages.push(previewImages[i]?.name);
          }
        }
        for (let i = 0; i < uploadMedia?.length; i++) {
          formData.append("farmPictures", uploadMedia[i]);
        }
      }

      if (data?.chainId) {
        formData.append("chainId", data?.chainId);
        formData.append("explorerUrl", data?.explorerUrl);
        formData.append("networkName", data?.networkName);
      }

      if (alreadySelectedChain && !data?.chainId) {
        formData.append("chainId", alreadySelectedChain?.chainId);
        formData.append("explorerUrl", alreadySelectedChain?.explorerUrl);
        formData.append("networkName", alreadySelectedChain?.name);
      }
      formData.append("uploaded", allUploadedFiles);
      formData.append(
        "retainedImages",
        newRetainedImages?.length > 0 ? newRetainedImages : []
      );
      formData.append("firstName", data?.firstName);
      formData.append("lastName", data?.lastName);
      formData.append("email", data?.email);
      formData.append("isEditor", false);
      formData.append("isViewer", false);
      formData.append("isFarmer", true);
      formData.append("userId", id);
      formData.append("farmerDescription", data?.farmerDescription);
      if (data?.latestReview?.reviewStatus === "pending") {
        toast.warn(
          "Profile is currently under review. Changes cannot be made until the review process is complete."
        );
        return;
      }
      setButtonLoading(true);
      const response = await axiosInstanceWithFormData.patch(
        "/user/edituser",
        formData
      );
      if (file) {
        const formData = new FormData();
        formData.append("pfp", file);
        formData.append("userId", id);
        await axiosInstanceWithFormData.post("/user/updatepfp", formData);
      }
      toast.success(response?.data?.message);
      setButtonLoading(false);
      navigate("/farmers");
      return;
    } catch (error) {
      toast.error("Something went wrong");
      setIsLoading(false);
      return;
    }
  };

  return (
    <section className="section-main">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="content-sec">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-between">
              <div className="breadcrumb-bar">
                <ul className="nav align-items-center">
                  <li className="nav-item">Edit Farmer</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="form-section user add-farmer-form">
                <div className="edit-profile-images">
                  <div className="upload-img">
                    <div className="image-section">
                      <div className="img-placeholder">
                        <div className="user-img-ic">
                          <img src={uploadUserImg} alt="icon" />
                        </div>
                        <div className="optional-txt">(Optional)</div>
                        <div className="title">
                          {profilePic
                            ? "Uploaded Picture"
                            : "Upload your Picture"}
                        </div>
                      </div>
                      {profilePic ? (
                        <div
                          className="image-show"
                          style={{
                            display: profilePic != null ? "block" : "none"
                          }}
                        >
                          <div className="img-details">
                            <div className="uploaded-image">
                              <img
                                id="uploaded-image-preview"
                                src={profilePic?.image}
                                alt="Uploaded Image"
                              />
                            </div>
                          </div>
                          <div
                            className="close-sec"
                            onClick={() => handleDeleteImage()}
                          ></div>
                        </div>
                      ) : null}
                      <input
                        id="upload-img-pu"
                        type="file"
                        onChange={handleImageChange}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="img-dimensions">
                      <div className="title">Picture Dimensions</div>
                      300 X 300 (JPG or PNG)
                    </div>
                  </div>
                </div>
                <div className="form-area">
                  <form onSubmit={handleFormSubmission}>
                    <div style={{ display: "flex", gap: "2%" }}>
                      <div className="form-group" style={{ width: "50%" }}>
                        <label>First Name</label>
                        <div className="input-area">
                          <input
                            type="text"
                            name="firstName"
                            value={data?.firstName}
                            className="form-control"
                            onChange={handleInputChange}
                          />
                          <span className="focus-bg"></span>
                        </div>
                      </div>
                      <div className="form-group" style={{ width: "50%" }}>
                        <label>Last Name</label>
                        <input
                          type="text"
                          name="lastName"
                          value={data?.lastName}
                          className="form-control"
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        name="email"
                        required
                        value={data?.email}
                        readOnly
                        disabled
                        className="form-control"
                      />
                    </div>

                    {data?.farmerDescription?.length > 0 && (
                      <div className="form-group mt-3">
                        <label>Farmer and Farm description</label>
                        <CKEditor
                          editor={ClassicEditor}
                          data={data?.farmerDescription || ""}
                          id="editor"
                          config={editorToolBar}
                          className="form-control"
                          onChange={handleEditorChange}
                          autofocus
                        />
                      </div>
                    )}

                    <div className="form-group mt-3">
                      <label>Select Network</label>
                      <Form.Select
                        onChange={handleNetworkSelectionChange}
                        required
                        className="select-options signup-select"
                        style={{ paddingTop: "10px" }}
                      >
                        <option
                          value="Select blockchain network"
                          disabled
                          selected
                        >
                          {alreadySelectedChain
                            ? alreadySelectedChain?.name
                            : "Select an option"}
                        </option>
                        {networks &&
                          networks?.map((item) => {
                            return (
                              <option
                                value={JSON.stringify(item)}
                                key={item?._id}
                              >
                                {item?.name}
                              </option>
                            );
                          })}
                      </Form.Select>
                    </div>

                    <div className="form-group mt-3">
                      <label>Your Wallet Address</label>
                      <input
                        type="text"
                        name="walletAddress"
                        value={data?.walletAddress}
                        onChange={handleInputChange}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group mt-3">
                      <div className="">
                        <div className="upload-farmer-product">
                          <label>Upload Farm Pictures</label>
                          <div className="image-section ">
                            <div className="img-placeholder">
                              <div className="user-img-ic">
                                <img src={uploadProductLogo} alt="icon" />
                              </div>
                              <div className="title">
                                Drop files here or browse to upload
                              </div>
                              <div className="img-dimensions mt-2">
                                Up to 1 MB (JPG or PNG)
                              </div>
                              <input
                                id="upload-img-pu"
                                type="file"
                                onChange={handleFarmerImages}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                            <DndContext
                              sensors={[sensor]}
                              onDragEnd={onDragEnd}
                            >
                              <SortableContext
                                items={previewImages.map((i) => i.uid)}
                                strategy={verticalListSortingStrategy}
                              >
                                <Upload
                                  fileList={previewImages}
                                  onChange={handleFarmerImages}
                                  showUploadList={false}
                                ></Upload>
                                {previewImages?.length > 0 && (
                                  <>
                                    {previewImages?.map((item, index) => {
                                      return (
                                        <DraggableUploadListItem
                                          key={item?.uid}
                                          file={item}
                                          originNode={
                                            <div
                                              key={index}
                                              className="image-show"
                                              style={{
                                                display:
                                                  previewImages.length > 0
                                                    ? "block"
                                                    : "none"
                                              }}
                                            >
                                              <div className="dragdrop-icon">
                                                <img
                                                  src={dragAndDropLogo}
                                                  alt="dragdrop-icon"
                                                />
                                              </div>
                                              <div className="img-details">
                                                <ul className="nav">
                                                  <li className="nav-item">
                                                    <div className="uploaded-image">
                                                      <img
                                                        className=""
                                                        id="uploaded-image-preview"
                                                        src={item?.image}
                                                        alt="preview image"
                                                      />
                                                    </div>
                                                  </li>
                                                  <li className="nav-item">
                                                    <div
                                                      className="product-name"
                                                      id="product-name"
                                                    >
                                                      {item?.name}
                                                    </div>
                                                    <div
                                                      className="product-size"
                                                      id="product-size"
                                                    >
                                                      {item?.size}
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>
                                              <div>
                                                <ImageUploadProgress
                                                  newImages={newImages}
                                                />
                                              </div>
                                              <div
                                                className="close-sec"
                                                onClick={() =>
                                                  handleFarmImageDelete(
                                                    index,
                                                    item
                                                  )
                                                }
                                              ></div>
                                            </div>
                                          }
                                        />
                                      );
                                    })}
                                  </>
                                )}
                              </SortableContext>
                            </DndContext>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group mt-4 mt-lg-5 text-end">
                      <button
                        className="btn btn-light me-3"
                        onClick={() => navigate("/farmers")}
                      >
                        Cancel
                      </button>
                      {buttonLoading ? (
                        <button className="btn btn-primary w-25" disabled>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      ) : (
                        <button
                          className="btn btn-primary"
                          disabled={isButtonDisabled}
                        >
                          Update Profile
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default EditFarmer;
