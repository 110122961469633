import React from "react";
import farmerPhoto from "../../../assets/images/farmer/farmer-photo.png"
import exploreImg1 from "../../../assets/images/farmer/explore-img-1.png"
import exploreImg2 from "../../../assets/images/farmer/explore-img-2.png"
import linkPlayImg from "../../../assets/images/farmer/link-play.svg"
const Roadmap = ()=>{
    return(

<section className = "section-main">



<div className = "content-sec">
	<div className = "container-fluid">
		<div className = "row">
			<div className = "col-12">
				<div className = "title-main mb-3">
					How it works
				</div>
			</div>
		</div>
		
		<div className = "how-work-sec">
			<div className = "howWork-heading">How to get started</div>
			<div className = "row">
				<div className = "col-lg-6 mb-4 mb-lg-0">
					<div className = "farmer-photo h-100">
						<img src = {farmerPhoto} alt = "farmer-photo"/>
					</div>
				</div>
				<div className = "col-lg-6">
					<div className = "started-info h-100">
						<div className = "started-info-sec">
							<div className = "numb-side">
								<div className = "numb-sec">1</div>
							</div>
							<div className = "info-description">
								<div className = "started-title">Create Wallet</div>
								<div className = "sec-details">
									If you don’t have a wallet address create one to get started.
								</div>
							</div>
						</div>
						
						<div className = "started-info-sec">
							<div className = "numb-side">
								<div className = "numb-sec">2</div>
							</div>
							<div className = "info-description">
								<div className = "started-title">Connect Wallet</div>
								<div className = "sec-details">
									Choose your provider (e.g., MetaMask, Trust Wallet), and connect.
								</div>
							</div>
						</div>
						
						<div className = "started-info-sec">
							<div className = "numb-side">
								<div className = "numb-sec">3</div>
							</div>
							<div className = "info-description">
								<div className = "started-title">Customize Your Profile</div>
								<div className = "sec-details">
									Easily customize your profile: edit your personal details plus upload the farm pictures.
								</div>
							</div>
						</div>
						
						<div className = "started-info-sec">
							<div className = "numb-side">
								<div className = "numb-sec">4</div>
							</div>
							<div className = "info-description">
								<div className = "started-title">View Your Tips</div>
								<div className = "sec-details">
									View in detail about your tips to see which of your products received the highest number of tips.
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		<div className = "how-work-sec">
			<div className = "howWork-heading">Explore More</div>
			<div className = "row">
			
				<div className = "col-md-6">
					<div className = "explore-card h-100 mb-3">
						<div className = "row">
							<div className = "col-lg-6 col-xl-4 mb-3 mb-lg-0">
								<div className = "explore-image">
									<img src = {exploreImg1} alt = "explore-img"/>
								</div>
							</div>
							<div className = "col-lg-6 col-xl-8">
								<div className = "explore-content">
									<div className = "ec-heading">
										How to create a secure crypto account. 
									</div>
									<p>
										If you don’t have a wallet address create one to get started.
									</p>
									<div className = "explore-link">
										<a href = "#">Play Video <img src = {linkPlayImg} alt = "play-icon"/> </a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className = "col-md-6">
					<div className = "explore-card h-100 mb-3">
						<div className = "row">
							<div className = "col-lg-6 col-xl-4 mb-3 mb-lg-0">
								<div className = "explore-image">
									<img src = {exploreImg2} alt = "explore-img"/>
								</div>
							</div>
							<div className = "col-lg-6 col-xl-8">
								<div className = "explore-content">
									<div className = "ec-heading">
										How to create a secure crypto account. 
									</div>
									<p>
										If you don’t have a wallet address create one to get started.
									</p>
									<div className = "explore-link">
										<a href = "#">Play Video <img src = {linkPlayImg} alt = "play-icon"/> </a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
		</div>
		
		<div className = "how-work-sec">
			<div className = "howWork-heading">Frequently asked questions</div>
			<div className = "row">
				<div className = "col-12">
					<div className = "accordion faq-accordion" id = "faq-accordion">
						<div className="accordion-item">
							<h2 className="accordion-header" id="headingOne">
							  <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
									How do I create an account on the platform and how do I connect my wallet to the platform?
							  </button>
							</h2>
							<div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#faq-accordion">
							  <div className="accordion-body">
								<strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
							  </div>
							</div>
						</div>
						<div className="accordion-item">
							<h2 className="accordion-header" id="headingTwo">
							  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
									Where can I view the tips that I've received for my products?
							  </button>
							</h2>
							<div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faq-accordion">
							  <div className="accordion-body">
								<strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
							  </div>
							</div>
						</div>
						<div className="accordion-item">
							<h2 className="accordion-header" id="headingThree">
							  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
									How do I upload pictures of my farm to my profile and how many pics i can upload?
							  </button>
							</h2>
							<div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faq-accordion">
							  <div className="accordion-body">
								<strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
							  </div>
							</div>
						</div>
						<div className="accordion-item">
							<h2 className="accordion-header" id="headingfour">
							  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour" aria-expanded="false" aria-controls="collapsefour">
									Is my personal information secure on the platform?
							  </button>
							</h2>
							<div id="collapsefour" className="accordion-collapse collapse" aria-labelledby="headingfour" data-bs-parent="#faq-accordion">
							  <div className="accordion-body">
								<strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
							  </div>
							</div>
						</div>
						<div className="accordion-item">
							<h2 className="accordion-header" id="headingfive">
							  <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefive" aria-expanded="false" aria-controls="collapsefive">
									How do I update my personal information?
							  </button>
							</h2>
							<div id="collapsefive" className="accordion-collapse collapse" aria-labelledby="headingfive" data-bs-parent="#faq-accordion">
							  <div className="accordion-body">
								<strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
							  </div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		
	</div>
</div>

</section>

    )
}
export default Roadmap;