import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { authContext } from "../../components/Context/AuthContext";
import { walletContext } from "../../components/Context/WalletConnectContext";
import { axiosInstance } from "../../axiosConfig/axiosInstance";
import { toast } from "react-toastify";
import { Pagination, PaginationItem } from "@mui/material";
import Loader from "../../components/Loader/Loader";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import { BrowserProvider, ContractFactory } from "ethers";
import contractBytecode from "../../contracts/bytecode.json";
import contractABI from "../../contracts/abi.json";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import contract1155ABI from "../../contracts/abi1155.json";
import contract1155Bytecode from "../../contracts/bytecode1155.json";
import deleteModal from "../../assets/images/icons/delet-modal.svg";
import {
  deployingPayload,
  deployingPayloadForAdminTransaction
} from "../../utils/payload";
import productPlaceholder from "../../assets/images/products/product.png";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const Products = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage] = useState(10);
  const navigate = useNavigate();
  const [isUpdating, setIsUpdating] = useState(false);
  const { state } = useContext(authContext);
  const { walletConnect, updateWallet } = useContext(walletContext);
  const [deployingProduct, setDeployingProduct] = useState(null);
  const [user] = useState(
    JSON.parse(localStorage.getItem("loggedinUser")) || state?.user
  );
  const [connectedWallet, setConnectedWallet] = useState();
  const [activeTab, setActiveTab] = useState("Active Products");
  const { walletProvider } = useWeb3ModalProvider(
    JSON.parse(sessionStorage.getItem("walletConfig"))
  );

  const [productToDelete, setProductToDelete] = useState(null);
  const [show, setShow] = useState(false);
  const [isTransactionPending, setIsTransactionPending] = useState(false);
  const [isTransactionPendingSession, setIsTransactionPendingSession] =
    useState(JSON.parse(sessionStorage.getItem("isTransactionPending")));
  const { chainId } = useWeb3ModalAccount();
  const [sessionObj, setSessionObj] = useState(
    JSON.parse(sessionStorage.getItem("walletConfig"))
  );
  const [totalCount, setTotalCount] = useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [totalDeletedProducts, setTotalDeletedProducts]= useState(0)
  const [totalActiveProducts, setActiveProducts]= useState(0)

  useEffect(() => {
    setConnectedWallet(walletConnect);
  }, [walletConnect]);
  // get all company prodcuts
  useEffect(() => {
    fetchData(currentPage, activeTab);
  }, [currentPage, activeTab]);

  // function that is called when component is mount
  const fetchData = async (currentlyPage, currentTab) => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        `/product/getall?allProducts=${
          user?.superAdmin ? 1 : user?.companyAdmin ? 0 : 0
        }&isDeleted=${
          currentTab === "Active Products" ? 0 : 1
        }&page=${currentlyPage}&perPage=${perPage}`
      );
      setData(response?.data?.products);
      setTotalPages(response?.data?.totalPages);
      setCurrentPage(currentlyPage);
      setTotalCount(response?.data?.totalProductsCount);
      setActiveProducts(response?.data?.totalActiveProducts)
      setTotalDeletedProducts(response?.data?.totalDeletedProducts)
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };
  const handlePagination = async (event, page) => {
    if (page < 1 || page > totalPages) {
      return; // Prevent clicking on disabled pages
    }
    // Calculate the new startPage based on the clicked page
    setCurrentPage(page);
  };

  const handleClose = () => setShow(false);

  // handle pagination mouse over page number
  const handleItemMouseEnter = (event, page) => {
    if (page !== currentPage) {
      event.target.style.color = "white"; // Change color on hover for other pages
      // event.target.style.background = "#202D46";
    }
  };

  // handle pagination mouse leave page number
  const handleItemMouseLeave = (event, page) => {
    if (page !== currentPage) {
      event.target.style.color = "#9FA1A1"; // Restore default color on mouse leave
      // event.target.style.background = "";
    }
  };

  const handleDeleteProduct = async (product) => {
    try {
      setIsUpdating(true);
      let response;
      // if product is already deleted then restore it, if product is not deleted, this will delete the product
      if (product?.isDeleted) {
        response = await axiosInstance.patch("/product/undelete", {
          productId: product?._id
        });
      } else {
        response = await axiosInstance.delete(`/product/delete`, {
          data: { productId: product?._id }
        });
      }

      toast.success(response?.data?.message);
      const updateProducts = await axiosInstance.get(
        `/product/getall?allProducts=${
          user?.superAdmin ? 1 : user?.companyAdmin ? 0 : 0
        }&isDeleted=${
          activeTab === "Active Products" ? 0 : 1
        }&page=${1}&perPage=${perPage}`
      );
      setTotalPages(updateProducts?.data?.totalPages);
      setTotalCount(response?.data?.totalProducts);
      fetchData(
        currentPage === 1 ? 1 : updateProducts?.data?.totalPages,
        activeTab
      );
      return;
    } catch (error) {
      toast.error("Something went wrong");
      return;
    } finally {
      setIsUpdating(false);
      setShow(false);
    }
  };

  // handle edit button
  const handleEditButton = (product) => {
    navigate(`/edit-product/${product?._id}`);
    return;
  };

  // handle active tab logic
  const handleTabs = async (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
    return;
  };

  const handleAlert = (item) => {
    setShow(true);
    setProductToDelete(item);
  };

  const deployProduct = async (product, getCompanySettings) => {
    let encryptedData;
    if (getCompanySettings?.data?.company?.defaultTransactions === "admin") {
      encryptedData = await deployingPayloadForAdminTransaction(
        product,
        getCompanySettings,
        user,
        connectedWallet
      );
    } else {
      let contractFactory, contract;
      try {
        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();
        if (getCompanySettings?.data?.company?.standard === "erc721") {
          contractFactory = new ContractFactory(
            contractABI,
            contractBytecode.bytecode,
            signer
          );
          contract = await contractFactory.deploy(
            getCompanySettings?.data?.company?.walletAddress,
            connectedWallet?.address,
            product?.name,
            product?.name?.substring(0, 3),
            process.env.REACT_APP_ERC721_MAX_SUPPLY,
            `${process.env.REACT_APP_FRONT_END_SERVER_BASE_URL}/product/getProduct/scan/${product?._id}`
          );
        }
        if (getCompanySettings?.data?.company?.standard === "erc1155") {
          contractFactory = new ContractFactory(
            contract1155ABI,
            contract1155Bytecode.bytecode,
            signer
          );
          contract = await contractFactory.deploy(
            getCompanySettings?.data?.company?.walletAddress,
            connectedWallet?.address,
            getCompanySettings?.data?.company?.walletAddress
          );
        }
        // Wait for the contract to be mined
        await contract.waitForDeployment();
      } catch (error) {
        console.log("error in deployment", error?.message);
        sessionStorage.removeItem("isTransactionPending");
        if (error?.info?.error?.message) {
          toast.error(error?.info?.error?.message);
        } else if (error?.error?.message) {
          toast.error(error?.error?.message);
        } else {
          toast.error(
            `Transaction failed. Please try again after some time or contact ${process.env.REACT_APP_SUPPORT_EMAIL}`
          );
        }
        return;
      }

      encryptedData = await deployingPayload(
        product,
        getCompanySettings,
        contract,
        user
      );
    }
    const response = await axiosInstance.post("/product/transaction/history", {
      encryptedData: encryptedData
    });
    if (response) {
      toast.success(response?.data?.message);
      // update product deployment status on real-time
      const updatedFilteredProducts = data?.map((obj) => {
        if (product?._id == obj?._id) {
          return { ...obj, deployed: true };
        } else {
          return { ...obj };
        }
      });
      setData(updatedFilteredProducts);
      return;
    }
  };

  // handle deploying product
  const handleDeployingProduct = async (product) => {
    try {
      const getCompanySettings = await axiosInstance.get("/company/settings");
      if (
        !connectedWallet.isConnected &&
        getCompanySettings?.data?.company?.defaultTransactions !== "admin"
      ) {
        toast.warn(
          "Please Connect your wallet for product deployment on blockchain"
        );
        return;
      }
      if (
        chainId !== user?.networkSetting?.chainId &&
        getCompanySettings?.data?.company?.defaultTransactions !== "admin"
      ) {
        toast.warn(
          `Network conflit occur! Connect your wallet with ${user?.networkSetting?.name} to make transactions`
        );
        return;
      }
      if (product?.company != user?.companyid) {
        toast.error("You are not allowed to deploy this product");
        return;
      }
      if (
        connectedWallet?.address?.toLowerCase() !==
          user?.walletAddress?.toLowerCase() &&
        getCompanySettings?.data?.company?.defaultTransactions !== "admin"
      ) {
        toast.warn(
          "Wallet mismatch! Your profile wallet is different from connected wallet"
        );
        return;
      }
      if (!user?.companyAdmin) {
        toast.warn("Only company Admin can deploy product");
        return;
      }
      setIsUpdating(true);
      setIsTransactionPending(true);
      if (sessionObj) {
        sessionObj.pendingSession = true;
        sessionStorage.setItem("walletConfig", JSON.stringify(sessionObj));
      }
      setDeployingProduct(product?._id);
      const isEligible =
        getCompanySettings?.data?.company?.defaultTransactions === "admin"
          ? true
          : getCompanySettings?.data?.company?.walletAddress?.toLowerCase() ===
            connectedWallet?.address?.toLowerCase();
      if (getCompanySettings?.data?.company?.walletAddress && isEligible) {
        if (
          getCompanySettings?.data?.company?.standard === "erc721" ||
          getCompanySettings?.data?.company?.standard === "erc1155"
        ) {
          updateWallet({ ...walletConnect, pendingSession: true });
          await deployProduct(product, getCompanySettings);
        } else {
          toast.warn(
            "Contract standard not found! Kindly contact with support team"
          );
          return;
        }
      } else {
        toast.warn(
          "You can not deploy product as Default admin wallet is missing. Kindly contact support team"
        );
        return;
      }
    } catch (error) {
      console.log("error in deploy product outer", error);
      // toast.error(error?.info?.error?.message);
      return;
    } finally {
      setIsUpdating(false);
      setDeployingProduct(null);
      setIsTransactionPending(false);
      if (sessionObj) {
        sessionObj.pendingSession = false;
        sessionStorage.setItem("walletConfig", JSON.stringify(sessionObj));
      }
      updateWallet({ ...walletConnect, pendingSession: false });
      setIsTransactionPendingSession(false);
    }
  };

  return (
    <>
      {show && (
        <>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            className="modal fade deleteModal"
          >
            <Modal.Body className="Modal-body text-center">
              <div className="modal-header-icon">
                <img
                  className="mx-auto"
                  src={deleteModal}
                  alt="email-icon"
                  style={{
                    width: "84px",
                    height: "auto",
                    marginBottom: "35px"
                  }}
                />
              </div>

              <div className="Modal-title ">
                Do you really want to delete this product?
              </div>
              <ul className="nav justify-content-center align-items-center">
                <li className="nav-item">
                  <Button
                    variant="delete"
                    onClick={() => handleDeleteProduct(productToDelete)}
                    className="btn btn-delete"
                  >
                    Yes, Delete it
                  </Button>
                </li>
                <li className="nav-item">
                  <Button
                    variant="danger"
                    onClick={handleClose}
                    className="btn btn-dngr"
                  >
                    No
                  </Button>
                </li>
              </ul>
            </Modal.Body>
          </Modal>
        </>
      )}

      <section className="section-main ">
        {isLoading ? (
          <Loader />
        ) : (
          <div className="content-sec ">
            <div className="container-fluid">
                <div className="row">
                  <div className="col-8">
                    <div className="nav nav-tabs products-tab flex-nowrap">
                      <button
                        className={`nav-link ${
                          activeTab === "Active Products" ? "active" : ""
                        }`}
                        data-bs-toggle="tab"
                        onClick={() => handleTabs("Active Products")}
                      >
                        Active Products ({totalActiveProducts})
                      </button>
                      <button
                        className={`nav-link ${
                          activeTab === "Deleted Products" ? "active" : ""
                        }`}
                        data-bs-toggle="tab"
                        onClick={() => handleTabs("Deleted Products")}
                      >
                        Deleted Products ({totalDeletedProducts})
                      </button>
                    </div>
                  </div>
                  {user?.isViewer || user?.superAdmin ? null : (
                    <div className="col-4">
                      <div className="d-flex justify-content-end">
                        <Link to={"/add-product"} className="btn btn-primary">
                          Add Product
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              
              {data?.length > 0 ? (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="products-table pt-30">
                        <div className="table-responsive handle-product-scroll ">
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Product Name</th>
                                <th></th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.map((item, index) => {
                                return (
                                  <tr
                                    key={index}
                                    style={{
                                      color:
                                        isUpdating ||
                                        isTransactionPendingSession
                                          ? "grey"
                                          : "",
                                      cursor:
                                        isUpdating ||
                                        isTransactionPendingSession
                                          ? "not-allowed"
                                          : ""
                                    }}
                                  >
                                    <td>
                                      <ul
                                        className="nav align-items-center"
                                        style={{ width: "50vw" }}
                                      >
                                        <li className="nav-item">
                                          <div className="product-img">
                                            {item?.pictures?.length ? (
                                              item?.isDeleted ? (
                                                <img
                                                  src={`${process.env.REACT_APP_SERVER_URL}/uploads/${item?.company}/${item?.pictures[0]}`}
                                                />
                                              ) : (
                                                <Link
                                                  to={`/product-details/${item?._id}`}
                                                >
                                                  <img
                                                    src={`${process.env.REACT_APP_SERVER_URL}/uploads/${item?.company}/${item?.pictures[0]}`}
                                                  />
                                                </Link>
                                              )
                                            ) : (
                                              <Link to={`/product-details/${item?._id}`}>
                                              <img src={productPlaceholder} />
                                              </Link>
                                            )}
                                          </div>
                                        </li>
                                        {item?.isDeleted ? (
                                          <li className="nav-item">
                                            {item?.name?.length > 40
                                              ? item?.name?.substring(0, 40) +
                                                "..."
                                              : item?.name}
                                          </li>
                                        ) : (
                                          <Link
                                            to={`/product-details/${item?._id}`}
                                            style={{ color: "#212529" }}
                                          >
                                            <li className="nav-item">
                                              {item?.name?.length > 40
                                                ? item?.name?.substring(0, 40) +
                                                  "..."
                                                : item?.name}
                                            </li>
                                          </Link>
                                        )}
                                      </ul>
                                    </td>

                                    {item?.isDeleted ? null : (
                                      <>
                                        {item?.deployed ? (
                                          <td>
                                            <Link
                                              to={`/product-details/${item?._id}`}
                                              className="btn btn-light"
                                            >
                                              <svg
                                                width="17"
                                                height="16"
                                                viewBox="0 0 17 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <g clipPath="url(#clip0_1121_792)">
                                                  <path
                                                    d="M8.50006 1.4375C6.11533 1.4375 4.20578 2.51562 2.81568 3.79883C1.43443 5.07031 
                      0.510646 6.59375 0.0738403 7.63965C-0.0235555 7.87109 -0.0235555 8.12891 0.0738403 8.36035C0.510646 
                      9.40625 1.43443 10.9297 2.81568 12.2012C4.20578 13.4844 6.11533 14.5625 8.50006 14.5625C10.8848 
                      14.5625 12.7943 13.4844 14.1844 12.2012C15.5657 10.9268 16.4895 9.40625 16.9292 8.36035C17.0266 
                      8.12891 17.0266 7.87109 16.9292 7.63965C16.4895 6.59375 15.5657 5.07031 14.1844 3.79883C12.7943 
                      2.51562 10.8848 1.4375 8.50006 1.4375ZM4.25006 8C4.25006 6.88112 4.69782 5.80806 5.49485 
                      5.01689C6.29188 4.22572 7.37289 3.78125 8.50006 3.78125C9.62723 3.78125 10.7082 4.22572 
                      11.5053 5.01689C12.3023 5.80806 12.7501 6.88112 12.7501 8C12.7501 9.11888 12.3023 
                      10.1919 11.5053 10.9831C10.7082 11.7743 9.62723 12.2188 8.50006 12.2188C7.37289 12.2188 
                      6.29188 11.7743 5.49485 10.9831C4.69782 10.1919 4.25006 9.11888 4.25006 8ZM8.50006 
                      6.125C8.50006 7.15918 7.65301 8 6.61117 8C6.40162 8 6.20092 7.96484 6.01203 7.90332C5.84971 
                      7.85059 5.66082 7.9502 5.66672 8.12012C5.67558 8.32227 5.70509 8.52441 5.76117 8.72656C6.16551 
                      10.2266 7.72089 11.1172 9.232 10.7158C10.7431 10.3145 11.6403 8.77051 11.236 7.27051C10.9084 
                      6.05469 9.82523 5.2373 8.62106 5.1875C8.44988 5.18164 8.34953 5.36621 8.40266 5.53027C8.46464 
                      5.71777 8.50006 5.91699 8.50006 6.125Z"
                                                    fill=""
                                                  />
                                                </g>
                                                <defs>
                                                  <clipPath id="clip0_1121_792">
                                                    <rect
                                                      width="17"
                                                      height="15"
                                                      fill="white"
                                                      transform="translate(0 0.5)"
                                                    />
                                                  </clipPath>
                                                </defs>
                                              </svg>
                                              Details
                                            </Link>
                                          </td>
                                        ) : (
                                          <td>
                                            {user?.companyAdmin ? (
                                              <Link
                                                style={{
                                                  cursor:
                                                    (isUpdating &&
                                                      item?._id ==
                                                        deployingProduct) ||
                                                    isTransactionPendingSession ||
                                                    !user?.companyAdmin
                                                      ? "not-allowed"
                                                      : null
                                                }}
                                                to={""}
                                                className={`btn btn-primary deploy`}
                                                onClick={() =>
                                                  user?.companyAdmin &&
                                                  !(
                                                    isUpdating ||
                                                    isTransactionPendingSession
                                                  )
                                                    ? handleDeployingProduct(
                                                        item
                                                      )
                                                    : null
                                                }
                                              >
                                                <svg
                                                  width="15"
                                                  height="16"
                                                  viewBox="0 0 15 16"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <g clipPath="url(#clip0_1121_782)">
                                                    <path
                                                      d="M4.58786 11.7764L3.68258 10.8711C3.43356 10.6221 3.34567 10.2617 3.457 
                   9.92775C3.54489 9.66701 3.66208 9.32717 3.8027 8.93752H0.703092C0.451139 8.93752 
                   0.216764 8.80275 0.0907877 8.58302C-0.0351889 8.3633 -0.0322592 8.09377 0.0966471 
                   7.87697L1.63473 5.2842C2.01559 4.64259 2.70407 4.25002 3.44821 4.25002H5.85934C5.92965 4.13283 
                   5.99997 4.02443 6.07028 3.91896C8.46969 0.379899 12.0439 0.262712 14.1767 0.65529C14.5166 
                   0.716814 14.7802 0.983415 14.8447 1.32326C15.2373 3.459 15.1172 7.03029 11.581 9.4297C11.4785 
                   9.50002 11.3672 9.57033 11.25 9.64064V12.0518C11.25 12.7959 10.8574 13.4873 10.2158 13.8653L7.62301 
                   15.4033C7.40622 15.5322 7.13669 15.5352 6.91696 15.4092C6.69723 15.2832 6.56247 15.0518 6.56247 
                   14.7969V11.6563C6.14938 11.7998 5.78903 11.917 5.51657 12.0049C5.18844 12.1104 4.83102 12.0195 
                   4.58493 11.7764H4.58786ZM11.25 5.42189C11.5608 5.42189 11.8588 5.29843 12.0786 5.07866C12.2984 
                   4.85889 12.4218 4.56082 12.4218 4.25002C12.4218 3.93922 12.2984 3.64115 12.0786 3.42138C11.8588 
                   3.20161 11.5608 3.07814 11.25 3.07814C10.9392 3.07814 10.6411 3.20161 10.4213 3.42138C10.2016 
                   3.64115 10.0781 3.93922 10.0781 4.25002C10.0781 4.56082 10.2016 4.85889 10.4213 5.07866C10.6411 
                   5.29843 10.9392 5.42189 11.25 5.42189Z"
                                                      fill=""
                                                      s
                                                    />
                                                  </g>
                                                  <defs>
                                                    <clipPath id="clip0_1121_782">
                                                      <rect
                                                        width="15"
                                                        height="15"
                                                        fill="white"
                                                        transform="translate(-3.05176e-05 0.5)"
                                                      />
                                                    </clipPath>
                                                  </defs>
                                                </svg>
                                                {isUpdating &&
                                                item?._id ==
                                                  deployingProduct ? (
                                                  <span
                                                    className="spinner-border spinner-border-sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                  ></span>
                                                ) : isTransactionPending ||
                                                  isTransactionPendingSession ? (
                                                  <span
                                                    style={{
                                                      cursor: "not-allowed"
                                                    }}
                                                  >
                                                    Deploy
                                                  </span>
                                                ) : (
                                                  <span>Deploy</span>
                                                )}
                                              </Link>
                                            ) : null}
                                          </td>
                                        )}
                                      </>
                                    )}

                                    {user?.superAdmin ||
                                    user?.isViewer ? null : (
                                      <>
                                          <td>
                                            <div className="dropdown">
                                              <button
                                                className="btn btn-secondary dropdown-toggle option-btn"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                                disabled={isUpdating}
                                              >
                                                <i className="bi bi-three-dots-vertical"></i>
                                              </button>

                                              <ul className="dropdown-menu">
                                                {item?.isDeleted ? (
                                                  <>
                                                    <li
                                                      className="dropdown-item grant-access-hover"
                                                      onClick={() =>
                                                        handleDeleteProduct(
                                                          item
                                                        )
                                                      }
                                                    >
                                                      <i className="bi bi-arrow-counterclockwise "></i>
                                                      Restore
                                                    </li>
                                                  </>
                                                ) : (
                                                  <>
                                                    <li
                                                      onClick={() => {
                                                        handleEditButton(item);
                                                      }}
                                                    >
                                                      <Link className="dropdown-item">
                                                        <i className="bi bi-pencil"></i>
                                                        Edit
                                                      </Link>
                                                    </li>
                                                    <li
                                                      onClick={() =>
                                                        handleAlert(item)
                                                      }
                                                    >
                                                      <Link
                                                        className="dropdown-item"
                                                        to={""}
                                                      >
                                                        <i className="bi bi-trash"></i>
                                                        Delete
                                                      </Link>
                                                    </li>
                                                  </>
                                                )}
                                              </ul>
                                            </div>
                                          </td>
                                      </>
                                    )}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        {totalCount > perPage ? (
                          <div className="table-footer row">
                            <div className="col-12">
                              <ul className="nav align-items-center justify-content-end">
                                <Pagination
                                  count={totalPages}
                                  page={currentPage}
                                  shape="rounded"
                                  onChange={handlePagination}
                                  siblingCount={isMobile ? 0 : 2}
                                  boundaryCount={isMobile ? 0 : 2}
                                  renderItem={(item) => (
                                    <PaginationItem
                                      {...item}
                                      onMouseEnter={(e) =>
                                        handleItemMouseEnter(e, item.page)
                                      }
                                      onMouseLeave={(e) =>
                                        handleItemMouseLeave(e, item.page)
                                      }
                                      style={{
                                        background:
                                          item.page === currentPage
                                            ? "#202D46"
                                            : "",
                                        color:
                                          item.page === currentPage
                                            ? "white"
                                            : "#9FA1A1"
                                      }}
                                    />
                                  )}
                                />
                              </ul>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-center align-items-center remove-scroll-bar">
                    <div>
                      <p>No Data Found</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </section>
    </>
  );
};
export default Products;
