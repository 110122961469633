import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { axiosInstance } from "../../axiosConfig/axiosInstance";
import { toast } from "react-toastify";
import { authContext } from "../../components/Context/AuthContext";
import VerificationApproval from "./VerificationApproval";
import { notfoundContext } from "../../components/Context/NotFoundContext";
import Welcome from "../Farmer/Operations/Welcome";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const Login = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [inputFields, setInputFields] = useState({ email: "", password: "" });
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInputAvailable, setIsInputAvailable] = useState(false);
  const { updateState } = useContext(authContext);
  const [isHolding, setIsHolding] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [user] = useState(JSON.parse(localStorage.getItem("loggedinUser")));
  const { update404State } = useContext(notfoundContext);
  const [farmerWelcom, setFarmerWelcom] = useState(false);

  const handleInputFields = (event) => {
    let { name, value } = event.target;
    setInputFields({ ...inputFields, [name]: value });
    // Check if any of the fields have a value and update isFormFilled state
    setIsInputAvailable((prevIsFormFilled) => {
      if (
        !prevIsFormFilled &&
        (name === "email" || name === "password") &&
        value !== ""
      ) {
        return true;
      } else if (
        prevIsFormFilled &&
        name === "email" &&
        value === "" &&
        inputFields.password === ""
      ) {
        return false;
      } else if (
        prevIsFormFilled &&
        name === "password" &&
        value === "" &&
        inputFields.email === ""
      ) {
        return false;
      } else {
        return prevIsFormFilled;
      }
    });
  };

  useEffect(() => {
    update404State(false);
  }, [isHolding]);

  const handleFormSubmission = async (event) => {
    event.preventDefault();
    try {
      // Add your logic to submit to your backend server here.
      if (!executeRecaptcha) {
        toast.warn("Recaptcha not yet available");
        return;
      }
      // Get reCAPTCHA token
      const token = await executeRecaptcha("login");
      setIsLoading(true);
      const response = await axiosInstance.post("/user/login", {
        ...inputFields,
        captchaToken: token
      });
      if (response) {
        if (response?.data && response?.data !== 400) {
          if (response?.data?.success) {
            localStorage.setItem(
              "loggedinUser",
              JSON.stringify(response?.data)
            );
            updateState({ user: response?.data });
            setIsLoading(false);
            // super admin
            if (response?.data?.superAdmin || response?.data?.companyAdmin) {
              toast.success("Admin login successfully");
              navigate("/dashboard");
            } else if (response?.data?.isFarmer) {
              toast.success("Login successful");
              if (response?.data?.reviewRequest) {
                navigate("/farmer/profile");
              } else {
                navigate("/welcome");
                setFarmerWelcom(true);
              }
            } else {
              toast.success("Company user login successfully");
              navigate("/products");
            }
          } else {
            setIsHolding(true);
          }
        } else {
          setIsLoading(false);
          toast.error("recaptcha validation failed");
        }
      } else {
        setIsLoading(false);
      }
      return;
    } catch (error) {
      setIsLoading(false);
      console.log("error is", error);
      toast.error(
        "Recaptcha does not load. Kindly check your internet or reload page."
      );
      return;
    }
  };
  const handleVerificationApproval = (newState) => {
    setIsHolding(newState);
    setIsLoading(newState);
  };
  return (
    <>
      {isHolding ? (
        <VerificationApproval
          handleVerificationApproval={handleVerificationApproval}
        />
      ) : farmerWelcom ? (
        <Welcome />
      ) : (
        <>
          <section className="section-main ms-0 removing-scrolling">
            <div className="login-signup-section d-flex align-items-center justify-content-center py-4">
              <div className="login-signup-form">
                <form onSubmit={handleFormSubmission} id="login-form">
                  <div className="form-title">Login in to your account</div>
                  <div className="form-group form-floating">
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      id="emailInput"
                      name="email"
                      onChange={handleInputFields}
                      required
                    />
                    <label>Email</label>
                  </div>

                  <div className="form-group form-floating">
                    <input
                      type={isPasswordShown ? "text" : "password"}
                      id="password"
                      className="form-control"
                      placeholder="Password"
                      name="password"
                      onChange={handleInputFields}
                      required
                    />
                    <label>Password</label>
                    <div
                      id="passwordbtn"
                      className={`show-hide-btn ${
                        isPasswordShown ? "active" : ""
                      }`}
                      data-target="password"
                      onClick={() => {
                        setIsPasswordShown(!isPasswordShown);
                      }}
                    >
                      <span>password-icon-show</span>
                    </div>
                  </div>

                  <div className="form-group mt-3">
                    <div className="note main-link">
                      <Link to={"/forget-password"}>Forgot your password?</Link>
                    </div>
                  </div>

                  <div className="form-group">
                    {isLoading ? (
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled
                      >
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={!isInputAvailable}
                      >
                        Login
                      </button>
                    )}
                  </div>
                </form>

                <div className="form-footer">
                  Don't have an account? <Link to={"/signup"}> Sign up </Link>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default Login;
