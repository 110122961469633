import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";

const RejectReviewRequestModal = ({
  updateRejectModalStatus,
  show,
  handleRejectModalClose,
  userInfo
}) => {
  const [declineReason, setDeclineReason] = useState("");
  
  const handleButtonClick = () => {
    if (declineReason) {
      updateRejectModalStatus(declineReason);
    }
  };
  const handleCloseButton = () => {
    handleRejectModalClose(false);
  };

  const handleInputChange = (event) => {
    event.preventDefault();
    setDeclineReason(event.target.value);
  };
  return (
    <Modal
      show={show}
      onHide={handleCloseButton}
      backdrop="static"
      keyboard={false}
      centered
      className="modal fade deleteModal"
    >
      <Modal.Body className="Modal-body text-center">
        <div className="Modal-title mb-3">Are you sure to Reject?</div>

        <div>
          <p>
            You are going to reject "{userInfo?.email}
            ". Please provide the reason of rejection.
          </p>
        </div>

        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Control
              as="textarea"
              rows={6}
              placeholder="Please Provide the reason of rejection."
              value={declineReason}
              onChange={handleInputChange}
            />
          </Form.Group>
        </Form>

        <ul className="nav justify-content-center align-items-center">
          <li className="nav-item">
            <Button
              variant="delete"
              onClick={handleButtonClick}
              className="btn btn-delete"
            >
              Yes, Decline
            </Button>
          </li>
          <li className="nav-item">
            <Button
              variant="danger"
              onClick={handleCloseButton}
              className="btn btn-dngr"
            >
              No
            </Button>
          </li>
        </ul>
      </Modal.Body>
    </Modal>
  );
};

export default RejectReviewRequestModal;
