import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { axiosInstance } from "../../axiosConfig/axiosInstance";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import { TbSettingsCheck } from "react-icons/tb";
import Modal from "react-bootstrap/Modal";
import deleteModal from "../../assets/images/icons/delet-modal.svg";
import Button from "react-bootstrap/Button";
import userPlaceholder from "../../assets/images/icons/userplaceholder.png";

const UsersListing = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("Active Users");
  const [isUpdating, setIsUpdating] = useState(false);
  const [user] = useState(JSON.parse(localStorage.getItem("loggedinUser")));
  const [show, setShow] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [totalDeletedUsers, setTotalDeletedUsers] = useState(0);
  const [totalActiveUsers, setTotalActiveUsers] = useState(0);

  useEffect(() => {
    fetchData(activeTab);
  }, [activeTab]);

  // function that is called when component is mount
  const fetchData = async (currentTab) => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        `/user/company-users?isDeleted=${currentTab === "Active Users" ? 0 : 1}`
      );
      setData(response?.data?.users);
      setTotalActiveUsers(response?.data?.totalActiveUsers);
      setTotalDeletedUsers(response?.data?.totalDeletedUsers);
    } catch (error) {
      toast.error("Something went wrong");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAlert = (item) => {
    setUserToDelete(item);
    setShow(true);
  };
  const handleClose = () => setShow(false);
  // handle Edit user
  const handleEditUser = (user) => {
    if (user?.isDeleted) {
      // if user already soft-deleted then admin can not edit profile of user
      toast.warn("Restore user to edit profile");
    } else {
      navigate(`/edit-user/${user?._id}`);
    }
  };

  

  // handle delete user
  const handleDeleteUser = async (user) => {
    try {
      setIsUpdating(true);
      let response;
      // if user is already deleted then restore it, if user is not deleted, this will delete the user
      if (user?.isDeleted) {
        response = await axiosInstance.patch("/user/undeleteuser", {
          userId: user?._id
        });
      } else {
        response = await axiosInstance.delete(`/user/deleteuser`, {
          data: { userId: user?._id }
        });
      }
      toast.success(response?.data?.message);
      const updatedUsers = await axiosInstance.get(
        `/user/company-users?isDeleted=${activeTab === "Active Users" ? 0 : 1}`
      );
      setData(updatedUsers?.data?.users);
      setTotalActiveUsers(updatedUsers?.data?.totalActiveUsers);
      setTotalDeletedUsers(updatedUsers?.data?.totalDeletedUsers);
      return;
    } catch (error) {
      toast.error("Something went wrong");
      return;
    } finally {
      setIsUpdating(false);
      setShow(false);
    }
  };
  // handle active tab logic
  const handleTabs = async (tab) => {
    setActiveTab(tab);
  };

  const shortEmail = (email) => {
      return email?.substring(0, 20) + "..";
  };

  const shortName = (name) => {
    return name?.length > 20 ? name?.substring(0, 20) + ".." : name;
  };

  return (
    <>
      {show && (
        <>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
            className="modal fade deleteModal"
          >
            <Modal.Body className="Modal-body text-center">
              <div className="modal-header-icon">
                <img
                  className="mx-auto"
                  src={deleteModal}
                  alt="email-icon"
                  style={{
                    width: "84px",
                    height: "auto",
                    marginBottom: "35px"
                  }}
                />
              </div>

              <div className="Modal-title ">
                Do you really want to delete this user?
              </div>
              <ul className="nav justify-content-center align-items-center">
                <li className="nav-item">
                  <Button
                    variant="delete"
                    onClick={() => handleDeleteUser(userToDelete)}
                    className="btn btn-delete"
                  >
                    Yes, Delete it
                  </Button>
                </li>
                <li className="nav-item">
                  <Button
                    variant="danger"
                    onClick={handleClose}
                    className="btn btn-dngr"
                  >
                    No
                  </Button>
                </li>
              </ul>
            </Modal.Body>
          </Modal>
        </>
      )}
      <>
        <section className="section-main">
          {isLoading ? (
            <Loader />
          ) : (
            <div className="content-sec">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-8">
                    <div className="nav nav-tabs products-tab">
                      <button
                        className={`nav-link ${
                          activeTab === "Active Users" ? "active" : ""
                        }`}
                        data-bs-toggle="tab"
                        onClick={() => handleTabs("Active Users")}
                      >
                        Active Users ({totalActiveUsers})
                      </button>
                      <button
                        className={`nav-link ${
                          activeTab === "Deleted Users" ? "active" : ""
                        }`}
                        data-bs-toggle="tab"
                        onClick={() => handleTabs("Deleted Users")}
                      >
                        Deleted Users ({totalDeletedUsers})
                      </button>
                    </div>
                  </div>
                  {user?.isViewer || user?.isEditor ? null : (
                    <div className="col-4">
                      <div className="d-flex justify-content-end">
                        <Link to={"/add-user"} className="btn btn-primary">
                          Add User
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
                {data?.length > 0 ? (
                    <div className="row py-3 py-lg-5">
                      {data?.map((item, index) => {
                        return (
                          <div className="col-12 col-md-6 col-lg-6 col-xl-4" key={index} >
                            <div
                              className="usercard"
                              style={{
                                color: isUpdating ? "grey" : "",
                                cursor: isUpdating ? "not-allowed" : ""
                              }}
                            >
                              <div className="usercard-img">
                                {item?.profilePic ? (
                                  <img
                                    src={`${process.env.REACT_APP_SERVER_URL}/uploads/pfp/${item?.profilePic}`}
                                    alt="user-img"
                                  />
                                ) : (
                                  <img src={userPlaceholder} />
                                )}
                              </div>
                              <div className="usercard-content">
                                <div className="user-name">
                                  {shortName(
                                    `${item?.firstName} ${item?.lastName}`
                                  )}
                                </div>
                                {item?.email?.length > 20 ? (
                                  <div className="user-email">
                                    <Link to={"#"}>
                                      {shortEmail(item?.email)}
                                    </Link>
                                  </div>
                                ) : (
                                  <div className="user-email">
                                    <Link to={"#"}>{item?.email}</Link>
                                  </div>
                                )}
                                <div className="user-post">
                                  {item?.isEditor ? "Editor":"Viewer"}
                                </div>
                              </div>
                              {user?.isViewer || user?.isEditor ? null : (
                                <div className="dropdown">
                                  <button
                                    className="btn btn-secondary dropdown-toggle option-btn"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="bi bi-three-dots-vertical"></i>
                                  </button>
                                  <ul className="dropdown-menu">
                                    {item?.isDeleted ? (
                                      <li
                                        onClick={() => handleDeleteUser(item)}
                                      >
                                        <Link className="dropdown-item grant-access-hover">
                                          <i className="bi bi-arrow-counterclockwise"></i>
                                          Restore
                                        </Link>
                                      </li>
                                    ) : (
                                      <>
                                        <li
                                          onClick={() => handleEditUser(item)}
                                        >
                                          <Link className="dropdown-item">
                                            <i className="bi bi-pencil"></i>
                                            Edit
                                          </Link>
                                        </li>
                                        <li onClick={() => handleAlert(item)}>
                                          <Link className="dropdown-item">
                                            <i className="bi bi-trash"></i>
                                            Delete
                                          </Link>
                                        </li>

                                        {item?.isEditor && (
                                          <li
                                            onClick={() =>
                                              navigate(
                                                `/manage-access/${item?._id}`
                                              )
                                            }
                                          >
                                            <Link className="dropdown-item">
                                              <TbSettingsCheck
                                                style={{
                                                  color: "#3071F1",
                                                  fontSize: "18px",
                                                  marginRight: "10px"
                                                }}
                                              />
                                              Manage Access
                                            </Link>
                                          </li>
                                        )}
                                      </>
                                    )}
                                  </ul>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                ) : (
                  <>
                    <div className="d-flex justify-content-center align-items-center remove-scroll-bar">
                      <div>
                        <p>No Data Found</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </section>
      </>
    </>
  );
};
export default UsersListing;
