import React,{useContext,useEffect} from "react";
import approvalHoldigLogo from "../../../assets/images/icons/holding.svg"
import { Link } from "react-router-dom";
import { notfoundContext } from "../../../components/Context/NotFoundContext";


const VerificationApproval = ({handleVerificationApproval}) => {
  const { update404State } = useContext(notfoundContext);
  useEffect(()=>{
    update404State(true);
  },[])

  const handleLogin = ()=>{
    handleVerificationApproval(false)
  }
  return (
    <>
      <section className="section-main ms-0">
        <section className="min-vh-100 d-flex justify-content-center align-items-center account-verified v-holding">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="body-account-verified text-center">
                  <img src={approvalHoldigLogo} alt="scuccess-icon" />
                  <h2>Your account approval is currently in progress</h2>
                  <p>
                    We are currently in the process of verifying your account.
                    You will receive a notification on your email once the
                    verification is complete.
                  </p>
                  <Link
                      className="btn btn-primary w-100"
                      role="button"
                      onClick={handleLogin}
                    >
                     Back to Login
                    </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};
export default VerificationApproval;
