import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  console.log("-------------------error in errorBoundary", error)
  return (

    <div role="alert" style={{display:"flex", justifyContent:"center", alignItems:"center", height:"100vh" }}>
    <p>Oops! Something went wrong! Please refresh the page.</p>
    {/* <button onClick={resetErrorBoundary}>Try Again</button> */}
  </div>
  )
}

const ErrorBoundary = ({ children }) => (
  <ReactErrorBoundary
    FallbackComponent={ErrorFallback}
    // onReset={() => {
    //   // Reset any state here if needed
    // }}
  >
    {children}
  </ReactErrorBoundary>
);

export default ErrorBoundary;
