import web3 from "web3";
import {
    createWeb3Modal,
    defaultConfig,
  } from "@web3modal/ethers/react";
export const setLocalStorage = (key, value) => {
  return localStorage.setItem(key, value);
};

export const validateWalletAddress = (walletAddress) => {
  // Define a regular expression to match a MetaMask wallet address
  const addressRegex = /^0x[a-fA-F0-9]{40}$/;
  if (addressRegex.test(walletAddress)) {
    return true;
  } else {
    return false;
  }
};

export const checkWalletChecksum = async (wallet) => {
  try {
    return web3.utils.toChecksumAddress(wallet);
  } catch (error) {
    console.log('error while wallet validation', error);
    return false;
  }
};


// network configurations
export const networkConfigurations = async (network, projectId)=>{
    const ethersConfig = defaultConfig({
        metadata: {
          name: "Scan and Trace",
          description:
            "Scan and trace connection",
          url: "https://web3modal.com",
          icons: [
            "https://drive.google.com/file/d/19BBUCil8jHTf872DXutrg2V4DQsMEjJr/view?usp=sharing"
          ]
        },
        defaultChain: network[0]
      });
    
      createWeb3Modal({
        ethersConfig,
        network,
        projectId,
        enableAnalytics: true, // Optional - defaults to your Cloud configuration
        allWallets: "ONLY_MOBILE",
        featuredWalletIds: []
      });
}




