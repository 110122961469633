import React from "react";
import mailLogo from "../../assets/images/login-signup/mail.svg";
import { useNavigate } from "react-router-dom";
const EmailPopup = (props) => {
  const navigate = useNavigate();
  return (
    <>
      <section className="section-main ms-0 removing-scrolling">
        <div className="login-signup-section d-flex align-items-center justify-content-center py-4">
          <div className="login-signup-form">
            <form>
              <div className="form-title">
                <img src={mailLogo} alt="email-icon" />
              </div>

              <div className="form-title">Check your mail</div>
              <div className="form-group">
                <div className="title-description">{props.text}</div>
              </div>

              <div className="form-group">
                {props.purpose == "feedback" ? (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => navigate(`/product/getProduct/scan/${props?.productId}`)}
                  >
                    Back to review
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => navigate("/login")}
                  >
                    Back to login
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default EmailPopup;
