import React, { useState, useEffect } from "react";
import breadcrumbLogo from "../../assets/images/icons/breadcrumb-icon.svg";
import uploadProductLogo from "../../assets/images/icons/upload-product.svg";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  axiosInstance,
  axiosInstanceWithFormData
} from "../../axiosConfig/axiosInstance";
import ImageUploadProgress from "./ImageUploadProgress";
import { v4 as uuidv4 } from "uuid";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { MdClose } from "react-icons/md";
import DraggableUploadListItem from "../../components/HOCs/DragAndDrop";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { Upload } from "antd";
import dragAndDropLogo from "../../assets/images/icons/dragdrop.svg";
import userPlaceholder from "../../assets/images/icons/userplaceholder.png";
import { Country, City } from "country-state-city";
import { Dropdown, DropdownButton } from "react-bootstrap";

const AddProduct = () => {
  const [product, setProduct] = useState({
    name: "",
    brand: "",
    description: "",
    nutritionImage: "",
    media: []
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [previewImages, setPreviewImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const [nutrition, setNutrition] = useState(null);
  const [cities, setCities] = useState([]);
  const [editorToolBar] = useState({
    toolbar: ["bold", "italic", "bulletedList", "numberedList"]
  });
  const [locations, setLocations] = useState([]);
  const [placeholderCountry, setPlaceholderCountry] =
    useState("Select Country");
  const [cityPlaceholder, setCityPlaceholder] = useState("Select City");
  const [isLocationAvailable, setIsLocationAvailable] = useState(false);
  const [activeLocation, setActiveLocation] = useState({
    label: "",
    country: "",
    city: "",
    countryCode: ""
  });
  const [isLocationEdit, setIsLocationEdit] = useState(false);
  const [countries, setCountries] = useState([]);
  const [farmers, setFarmers] = useState([]);
  const [selectedFarmer, setSelectedFarmer] = useState(null);

  useEffect(() => {
    getCountries();
    getCompanyFarmers();
  }, []);

  const getCountries = () => {
    setCountries(Country.getAllCountries());
  };

  // get company farmers
  const getCompanyFarmers = async () => {
    try {
      const response = await axiosInstance.get("/product/farmers");
      setFarmers(response?.data?.farmers);
    } catch (error) {
      toast.error(error?.message);
      return;
    }
  };

  // handle input fields
  const handleInputFields = (event) => {
    const { name, value } = event.target;
    setProduct({ ...product, [name]: value });
  };

  // handle nutrition image
  const handleNutritionImage = (event) => {
    if (event.target.files.length > 0) {
      const { type, size, name } = event.target.files[0];
      if (
        type === "image/jpeg" ||
        type === "image/jpg" ||
        type === "image/png"
      ) {
        if (size <= 5 * 1024 * 1024) {
          setProduct({ ...product, nutritionImage: event.target.files[0] });
          const reader = new FileReader();
          reader.onload = (e) => {
            const newPreviewImage = {
              image: e.target.result,
              size: `${(size / 1024).toFixed(2)} KB`,
              name: name
            };
            setNutrition(newPreviewImage);
          };
          reader.readAsDataURL(event.target.files[0]);
        } else {
          toast.warn("File size must be less than 5MB");
        }
      } else {
        toast.warn("Only JPG and PNG files supported");
      }
    }
  };
  // handle upload image logic here
  const handleImageChange = (event) => {
    if (event.target.files.length > 0) {
      const { type, size, name } = event.target.files[0];

      if (previewImages.length >= 5) {
        toast.warn("You can add only 5 pictures of the product");
        return;
      }

      if (
        type === "image/jpeg" ||
        type === "image/jpg" ||
        type === "image/png"
      ) {
        if (size <= 1 * 1024 * 1024) {
          // Update the product state with the new media item added to the existing media array
          setProduct((prevProduct) => ({
            ...prevProduct,
            media: [...prevProduct.media, event.target.files[0]]
          }));
          const reader = new FileReader();
          reader.onload = (e) => {
            const newPreviewImage = {
              uid: uuidv4(),
              image: e.target.result,
              size: `${(size / 1024).toFixed(2)} KB`,
              name: name
            };
            setNewImages((previous) => [...previous, newPreviewImage]);
            setPreviewImages((previous) => [...previous, newPreviewImage]);
          };

          reader.readAsDataURL(event.target.files[0]);
        } else {
          toast.warn("File size must be less than 1MB");
        }
      } else {
        toast.warn("Only JPG and PNG files supported");
      }
    }
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setProduct({ ...product, description: data });
  };

  // countries input changing
  const onCountryChange = async (e) => {
    const cities = City.getCitiesOfCountry(e.target.value);
    const country = Country.getCountryByCode(e.target.value);
    setCities(cities);
    setPlaceholderCountry(country?.name);
    setCityPlaceholder(cities[0]?.name);
    setActiveLocation({
      ...activeLocation,
      country: country,
      city: cities[0],
      countryCode: country?.isoCode
    });
  };

  // cities input
  const onCityChange = async (e) => {
    const filteredCities = JSON.parse(e.target.value);
    setCityPlaceholder(filteredCities?.name);
    setActiveLocation({ ...activeLocation, city: filteredCities });
  };

  // add location button
  const handleAddLocationButton = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsLocationAvailable(true);
    setIsLocationEdit(false);
    setActiveLocation({ label: "", country: "", city: "", uid: "" });
    setCityPlaceholder("Select City");
    setPlaceholderCountry("Select Country");
  };

  const handleCanelButtonClick = (event) => {
    event.stopPropagation();
    setIsLocationAvailable(false);
  };

  // handle save location
  const handleSaveLocationClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (
      !activeLocation?.label ||
      !activeLocation?.country ||
      !activeLocation?.city
    ) {
      toast.warn("All fields are required to add location");
      return;
    }
    const existingLocationIndex = locations.findIndex(
      (loc) => loc.uid === activeLocation.uid
    );
    if (existingLocationIndex !== -1) {
      // Update existing location
      let updatedLocations = [...locations];
      updatedLocations[existingLocationIndex] = activeLocation;
      setLocations(updatedLocations);
    } else {
      // Add new location
      setLocations([...locations, activeLocation]);
    }
    setIsLocationAvailable(false);
    setIsLocationEdit(false);
    setCityPlaceholder("Select City");
    setPlaceholderCountry("Select Country");
    setActiveLocation({ label: "", country: "", city: "", uid: "" });
  };

  // delete, added images
  const handleDeleteImage = (index, item) => {
    const filteredPreviewImages = previewImages?.filter((img) => {
      return img?.uid != item?.uid;
    });
    setPreviewImages(filteredPreviewImages);
    const filterRemoveProduct = [...product?.media];
    filterRemoveProduct.splice(index, 1);
    setProduct({ ...product, media: filterRemoveProduct });
  };

  const handleDeleteSavedLocationClick = (location) => {
    const filteredLocations = locations?.filter(
      (item) => location?.uid != item?.uid
    );
    setLocations(filteredLocations);
  };

  const handleLocationLabelOnChange = (event) => {
    const existingLocationIndex = locations.findIndex(
      (loc) => loc.uid === activeLocation.uid
    );
    if (existingLocationIndex !== -1) {
      setActiveLocation({
        ...activeLocation,
        label: event.target.value
      });
    } else {
      setActiveLocation({
        ...activeLocation,
        label: event.target.value,
        uid: uuidv4()
      });
    }
  };

  const handleEditLocationClick = (location) => {
    setIsLocationAvailable(true);
    setIsLocationEdit(true);
    setActiveLocation(location);
    setCityPlaceholder(location?.city?.name);
    setPlaceholderCountry(location?.country?.name);
    setCities(City.getCitiesOfCountry(location?.country?.isoCode));
  };

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10
    }
  });

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setPreviewImages((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const handleFarmerSelection = (eventKey) => {
    setSelectedFarmer(JSON.parse(eventKey));
  };
  // handle form submission
  const handleFormSubmission = async (event) => {
    event.preventDefault();
    if (!product?.description) {
      toast.warn("product description is required");
      return;
    }
    if (product.media?.length == 0) {
      toast.warn("Product image is required");
      return;
    }

    if (locations?.length === 0) {
      toast.warn("Product tracking location is required");
      return;
    }
    try {
      const formData = new FormData();
      if (product.media?.length > 0) {
        for (let i = 0; i < previewImages?.length; i++) {
          const newImg = product?.media?.find((obj) => {
            return previewImages[i]?.name == obj?.name;
          });
          formData.append("media", newImg); // Use 'media' as the field name
        }
      }

      let locationArray = [];
      locations?.forEach((location) => {
        locationArray.push({
          label: location?.label,
          uid: location?.uid,
          country: location?.country?.name,
          city: location?.city?.name,
          lng: location?.city?.longitude,
          ltd: location?.city?.latitude,
          countryCode: location?.countryCode
        });
      });
      formData.append("name", product.name);
      formData.append("brand", product.brand);
      formData.append("description", product.description);
      formData.append("nutritionImage", product?.nutritionImage);
      formData.append("quantity", product.quantity);
      formData.append("locations", JSON.stringify(locationArray));
      if (selectedFarmer) {
        formData.append("farmerId", selectedFarmer?._id);
      }
      setIsLoading(true);
      const response = await axiosInstanceWithFormData.post(
        "/product/add",
        formData
      );
      if (response) {
        toast.success(response?.data?.message);
        navigate("/products");
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className="section-main">
        <div className="content-sec">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-between">
              <div className="breadcrumb-bar">
                <ul className="nav align-items-center">
                  <li className="nav-item">
                    <Link to={"/products"}>Products</Link>
                  </li>
                  <li className="nav-item">
                    <img src={breadcrumbLogo} alt="icon" />
                  </li>
                  <li className="nav-item">Add Product</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="form-section">
                <div className="edit-profile-images">
                  <div className="upload-img">
                    <label>Upload Product Pictures</label>
                    <span className="required-asterisk">*</span>
                    <div className="image-section ">
                      <div className="img-placeholder">
                        <div className="user-img-ic">
                          <img src={uploadProductLogo} alt="icon" />
                        </div>
                        <div className="title">
                          Drop files here or browse to upload
                        </div>
                        <div className="img-dimensions mt-2">
                          Up to 1 MB (JPG or PNG)
                        </div>
                        <input
                          id="upload-img-pu"
                          type="file"
                          onChange={handleImageChange}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
                        <SortableContext
                          items={previewImages.map((i) => i.uid)}
                          strategy={verticalListSortingStrategy}
                        >
                          <Upload
                            fileList={previewImages}
                            onChange={handleImageChange}
                            showUploadList={false}
                          ></Upload>
                          {previewImages?.length > 0 && (
                            <>
                              {previewImages?.map((item, index) => {
                                return (
                                  <DraggableUploadListItem
                                    key={item?.uid}
                                    file={item}
                                    originNode={
                                      <div
                                        key={index}
                                        className="image-show"
                                        style={{
                                          display:
                                            previewImages.length > 0
                                              ? "block"
                                              : "none"
                                        }}
                                      >
                                        <div class="dragdrop-icon">
                                          <img
                                            src={dragAndDropLogo}
                                            alt="dragdrop-icon"
                                          />
                                        </div>
                                        <div className="img-details">
                                          <ul className="nav">
                                            <li className="nav-item">
                                              <div className="uploaded-image">
                                                <img
                                                  className=""
                                                  id="uploaded-image-preview"
                                                  src={item?.image}
                                                  alt="preview image"
                                                />
                                              </div>
                                            </li>
                                            <li className="nav-item">
                                              <div
                                                className="product-name"
                                                id="product-name"
                                              >
                                                {item?.name?.length > 15
                                                  ? `${item?.name?.substring(0, 15)}...`
                                                  : item?.name}
                                                
                                              </div>
                                              <div
                                                className="product-size"
                                                id="product-size"
                                              >
                                                {item?.size}
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                        <div>
                                          <ImageUploadProgress
                                            newImages={newImages}
                                          />
                                        </div>
                                        <div
                                          className="close-sec"
                                          onClick={() =>
                                            handleDeleteImage(index, item)
                                          }
                                        ></div>
                                      </div>
                                    }
                                  />
                                );
                              })}
                            </>
                          )}
                        </SortableContext>
                      </DndContext>
                    </div>
                  </div>
                </div>

                <div className="form-area">
                  <form onSubmit={handleFormSubmission}>
                    <div className="form-group">
                      <label>Product Name</label>
                      <span className="required-asterisk">*</span>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={product.name}
                        required
                        onChange={handleInputFields}
                      />
                    </div>
                    <div className="form-group">
                      <label>Brand</label>
                      <span className="required-asterisk">*</span>
                      <input
                        type="text"
                        className="form-control"
                        name="brand"
                        value={product.brand}
                        onChange={handleInputFields}
                        required
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label>Product Description</label>
                      <span className="required-asterisk">*</span>
                      <CKEditor
                        editor={ClassicEditor}
                        data={product?.description}
                        id="editor"
                        config={editorToolBar}
                        className="form-control"
                        onChange={handleEditorChange}
                        autofocus
                        required
                      />
                    </div>
                    {/* <!-- New code 2 may 2024 --> */}
                    <div className="form-group mt-3">
                      <div className="add-location-btn">
                        <button
                          className="btn btn-light"
                          onClick={handleAddLocationButton}
                        >
                          <i className="bi bi-plus me-1 "></i> Add Location
                          <span className="required-asterisk">*</span>
                        </button>
                      </div>

                      {isLocationAvailable && (
                        <div className="form-group location-col mt-3">
                          <div className="row mb-3">
                            <div className="col-md-4 mb-3 mb-md-0">
                              <label>Label</label>
                              <OverlayTrigger
                                key={"tope"}
                                placement={"top"}
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    To display the product journey, name each
                                    entry to specify its purpose, such as
                                    'Production,' 'Harvesting,' etc, and select
                                    the country and city.
                                  </Tooltip>
                                }
                              >
                                <span>
                                  <AiOutlineQuestionCircle
                                    style={{
                                      marginLeft: "10px",
                                      marginBottom: "2px"
                                    }}
                                  />
                                </span>
                              </OverlayTrigger>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Ex: Manufactured"
                                onChange={handleLocationLabelOnChange}
                                value={activeLocation?.label}
                              />
                            </div>
                            <div className="col-md-4 mb-3 mb-md-0">
                              <label>Country</label>
                              <select
                                id="select-country"
                                className="form-select select-country select-options"
                                onChange={onCountryChange}
                                value={placeholderCountry}
                                required
                              >
                                <option value={placeholderCountry}>
                                  {placeholderCountry}
                                </option>
                                {countries?.map((country, index) => {
                                  return (
                                    <option
                                      value={country?.isoCode}
                                      key={index}
                                    >
                                      {country?.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>

                            <div className="col-md-4 mb-3 mb-md-0">
                              <label>City</label>
                              <select
                                id="select-country"
                                className="form-select select-country select-options"
                                onChange={onCityChange}
                                value={cityPlaceholder}
                                required
                              >
                                <option value={cityPlaceholder}>
                                  {cityPlaceholder}
                                </option>

                                {activeLocation?.country &&
                                  cities.map((item, index) => {
                                    return (
                                      <option
                                        key={index}
                                        value={JSON.stringify(item)}
                                      >
                                        {item?.name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 text-end ">
                              <button
                                className="btn btn-light me-2"
                                onClick={handleCanelButtonClick}
                              >
                                Cancel
                              </button>
                              <button
                                className="btn btn-secondary"
                                onClick={handleSaveLocationClick}
                              >
                                {isLocationEdit ? "Update" : "Save"}
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {locations?.length ? (
                      <div className="from-group d-location">
                        <div className="row">
                          <div className="col-12">
                            <div className="table-responsive-sm products-table add-product-table">
                              <table className="table">
                                <tr className="mb-3">
                                  <th>Label</th>
                                  <th>Country</th>
                                  <th>City</th>
                                </tr>
                                {locations?.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{item?.label}</td>
                                      <td>{item?.country?.name}</td>
                                      <td>{item?.city?.name}</td>
                                      <td>
                                        <div className="dropdown">
                                          <button
                                            className="btn btn-secondary dropdown-toggle option-button"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <i className="bi bi-three-dots-vertical"></i>
                                          </button>
                                          <ul className="dropdown-menu">
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                onClick={() =>
                                                  handleEditLocationClick(item)
                                                }
                                              >
                                                <i className="bi bi-pencil"></i>
                                                Edit
                                              </Link>
                                            </li>
                                            <li>
                                              <Link
                                                className="dropdown-item"
                                                onClick={() =>
                                                  handleDeleteSavedLocationClick(
                                                    item
                                                  )
                                                }
                                              >
                                                <i className="bi bi-trash"></i>
                                                Delete
                                              </Link>
                                            </li>
                                          </ul>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="form-group mt-3">
                      <label>Specification Image</label>
                      <input
                        type="file"
                        className="form-control h-auto"
                        name="nutritionImage"
                        id="fileInput"
                        onChange={handleNutritionImage}
                        value={null}
                      />
                      {nutrition && (
                        <div style={{ position: "relative" }}>
                          <img
                            src={nutrition?.image}
                            alt="nutrition image"
                            style={{
                              maxWidth: "200px",
                              maxHeight: "200px",
                              width: "auto",
                              height: "auto"
                            }}
                          />
                          <div
                            style={{
                              position: "absolute",
                              top: "3px",
                              left: "177px",
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              background: "#fff",
                              cursor: "pointer"
                            }}
                          >
                            <MdClose
                              style={{ marginBottom: "5px", marginLeft: "2px" }}
                              onClick={() => {
                                setNutrition(null);
                                setProduct({
                                  ...product,
                                  nutritionImage: null
                                });
                                document.getElementById("fileInput").value = "";
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    {farmers?.length > 0 ? (
                      <div className="form-group add-farmer mt-3">
                        <label>Choose Farmer</label>
                        <DropdownButton
                          variant="farmer"
                          id="dropdown-basic-button"
                          title={
                            selectedFarmer
                              ? `${selectedFarmer?.firstName}-${selectedFarmer?.email}`
                              : ""
                          }
                          onSelect={handleFarmerSelection}
                        >
                          {farmers?.map((item, index) => {
                            return (
                              <Dropdown.Item
                                key={index}
                                eventKey={JSON.stringify(item)}
                              >
                                <div className="user-icon-img">
                                  {item?.pfp ? (
                                    <img
                                      src={`${process.env.REACT_APP_SERVER_URL}/uploads/pfp/${item?.pfp}`}
                                      alt="profile-picture"
                                    />
                                  ) : item?.firstName ? (
                                    item?.firstName.substring(0, 2)
                                  ) : (
                                    <img
                                      src={userPlaceholder}
                                      alt="profile-picture"
                                    />
                                  )}
                                </div>
                                <div className="user-name-email">
                                  {item?.firstName}-{item?.email}
                                </div>
                              </Dropdown.Item>
                            );
                          })}
                        </DropdownButton>
                      </div>
                    ) : null}

                    <div className="form-group mt-4 mt-lg-5 row">
                      <div className="col-8 pe-0">
                        {isLoading ? (
                          <button className="btn btn-primary w-100" disabled>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="btn btn-primary w-100"
                          >
                            Add Product
                          </button>
                        )}
                      </div>
                      <div className="col-4">
                        <button
                          className="btn btn-light me-3 w-100"
                          onClick={() => navigate("/products")}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AddProduct;
