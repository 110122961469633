import React, { useEffect, useState, useContext, useCallback } from "react";
import track from "../../../assets/images/verify/ic-track.svg";
import details from "../../../assets/images/verify/ic-detail.svg";
import feedbackLogo from "../../../assets/images/verify/ic-feedback.svg";
import { Link, useParams,useLocation } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../axiosConfig/axiosInstance";
import DOMPurify from "dompurify";
import starsData from "../../../constants/stars";
import { v4 as uuidv4 } from "uuid";
import EmailPopup from "../../../components/EmailPopup";
import blockchainVerify from "../../../assets/images/verify/verify-icp.svg";
import blockchainLink from "../../../assets/images/verify/copy-ic.svg";
import verifyLogo from "../../../assets/images/verify/verify-logo-main.svg";
import Modal from "react-bootstrap/Modal";
import Map from "./Map";
import rewardIcon from "../../../assets/images/verify/reward-icon.svg";
import cancelButton from "../../../assets/images/verify/crose-rm.svg";
import nuturitionLogo from "../../../assets/images/verify/ic-nutrition.svg";
import productPlaceholder from "../../../assets/images/products/product.png";
import starIcon from "../../../assets/images/icons/star.svg";
import LoginSignupHeader from "../../../components/Header";
import { HeaderContext } from "../../../components/Context/HeaderContext";
import {
  createWeb3Modal,
  defaultConfig,
  useWeb3ModalAccount,
  useWeb3Modal,
  useDisconnect,
  useWeb3ModalEvents,
  useSwitchNetwork,
  useWeb3ModalProvider
} from "@web3modal/ethers/react";
import { BrowserProvider, Contract, parseUnits } from "ethers";
import erc20abi from "../../../contracts/erc20abi.json";
import { encryptData } from "../../../utils/encryption";
import disconnectWalletLogo from "../../../assets/images/icons/disconnect-icon.svg";
import { throttle } from "lodash";
import { Dropdown, DropdownButton } from "react-bootstrap";
import DropdownProperty from "../../../components/Common/DropdownProperty";
import FullImage from "../../../components/Modals/FullimageModal";
import userPlaceholder from "../../../assets/images/icons/userplaceholder.png";
import FarmerModal from "../../../components/Modals/FarmerModal";
import RewardSuccessModal from "../../../components/Modals/RewardSuccessModal";
import ReactGA from "react-ga4"
import Cookies from 'js-cookie';

const VerifyProduct = () => {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASURE_ID)

  const { productId } = useParams();
  const [product, setProduct] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [stars, setStars] = useState(starsData);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [userLocation, setUserLocation] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAuthentic, setIsAuthentic] = useState(false);
  const [systemSetting, setSystemSetting] = useState(null);
  const [payload, setPayload] = useState({
    productId: productId,
    comment: "",
    rating: 0,
    email: "",
    name: "",
    token: ""
  });
  const [locations, setLocations] = useState(null);
  const [userCurrentLocation, setUserCurrentLocation] = useState({
    city: "",
    country: ""
  });
  const [isSticky, setIsSticky] = useState(false);
  const [fullPicture, setFullPicture] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0); // State to track active index
  const [error, setError] = useState(null);
  const [rewardModal, setRewardModal] = useState(false);
  const [payModal, setPayModal] = useState(false);
  const [checkedList, setCheckedList] = useState("crypto");
  const [tipAmount, setTipAmount] = useState(null);
  const [payNowError, setPayNowError] = useState(null);
  const [paymentLoader, setPaymentLoader] = useState(false);
  const [companyLogo, setCompanyLogo] = useState(null);
  const { updateHeaderState } = useContext(HeaderContext);
  const [isSwitch, setIsSwitch] = useState(false);
  const [tipNetworks, setTipNetworks] = useState([]);
  const [farmerModal, setFarmerModal] = useState(false);
  const chains = [
    {
      chainId: 97,
      name: "Binance Smart Chain Testnet",
      currency: "tBNB",
      explorerUrl: "https://testnet.bscscan.com",
      rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545"
    },
    {
      chainId: 80002,
      name: "Polygon Testnet",
      currency: "Matic",
      explorerUrl: "https://testnet.bscscan.com",
      rpcUrl: "https://rpc-amoy.polygon.technology"
    },

    {
      chainId: 11155111,
      name: "Ethereum Testnet",
      currency: "Eth",
      explorerUrl: "https://sepolia.etherscan.io/tx/",
      rpcUrl: "https://sepolia.infura.io/v3/ac53d6a9c60f4613b88ca256c4120a5c"
    }
  ];

  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [dropDownSelectedNetwork, setDropDownSelectedNetwork] = useState(null);
  const [networkSelectionError, setNetworkSelectionError] = useState(null);
  const [walletAddress, setWalletAddress] = useState("");
  const location = useLocation();

  // wallet config
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider({
    address,
    chainId,
    isConnected
  });

  useEffect(() => {
    if (address) {
      setWalletAddress(address);
    }
  }, [address, chainId, isConnected]);

  const projectId = process.env.REACT_APP_METAMASK_PROJECT_ID;

  const ethersConfig = defaultConfig({
    metadata: {
      name: "Scan and Trace",
      description: "Scan and trace connection established",
      url: "https://web3modal.com",
      icons: [
        "https://drive.google.com/file/d/19BBUCil8jHTf872DXutrg2V4DQsMEjJr/view?usp=sharing"
      ]
    }
    // defaultChain: tipNetworks[0]
  });

  createWeb3Modal({
    ethersConfig,
    chains,
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    allWallets: "ONLY_MOBILE",
    featuredWalletIds: []
  });
  const events = useWeb3ModalEvents();

  const { open, close } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { switchNetwork } = useSwitchNetwork();

  useEffect(() => {
    const qrVisited = Cookies.get(`qrVisited_${productId}`);
    if (!qrVisited) {
      // Set the cookie with an expiration date
      Cookies.set(`qrVisited_${productId}`, 'true', { expires: 1 }); // Cookie expires in 1 days
      // Make the API call to increment the count
      incrementScan()
    }
    fetchProductData();
    fetchUserLocation();
    fetchTipNetworks();
  }, []);


  const switchBlockchainNetwork = async (networkId) => {
    if (!networkId) return;
    try {
      if (networkId !== chainId) {
        setIsSwitch(true);
        await switchNetwork(networkId);
        setIsSwitch(false);
        close();
      }
    } catch (error) {
      console.log("error in switch", error);
      setIsSwitch(false);
      close();
      disconnect();
    }
    // await signerFunction();
  };

  useEffect(() => {
    if (isSwitch && events?.data?.event === "MODAL_CLOSE") {
      disconnect();
    }
  }, [events?.data?.event]);


  // increment total scan 
  const incrementScan =async ()=> {
    try{
      await axiosInstance.get(`/product/increment/scan/${productId}`)
    }catch(error){
      console.log("error occur while increment the total scan")
    }
  }

  //  handle farmer tip
  const fetchTipNetworks = async () => {
    try {
      const response = await axiosInstance.get("/general/networks");
      setTipNetworks(response?.data?.tokenNetworks);
      if (response?.data?.tokenNetworks?.length) {
        setDropDownSelectedNetwork({
          name: response?.data?.tokenNetworks[0]?.currency,
          icon: `${process.env.REACT_APP_SERVER_URL}/networks/${response?.data?.tokenNetworks[0]?.icon}`
        });
        setSelectedNetwork(JSON.stringify(response?.data?.tokenNetworks[0]));
      }
    } catch (error) {
      console.log("while loading token transfer networks", error?.message);
      toast.error("Something went wrong while fetching blockchain networks");
      return;
    }
  };

  const handleScroll = useCallback(
    throttle(() => {
      if (window.scrollY > 800) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }, 200),
    []
  ); // Adjust the throttle time as needed

  useEffect(() => {
    // Add event listener for scroll
    window.addEventListener("scroll", handleScroll);

    // Clean up by removing the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
      handleScroll.cancel(); // Cancel any trailing calls to handleScroll
    };
  }, [handleScroll]);

  const fetchUserLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        setUserLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
        const userAddress = await axiosInstance.post(
          "/product/scan/user/location",
          {
            productId,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          }
        );
        if (
          userAddress?.data?.userLocation?.city &&
          userAddress?.data?.userLocation?.country
        ) {
          setUserCurrentLocation({
            city: userAddress?.data?.userLocation?.city,
            country: userAddress?.data?.userLocation?.country
          });
        } else {
          try {
            const accessUserLocation = await fetch(
              `${process.env.REACT_APP_GET_USER_LOCATION_API}${position.coords.latitude},${position.coords.longitude}&key=${process.env.REACT_APP_GOOGLE_MAP_API}`
            );

            const response = await accessUserLocation.json();
            if (response.status === "OK") {
              // Extract country and city from the address components
              const addressComponents = response.results[0].address_components;
              let country, city;

              for (let component of addressComponents) {
                if (component.types.includes("country")) {
                  country = component.long_name;
                }
                if (
                  component.types.includes("locality") ||
                  component.types.includes("postal_town")
                ) {
                  city = component.long_name;
                }
              }
              setUserCurrentLocation({ city: city, country: country });
              axiosInstance.post("/product/scan/user/location", {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                city: city,
                country: country,
                productId,
                scannedIn: response?.results[0]?.formatted_address
              });
            } else {
              console.error("Error:", response.status);
            }
          } catch (error) {
            console.log(
              "some error occur while accessing user location",
              error
            );
          }
        }
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const fetchProductData = async () => {
    try {
      setIsLoading(true);
      const data = await axiosInstance.get(`/product/scan/${productId}`);
      setProduct(data?.data?.product);
      setIsAuthentic(data?.data?.isAuthentic);
      setSystemSetting(data?.data?.systemSettings);
      setCompanyLogo(data?.data?.product?.addedBy?.company?.companyLogo);
      setPayload({ ...payload });
      ReactGA.event("product_scan", {
        page_path:location?.pathname + location?.search,
        product_name: data?.data?.product?.parentProduct?.name,
        product_id: productId
      })
      if (!data?.data?.product) {
        toast.error(data?.data?.message);
      } else {
        document.title = data?.data?.product?.addedBy?.company?.companyName;
        if (data?.data?.product?.addedBy?.company?.companyLogo) {
          let link =
            document.querySelector("link[rel*='icon']") ||
            document.createElement("link");
          link.type = "image/x-icon";
          link.rel = "shortcut icon";
          link.href = `${process.env.REACT_APP_SERVER_URL}/uploads/companyLogos/${data?.data?.product?.addedBy?.company?.companyLogo}`;
          document.getElementsByTagName("head")[0].appendChild(link);
          updateHeaderState(data?.data?.product?.addedBy?.company?.companyLogo);
        }
        let locations = data?.data?.product?.parentProduct?.locations;
        let locationsCoordinates = [];
        for (let i = 0; i < locations?.length; i++) {
          locationsCoordinates.push({
            lat: locations[i]?.ltd,
            lng: locations[i]?.lng,
            label: locations[i]?.label,
            country: locations[i]?.country
          });
        }
        setLocations(locationsCoordinates);
      }
    } catch (error) {
      toast.error("Something went wrong");
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const handleStars = (star) => {
    const modifiedStars = stars?.map((item) => {
      if (item?.id <= star?.id) {
        return { ...item, className: "bi-star-fill" };
      } else {
        return { ...item, className: "bi-star" };
      }
    });
    setStars(modifiedStars);
    setPayload({ ...payload, rating: star?.star });
  };

  const handleFormSubmission = async (event) => {
    event.preventDefault();
    if (payload?.rating === 0) {
      setError("Rating is required");
      setTimeout(() => {
        setError(null);
      }, 3000);
      return;
    } else {
      try {
        setButtonLoader(true);
        const response = await axiosInstance.post("/product/review", {
          ...payload,
          token: uuidv4()
        });
        setButtonLoader(false);
        if (response) {
          setIsModalOpen(true);
          return;
        }
      } catch (error) {
        setTimeout(() => {
          setError(error?.message);
        }, 3000);
        setButtonLoader(false);
        return;
      }
    }
  };

  const resetFullPicture = (newState) => {
    setFullPicture(newState);
  };

  const handleNextSlide = () => {
    setActiveIndex((prevIndex) => {
      const newIndex =
        prevIndex === product?.parentProduct?.pictures?.length - 1
          ? 0
          : prevIndex + 1;
      scrollThumbnails(newIndex);
      return newIndex;
    });
  };

  const handlePreviousSlide = () => {
    setActiveIndex((prevIndex) => {
      const newIndex =
        prevIndex === 0
          ? product?.parentProduct?.pictures?.length - 1
          : prevIndex - 1;
      scrollThumbnails(newIndex);
      return newIndex;
    });
  };

  const handleScrolling = (sectionId) => {
    try{

      const section = document.getElementById(sectionId);
      window.scrollTo({
        top: section.offsetTop - 60,
        behavior: "smooth"
      });
    }catch(error){
      console.log("handle scrolling error", error?.message)
    }
  };

  const handleThumbClick = (index) => {
    setActiveIndex(index);
    scrollThumbnails(index);
  };

  const updateModalStatus = () => {
    setPayModal(false);
    setPayNowError(null);
    setTipAmount(null);
    setNetworkSelectionError(null);
  };

  const scrollThumbnails = (index) => {
    try{

      const thumbContainer = document.querySelector(".thumb-product_carousel ul");
      if (thumbContainer && thumbContainer?.children?.length >0) {
        const thumbWidth = thumbContainer.children[0].offsetWidth; // Assuming all thumbnails have the same width
        const containerScrollPosition =
        thumbWidth * index - thumbContainer.clientWidth / 2 + thumbWidth / 2;
        thumbContainer.scrollTo({
          left: containerScrollPosition,
          behavior: "smooth"
        });
      }
    }catch(error){
      
    }
  };

  const updateSupportModalStatus = (newState) => {
    setFarmerModal(newState);
    setRewardModal(true);
  };

  const closeFarmerModal = (newState) => {
    setFarmerModal(newState);
  };

  //  handle farmer tip
  const handleFarmerTip = async () => {
    setRewardModal(true);
  };

  const handleSelect = (eventKey) => {
    setDropDownSelectedNetwork({
      name: JSON.parse(eventKey)?.currency,
      icon: `${process.env.REACT_APP_SERVER_URL}/networks/${
        JSON.parse(eventKey)?.icon
      }`
    });
    setSelectedNetwork(eventKey);
  };

  // crypto token transfer
  const handleCryptoTokenTransfer = async () => {
    try {
      if (!tipAmount) {
        setPayNowError("Please select the amount");
      } else if (!selectedNetwork) {
        setNetworkSelectionError("Please select a blockchain network");
        if (tipAmount) {
          setPayNowError(null);
        }
      } else {
        const networkId = JSON.parse(selectedNetwork).chainId;
        if (!isConnected) {
          setPayNowError(null);
          setNetworkSelectionError(null);
          setRewardModal(false);
          open();
        } else {
          if (Number(networkId) !== Number(chainId)) {
            await switchBlockchainNetwork(networkId);
          } else {
            setPaymentLoader(true);
            const parsedData = JSON.parse(selectedNetwork);
            const {
              contractAddress,
              explorerUrl,
              name,
              type,
              chainId,
              currency
            } = parsedData;
            let nativeTip, transaction, nativeCurrentPrice;
            if (type === "coin") {
              try {
                const latestPrice = await axiosInstance.get(
                  `/general/token/price?chainId=${chainId}&address=${contractAddress}`
                );
                if(latestPrice){
                  nativeTip = tipAmount / latestPrice?.data?.data?.usdPrice;
                  nativeCurrentPrice = nativeTip;
                  nativeTip = parseUnits(
                    nativeTip.toFixed(12).toString(),
                    18
                  )?.toString();
                }else{
                  return
                }
              } catch (error) {
                console.log("errror is", error);
                toast.error(error?.message);
                return;
              }
            }

            let provider = new BrowserProvider(walletProvider);
            let signer = await provider.getSigner();
            let userId, walletAddress, farmerName;
            if (product?.farmers?.length) {
              userId = product?.farmers[0]?._id;
              walletAddress =
                product?.farmers[0]?.walletAddress ||
                product?.companyAdmin[0]?.walletAddress;
              farmerName = `${product?.farmers[0]?.firstName} ${product?.farmers[0]?.lastName}`;
            } else {
              userId = product?.companyAdmin[0]?._id;
              walletAddress = product?.companyAdmin[0]?.walletAddress;
            }
            if (type === "coin") {
              const transfer = await signer.sendTransaction({
                to: walletAddress,
                value: nativeTip
              });
              transaction = await transfer.wait();
            } else {
              const erc20contract = new Contract(
                contractAddress,
                erc20abi,
                signer
              );
              const decimal = Number(await erc20contract.decimals());
              let amount = parseUnits(tipAmount.toString(), decimal).toString();

              const transfer = await erc20contract.transfer(
                walletAddress,
                amount
              );
              transaction = await transfer.wait();
            }

            const encryptedPayload = encryptData(
              JSON.stringify({
                productId,
                country: userCurrentLocation?.country,
                city: userCurrentLocation?.city,
                latitude: userLocation?.lat,
                longitude: userLocation?.lng,
                chainId,
                explorerUrl: explorerUrl,
                type: type,
                contractAddress: contractAddress,
                toWallet: walletAddress,
                fromWallet: address,
                blockchainNetwork: name,
                txHash: transaction?.hash,
                amount: tipAmount,
                currency: currency,
                nativePrice: nativeCurrentPrice,
                userId: userId,
                picture: product?.parentProduct?.pictures[0],
                productName: product?.parentProduct?.name,
                companyId: product?.parentProduct?.company,
                farmerName: farmerName
              }),
              process.env.REACT_APP_ENCRYPTION_KEY
            );
            await axiosInstance.post("/product/token/transaction", {
              encryptedData: encryptedPayload
            });
            setRewardModal(false);
            setPayModal(true);
            setPayNowError(null);
            setNetworkSelectionError(null);
          }
        }
      }
    } catch (error) {
      console.log("error is", error);
      if (error?.info?.error?.message) {
        toast.error(error?.info?.error?.message);
      } else if (error?.error?.message) {
        toast.error(error?.error?.message);
      } else {
        toast.error(
          `Transaction failed. Please try again after some time or contact ${process.env.REACT_APP_SUPPORT_EMAIL}`
        );
      }
    } finally {
      setPaymentLoader(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {/* Review submit email sent */}
          {isModalOpen ? (
            <EmailPopup
              text="We have sent you an email to verify your review. Kindly verify your email to consider your review as verify"
              purpose="feedback"
              productId={productId}
            />
          ) : (
            // show full image
            <>
              {fullPicture && (
                <FullImage
                  image={fullPicture}
                  resetFullPicture={resetFullPicture}
                />
              )}
              {rewardModal && (
                <Modal show={rewardModal} className="fade rewardModal" centered>
                  <div
                    className="btnClose-rm"
                    onClick={() => {
                      setRewardModal(false);
                      setTipAmount(null);
                      setPayNowError(null);
                      setNetworkSelectionError(null);
                    }}
                  >
                    <img src={cancelButton} alt="delete-icon" />
                  </div>
                  <Modal.Body className="position-relative">
                    <div className="modal-body p-0">
                      <div className="rewardModal-title">Show your support</div>
                      <div className="rewardModal-text">
                        Select the Tip Amount
                      </div>
                      <div className="select-ammount">
                        <ul className="nav">
                          <li
                            className={`nav-item ${
                              tipAmount === 5 ? "active" : null
                            }`}
                            onClick={() => setTipAmount(5)}
                          >
                            $5
                          </li>
                          <li
                            className={`nav-item ${
                              tipAmount === 10 ? "active" : null
                            }`}
                            onClick={() => setTipAmount(10)}
                          >
                            $10
                          </li>
                          <li
                            className={`nav-item ${
                              tipAmount === 15 ? "active" : null
                            }`}
                            onClick={() => setTipAmount(15)}
                          >
                            $15
                          </li>
                          <li
                            className={`nav-item ${
                              tipAmount === 20 ? "active" : null
                            }`}
                            onClick={() => setTipAmount(20)}
                          >
                            $20
                          </li>
                        </ul>
                        {payNowError && (
                          <p className="text-danger mb-4">{payNowError}</p>
                        )}
                      </div>
                      <div className="rewardModal-text">
                        Select the Payment Method
                      </div>

                      {walletAddress && (
                        <>
                          <span>
                            Connected Wallet:
                            {`${walletAddress?.substring(
                              0,
                              4
                            )}....${walletAddress?.substring(
                              walletAddress?.length - 3
                            )}`}
                          </span>
                          <br />
                          <button
                            className="btn btn-outline-primary text-white"
                            onClick={() => {
                              sessionStorage.removeItem("walletConfig");
                              disconnect();
                              window.location.reload();
                            }}
                          >
                            <img
                              src={disconnectWalletLogo}
                              alt="disconnect-icon"
                            />
                            Disconnect
                          </button>
                        </>
                      )}

                      <div className="payment-methods">
                        <div className={`form-check creditCard`}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="payment-methods"
                            id="CreditDebit-card"
                            disabled
                          />
                          <label
                            className="form-check-label"
                            for="CreditDebit-card"
                          >
                            Credit or Debit Card
                          </label>
                        </div>

                        <div className={`form-check paypal`}>
                          <input
                            className="form-check-input"
                            type="radio"
                            name="payment-methods"
                            id="paypal"
                            disabled
                          />
                          <label className="form-check-label" for="paypal">
                            Paypal
                          </label>
                        </div>

                        <div
                          className={`form-check crypto ${
                            checkedList === "crypto" ? "checked" : null
                          }`}
                          onClick={() => setCheckedList("crypto")}
                        >
                          <input
                            className="form-check-input"
                            type="radio"
                            name="payment-methods"
                            id="crypto"
                            checked={checkedList === "crypto"}
                          />
                          <label className="form-check-label" for="crypto">
                            Crypto Currency
                          </label>
                        </div>
                        <div className="form-group farmer-tip-modal">
                          {networkSelectionError && (
                            <p className="text-danger mb-4">
                              {networkSelectionError}
                            </p>
                          )}
                        </div>
                      </div>
                      <DropdownButton
                        id="dropdown-basic-button"
                        title={
                          <DropdownProperty
                            selectedNetwork={dropDownSelectedNetwork}
                          />
                        }
                        onSelect={handleSelect}
                        className="select-options  blockchain-networks-selection"
                        style={{ paddingTop: "10px" }}
                      >
                        {tipNetworks?.length &&
                          tipNetworks?.map((network, index) => (
                            <Dropdown.Item
                              eventKey={JSON.stringify(network)}
                              key={index}
                              className="tip-amount-option"
                            >
                              <img
                                src={`${process.env.REACT_APP_SERVER_URL}/networks/${network?.icon}`}
                                alt={network?.currency}
                                style={{
                                  width: "20px"
                                }}
                              />
                              {network?.currency}
                            </Dropdown.Item>
                          ))}
                      </DropdownButton>

                      <button
                        className={`btn btn-primary w-100 mb-3 ${
                          paymentLoader ? "loading" : null
                        }`}
                        onClick={handleCryptoTokenTransfer}
                      >
                        {paymentLoader ? (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Pay Now"
                        )}
                      </button>
                    </div>
                  </Modal.Body>
                </Modal>
              )}

              {/* reward success popup */}
              {payModal && (
                <RewardSuccessModal
                  tipAmount={tipAmount}
                  updateModalStatus={updateModalStatus}
                />
              )}

              {/* show farmer details in modal */}
              {farmerModal && (
                <FarmerModal
                  farmer={product?.farmers[0]}
                  companyId={product?.parentProduct?.company}
                  updateSupportModalStatus={updateSupportModalStatus}
                  closeFarmerModal={closeFarmerModal}
                />
              )}
              {product && product?.parentProduct ? (
                <>
                  {companyLogo && (
                    <LoginSignupHeader
                      companyLogo={companyLogo}
                      type="verifyPage"
                    />
                  )}
                  <section className="verify-header d-none">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 px-0">
                          <img src={verifyLogo} alt="s&t_logo" />
                        </div>
                      </div>
                    </div>
                  </section>

                  <section className="verify-main">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 px-0">
                          <section className="main-slider product-slider">
                            <div
                              className="carousel slide"
                            >
                              <div className="carousel-inner">
                                {product?.parentProduct?.pictures?.length >
                                0 ? (
                                  product?.parentProduct?.pictures?.map(
                                    (picture, index) => {
                                      return (
                                        <div
                                          className={`carousel-item ${
                                            index === activeIndex
                                              ? "active"
                                              : ""
                                          }`}
                                          key={index}
                                        >
                                          <img
                                            src={`${process.env.REACT_APP_SERVER_URL}/uploads/${product?.parentProduct?.company}/${picture}`}
                                            alt="Slide 1"
                                          />
                                          <button
                                            className="btn fullscreen-btn shadow"
                                            onClick={() =>
                                              setFullPicture(
                                                `${process.env.REACT_APP_SERVER_URL}/uploads/${product?.parentProduct?.company}/${picture}`
                                              )
                                            }
                                          >
                                            <i className="bi bi-arrows-fullscreen"></i>
                                          </button>
                                        </div>
                                      );
                                    }
                                  )
                                ) : (
                                  <div className="carousel-item active">
                                    <img
                                      src={productPlaceholder}
                                      className="d-block mx-auto"
                                      style={{
                                        width: "300px",
                                        marginTop: "15px"
                                      }}
                                    />
                                  </div>
                                )}
                              </div>
                              {product?.parentProduct?.pictures?.length > 1 ? (
                                <>
                                  <button
                                    className={`carousel-control-prev shadow-sm ${
                                      activeIndex === 0 ? "active" : ""
                                    }`}
                                    type="button"
                                    onClick={handlePreviousSlide}
                                    disabled={activeIndex === 0}
                                  >
                                    <i className="bi bi-chevron-left"></i>
                                  </button>
                                  <button
                                    className={`carousel-control-next shadow-sm ${
                                      activeIndex ===
                                      product?.parentProduct?.pictures?.length -
                                        1
                                        ? "active"
                                        : ""
                                    }`}
                                    type="button"
                                    onClick={handleNextSlide}
                                    disabled={
                                      activeIndex ===
                                      product?.parentProduct?.pictures?.length -
                                        1
                                    }
                                  >
                                    <i className="bi bi-chevron-right"></i>
                                  </button>
                                </>
                              ) : null}
                            </div>
                          </section>

                          <section className="thumb-slider thumb-product_carousel my-3">
                            <div className="container">
                              <div className="row">
                                <div className="col-12">
                                  <ul className="nav align-items-center justify-content-center flex-nowrap">
                                    {product?.parentProduct?.pictures?.length >
                                    0
                                      ? product?.parentProduct?.pictures?.map(
                                          (picture, index) => {
                                            return (
                                              <li
                                                className="nav-item"
                                                key={index}
                                                onClick={() =>
                                                  handleThumbClick(index)
                                                }
                                              >
                                                <div
                                                  className={`thumb-img ${
                                                    index === activeIndex
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                  data-bs-slide-to={activeIndex}
                                                >
                                                  <div className="overlay"></div>
                                                  <img
                                                    src={`${process.env.REACT_APP_SERVER_URL}/uploads/${product?.parentProduct?.company}/${picture}`}
                                                    className="img-fluid"
                                                    alt="Thumbnail 1"
                                                  />
                                                </div>
                                              </li>
                                            );
                                          }
                                        )
                                      : null}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </section>

                          {isAuthentic ? (
                            <div className="authentication authentic-product">
                              <div className="title">Authentic Product</div>
                              <div className="scan-txt">
                                Authentication powered by{" "}
                                <span>{systemSetting?.name}</span>
                              </div>
                            </div>
                          ) : (
                            <div className="authentication authentic-product">
                              <div className="title">Authentic Product</div>
                              <div className="scan-txt">
                                Authentication powered by{" "}
                                <span>{systemSetting?.name}</span>
                              </div>
                            </div>
                          )}

                          <div className="product-name">
                            {product?.parentProduct?.name}
                          </div>

                          <div className="serial-number">
                            SN: {product?.serialNum}
                          </div>
                          <div className="pt-rating">
                            <img src={starIcon} alt="rating-star" />{" "}
                            <span>{product?.rating} / 5</span>
                          </div>

                          <div className="row mx-0">
                            <div className="col-12 pb-3">
                              <div className="border-bottom"></div>
                            </div>
                            <div className="col-12 mb-3">
                              <div className="blockchain-detail d-flex align-items-center justify-content-center">
                                <div className="vb-title">
                                  <span className="badge badge-primary p-2">
                                    <img
                                      className="me-2"
                                      src={blockchainVerify}
                                      alt="icon"
                                    />{" "}
                                    Verify on Blockchain
                                  </span>
                                </div>
                                <div className="blockchain-address">
                                  <div className="d-flex align-items-center">
                                    <a
                                      href={`${product?.explorer}/tx/${product?.txHash}`}
                                      target="_blank"
                                    >
                                      <span>
                                        {product?.txHash
                                          ? product?.txHash?.substring(0, 8) +
                                            "...." +
                                            product?.txHash.substring(
                                              product?.txHash?.length - 5
                                            )
                                          : null}
                                      </span>
                                      <span className="copy-ic ms-2">
                                        <img
                                          src={blockchainLink}
                                          alt="icon-copy"
                                        />
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            className={`product-navbar ${
                              isSticky ? "affix" : null
                            }`}
                            id="middle-navbar"
                          >
                            <ul className="nav nav-fill">
                              {product?.parentProduct?.nutritionImage ? (
                                <li
                                  className="nav-item"
                                  onClick={() => handleScrolling("nutrition")}
                                >
                                  <Link>
                                    <div className="ic-img">
                                      <img src={nuturitionLogo} alt="ic" />
                                    </div>
                                    Nutrition
                                  </Link>
                                </li>
                              ) : null}
                              <li
                                className="nav-item"
                                onClick={() => handleScrolling("Details")}
                              >
                                <Link>
                                  <div className="ic-img">
                                    <img src={details} alt="ic" />
                                  </div>
                                  Details
                                </Link>
                              </li>
                              <li
                                className="nav-item"
                                onClick={() => handleScrolling("tracking")}
                              >
                                <Link>
                                  <div className="ic-img">
                                    <img src={track} alt="ic" />
                                  </div>
                                  Tracking
                                </Link>
                              </li>
                              <li
                                className="nav-item"
                                onClick={() => handleScrolling("feedback")}
                              >
                                <Link>
                                  <div className="ic-img">
                                    <img src={feedbackLogo} alt="ic" />
                                  </div>
                                  Feedback
                                </Link>
                              </li>
                            </ul>
                          </div>

                          {product?.parentProduct?.nutritionImage ? (
                            <div
                              className="nutrition-facts mb-3 px-4"
                              id="nutrition"
                            >
                              <img
                                src={`${process.env.REACT_APP_SERVER_URL}/uploads/${product?.parentProduct?.company}/${product?.parentProduct?.nutritionImage}`}
                                alt="nutrition-fact-img"
                                className="w-100"
                              />
                            </div>
                          ) : null}

                          <div className="product-details" id="Details">
                            <h1>Product Details</h1>
                            <p
                              dangerouslySetInnerHTML={{
                                __html: DOMPurify.sanitize(
                                  product?.parentProduct?.description
                                )
                              }}
                            ></p>
                          </div>

                          {/* new section */}
                          {product?.farmers?.length > 0 ? (
                            <div class="reward-frame">
                              <div class="container px-3">
                                <div class="row">
                                  <div class="col-12 text-center">
                                    <div class="farmer-photo">
                                      {product?.farmers[0]?.pfp ? (
                                        <img
                                          src={`${process.env.REACT_APP_SERVER_URL}/uploads/pfp/${product?.farmers[0]?.pfp}`}
                                          alt="farmer-img"
                                        />
                                      ) : (
                                        <img
                                          src={userPlaceholder}
                                          alt="farmer-img"
                                        />
                                      )}
                                    </div>
                                    <div class="farmer-name">
                                      {product?.farmers[0]?.firstName}{" "}
                                      {product?.farmers[0]?.lastName}
                                    </div>
                                    <div
                                      class="profile-link"
                                      onClick={() => setFarmerModal(true)}
                                    >
                                      <a>
                                        <span>View Profile</span>
                                        <svg
                                          width="23"
                                          height="23"
                                          viewBox="0 0 23 23"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M20.2619 9.30957V3.83338H14.7857"
                                            stroke="#47C73D"
                                            stroke-width="1.8"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M20.2621 3.83301L12.595 11.5001"
                                            stroke="#47C73D"
                                            stroke-width="1.8"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                          <path
                                            d="M9.37035 3.83301H6.02379C4.81402 3.83301 3.83331 4.81372 3.83331 6.02348V16.9759C3.83331 18.1856 4.81402 19.1663 6.02379 19.1663H18.0714C19.2812 19.1663 20.2619 18.1856 20.2619 16.9759V14.0552"
                                            stroke="#47C73D"
                                            stroke-width="1.8"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </a>
                                    </div>
                                    {/* </div>--> */}
                                    <div class="reward-heading">
                                      Reward Farmer With Your Tip!
                                    </div>
                                    <div class="reward-pra">
                                      Farmers will keep 100% of the tip
                                    </div>
                                    <button
                                      class="btn btn-primary"
                                      onClick={handleFarmerTip}
                                    >
                                      Tip the Farmer
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="reward-frame">
                              <div className="container px-3">
                                <div className="row">
                                  <div className="col-12 text-center">
                                    <div className="reward-img-icon">
                                      <img src={rewardIcon} alt="reward-icon" />
                                    </div>
                                    <div className="reward-heading">
                                      Reward Farmer with a Tip!
                                    </div>
                                    <div className="reward-pra">
                                      Farmers will keep 100% of the tip
                                    </div>
                                    <button
                                      className="btn btn-primary"
                                      onClick={handleFarmerTip}
                                    >
                                      Support the Farmer
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {/* end new section */}
                        </div>

                        <div className="track-trace" id="tracking">
                          <div className="header">Track & Trace</div>
                          <div
                            className="product-trace position-relative"
                            style={{ width: "100%", height: "400px" }}
                          >
                            <div
                              className="maps"
                              id="maps"
                              style={{ width: "100%", height: "420px" }}
                            >
                              {locations?.length || userLocation ? (
                                <Map
                                  productLocations={locations}
                                  userLocation={userLocation}
                                />
                              ) : (
                                <div
                                  className="d-flex align-items-center justify-content-center text-center"
                                  style={{ height: "50vh" }}
                                >
                                  <p>
                                    Please Turn on Location to View Product Map
                                  </p>
                                </div>
                              )}
                            </div>
                            <div className="map-overlay"></div>
                          </div>
                        </div>

                        <div className="product-journey">
                          {product?.title ? (
                            <div className="header">{product?.title}</div>
                          ) : (
                            <div className="header">Your Product Journey</div>
                          )}

                          {product?.journeyDescription ? (
                            <div className="journery-pra">
                              {product?.journeyDescription}
                            </div>
                          ) : (
                            <div className="journery-pra">
                              Your Coffee has had a long Journey to get to you.
                              Lots of love and care has been put in especially
                              by your coffee farmer to bring you this lovely cup
                              you are savouring!
                            </div>
                          )}
                          <div className="journery-step">
                            {product?.parentProduct?.locations?.length
                              ? product?.parentProduct?.locations?.map(
                                  (location, index) => {
                                    return (
                                      <div className="info-box" key={index}>
                                        <div className="d-icon"></div>
                                        <div className="step-indicator">
                                          <div className="si-inner"></div>
                                        </div>
                                        <div className="si-line">
                                          <div className="sil-inner"></div>
                                        </div>
                                        <ul className="nav align-items-center">
                                          <li className="nav-item">
                                            <div className="info-sec">
                                              <div className="heading">
                                                {location?.label}
                                              </div>
                                              <div className="location">
                                                <i className="bi bi-geo-alt-fill"></i>{" "}
                                                {location?.city},{" "}
                                                {location?.country}
                                              </div>
                                            </div>
                                          </li>
                                        </ul>
                                      </div>
                                    );
                                  }
                                )
                              : null}
                            {userLocation ? (
                              <div className="info-box">
                                <div className="d-icon"></div>
                                <div className="step-indicator">
                                  <div className="si-inner"></div>
                                </div>
                                <div className="si-line">
                                  <div className="sil-inner"></div>
                                </div>
                                <ul className="nav align-items-center">
                                  <li className="nav-item">
                                    <div className="info-sec">
                                      <div className="heading">You Scanned</div>
                                      <div className="location">
                                        <i className="bi bi-geo-alt-fill"></i>
                                        {userCurrentLocation?.city},{" "}
                                        {userCurrentLocation?.country}
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            ) : null}
                          </div>
                        </div>

                        <div className="verify-bottom" id="feedback">
                          <div className="feedback">
                            <div className="title">Rate the Product</div>
                            <div className="product-rating">
                              <ul className="nav align-items-cetner justify-content-center">
                                {stars?.map((star, index) => {
                                  return (
                                    <div key={index}>
                                      <li
                                        className="nav-item"
                                        onClick={() => handleStars(star)}
                                      >
                                        <i
                                          className={`bi ${star?.className}`}
                                        ></i>
                                      </li>
                                    </div>
                                  );
                                })}
                              </ul>
                            </div>
                            <form onSubmit={handleFormSubmission}>
                              <input
                                type="name"
                                className="form-control"
                                placeholder="Enter your name"
                                id="emailInput"
                                name="email"
                                value={payload.name}
                                required
                                style={{ padding: "16px 20px" }}
                                onChange={(e) =>
                                  setPayload({
                                    ...payload,
                                    name: e.target.value
                                  })
                                }
                              />

                              <input
                                type="email"
                                className="form-control"
                                placeholder="Enter your email"
                                id="emailInput"
                                name="email"
                                value={payload.email}
                                required
                                style={{ padding: "16px 20px" }}
                                onChange={(e) =>
                                  setPayload({
                                    ...payload,
                                    email: e.target.value
                                  })
                                }
                              />
                              <textarea
                                required={true}
                                className="form-control"
                                rows="5"
                                placeholder="Write your feedback"
                                value={payload.comment}
                                onChange={(e) =>
                                  setPayload({
                                    ...payload,
                                    comment: e.target.value
                                  })
                                }
                              ></textarea>
                              {error ? (
                                <div>
                                  <p className="text-danger">{error}</p>
                                </div>
                              ) : null}
                              {buttonLoader ? (
                                <button
                                  className="btn btn-primary w-100"
                                  disabled
                                >
                                  <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                </button>
                              ) : (
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  SUBMIT
                                </button>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </>
              ) : (
                <>
                  <div
                    style={{ height: "100vh" }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    <div>
                      <p>No Data Found</p>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};
export default VerifyProduct;
