import React, { useState, useEffect } from "react";
import { Pagination, PaginationItem } from "@mui/material";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader/Loader";
import { axiosInstance } from "../../../axiosConfig/axiosInstance";
import { useNavigate } from "react-router-dom";
import productPlaceholder from "../../../assets/images/products/product.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";
import searchIcon from "../../../assets/images/reporting/report-search.svg"

const Reporting = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage] = useState(10);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [totalReviewedProducts, setTotalReviewedProducts] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        `/product/review?page=${currentPage}&limit=${perPage}&searchInput=${searchQuery}`
      );
      setData(response?.data?.products);
      setTotalPages(response?.data?.totalPages);
      setTotalReviewedProducts(response?.data?.totalReviewedProducts);

      setCurrentPage(response?.data?.currentPage);
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePagination = async (event, page) => {
    if (page < 1 || page > totalPages) {
      return; // Prevent clicking on disabled pages
    }
    // Calculate the new startPage based on the clicked page
    setCurrentPage(page);
    // await fetchData(page);
  };

  // handle pagination mouse over page number
  const handleItemMouseEnter = (event, page) => {
    if (page !== currentPage) {
      event.target.style.color = "white"; // Change color on hover for other pages
    }
  };

  // handle pagination mouse leave page number
  const handleItemMouseLeave = (event, page) => {
    if (page !== currentPage) {
      event.target.style.color = "#9FA1A1"; // Restore default color on mouse leave
    }
  };


  // get searched data from api
  const fetchedData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        `/product/review?page=${1}&limit=${perPage}&searchInput=${searchQuery}`
      );
      setData(response?.data?.products);
      setTotalPages(response?.data?.totalPages);
      setTotalReviewedProducts(response?.data?.totalReviewedProducts);
      setCurrentPage(response?.data?.currentPage);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  // handle report searching
  const handleReportingSearching = (e) => {
    fetchedData()
  };
  return (
    <>
      <section className="section-main">
        <div className="content-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 d-md-flex align-items-center justify-content-between">
                <div className="title-main mb-3 mb-md-0">Reporting</div>
                <div className = "search-input position-relative">
                  <input
                    className="form-control report-searching mb-0"
                    type="search"
                    name="search"
                    id="searching"
                    placeholder="Name or serial code"
                    onChange={(event)=>setSearchQuery(event.target.value)}
                  />
                  <button className = "search-product" onClick={handleReportingSearching}>
                    <img src = {searchIcon} alt = "serach-icon"/>
                  </button>
                </div>
              </div>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <div className="row">
                <div className="col-12">
                  <div className="products-table pt-30">
                    <div className="products-table reporting-table table-responsive">
                      {data?.length > 0 ? (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Product</th>
                              <th>Serial No</th>
                              <th>Scans</th>
                              <th>Feedback</th>
                              <th>Ratings</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.length > 0 &&
                              data.map((product, index) => (
                                <tr
                                  key={index}
                                  onClick={() =>
                                    navigate(
                                      `/reporting-details/${product?._id}`
                                    )
                                  }
                                >
                                  <td>
                                    <ul className="nav align-items-center">
                                      <li className="nav-item">
                                        <div className="product-img">
                                          {product?.parentProductData?.pictures
                                            ?.length > 0 ? (
                                            <img
                                              src={`${process.env.REACT_APP_SERVER_URL}/uploads/${product.companyId}/${product?.parentProductData?.pictures[0]}`}
                                            />
                                          ) : (
                                            <img src={productPlaceholder} />
                                          )}
                                        </div>
                                      </li>
                                      <li className="nav-item">
                                        {product?.parentProductData?.name
                                          ?.length > 20
                                          ? product?.parentProductData?.name.substring(
                                              0,
                                              20
                                            ) + "..."
                                          : product?.parentProductData?.name}
                                      </li>
                                    </ul>
                                  </td>
                                  <td>{product?.serialNum}</td>
                                  <td>{product?.totalScan}</td>
                                  <td className="pl-1">
                                    {product?.commentCount}
                                  </td>
                                  <td>
                                    <ul className="nav align-items-center feedback-rating">
                                      <li className="nav-item">
                                        <div className="star-list">
                                          {[...Array(5).fill(0)].map((_, i) => (
                                            <i
                                              key={i}
                                              className={`bi bi-star${
                                                i <=
                                                Math.round(
                                                  product?.averageRating
                                                ) -
                                                  1
                                                  ? "-fill active"
                                                  : i < product?.averageRating
                                                  ? "-half active"
                                                  : ""
                                              }`}
                                            ></i>
                                          ))}
                                        </div>
                                      </li>
                                      <li className="nav-item">
                                        {product?.averageRating ? (
                                          <span>
                                            (
                                            {product?.averageRating?.toFixed(2)}
                                            )
                                          </span>
                                        ) : (
                                          <span>(0.00)</span>
                                        )}
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      ) : (
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{ minHeight: "70vh" }}
                        >
                          <div>
                            <p>No Data Found</p>
                          </div>
                        </div>
                      )}
                    </div>
                    {totalReviewedProducts > perPage ? (
                      <div className="table-footer row">
                        <div className="col-12">
                          <ul className="nav align-items-center justify-content-end">
                            <Pagination
                              count={totalPages}
                              page={currentPage}
                              shape="rounded"
                              disabled={totalPages <= 1}
                              onChange={handlePagination}
                              siblingCount={isMobile ? 0 : 2}
                              boundaryCount={isMobile ? 0 : 2}
                              renderItem={(item) => (
                                <PaginationItem
                                  {...item}
                                  onMouseEnter={(e) =>
                                    handleItemMouseEnter(e, item.page)
                                  }
                                  onMouseLeave={(e) =>
                                    handleItemMouseLeave(e, item.page)
                                  }
                                  style={{
                                    background:
                                      item.page === currentPage
                                        ? "#202D46"
                                        : "",
                                    color:
                                      item.page === currentPage
                                        ? "white"
                                        : "#9FA1A1"
                                  }}
                                />
                              )}
                            />
                          </ul>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Reporting;
