import { useEffect, useContext, useState } from "react";
import { axiosInstance } from "../../axiosConfig/axiosInstance";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import successLogo from "../../assets/images/icons/success.svg";
import { notfoundContext } from "../../components/Context/NotFoundContext";

const EmailVerification = () => {
  const { update404State } = useContext(notfoundContext);

  const [searchParams] = useSearchParams();
  const [showPage, setShowPage] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const verifyToken = async () => {
      if (searchParams.get("token")) {
        try {
          const token = await axiosInstance.post("/user/verifyuser", {
            verificationToken: searchParams.get("token")
          });
          if (token) {
            update404State(true);
            setShowPage(true);
          } else {
            setShowPage(false);
            navigate("/login")
          }
          return;
        } catch (error) {
          toast.error("Something went wrong");
          navigate("/login");
          return;
        }
      }
    };
    verifyToken();
  }, []);
  return (
    <>
      {showPage ? (
        <section className="section-main ms-0">
          <section className="min-vh-100 d-flex justify-content-center align-items-center account-verified">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="body-account-verified text-center">
                    <img src={successLogo} alt="scuccess-icon" />
                    <h2>Account Verified!</h2>
                    <p>
                      Your account has been successfully verified. You can now
                      log in and use your account
                    </p>
                    <Link
                      className="btn btn-primary w-100"
                      onClick={() => {
                        update404State(false);
                      }}
                      to={"/login"}
                      role="button"
                    >
                      Login to your account
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      ) : null}
    </>
  );
};

export default EmailVerification;
