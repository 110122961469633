import { encryptData } from "./encryption";
export const mintingPayload = async (
  product,
  getCompanySettings,
  result,
  type,
  user,
  transactionVia,
  standard
) => {
  const payload = {
    productId: product?._id,
    initiatedBy: user.userId,
    chainId: getCompanySettings?.data?.company?.blockchain?.chainId,
    txHash: result.hash,
    walletAddress: result?.from,
    contractAddress: result?.to,
    type: type,
    chainName: getCompanySettings?.data?.company?.blockchain?.name,
    explorer: getCompanySettings?.data?.company?.blockchain?.explorerUrl,
    standard: standard,
    transactionVia: transactionVia,
    transactionDetails: {
      chainId: getCompanySettings?.data?.company?.blockchain?.chainId,
      blockNumber: result.blockNumber,
      blockhash: result.blockHash,
      gasLimit: Number(result.gasLimit),
      gasPrice: Number(result.gasPrice)
    }
  };

  const encryptedData = encryptData(
    JSON.stringify(payload),
    process.env.REACT_APP_ENCRYPTION_KEY
  );

  return encryptedData;
};

export const mintingPayloadForAdminTransaction = async (
  product,
  getCompanySettings,
  type,
  user,
  connectedWallet,
  contractAddress,
  transactionVia,
  standard
) => {
  const payload = {
    productId: product?._id,
    initiatedBy: user.userId,
    chainId: getCompanySettings?.data?.company?.blockchain?.chainId,
    walletAddress: connectedWallet?.address,
    contractAddress: contractAddress,
    type: type,
    chainName: getCompanySettings?.data?.company?.blockchain?.name,
    explorer: getCompanySettings?.data?.company?.blockchain?.explorerUrl,
    standard: standard,
    transactionVia:transactionVia,
  };

  const encryptedData = encryptData(
    JSON.stringify(payload),
    process.env.REACT_APP_ENCRYPTION_KEY
  );

  return encryptedData;
};

export const deployingPayload = async (
  product,
  getCompanySettings,
  contract,
  user
) => {
  const payload = {
    productId: product?._id,
    initiatedBy: user.userId,
    chainId: getCompanySettings?.data?.company?.blockchain?.chainId,
    txHash: contract.deploymentTransaction().hash,
    walletAddress: contract.deploymentTransaction().from,
    contractAddress: contract.target,
    standard: getCompanySettings?.data?.company?.standard,
    transactionVia: getCompanySettings?.data?.company?.defaultTransactions,
    chainName: getCompanySettings?.data?.company?.blockchain?.name,
    explorer: getCompanySettings?.data?.company?.blockchain?.explorerUrl,
    transactionDetails: {
      chainId: getCompanySettings?.data?.company?.blockchain?.chainId,
      blockNumber: contract.deploymentTransaction().blockNumber,
      blockhash: contract.deploymentTransaction().blockHash,
      gasLimit: Number(contract.deploymentTransaction().gasLimit),
      gasPrice: Number(contract.deploymentTransaction().gasPrice)
    }
  };

  const encryptedData = encryptData(
    JSON.stringify(payload),
    process.env.REACT_APP_ENCRYPTION_KEY
  );

  return encryptedData;
};

export const deployingPayloadForAdminTransaction = async (
  product,
  getCompanySettings,
  user,
  connectedWallet
) => {
  const payload = {
    productId: product?._id,
    initiatedBy: user.userId,
    chainId: getCompanySettings?.data?.company?.blockchain?.chainId,
    walletAddress: connectedWallet?.address,
    standard: getCompanySettings?.data?.company?.standard,
    transactionVia: getCompanySettings?.data?.company?.defaultTransactions,
    chainName: getCompanySettings?.data?.company?.blockchain?.name,
    explorer: getCompanySettings?.data?.company?.blockchain?.explorerUrl,
    params: {
      defaultAdmin: getCompanySettings?.data?.company?.walletAddress,
      wallet: connectedWallet.address,
      name: product?.name,
      shortName: product?.name?.substring(0, 3),
      maxSupply: process.env.REACT_APP_ERC721_MAX_SUPPLY,
      uri: `${process.env.REACT_APP_FRONT_END_SERVER_BASE_URL}/product/getProduct/scan/${product?._id}`
    }
  };

  const encryptedData = encryptData(
    JSON.stringify(payload),
    process.env.REACT_APP_ENCRYPTION_KEY
  );

  return encryptedData;
};
