import { createContext, useState } from "react";

const walletContext = createContext();

const WalletConnectProvider = ({ children }) => {
  const [walletConnect, setWalletConnect] = useState({});
  const updateWallet = newState => {
    setWalletConnect(newState);
  };
  return (
    <walletContext.Provider value={{ walletConnect, updateWallet }}>
      {children}
    </walletContext.Provider>
  );
};
export { walletContext, WalletConnectProvider };
