import React, { useState, useContext } from "react";
import dropdownIcon from "../../assets/images/icons/Vector-dropdown.svg";
import logo from "../../assets/images/logo/scane-trace-logo.svg";
import { authContext } from "../Context/AuthContext";
import { Link } from "react-router-dom";
import { useDisconnect } from "@web3modal/ethers/react";
import userPlaceholder from "../../assets/images/icons/userplaceholder.png";

const FarmerNavbar = () => {
  const { state } = useContext(authContext);
  const [user] = useState(
    JSON.parse(localStorage?.getItem("loggedinUser")) || state?.user
  );
  const { disconnect } = useDisconnect();

  return (
    <section className="header-top">
      <div className="container-fluid px-md-0">
        <div className="row">
          <div className="col-12">
            <div className="d-flex justify-content-between align-items-center">
              <div className="navbar-logo pt-2 pb-1">
                <img src={logo} alt="logo" />
              </div>
              <div className="dropdown admin-dropdown">
                <button
                  className="btn btn-secondary dropdown-toggle admin-btn px-0"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="admin-img">
                    {user.profilePic ? (
                      <img
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "50%"
                        }}
                        src={`${process.env.REACT_APP_SERVER_URL}/uploads/pfp/${user?.profilePic}`}
                      />
                    ) : user?.firstName ? (
                      user?.firstName?.charAt(0)
                    ) : (
                      <img
                        src={userPlaceholder}
                        alt="user-placeholder"
                        style={{ height: "30px", width: "30px" }}
                      />
                    )}
                  </span>
                  {user?.firstName}
                  <img
                    className="dd-icon"
                    src={dropdownIcon}
                    alt="icon-dropdown"
                  />
                </button>
                <ul className="dropdown-menu">
                  <li
                    onClick={() => {
                      localStorage.removeItem("loggedinUser");
                      sessionStorage.removeItem("walletConfig");
                      disconnect();
                      window.location.href = "/login";
                    }}
                  >
                    <Link className="dropdown-item">Logout</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default FarmerNavbar;
