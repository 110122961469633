import { useState, useEffect, useContext } from "react";
import breadcrumb from "../../assets/images/icons/breadcrumb-icon.svg";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../axiosConfig/axiosInstance";
import Loader from "../../components/Loader/Loader";
import { toast } from "react-toastify";
import { Pagination, PaginationItem } from "@mui/material";
import AddSubProduct from "./SubProducts/AddSubProduct";
import { BrowserProvider, Contract, formatEther } from "ethers";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";
import contractABI from "../../contracts/abi.json";
import contract1155 from "../../contracts/abi1155.json";
import { authContext } from "../../components/Context/AuthContext";
import DOMPurify from "dompurify";
import {
  mintingPayload,
  mintingPayloadForAdminTransaction
} from "../../utils/payload";
import { walletContext } from "../../components/Context/WalletConnectContext";
import productPlaceholder from "../../assets/images/products/product.png";
import { useWeb3ModalAccount } from "@web3modal/ethers/react";
import Modal from "react-bootstrap/Modal";
import downloadIcon from "../../assets/images/icons/download-ic.svg";
import closeIcon from "../../assets/images/icons/close-m.svg";
import Carousel from "react-bootstrap/Carousel";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const ProductDetails = () => {
  const [isNewStock, setIsNewStock] = useState(false);
  const [product, setProduct] = useState(null);
  const [subProducts, setSubProducts] = useState([]);
  const { productId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeployed, setIsDeployed] = useState(false);
  const [standard, setStandard] = useState("");
  const [contractAddress, setContractAddress] = useState(null);
  const [connectedWallet, setConnectedWallet] = useState();
  const { state } = useContext(authContext);
  const { walletProvider } = useWeb3ModalProvider(
    JSON.parse(sessionStorage.getItem("walletConfig"))
  );
  const [user] = useState(
    JSON.parse(localStorage.getItem("loggedinUser")) || state?.user
  );
  const { walletConnect } = useContext(walletContext);
  const [isUpdating, setIsUpdating] = useState(false);
  const [history, setHistory] = useState(null);
  const [isContractCopied, setIsContractCopied] = useState(false);
  const [isWalletCopied, setIsWalletCopied] = useState(false);
  const [transactionVia, setTransactionVia] = useState(null);
  const [mintingProduct, setMintingProduct] = useState(null);
  const { chainId } = useWeb3ModalAccount();
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
  const [QrCode, setQrCode] = useState(null);
  const [sessionConfig] = useState(
    walletConnect || JSON.parse(sessionStorage.getItem("walletConfig"))
  );
  const [slideIndex, setSlideIndex] = useState(0);
  const totalItems = product?.pictures?.length || 1;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [totalSubProducts, setTotalSubProducts] = useState(0);

  useEffect(() => {
    setConnectedWallet(walletConnect);
  }, [walletConnect]);
  useEffect(() => {
    fetchProductDetails(currentPage);
  }, [currentPage, productId, isNewStock]);

  const fetchProductDetails = async (currentlyPage) => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get(
        `/product/sub-products/${productId}?page=${currentPage}&limit=${perPage}`
      );
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth" // Use 'auto' for instant scrolling
      // });
      setProduct(response?.data?.productDetails);
      setSubProducts(response?.data?.productDetails?.subProducts);
      setTotalPages(response?.data?.totalPages);
      setCurrentPage(currentlyPage);
      setIsDeployed(response?.data?.isDeployed);
      setStandard(response?.data?.standard);
      setContractAddress(response?.data?.contractAddress);
      setTransactionVia(response?.data?.transactionVia);
      setDescription(
        response?.data?.productDetails?.description?.substring(0, 300)
      );
      setTotalSubProducts(response?.data?.totalSubProducts);
    } catch (error) {
      toast.error("Something went wrong");
      return;
    } finally {
      setIsLoading(false);
    }
  };
  const handlePagination = async (event, page) => {
    if (page < 1 || page > totalPages) {
      return; // Prevent clicking on disabled pages
    }
    // Calculate the new startPage based on the clicked page
    setCurrentPage(page);
  };

  // handle pagination mouse over page number
  const handleItemMouseEnter = (event, page) => {
    if (page !== currentPage) {
      event.target.style.color = "white"; // Change color on hover for other pages
      // event.target.style.background = "#202D46";
    }
  };

  // handle pagination mouse leave page number
  const handleItemMouseLeave = (event, page) => {
    if (page !== currentPage) {
      event.target.style.color = "#9FA1A1"; // Restore default color on mouse leave
      // event.target.style.background = "";
    }
  };

  const copyToClipboard = (text, type) => {
    if (type == "wallet") {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setIsWalletCopied(true);
          setTimeout(() => setIsWalletCopied(false), 1500); // Reset copied state after 1.5 seconds
        })
        .catch((err) => console.error("Failed to copy:", err));
    } else {
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setIsContractCopied(true);
          setTimeout(() => setIsContractCopied(false), 1500); // Reset copied state after 1.5 seconds
        })
        .catch((err) => console.error("Failed to copy:", err));
    }
  };

  const calculateGasConsumed = () => {
    if (history) {
      // Calculate gas cost
      const gasCost =
        Number(history?.transactionDetails?.gasLimit) *
        Number(history?.transactionDetails?.gasPrice);
      return formatEther(gasCost.toString());
    } else {
      return 0.0;
    }
  };

  const handleHistoryModel = async (product) => {
    try {
      const response = await axiosInstance.get(
        `/product/transaction/history?parentProductId=${product?.parentProduct}&subProductId=${product?._id}`
      );
      if (response) {
        setHistory(response?.data?.data);
      }
    } catch (error) {
      toast.error("Something went wrong");
      return;
    }
  };

  const mintProduct = async (product, getCompanySettings) => {
    let encryptedData;
    if (transactionVia === "admin") {
      encryptedData = await mintingPayloadForAdminTransaction(
        product,
        getCompanySettings,
        "minting",
        user,
        connectedWallet,
        contractAddress,
        transactionVia,
        standard
      );
    } else {
      let contract, result;
      try {
        const provider = new BrowserProvider(walletProvider);
        const signer = await provider.getSigner();
        if (standard === "erc721") {
          contract = new Contract(contractAddress, contractABI, signer);
          result = await contract.safeMint(
            connectedWallet.address,
            `${process.env.REACT_APP_FRONT_END_SERVER_BASE_URL}/product/getProduct/scan/${product?._id}`
          );
        } else if (standard === "erc1155") {
          contract = new Contract(contractAddress, contract1155, signer);
          result = await contract.mint(
            connectedWallet?.address,
            product?.quantity,
            `${process.env.REACT_APP_FRONT_END_SERVER_BASE_URL}/product/getProduct/scan/${product?._id}`
          );
        } else {
          toast.warn("No contract standard found");
          return;
        }
        await result.wait();
      } catch (error) {
        console.log("error occur while minting ", error);
        if (error?.info?.error?.message) {
          toast.error(error?.info?.error?.message);
        } else if (error?.error?.message) {
          toast.error(error?.error?.message);
        } else {
          toast.error(
            `Transaction failed. Please try again after some time or contact ${process.env.REACT_APP_SUPPORT_EMAIL}`
          );
        }
        return;
      }

      encryptedData = await mintingPayload(
        product,
        getCompanySettings,
        result,
        "minting",
        user,
        transactionVia,
        standard
      );
    }
    const response = await axiosInstance.post("/product/transaction/history", {
      encryptedData: encryptedData
    });
    if (response) {
      // update product minting status on real-time
      const updatedFilteredProducts = subProducts?.map((obj) => {
        if (product?._id == obj?._id) {
          return { ...obj, isMinted: true };
        } else {
          return { ...obj };
        }
      });
      setSubProducts(updatedFilteredProducts);
    }
    toast.success(response?.data?.message);

    return;
  };

  const handleMintingProduct = async (product) => {
    try {
      const getCompanySettings = await axiosInstance.get("/company/settings");
      if (
        chainId !== user?.networkSetting?.chainId &&
        getCompanySettings?.data?.company?.defaultTransactions !== "admin"
      ) {
        toast.warn(
          `Network conflit occur! Connect your wallet with ${user?.networkSetting?.name} to make transactions`
        );
        return;
      }
      if (
        !connectedWallet.isConnected &&
        getCompanySettings?.data?.company?.defaultTransactions !== "admin"
      ) {
        toast.warn(
          "Please Connect your wallet for product Minting on blockchain"
        );
        return;
      }
      if (!isDeployed) {
        toast.warn(
          "You need to deploy product first and then try to mint product"
        );
        return;
      }
      if (
        connectedWallet?.address?.toLowerCase() !==
          user?.walletAddress?.toLowerCase() &&
        getCompanySettings?.data?.company?.defaultTransactions !== "admin"
      ) {
        toast.warn(
          "Wallet mismatch! Your profile wallet is different from connected wallet"
        );
        return;
      }
      if (getCompanySettings?.data?.company?.walletAddress) {
        if (standard === getCompanySettings?.data?.company?.standard) {
          if (contractAddress) {
            setIsUpdating(true);
            setMintingProduct(product?._id);
            if (
              connectedWallet?.address ===
              getCompanySettings?.data?.company?.walletAddress
            ) {
              await mintProduct(product, getCompanySettings);
            } else {
              if (
                getCompanySettings?.data?.company?.defaultTransactions ===
                "admin"
              ) {
                await mintProduct(product, getCompanySettings);
              } else {
                // check whether user has access or not to mint product
                const checkAccessStatus = await axiosInstance.post(
                  "/user/check/minting-access",
                  {
                    productId: productId,
                    userId: user?.userId,
                    walletAddress: connectedWallet?.address
                  }
                );
                if (checkAccessStatus) {
                  await mintProduct(product, getCompanySettings);
                }
              }
            }
          } else {
            toast.warn("Deployed product contract address not found");
            return;
          }
        } else {
          toast.error("Product contract standard conflict occur");
          return;
        }
      } else {
        toast.warn(
          "You can not Mint product as Default admin wallet is missing. Kindly contact support team"
        );
        return;
      }
    } catch (error) {
      toast.error(error?.info?.error?.message);
      return;
    } finally {
      setIsUpdating(false);
      setMintingProduct(null);
    }
  };
  // Function to update parent state
  const updateParentState = (newState) => {
    setIsNewStock(newState);
  };

  const handleQRCodeModal = (qrcode) => {
    setIsQRCodeModalOpen(true);
    setQrCode(qrcode);
  };
  const handleClose = () => setIsQRCodeModalOpen(false);

  const handleSelect = (selectedIndex, e) => {
    // setIndex(selectedIndex);
    if (selectedIndex >= 0 && selectedIndex < totalItems) {
      setSlideIndex(selectedIndex);
    }
    scrollThumbnails(selectedIndex);
  };

  const handlePrev = () => {
    if (slideIndex > 0) {
      setSlideIndex(slideIndex - 1);
      scrollThumbnails(slideIndex-1)
    }
  };

  const handleNext = () => {
    if (slideIndex < totalItems - 1) {
      setSlideIndex(slideIndex + 1);
      scrollThumbnails(slideIndex+1)
    }
    
  };

  const downloadQrCode = (type, qrCodeData) => {
    try {
      if (!qrCodeData) {
        toast.warn("QR Code is not loaded properly. Please try again");
        return;
      }
      const base64Data = qrCodeData.split(",")[1];
      const byteCharacters = atob(base64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: `image/${type}` });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `qrcode.${type}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.log("error is ", error);
      toast.error(error?.message);
      return;
    } finally {
      setIsQRCodeModalOpen(false);
    }
  };

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    // Array of month names
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    // Get components of the date
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Pad single digit numbers with leading zero
    const padZero = (num) => (num < 10 ? "0" : "") + num;

    // Format the date string
    const formattedDate = `${padZero(day)}-${month}-${year} ${padZero(
      hours
    )}:${padZero(minutes)}:${padZero(seconds)}`;

    return formattedDate;
  }


  const scrollThumbnails = (index) => {
    try{

      const thumbContainer = document.querySelector('.thumb-product_carousel ul');
      if (thumbContainer) {
        const thumbWidth = thumbContainer.children[0].offsetWidth; // Assuming all thumbnails have the same width
        const containerScrollPosition = thumbWidth * index - thumbContainer.clientWidth / 2 + thumbWidth / 2;
        thumbContainer.scrollTo({
          left: containerScrollPosition,
          behavior: 'smooth'
        });
      }
    }catch(error){
      console.log(error?.message)
    }
  };

  const handleThumbClick = (index) => {
    setSlideIndex(index);
    scrollThumbnails(index);
  };
  return (
    <>
      {isNewStock ? (
        <AddSubProduct
          product={product}
          updateParentState={updateParentState}
        />
      ) : (
        <>
          {isQRCodeModalOpen ? (
            <>
              <Modal
                show={isQRCodeModalOpen}
                onHide={handleClose}
                className="fade qrModal"
                centered
              >
                <button
                  className="close-btn border-0 bg-transparent"
                  onClick={handleClose}
                >
                  <img src={closeIcon} alt="close-modal-icon" />
                </button>
                <Modal.Body className="position-relative">
                  <div className="text-center p-0">
                    <div className="qr-icon-img mt-3">
                      <img className="w-100" src={QrCode} alt="email-icon" />
                    </div>

                    <div className="modal-text text-center">
                      Download QR Code
                    </div>

                    <ul className="nav justify-content-center align-items-center">
                      <li className="nav-item">
                        <button
                          className="btn btn-dm"
                          onClick={() => downloadQrCode("svg", QrCode)}
                        >
                          Svg
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className="btn btn-dm"
                          onClick={() => downloadQrCode("png", QrCode)}
                        >
                          Png
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className="btn btn-dm"
                          onClick={() => downloadQrCode("jpg", QrCode)}
                        >
                          jpg
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          className="btn btn-dm"
                          onClick={() => downloadQrCode("jpeg", QrCode)}
                        >
                          jpeg
                        </button>
                      </li>
                    </ul>
                  </div>
                </Modal.Body>
              </Modal>
            </>
          ) : null}

          <>
            <section className="section-main">
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  {product ? (
                    <div className="content-sec">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-12 d-flex align-items-center justify-content-between">
                            <div className="breadcrumb-bar">
                              <ul className="nav align-items-center">
                                <li className="nav-item">
                                  <Link to="/products">Products</Link>
                                </li>
                                <li className="nav-item">
                                  <img src={breadcrumb} alt="icon" />
                                </li>
                                <li className="nav-item">
                                  <span className="product-name">Details</span>
                                </li>
                              </ul>
                            </div>
                            {isDeployed &&
                            !user?.isViewer &&
                            !user?.superAdmin ? (
                              <Link
                                onClick={() => setIsNewStock(true)}
                                className="btn btn-primary"
                              >
                                Add New Stock
                              </Link>
                            ) : null}
                          </div>
                        </div>

                        <div className="report-header mt-4">
                          <div className="row">
                            <div className="col-xl-6 col-xxl-7 rh-col">
                              <div className="rh-body product_detail--carousel">
                                {/* <!-- New design of show case of uploaded product iamges 21 july 2024 --> */}
                                <div
                                  id="products_carousel"
                                >
                                  <Carousel
                                    interval={null}
                                    activeIndex={slideIndex}
                                    controls={false}
                                    onSelect={handleSelect}
                                    indicators={false}
                                  >
                                    {
                                      product?.pictures?.length ? (
                                        product?.pictures?.map(
                                          (picture, index) => {
                                            return (
                                              <Carousel.Item key={index}>
                                                <img
                                                  src={`${process.env.REACT_APP_SERVER_URL}/uploads/${product?.company}/${picture}`}
                                                  alt="product-img"
                                                />
                                              </Carousel.Item>
                                            );
                                          }
                                        )
                                      ) : (
                                        <Carousel.Item>
                                          <img
                                            src={productPlaceholder}
                                            alt="placeholder image"
                                          />
                                        </Carousel.Item>
                                      )
                                      // {/* <!-- End new design --> */}
                                    }
                                    <>
                                      <button
                                        type="button"
                                        data-bs-target="#products_carousel"
                                        data-bs-slide="prev"
                                        onClick={handlePrev}
                                        disabled={slideIndex == 0}
                                        className={`carousel-control-prev ${
                                          slideIndex == 0 ? "" : "active"
                                        }`}
                                      >
                                        <svg
                                          width="6.98"
                                          height="12.33"
                                          viewBox="0 0 8 14"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M6.78107 1.1175C6.92499 1.29739 7.00144 1.49528 7.01044 1.71115C7.01943 1.92702 6.94298 2.11591 6.78107 2.27782L2.03187 7.02702L6.80806 11.8032C6.95197 11.9471 7.01943 12.1405 7.01044 12.3834C7.00144 12.6262 6.92499 12.8196 6.78107 12.9635C6.60118 13.1434 6.40779 13.2289 6.20091 13.2199C5.99403 13.2109 5.80964 13.1254 5.64774 12.9635L0.277897 7.59369C0.18795 7.50374 0.124987 7.41379 0.0890079 7.32385C0.0530291 7.2339 0.03504 7.13496 0.03504 7.02702C0.03504 6.91908 0.0530291 6.82014 0.0890079 6.7302C0.124987 6.64025 0.18795 6.5503 0.277897 6.46035L5.62076 1.1175C5.78266 0.955593 5.97155 0.879139 6.18742 0.888133C6.40329 0.897128 6.60118 0.973583 6.78107 1.1175Z"
                                            fill="#ffffff"
                                          />
                                        </svg>
                                        <span className="visually-hidden">
                                          Previous
                                        </span>
                                      </button>
                                      <button
                                        type="button"
                                        data-bs-target="#products_carousel"
                                        data-bs-slide="next"
                                        onClick={handleNext}
                                        disabled={slideIndex == totalItems - 1}
                                        className={`carousel-control-next ${
                                          slideIndex == totalItems - 1
                                            ? ""
                                            : "active"
                                        }`}
                                      >
                                        <svg
                                          width="6.98"
                                          height="12.33"
                                          viewBox="0 0 8 14"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M0.409357 1.1175C0.265442 1.29739 0.188987 1.49528 0.179992 1.71115C0.170997 1.92702 0.247452 2.11591 0.409357 2.27782L5.15856 7.02702L0.382374 11.8032C0.238458 11.9471 0.170998 12.1405 0.179993 12.3834C0.188988 12.6262 0.265443 12.8196 0.409358 12.9635C0.589252 13.1434 0.782638 13.2289 0.989517 13.2199C1.1964 13.2109 1.38079 13.1254 1.54269 12.9635L6.91253 7.59369C7.00248 7.50374 7.06544 7.41379 7.10142 7.32385C7.1374 7.2339 7.15539 7.13496 7.15539 7.02702C7.15539 6.91908 7.1374 6.82014 7.10142 6.7302C7.06544 6.64025 7.00248 6.5503 6.91253 6.46035L1.56967 1.1175C1.40777 0.955594 1.21888 0.879139 1.00301 0.888134C0.787135 0.897129 0.589251 0.973584 0.409357 1.1175Z"
                                            fill="#ffffff"
                                          />
                                        </svg>
                                        <span className="visually-hidden">
                                          Next
                                        </span>
                                      </button>
                                    </>
                                  </Carousel>
                                </div>
                                {product?.pictures?.length ? (
                                  <section className="thumb-product_carousel mt-2">
                                    <div className="row">
                                      <div className="col-12">
                                        <ul className="nav align-items-center justify-content-start flex-nowrap">
                                          {product?.pictures?.map(
                                            (picture, index) => {
                                              return (
                                                <li
                                                  className="nav-item"
                                                  key={index}
                                                  onClick={() => handleThumbClick(index)}
                                                >
                                                  <div
                                                    className={`thumb-img ${
                                                      index === slideIndex
                                                        ? "active"
                                                        : ""
                                                    }`}
                                                    data-bs-slide-to={
                                                      slideIndex
                                                    }
                                                  >
                                                    <div className="overlay"></div>
                                                    <img
                                                      src={`${process.env.REACT_APP_SERVER_URL}/uploads/${product?.company}/${picture}`}
                                                      className="img-fluid"
                                                      alt="Thumbnail 1"
                                                    />
                                                  </div>
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </div>
                                    </div>
                                  </section>
                                ) : null}
                              </div>
                            </div>

                            <div className="col-xl-6 col-xxl-5 rh-col">
                              <div className="rh-body product_detail--info h-100">
                                <ul
                                  className="nav nav-tabs nav-fill border-0"
                                  id="myTab"
                                  role="tablist"
                                >
                                  <li className="nav-item">
                                    <button
                                      className="nav-link active"
                                      id="gen-info"
                                      data-bs-toggle="tab"
                                      data-bs-target="#Genral_info"
                                      type="button"
                                      role="tab"
                                      aria-selected="true"
                                    >
                                      General Information
                                    </button>
                                  </li>
                                  <li className="nav-item">
                                    <button
                                      className="nav-link"
                                      id="location"
                                      data-bs-toggle="tab"
                                      data-bs-target="#Location"
                                      type="button"
                                      role="tab"
                                      aria-controls="profile"
                                      aria-selected="false"
                                    >
                                      Location
                                    </button>
                                  </li>
                                </ul>
                                <div className="tab-content mt-3">
                                  <div
                                    className="tab-pane fade show active"
                                    id="Genral_info"
                                  >
                                    <ul className="nav">
                                      <li className="nav-item">
                                        <div className="product-details">
                                          <div className="serial mb-2">
                                            <b>Brand</b>: {product?.brand}
                                          </div>
                                          <div className="date mb-2">
                                            <b>Created Date</b>:
                                            {formatDate(product?.createdAt)}
                                          </div>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="tab-pane fade" id="Location">
                                    <div className="table-responsive">
                                      <table className="table location-table">
                                        {product?.locations?.length > 0 ? (
                                          <tr>
                                            <th>Label</th>
                                            <th>Country</th>
                                            <th>City</th>
                                          </tr>
                                        ) : null}
                                        {product?.locations?.length > 0 ? (
                                          product?.locations?.map(
                                            (location) => {
                                              return (
                                                <tr key={location?.uid}>
                                                  <td>{location?.label}</td>
                                                  <td>{location?.country}</td>
                                                  <td>{location?.city}</td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ) : (
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center"
                                            }}
                                          >
                                            <span className="text-secondary">
                                              Locations not added yet
                                            </span>
                                          </div>
                                        )}
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div className="product-f-details">
                                <div className="pd-header">{product?.name}</div>
                                <div className="pdp">
                                  <p
                                    className="product-details-pra"
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(description)
                                    }}
                                  ></p>
                                  {product?.description?.length < 300 ? null : (
                                    <div className="vm-vl">
                                      <span
                                        className={`v-more ${
                                          isViewOpen ? "v-more-hide" : null
                                        }`}
                                        onClick={() => {
                                          setIsViewOpen(!isViewOpen);
                                          setDescription(product?.description);
                                        }}
                                      >
                                        View More
                                        <i className="bi bi-chevron-down"></i>
                                      </span>
                                      <span
                                        className={`v-less ${
                                          isViewOpen ? null : "v-less-hide"
                                        }`}
                                        onClick={() => {
                                          setIsViewOpen(!isViewOpen);
                                          setDescription(
                                            product?.description?.substring(
                                              0,
                                              300
                                            )
                                          );
                                        }}
                                      >
                                        View Less
                                        <i className="bi bi-chevron-up"></i>
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {subProducts?.length > 0 ? (
                          <div className="row">
                            <div className="col-12">
                              <div className="products-table pt-30">
                                <div className="table-responsive">
                                  <table className="table">
                                    <thead>
                                      <tr>
                                        <th>Product</th>
                                        <th>Quantity</th>
                                        <th>Serial No</th>
                                        <th>Date</th>
                                        <th>QR Code</th>
                                        <th></th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {subProducts?.map((item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>
                                              <ul className="nav align-items-center">
                                                <li className="nav-item">
                                                  <div className="product-img">
                                                    {product?.pictures
                                                      ?.length ? (
                                                      <img
                                                        src={`${process.env.REACT_APP_SERVER_URL}/uploads/${product?.company}/${product?.pictures[0]}`}
                                                      />
                                                    ) : (
                                                      <img
                                                        src={productPlaceholder}
                                                      />
                                                    )}
                                                  </div>
                                                </li>
                                                {product?.name?.length > 20 ? (
                                                  <li className="nav-item">
                                                    {product?.name?.substring(
                                                      0,
                                                      20
                                                    )}
                                                    ...
                                                  </li>
                                                ) : (
                                                  <li className="nav-item">
                                                    {product?.name}
                                                  </li>
                                                )}
                                              </ul>
                                            </td>
                                            <td>{item?.quantity}</td>
                                            <td>{item?.serialNum}</td>
                                            <td>
                                              {formatDate(item?.createdAt)}
                                            </td>
                                            <td>
                                              <div className="d-flex align-items-center gap-2">
                                                <button
                                                  className="btn btn-light"
                                                  onClick={() =>
                                                    handleQRCodeModal(
                                                      item?.qrCodeData
                                                    )
                                                  }
                                                >
                                                  <img
                                                    src={downloadIcon}
                                                    alt="download-icon"
                                                    className="me-2"
                                                  />
                                                  QR Code
                                                </button>
                                              </div>
                                            </td>

                                            {item?.isMinted ? (
                                              <>
                                                <td>
                                                  <a
                                                    data-bs-toggle="offcanvas"
                                                    href="#transaction-Offcanvas"
                                                    className="btn btn-light"
                                                    onClick={() =>
                                                      handleHistoryModel(item)
                                                    }
                                                  >
                                                    <svg
                                                      width="17"
                                                      height="16"
                                                      viewBox="0 0 17 16"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <g clipPath="url(#clip0_1121_792)">
                                                        <path
                                                          d="M8.50006 1.4375C6.11533 1.4375 4.20578 2.51562 2.81568 3.79883C1.43443 5.07031 
                                   0.510646 6.59375 0.0738403 7.63965C-0.0235555 7.87109 -0.0235555 8.12891 0.0738403 8.36035C0.510646 
                                   9.40625 1.43443 10.9297 2.81568 12.2012C4.20578 13.4844 6.11533 14.5625 8.50006 14.5625C10.8848 
                                   14.5625 12.7943 13.4844 14.1844 12.2012C15.5657 10.9268 16.4895 9.40625 16.9292 8.36035C17.0266 
                                   8.12891 17.0266 7.87109 16.9292 7.63965C16.4895 6.59375 15.5657 5.07031 14.1844 3.79883C12.7943 
                                   2.51562 10.8848 1.4375 8.50006 1.4375ZM4.25006 8C4.25006 6.88112 4.69782 5.80806 5.49485 
                                   5.01689C6.29188 4.22572 7.37289 3.78125 8.50006 3.78125C9.62723 3.78125 10.7082 4.22572 
                                   11.5053 5.01689C12.3023 5.80806 12.7501 6.88112 12.7501 8C12.7501 9.11888 12.3023 
                                   10.1919 11.5053 10.9831C10.7082 11.7743 9.62723 12.2188 8.50006 12.2188C7.37289 12.2188 
                                   6.29188 11.7743 5.49485 10.9831C4.69782 10.1919 4.25006 9.11888 4.25006 8ZM8.50006 
                                   6.125C8.50006 7.15918 7.65301 8 6.61117 8C6.40162 8 6.20092 7.96484 6.01203 7.90332C5.84971 
                                   7.85059 5.66082 7.9502 5.66672 8.12012C5.67558 8.32227 5.70509 8.52441 5.76117 8.72656C6.16551 
                                   10.2266 7.72089 11.1172 9.232 10.7158C10.7431 10.3145 11.6403 8.77051 11.236 7.27051C10.9084 
                                   6.05469 9.82523 5.2373 8.62106 5.1875C8.44988 5.18164 8.34953 5.36621 8.40266 5.53027C8.46464 
                                   5.71777 8.50006 5.91699 8.50006 6.125Z"
                                                          fill=""
                                                        />
                                                      </g>
                                                      <defs>
                                                        <clipPath id="clip0_1121_792">
                                                          <rect
                                                            width="17"
                                                            height="15"
                                                            fill="white"
                                                            transform="translate(0 0.5)"
                                                          />
                                                        </clipPath>
                                                      </defs>
                                                    </svg>
                                                    Details
                                                  </a>
                                                </td>
                                              </>
                                            ) : (
                                              <>
                                                <td>
                                                  {user?.companyAdmin ||
                                                  user?.isEditor ? (
                                                    <Link
                                                      to={""}
                                                      className={`btn btn-primary deploy`}
                                                      onClick={() =>
                                                        !(
                                                          isUpdating ||
                                                          sessionConfig?.pendingSession
                                                        )
                                                          ? handleMintingProduct(
                                                              item
                                                            )
                                                          : null
                                                      }
                                                      style={{
                                                        cursor: isUpdating
                                                          ? "not-allowed"
                                                          : ""
                                                      }}
                                                    >
                                                      <svg
                                                        width="14"
                                                        height="16"
                                                        viewBox="0 0 14 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                      >
                                                        <g clipPath="url(#clip0_1125_861)">
                                                          <path
                                                            d="M6.07143 2.375V4.25C6.07143 4.76855 6.48638 5.1875 7 5.1875H8.85714C9.37076 5.1875 
        9.78571 4.76855 9.78571 4.25V2.375C9.78571 1.85645 9.37076 1.4375 8.85714 1.4375H7C6.48638 1.4375
        6.07143 1.85645 6.07143 2.375ZM2.89978 6.56445C2.4558 6.82227 2.30491 7.39648 2.56027 7.84473L3.48884 
        9.46777C3.7442 9.91602 4.31295 10.0684 4.75692 9.81055L6.36451 8.87305C6.80848 8.61523 6.95937 8.04102 
        6.70402 7.59277L5.77545 5.96973C5.52009 5.52148 4.95134 5.36914 4.50737 5.62695L2.89978 6.56445ZM8.85714 
        6.125C8.34353 6.125 7.92857 6.54395 7.92857 7.0625V8.9375C7.92857 9.45605 8.34353 9.875 8.85714 9.875H10.7143C11.2279 
        9.875 11.6429 9.45605 11.6429 8.9375V7.0625C11.6429 6.54395 11.2279 6.125 10.7143 6.125H8.85714ZM10.7143 10.8125C10.2007 
        10.8125 9.78571 11.2314 9.78571 11.75V13.625C9.78571 14.1436 10.2007 14.5625 10.7143 14.5625H12.5714C13.085 14.5625 13.5
        14.1436 13.5 13.625V11.75C13.5 11.2314 13.085 10.8125 12.5714 10.8125H10.7143ZM5.14286 11.75V13.625C5.14286 14.1436 5.55781 
        14.5625 6.07143 14.5625H7.92857C8.44219 14.5625 8.85714 14.1436 8.85714 13.625V11.75C8.85714 11.2314 8.44219 10.8125 
        7.92857 10.8125H6.07143C5.55781 10.8125 5.14286 11.2314 5.14286 11.75ZM1.42857 10.8125C0.914955 10.8125 0.5 11.2314 
        0.5 11.75V13.625C0.5 14.1436 0.914955 14.5625 1.42857 14.5625H3.28571C3.79933 14.5625 4.21429 14.1436 4.21429 13.625V11.75C4.21429 
        11.2314 3.79933 10.8125 3.28571 10.8125H1.42857Z"
                                                            fill=""
                                                          />
                                                        </g>
                                                        <defs>
                                                          <clipPath id="clip0_1125_861">
                                                            <rect
                                                              width="13"
                                                              height="15"
                                                              fill="white"
                                                              transform="translate(0.5 0.5)"
                                                            />
                                                          </clipPath>
                                                        </defs>
                                                      </svg>
                                                      {isUpdating &&
                                                      item?._id ==
                                                        mintingProduct ? (
                                                        <span
                                                          className="spinner-border spinner-border-sm"
                                                          role="status"
                                                          aria-hidden="true"
                                                        ></span>
                                                      ) : sessionConfig?.pendingSession ? (
                                                        <span
                                                          style={{
                                                            cursor:
                                                              "not-allowed"
                                                          }}
                                                        >
                                                          Mint Stock
                                                        </span>
                                                      ) : (
                                                        <span>Mint Stock</span>
                                                      )}
                                                    </Link>
                                                  ) : null}
                                                </td>
                                              </>
                                            )}
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                                {totalSubProducts > perPage ? (
                                  <div className="table-footer row">
                                    <div className="col-12">
                                      <ul className="nav align-items-center justify-content-end">
                                        <Pagination
                                          count={totalPages}
                                          page={currentPage}
                                          shape="rounded"
                                          onChange={handlePagination}
                                          siblingCount={isMobile ? 0 : 2}
                                          boundaryCount={isMobile ? 0 : 2}
                                          renderItem={(item) => (
                                            <PaginationItem
                                              {...item}
                                              onMouseEnter={(e) =>
                                                handleItemMouseEnter(
                                                  e,
                                                  item.page
                                                )
                                              }
                                              onMouseLeave={(e) =>
                                                handleItemMouseLeave(
                                                  e,
                                                  item.page
                                                )
                                              }
                                              style={{
                                                background:
                                                  item.page === currentPage
                                                    ? "#202D46"
                                                    : "",
                                                color:
                                                  item.page === currentPage
                                                    ? "white"
                                                    : "#9FA1A1"
                                              }}
                                            />
                                          )}
                                        />
                                      </ul>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div
                              style={{ height: "50vh" }}
                              className="d-flex justify-content-center align-items-center"
                            >
                              <div>
                                <p>No sub-product added yet</p>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div
                        style={{ height: "100vh" }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <div>
                          <p>No Data Found</p>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </section>
          </>
        </>
      )}

      <div
        className="offcanvas offcanvas-end details-Offcanvas"
        tabIndex="-1"
        id="transaction-Offcanvas"
        aria-labelledby="offcanvasRightLabel"
      >
        <div className="offcanvas-header">
          <h5 id="offcanvasRightLabel">Transaction Detail</h5>
          <button
            type="button"
            className="btn-close text-reset"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <i className="bi bi-x"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <div className="t-details">
            <div className="title">Transaction Hash:</div>
            <div className="decb">
              <a
                href={`${history?.explorer}/tx/${history?.txHash}`}
                target="_blank"
              >
                {history?.txHash.substring(0, 24)}.....
                {history?.txHash?.substring(history?.txHash?.length - 24)}
                <i className="bi bi-box-arrow-up-right"></i>
              </a>
            </div>
          </div>

          <div className="t-details">
            <div className="title">Wallet Address:</div>
            <div className="decb">
              {history?.walletAddress}

              <span
                onClick={() =>
                  copyToClipboard(history?.walletAddress, "wallet")
                }
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.3078 10.875V5.20834C14.3078 4.31417 13.583 3.58929 12.6888 
                 3.58929H7.02212C6.12795 3.58929 5.40308 4.31417 5.40308 5.20834V10.875C5.40308 11.7692 6.12795 12.4941 7.02212 
                 12.4941H12.6888C13.583 12.4941 14.3078 11.7692 14.3078 10.875Z"
                    stroke="#9FA1A2"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.8794 12.4941V13.3036C11.8794 14.1978 11.1545 14.9226 10.2603 14.9226H4.59366C3.69948 
                 14.9226 2.97461 14.1978 2.97461 13.3036V7.63693C2.97461 6.74276 3.69948 6.01788 4.59366 6.01788H5.40318"
                    stroke="#9FA1A2"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              {isWalletCopied && (
                <span
                  style={{
                    width: "80px",
                    marginLeft: "5px",
                    color: "#202D46",
                    borderRadius: "4px"
                  }}
                >
                  Copied !
                </span>
              )}
            </div>
          </div>

          <div className="t-details">
            <div className="title">Contract Address:</div>
            <div className="decb">
              {contractAddress}
              <span
                onClick={() => copyToClipboard(contractAddress, "contract")}
              >
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M14.3078 10.875V5.20834C14.3078 4.31417 13.583 3.58929 12.6888 
                 3.58929H7.02212C6.12795 3.58929 5.40308 4.31417 5.40308 5.20834V10.875C5.40308 11.7692 6.12795 12.4941 7.02212 
                 12.4941H12.6888C13.583 12.4941 14.3078 11.7692 14.3078 10.875Z"
                    stroke="#9FA1A2"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.8794 12.4941V13.3036C11.8794 14.1978 11.1545 14.9226 10.2603 14.9226H4.59366C3.69948 
                 14.9226 2.97461 14.1978 2.97461 13.3036V7.63693C2.97461 6.74276 3.69948 6.01788 4.59366 6.01788H5.40318"
                    stroke="#9FA1A2"
                    strokeWidth="1.3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              {isContractCopied && (
                <span
                  style={{
                    width: "80px",
                    marginLeft: "5px",
                    color: "#202D46",
                    borderRadius: "4px"
                  }}
                >
                  Copied !
                </span>
              )}
            </div>
          </div>

          <div className="t-details">
            <div className="title">Chain:</div>
            <div className="decb">
              {history?.chainName ? history?.chainName : history?.chainId}
            </div>
          </div>

          <div className="t-details">
            <div className="title">Minted By:</div>
            <div className="decb">{history?.initiatedBy?.email}</div>
          </div>

          <div className="t-details">
            <div className="title">Gas Fee:</div>
            <div className="decb">{calculateGasConsumed()}</div>
          </div>

          <div className="t-details">
            <div className="title">Created at:</div>
            <div className="decb">{formatDate(history?.createdAt)}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetails;
