import React, { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import CompleteProfile from "./CompleteProfile";
import handShakeIcon from "../../../assets/images/farmer/hand-shake.png";
import WalletConnect from "./WalletConnect";

const Welcome = () => {
  const [isStarted, setIsStarted] = useState(false);

  const updateStartSession = (newState)=>{
    setIsStarted(newState)
  }

  return (
    <>
      {isStarted ? (
        <CompleteProfile updateStartSession={updateStartSession} />
      ) : (
        <section className="section-main ms-0 link-wallet">
          

          <div className="content-sec">
            <div className="container-fluid pt-5">
              <div className="row justify-content-center">
                <div className="col-12">
                  <div className="welcome-banner">
                    <div className="img-handShake mb-3">
                      <img src={handShakeIcon} alt="img-handShake" />
                    </div>
                    <div className="title">Welcome to your Account</div>
                    <p>
                      You have successfully Logged in to your account you can
                      now customise your profile and view your Tips detail.
                    </p>
                    <a  className="btn btn-primary w-100" onClick={()=>{
                        setIsStarted(true)
                    }}>
                      GET STARTED
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
export default Welcome;
