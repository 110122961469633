import React, { useState, useEffect } from "react";
import breadcrumbLogo from "../../../assets/images/icons/breadcrumb-icon.svg";
import { useParams, Link } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import { axiosInstance } from "../../../axiosConfig/axiosInstance";
import { toast } from "react-toastify";
import activeStars from "../../../assets/images/icons/active-star.svg";
import ratingStats from "../../../constants/ratingStats";
import starsData from "../../../constants/stars";
import { MdVerified } from "react-icons/md";
import MultipleLocations from "../../../GoogleMap/MultipleLocations";
import { Pagination, PaginationItem } from "@mui/material";
import { DatePicker, Space } from "antd";
import productPlaceholder from "../../../assets/images/products/product.png";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import scanLogo from "../../../assets/images/icons/scan.svg";
import comentsLogo from "../../../assets/images/icons/total-comment.svg";
import { convertToCSV } from "../../../utils/csvConversion";
import { downloadCSV } from "../../../utils/csvDownload";
import exportIcon from "../../../assets/images/reporting/exports.svg"
import Button from "react-bootstrap/Button";

const ReportDetails = () => {
  const { RangePicker } = DatePicker;
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [scanLocations, setScanLocations] = useState([]);
  const [review, setReview] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [perPage] = useState(100);
  const [isLoading, setIsLoading] = useState(false);
  const [rating, setRating] = useState(ratingStats);
  const [stars] = useState(starsData);
  const [reviewLoading, setReviewLoading] = useState(false);
  const [totalReviewsCount, setTotalReviewsCount] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);
  const [isCalenderAvailable, setIsCalenderAvailable] = useState(false);
  const [productScannedLocations, setProductScannedLocations] = useState([]);
  const [activeTab, setActiveTab] = useState("scans")
  const [filterType, setFilterType] = useState({
    stars: 0,
    dropDown: "newest"
  });
  const [filters] = useState([
    { id: 1, name: "Newest", isSelected: true },
    { id: 2, name: "1 Week", isSelected: false },
    { id: 3, name: "3 Weeks", isSelected: false },
    { id: 4, name: "1 Month", isSelected: false },
  ]);
  const [filterStars, setFilterStars] = useState([
    { star: 5, className: "" },
    { star: 4, className: "" },
    { star: 3, className: "" },
    { star: 2, className: "" },
    { star: 1, className: "" }
  ]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    fetchData();
  }, [productId]);

  useEffect(() => {
    fetchReviews();
  }, [
    currentPage,
    filterType?.stars,
    filterType?.dropDown,
    startDate,
    endDate
  ]);

  // fetch product details
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const data = await axiosInstance.get(
        `/product/report/details/${productId}`
      );
      setProduct(data?.data?.product);
      setScanLocations(data?.data?.scanLocations);
      setProductScannedLocations(data?.data?.scanLocations);
      if (data?.data?.product) {
        // Initialize counters for each star rating
        let starsCount = [0, 0, 0, 0, 0];
        // Count the number of occurrences of each star rating
        data?.data?.product.reviews.forEach((review) => {
          starsCount[review.rating - 1]++;
        });
        // Calculate the total number of reviews
        const totalReviews = data?.data?.product?.reviews.length;
        // Update the progress property of each object in the ratingStats array
        ratingStats.forEach((stat) => {
          const stars = stat.stars;
          const count = starsCount[stars - 1];
          stat.progress = totalReviews === 0 ? 0 : (count / totalReviews) * 100;
        });
        ratingStats.sort((a, b) => b.stars - a.stars);
        setRating(ratingStats);
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  // fetch product reviews
  const fetchReviews = async () => {
    try {
      setReviewLoading(true);
      const productReviews = await axiosInstance.get(
        `/product/review/${productId}?limit=${perPage}&page=${currentPage}&rating=${filterType?.stars}&filterType=${filterType?.dropDown}&startDate=${startDate}&endDate=${endDate}`
      );
      setReview(productReviews?.data?.reviews);
      setCurrentPage(productReviews?.data?.currentPage);
      setTotalPages(productReviews?.data?.totalPages);
      setTotalReviewsCount(productReviews?.data?.totalReviewsCount);
    } catch (error) {
      toast.error("Someting went wrong");
    } finally {
      setReviewLoading(false);
    }
  };

  const handlePagination = async (event, page) => {
    if (page < 1 || page > totalPages) {
      return; // Prevent clicking on disabled pages
    }
    // Calculate the new startPage based on the clicked page
    setCurrentPage(page);
    // await fetchData(page);
  };

  // handle pagination mouse over page number
  const handleItemMouseEnter = (event, page) => {
    if (page !== currentPage) {
      event.target.style.color = "white"; // Change color on hover for other pages
      // event.target.style.background = "#202D46";
    }
  };

  // handle pagination mouse leave page number
  const handleItemMouseLeave = (event, page) => {
    if (page !== currentPage) {
      event.target.style.color = "#9FA1A1"; // Restore default color on mouse leave
      // event.target.style.background = "";
    }
  };

  // date formate
  const dateFormate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  // drpdown total comments filters
  const handleDropdownFilters = (event) => {
    
    if (event.target.value !== "Newest") {
      setIsCalenderAvailable(true);
    } else {
      setIsCalenderAvailable(false);
    }
    setFilterType({ ...filterType, dropDown: event.target.value });
    setCurrentPage(1);
  };

  // dropdown total scans filter
  const handleTotalScansFilter = (event) => {
    const { value } = event.target;
    if (scanLocations?.length) {
      let filteredResults;

      if (value === "Newest") {
        filteredResults = scanLocations;
      } else if (value === "1 Week") {
        const oneWeekAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
        filteredResults = scanLocations.filter((location) => {
          return new Date(location?.createdAt) <= oneWeekAgo;
        });
      } else if (value === "3 Weeks") {
        const threeWeeksAgo = new Date(Date.now() - 21 * 24 * 60 * 60 * 1000);
        filteredResults = scanLocations.filter((location) => {
          return new Date(location?.createdAt) <= threeWeeksAgo;
        });
      } else if (value === "1 Month") {
        const oneMonthAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
        filteredResults = scanLocations.filter((location) => {
          return new Date(location?.createdAt) <= oneMonthAgo;
        });
      }

      setProductScannedLocations(filteredResults);
    }
  };

  // handle date picker
  const handleDatePicker = (dates, dateString) => {
    if (dateString?.length) {
      if (dateString[0]?.length && dateString[1]?.length) {
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
        setIsDropdownDisabled(true);
      } else {
        setIsDropdownDisabled(false);
        setStartDate(dateString[0]);
        setEndDate(dateString[1]);
      }
    }
  };

  // tab filters
  const handleStarsFilter = (totalStar) => {
    const updatedClassname = filterStars?.map((star) => {
      if (star?.star === totalStar?.star && star.className !== "active") {
        return { ...star, className: "active" };
      } else {
        return { ...star, className: "" };
      }
    });
    setFilterStars(updatedClassname);
    if (totalStar?.className === "active") {
      setFilterType({ ...filterType, stars: 0 });
    } else {
      setFilterType({ ...filterType, stars: totalStar?.star });
    }
    setCurrentPage(1);
  };

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    // Array of month names
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];

    // Get components of the date
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();

    // Pad single digit numbers with leading zero
    const padZero = (num) => (num < 10 ? "0" : "") + num;

    // Format the date string
    const formattedDate = `${padZero(day)}-${month}-${year} ${padZero(
      hours
    )}:${padZero(minutes)}:${padZero(seconds)}`;

    return formattedDate;
  }

  const handleCsvExport = ()=>{
    if(activeTab === "scans"){
      if (productScannedLocations?.length) {
        const csvScanData = productScannedLocations.map((item) => ({
          productId: item?.productId,
          country: item?.country,
          city: item?.city,
          "created at": item?.createdAt,
          "scan address": item?.scannedIn
        }));
        const csv = convertToCSV(csvScanData);
        downloadCSV(csv, "scanned_locations.csv");
      } else {
        toast.warn("No location found to export");
      }
      return;
    }else{
        if (review?.length) {
          let csvReviewsData = [];
          review.forEach((item) => {
            csvReviewsData.push({
              productId: item?.productId,
              rating: item?.rating,
              comment: item?.comment,
              name: item?.name,
              email: item?.email,
              verified: item?.isVerified ? "yes" : "no",
              "created at": item?.createdAt
            });
          });
          const csv = convertToCSV(csvReviewsData);
          downloadCSV(csv, "feedback.csv");
          return;
        } else {
          toast.warn("No comments found to export");
          return;
        }
    }
  }
  return (
    <>
      <>
        <section className="section-main">
          {isLoading ? (
            <Loader />
          ) : product ? (
            <div className="content-sec">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 d-flex align-items-center justify-content-between">
                    <div className="breadcrumb-bar">
                      <ul className="nav align-items-center">
                        <li className="nav-item">
                          <Link to={"/products-reporting"}>Reporting</Link>
                        </li>
                        <li className="nav-item">
                          <img src={breadcrumbLogo} alt="icon" />
                        </li>
                        <li className="nav-item">
                          <span className="product-name">
                            {product?.parentProductData?.name}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="report-header mt-4">
                  <div className="row">
                    <div className="col-md-12 col-lg-12 col-xl-5 mb-3 mb-xl-0">
                      <ul className="nav flex-nowrap">
                        <li className="nav-item">
                          <div className="product-img">
                            {product?.parentProductData?.pictures?.length ? (
                              <img
                                src={`${process.env.REACT_APP_SERVER_URL}/uploads/${product?.parentProductData?.company}/${product?.parentProductData?.pictures[0]}`}
                                alt="product-b"
                              />
                            ) : (
                              <img src={productPlaceholder} alt="product-b" />
                            )}
                          </div>
                        </li>
                        <li className="nav-item">
                          <div className="product-details">
                            {product?.parentProductData?.name?.length > 20 ? (
                              <div className="name">
                                {product?.parentProductData?.name?.substring(
                                  0,
                                  20
                                )}
                                ...
                              </div>
                            ) : (
                              <div className="name">
                                {product?.parentProductData?.name}
                              </div>
                            )}

                            <div className="serial">
                              Serial No: {product?.serialNum}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div className="col-md-12 col-lg-12 col-xl-7">
                      <div className="row rating-row">
                        <div className="point-rateing">
                          <div className="perfect-rating">
                            {product?.averageRating?.toFixed(2)}
                          </div>
                          <div className="rate-text">
                            Based on {product?.totalReviews} reviews
                          </div>
                          <ul className="nav star-indicator">
                            {stars?.map((star) => {
                              return (
                                <li className="nav-item" key={star.id}>
                                  <div
                                    className={`r-star ${
                                      star?.star <= product?.averageRating
                                        ? "active"
                                        : null
                                    }`}
                                  ></div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div className="saperate-rateing">
                          {rating?.map((item, index) => {
                            return (
                              <div className="rating-details" key={index}>
                                <ul className="nav align-items-center">
                                  <li className="nav-item">{item.stars}</li>
                                  <li className="nav-item">
                                    <img src={activeStars} alt="star-icon" />
                                  </li>
                                  <li className="nav-item">
                                    <div className="progress rounded-pill">
                                      <div
                                        className="progress-bar rounded-pill"
                                        role="progressbar"
                                        style={{
                                          width:
                                            item.progress == 100
                                              ? "120px"
                                              : item?.progress
                                        }}
                                        aria-valuenow={item.progress}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      ></div>
                                    </div>
                                  </li>
                                  <li className="nav-item">
                                    {item.progress?.toFixed(0)}%
                                  </li>
                                </ul>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row dashboard-content reportDetail-content">
                  {product && scanLocations?.length > 0 ? (
                    <div className="col-xl-7 mb-3 mb-xl-0">
                      <div className="dashboard-area map">
                        <div className="content-badge">Scanned Locations</div>
                        <MultipleLocations scanLocations={scanLocations} />
                      </div>
                    </div>
                  ) : null}
                  <div className="col-xl-5 mb-3 mb-xl-0">
                    <div className="dashboard-area detail-scan-report">
                      <div className="content-badge">Scan Report</div>
                      <div className="report-sec total-scan">
                        <ul className="nav align-items-center">
                          <li className="nav-item">
                            <div className="scan-icon">
                              <img src={scanLogo} alt="icon" />
                            </div>
                          </li>
                          <li className="nav-item">
                            <div className="number-sec">{product?.totalScan}</div>
                            <div className="title">Total Scan</div>
                          </li>
                        </ul>
                      </div>
                      <div className="report-sec total-scan">
                        <ul className="nav align-items-center">
                          <li className="nav-item">
                            <div className="scan-icon">
                              <img src={comentsLogo} alt="icon" />
                            </div>
                          </li>
                          <li className="nav-item">
                            <div className="number-sec">
                              {product?.totalComments}
                            </div>
                            <div className="title">Total Comments</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="content-sec px-0">
                  <div className="container-fluid px-0">
                    <div className="row" >
                      <div className="col-md-8 mb-3 mb-md-0">
                        <div
                          className="nav nav-tabs products-tab"
                          id="details-tabs"
                        >
                          <button
                            className="nav-link active"
                            data-bs-toggle="tab"
                            data-bs-target="#total-scans"
                            onClick={()=> setActiveTab("scans")}
                          >
                            Total Scans ({product?.totalScan})
                          </button>
                          <button
                            className="nav-link"
                            data-bs-toggle="tab"
                            data-bs-target="#total-comments"
                            onClick={()=> setActiveTab("comments")}
                          >
                            Total Comments ({product?.totalComments})
                          </button>
                        </div>
                      </div>
                      <div className = "col-md-4">
                          <Button variant = "export" onClick={handleCsvExport}>
                            <img src={exportIcon} alt="export-icon" />
                            Export Data
                          </Button>
                      </div>
                    </div>
                    <div className="tab-content mt-3" id="details-tab-content">
                      <div className="tab-pane fade show active" id="total-scans">
                        <div className="row">
                          <div className="col-12">
                            <div className="scan-details">
                              <div className="heading">
                                Total Scans ({product?.totalScan})
                              </div>
                              {scanLocations?.length ? (
                                <div className="details-tab">
                                  <ul className="nav comment-nav">
                                    <li className="nav-item filter-stars">
                                      <div className="time-select filter-stars total-scan-dropdown">
                                        <select
                                          className="form-select filter-stars"
                                          onChange={handleTotalScansFilter}
                                        >
                                          {filters?.map((item, index) => {
                                            return (
                                              <option
                                                value={item?.name}
                                                key={index}
                                                className="filter-stars"
                                              >
                                                {item?.name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              ) : null}

                              <div className="table-responsive scans-table">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Country</th>
                                      <th>City</th>
                                      <th>Scan Address</th>
                                      <th>Date & Time</th>
                                    </tr>
                                  </thead>
                                  {productScannedLocations?.length ? (
                                    <tbody>
                                      {productScannedLocations?.map(
                                        (location, index) => {
                                          return (
                                            <tr key={index}>
                                              <td>
                                                <ul className="nav align-items-center country-nav">
                                                  <li className="nav-item">
                                                    {location?.country}
                                                  </li>
                                                </ul>
                                              </td>
                                              <td>{location?.city}</td>
                                              <td
                                                style={{
                                                  maxWidth: "250px",
                                                  whiteSpace: "pre-line"
                                                }}
                                              >
                                                {location?.scannedIn}
                                              </td>
                                              <td>
                                                <span className="date">
                                                  {formatDate(
                                                    location?.createdAt
                                                  )}
                                                </span>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  ) : (
                                    <tbody className="py-3">
                                      <tr className="">
                                        <td
                                          colSpan={4}
                                          className="text-center pt-5"
                                        >
                                          <p className="text-secondary">
                                            No Scan Location Found
                                          </p>
                                        </td>
                                      </tr>
                                    </tbody>
                                  )}
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="tab-pane fade" id="total-comments">
                        <div className="row">
                          <div className="col-12">
                            <div className="comment-details mt-3">
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  flexWrap: "wrap"
                                }}
                              >
                                <div className="heading">
                                  Total Comments ({product?.totalComments})
                                </div>
                                
                              </div>
                              <div className="details-tab">
                                <ul className="nav comment-nav">
                                  <li className="nav-item filter-stars">
                                    <div className="time-select filter-stars">
                                      <select
                                        className="form-select filter-stars"
                                        onChange={handleDropdownFilters}
                                        disabled={isDropdownDisabled}
                                      >
                                        {filters?.map((item, index) => {
                                          return (
                                            <option
                                              value={item?.name}
                                              key={index}
                                              className="filter-stars"
                                            >
                                              {item?.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </li>
                                  <div className="responsive-container">
                                    <Space
                                      direction="horizontal"
                                      size={6}
                                      style={{ width: "100%" }}
                                    >
                                      <RangePicker
                                        onChange={handleDatePicker}
                                        disabled={isCalenderAvailable}
                                        size="large" // Use the size prop to control input height
                                        style={{
                                          height: "30px",
                                          border: "1px solid #dee4ef",
                                          marginRight: "10px"
                                        }} // Ensure the input field is full width
                                        dropdownClassName="responsive-range-picker"
                                      />
                                    </Space>
                                  </div>
                                  {filterStars.map((star, index) => {
                                    return (
                                        <li
                                          className="nav-item"
                                          key={index}
                                          onClick={() =>
                                            handleStarsFilter(star)
                                          }
                                        >
                                          <div
                                            className={`rate-pill ${
                                              star?.className === "active"
                                                ? "active"
                                                : ""
                                            }`}
                                            data-bs-toggle="tab"
                                          >
                                            <i className="bi bi-star-fill"></i>
                                            <span className="">
                                              {star.star}
                                            </span>
                                          </div>
                                        </li>
                                    );
                                  })}
                                </ul>

                                <div className="tab-content mt-2 mt-lg-4">
                                  <div
                                    className="tab-pane fade show active"
                                    id="five-star"
                                  >
                                    <>
                                      {review?.length > 0 ? (
                                        reviewLoading ? (
                                          <Loader />
                                        ) : (
                                          review.map((review) => {
                                            return (
                                                <div
                                                  className="person-comment"
                                                  key={review?._id}
                                                >
                                                  <div className="row">
                                                    <div className="col-lg-6 col-xl-9 col-md-9">
                                                      <div className="user-info">
                                                        <ul className="nav flex-nowrap">
                                                          <li className="nav-item">
                                                            <div className="cp-photo"></div>
                                                          </li>
                                                          <li className="nav-item">
                                                            <div
                                                              className="cp-name"
                                                              style={{
                                                                fontSize: "18px"
                                                              }}
                                                            >
                                                              {review?.name
                                                                ? review?.name
                                                                : "Anonymous"}
                                                              {review?.isVerified ? (
                                                                <MdVerified
                                                                  style={{
                                                                    marginLeft:
                                                                      "5px",
                                                                    marginBottom:
                                                                      "2px",
                                                                    color:
                                                                      "#3071F1"
                                                                  }}
                                                                />
                                                              ) : null}
                                                            </div>
                                                            <div className="c-date">
                                                              {dateFormate(
                                                                review?.createdAt
                                                              )}
                                                            </div>
                                                            <div className="comment-sec">
                                                              {review?.comment}
                                                            </div>
                                                            <div className="col-lg-3 d-lg-none total-comments-star-rating">
                                                              <ul className="nav star-indicator">
                                                                {[
                                                                  1, 2, 3, 4, 5
                                                                ].map(
                                                                  (
                                                                    item,
                                                                    index
                                                                  ) => {
                                                                    return (
                                                                      <li
                                                                        className="nav-item"
                                                                        key={
                                                                          index
                                                                        }
                                                                      >
                                                                        <div
                                                                          className={`r-star ${
                                                                            item <=
                                                                            review?.rating
                                                                              ? "active"
                                                                              : null
                                                                          }`}
                                                                        ></div>
                                                                      </li>
                                                                    );
                                                                  }
                                                                )}

                                                                <li className="nav-item">
                                                                  {review?.rating?.toFixed(
                                                                    1
                                                                  )}
                                                                </li>
                                                              </ul>
                                                            </div>
                                                          </li>
                                                        </ul>
                                                      </div>
                                                    </div>

                                                    <div className="col-lg-6 col-xl-3 d-none d-lg-block total-comments-star-rating">
                                                      <ul className="nav star-indicator">
                                                        {[1, 2, 3, 4, 5].map(
                                                          (item, index) => {
                                                            return (
                                                              <li
                                                                className="nav-item"
                                                                key={index}
                                                              >
                                                                <div
                                                                  className={`r-star ${
                                                                    item <=
                                                                    review?.rating
                                                                      ? "active"
                                                                      : null
                                                                  }`}
                                                                ></div>
                                                              </li>
                                                            );
                                                          }
                                                        )}
                                                        <li className="nav-item">
                                                          {review?.rating?.toFixed(
                                                            1
                                                          )}
                                                        </li>
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </div>
                                            );
                                          })
                                        )
                                      ) : (
                                        <>
                                          <div
                                            style={{ height: "20vh" }}
                                            className="d-flex justify-content-center align-items-center"
                                          >
                                            <div>
                                              <p className="text-secondary no-review" >No Review Found</p>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container-fluid products-table">
                {totalReviewsCount > perPage ? (
                  <div className="table-footer row mt-3">
                    <div className="col-12">
                      <ul className="nav align-items-center justify-content-end">
                        <Pagination
                          count={totalPages}
                          page={currentPage}
                          shape="rounded"
                          disabled={totalPages <= 1}
                          onChange={handlePagination}
                          siblingCount={isMobile ? 0 : 2}
                          boundaryCount={isMobile ? 0 : 2}
                          renderItem={(item) => (
                            <PaginationItem
                              {...item}
                              onMouseEnter={(e) =>
                                handleItemMouseEnter(e, item.page)
                              }
                              onMouseLeave={(e) =>
                                handleItemMouseLeave(e, item.page)
                              }
                              style={{
                                background:
                                  item.page === currentPage ? "#202D46" : "",
                                color: item.page === currentPage ? "white" : ""
                              }}
                            />
                          )}
                        />
                      </ul>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-center align-items-center">
                <div>
                  <p>No Data Found</p>
                </div>
              </div>
            </>
          )}
        </section>
      </>
    </>
  );
};

export default ReportDetails;
