import React from "react";
import { Modal } from "react-bootstrap";
import cancelButton from "../../assets/images/verify/crose-rm.svg";
import successLogo from "../../assets/images/verify/success.svg";

const RewardSuccessModal = ({ tipAmount, updateModalStatus }) => {
  const handleModal = () => {
    updateModalStatus(false);
  };
  return (
    <Modal className="fade rewardModal rewardSuccess" centered show={true}>
      <div className="btnClose-rm" onClick={handleModal}>
        <img src={cancelButton} alt="delete-icon" />
      </div>
      <Modal.Body className="position-relative">
        <div className="modal-body p-0">
          <div className="successModal-icon">
            <img src={successLogo} alt="success-icon" />
          </div>

          <div className="rewardModal-title">Payment Successful</div>
          <div className="rewardModal-text">You have Paid ${tipAmount}</div>

          <div className="rewardModal-text">Thank you for the Tip!</div>

          <button className="btn btn-primary w-100 mb-3" onClick={handleModal}>
            Done
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RewardSuccessModal;
