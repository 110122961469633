import React from "react";

const Loader = ()=>{
    return (
        <>
         <div
            className="d-flex justify-content-center"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "92vh"
            }}
          >
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </>
    )
}

export default Loader