import React, { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { authContext } from "../Context/AuthContext";

function ProtectedRoute({ children, allowedRoles }) {
  const { state } = useContext(authContext);
  const user = JSON.parse(localStorage.getItem("loggedinUser")) || state?.user;
  const location = useLocation();

  if (!user) {
    // Redirect to login if user is not authenticated
    return <Navigate to="/login" state={{ from: location }} />;
  }
  let role = "";
  if (user?.superAdmin) {
    role = "superAdmin";
  }
  if (user?.companyAdmin) {
    role = "companyAdmin";
  }
  if (!user?.companyAdmin && !user?.superAdmin && !user?.isFarmer) {
    role = "companyUser";
  }
  if (user?.isFarmer) {
    role = "companyFarmer";
  }
  if (allowedRoles.includes(role)) {
    // User has the necessary role, allow access
    return children;
  } else {
    // User does not have the necessary role, redirect to access denied or login
    if (role === "companyAdmin" || role === "superAdmin") {
      return <Navigate to={"/dashboard"} />;
    } else if (role === "companyUser") {
      return <Navigate to={"/products"} />;
    } else if (role === "companyFarmer") {
      return <Navigate to={"/farmer/profile"} />;
    } else {
      return <Navigate to={"/login"} />;
    }
  }
}
export default ProtectedRoute;
