import { createContext, useState } from "react";

const HeaderContext = createContext({
  companyLogo: null,
  updateHeaderState: () => {}
});
const HeaderProvider = ({ children }) => {
  const [companyLogo, setCompanyLogo] = useState(null);
  const updateHeaderState = (newState) => {
    setCompanyLogo(newState);
  };
  return (
    <HeaderContext.Provider value={{ companyLogo, updateHeaderState }}>
      {children}
    </HeaderContext.Provider>
  );
};
export { HeaderContext, HeaderProvider };
