import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import breadcrumbImg from "../../../assets/images/icons/breadcrumb-icon.svg";
import uploadUserImg from "../../../assets/images/icons/upload-user.svg";
import { axiosInstanceWithFormData } from "../../../axiosConfig/axiosInstance";
import { toast } from "react-toastify";
import { checkWalletChecksum } from "../../../utils/utills";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DraggableUploadListItem from "../../../components/HOCs/DragAndDrop";
import { DndContext, PointerSensor, useSensor } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy
} from "@dnd-kit/sortable";
import { Upload } from "antd";
import dragAndDropLogo from "../../../assets/images/icons/dragdrop.svg";
import uploadProductLogo from "../../../assets/images/icons/upload-product.svg";
import ImageUploadProgress from "../../Products/ImageUploadProgress";
import { v4 as uuidv4 } from "uuid";

const AddFarmer = () => {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    walletAddress: "",
    farmerDescription: "",
    farmPictures: []
  });
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [editorToolBar] = useState({
    toolbar: ["bold", "italic", "bulletedList", "numberedList"]
  });
  const [previewImages, setPreviewImages] = useState([]);
  const [newImages, setNewImages] = useState([]);
  const navigate = useNavigate();

  // handle Input changes
  const handleInputChanges = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setUser({ ...user, farmerDescription: data });
  };

  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10
    }
  });

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setPreviewImages((prev) => {
        const activeIndex = prev.findIndex((i) => i.uid === active.id);
        const overIndex = prev.findIndex((i) => i.uid === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  // handle Image changes
  const handleImageChange = (event) => {
    const { type, size, name } = event.target.files[0];
    if (type === "image/png" || type === "image/jpg" || type === "image/jpeg") {
      // Size must be equal to or less then 4 MBs
      if (size <= 4 * 1024 * 1024) {
        setImage(event.target.files[0]);
        event.target.value = null;
        return;
      } else {
        event.target.value = null;
        toast.warn("File Size must be less than 4MBs");
        return;
      }
    } else {
      event.target.value = null;
      toast.warn("Only JPG, JPEG and PNG extension supported");
      return;
    }
  };
  const handleDeleteImage = () => {
    setImage(null);
  };

  // handle farmer pictures change
  const handleFarmerImages = async (event) => {
    if (event.target.files.length > 0) {
      const { type, size, name } = event.target.files[0];
      if (previewImages.length >= 10) {
        toast.warn("You can add only 10 pictures of a farm");
        return;
      }

      if (
        type === "image/jpeg" ||
        type === "image/jpg" ||
        type === "image/png"
      ) {
        if (size <= 1 * 1024 * 1024) {
          // Update the product state with the new media item added to the existing media array
          setUser((prevUser) => ({
            ...prevUser,
            farmPictures: [...prevUser.farmPictures, event.target.files[0]]
          }));
          const reader = new FileReader();
          reader.onload = (e) => {
            const newPreviewImage = {
              uid: uuidv4(),
              image: e.target.result,
              size: `${(size / 1024).toFixed(2)} KB`,
              name: name
            };
            setNewImages((previous) => [...previous, newPreviewImage]);
            setPreviewImages((previous) => [...previous, newPreviewImage]);
          };

          reader.readAsDataURL(event.target.files[0]);
        } else {
          toast.warn("File size must be less than 1MB");
        }
      } else {
        toast.warn("Only JPG and PNG files supported");
      }
    }
  };

  // delete, added images
  const handleFarmImageDelete = (index, item) => {
    const filteredPreviewImages = previewImages?.filter((img) => {
      return img?.uid != item?.uid;
    });
    setPreviewImages(filteredPreviewImages);
    setNewImages(filteredPreviewImages);
    const filterRemoveProduct = [...user?.farmPictures];
    filterRemoveProduct.splice(index, 1);
    setUser({ ...user, farmPictures: filterRemoveProduct });
  };

  // handle form submission
  const handleFormSubmission = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      if (user?.walletAddress) {
        const isValid = await checkWalletChecksum(user?.walletAddress);
        if (!isValid) {
          toast.error("Error: invalid ethereum address.");
          return;
        }
        formData.append("walletAddress", user?.walletAddress);
      }

      if (user?.farmPictures?.length > 0) {
        for (let i = 0; i < previewImages?.length; i++) {
          const newImg = user?.farmPictures?.find((obj) => {
            return previewImages[i]?.name == obj?.name;
          });
          formData.append("farmPictures", newImg); // Use 'media' as the field name
        }
      }

      formData.append("firstName", user?.firstName);
      formData.append("lastName", user?.lastName);
      formData.append("email", user?.email);
      formData.append("password", user?.password);
      formData.append("isEditor", false);
      formData.append("isViewer", false);
      formData.append("isFarmer", true);
      formData.append("farmerDescription", user?.farmerDescription);
      setIsLoading(true);
      const response = await axiosInstanceWithFormData.post(
        "/user/register",
        formData
      );
      if (image) {
        const formData = new FormData();
        formData.append("pfp", image);
        formData.append("userId", response?.data?.userId);
        await axiosInstanceWithFormData.post("/user/updatepfp", formData);
      }
      toast.success(response?.data?.message);
      if (response) {
        navigate("/farmers");
      }

      return;
    } catch (error) {
      toast.error("Something went wrong");
      return;
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <>
      <section className="section-main">
        <div className="content-sec">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-between">
              <div className="breadcrumb-bar">
                <ul className="nav align-items-center">
                  <li className="nav-item">
                    <Link to={"/farmers"}>Farmers</Link>
                  </li>
                  <li className="nav-item">
                    <img src={breadcrumbImg} alt="icon" />
                  </li>
                  <li className="nav-item">Add Farmer</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="form-section user add-farmer-form">
                <div className="edit-profile-images">
                  <div className="upload-img">
                    <div className="image-section">
                      <div className="img-placeholder">
                        <div className="user-img-ic">
                          <img src={uploadUserImg} alt="icon" />
                        </div>
                        <div className="optional-txt">(Optional)</div>
                        <div className="title">
                          {image ? "Uploaded Picture" : "Upload your Picture"}
                        </div>
                      </div>
                      {image ? (
                        <div
                          className="image-show"
                          style={{ display: image != null ? "block" : "none" }}
                        >
                          <div className="img-details">
                            <div className="uploaded-image">
                              <img
                                id="uploaded-image-preview"
                                src={URL.createObjectURL(image)}
                                alt="Uploaded Image"
                              />
                            </div>
                          </div>
                          <div
                            className="close-sec"
                            onClick={() => handleDeleteImage()}
                          ></div>
                        </div>
                      ) : null}
                      <input
                        id="upload-img-pu"
                        type="file"
                        onChange={handleImageChange}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="img-dimensions">
                      <div className="title">Picture Dimensions</div>
                      300 X 300 (JPG or PNG)
                    </div>
                  </div>
                </div>
                <div className="form-area">
                  <form onSubmit={handleFormSubmission}>
                    <div style={{ display: "flex", gap: "2%" }}>
                      <div className="form-group" style={{ width: "50%" }}>
                        <label>First Name</label>
                        <div className="input-area">
                          <input
                            type="text"
                            name="firstName"
                            onChange={handleInputChanges}
                            value={user?.firstName}
                            className="form-control"
                            autoComplete="new-password"
                          />
                          <span className="focus-bg"></span>
                        </div>
                      </div>
                      <div className="form-group" style={{ width: "50%" }}>
                        <label>Last Name</label>
                        <input
                          type="text"
                          name="lastName"
                          onChange={handleInputChanges}
                          value={user?.lastName}
                          className="form-control"
                          autoComplete="new-password"
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Email</label>
                      <span className="required-asterisk">*</span>
                      <input
                        type="email"
                        name="email"
                        onChange={handleInputChanges}
                        required
                        value={user?.email}
                        className="form-control"
                        autoComplete="new-password"
                      />
                    </div>

                    <div className="form-group">
                      <label>Password</label>
                      <span className="required-asterisk">*</span>
                      <input
                        type="password"
                        name="password"
                        onChange={handleInputChanges}
                        required
                        value={user?.password}
                        className="form-control"
                        autoComplete="new-password"
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label>Wallet Address</label>
                      <input
                        type="text"
                        name="walletAddress"
                        onChange={handleInputChanges}
                        value={user?.walletAddress}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group mt-3">
                      <label>Farmer Description</label>
                      <CKEditor
                        editor={ClassicEditor}
                        data={user?.farmerDescription}
                        id="editor"
                        config={editorToolBar}
                        className="form-control"
                        onChange={handleEditorChange}
                        autofocus
                        required
                      />
                    </div>

                    <div className="form-group mt-3">
                      <div className="">
                        <div className="upload-farmer-product">
                          <label>Upload Farm Pictures</label>
                          <div className="image-section ">
                            <div className="img-placeholder">
                              <div className="user-img-ic">
                                <img src={uploadProductLogo} alt="icon" />
                              </div>
                              <div className="title">
                                Drop files here or browse to upload
                              </div>
                              <div className="img-dimensions mt-2">
                                Up to 1 MB (JPG or PNG)
                              </div>
                              <input
                                id="upload-img-pu"
                                type="file"
                                onChange={handleFarmerImages}
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                            <DndContext
                              sensors={[sensor]}
                              onDragEnd={onDragEnd}
                            >
                              <SortableContext
                                items={previewImages.map((i) => i.uid)}
                                strategy={verticalListSortingStrategy}
                              >
                                <Upload
                                  fileList={previewImages}
                                  onChange={handleFarmerImages}
                                  showUploadList={false}
                                ></Upload>
                                {previewImages?.length > 0 && (
                                  <>
                                    {previewImages?.map((item, index) => {
                                      return (
                                        <DraggableUploadListItem
                                          key={item?.uid}
                                          file={item}
                                          originNode={
                                            <div
                                              key={index}
                                              className="image-show"
                                              style={{
                                                display:
                                                  previewImages.length > 0
                                                    ? "block"
                                                    : "none"
                                              }}
                                            >
                                              <div class="dragdrop-icon">
                                                <img
                                                  src={dragAndDropLogo}
                                                  alt="dragdrop-icon"
                                                />
                                              </div>
                                              <div className="img-details">
                                                <ul className="nav">
                                                  <li className="nav-item">
                                                    <div className="uploaded-image">
                                                      <img
                                                        className=""
                                                        id="uploaded-image-preview"
                                                        src={item?.image}
                                                        alt="preview image"
                                                      />
                                                    </div>
                                                  </li>
                                                  <li className="nav-item">
                                                    <div
                                                      className="product-name"
                                                      id="product-name"
                                                    >
                                                      {item?.name}
                                                    </div>
                                                    <div
                                                      className="product-size"
                                                      id="product-size"
                                                    >
                                                      {item?.size}
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>
                                              <div>
                                                <ImageUploadProgress
                                                  newImages={newImages}
                                                />
                                              </div>
                                              <div
                                                className="close-sec"
                                                onClick={() =>
                                                  handleFarmImageDelete(
                                                    index,
                                                    item
                                                  )
                                                }
                                              ></div>
                                            </div>
                                          }
                                        />
                                      );
                                    })}
                                  </>
                                )}
                              </SortableContext>
                            </DndContext>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group mt-4 mt-lg-5 text-end">
                      <button
                        className="btn btn-light me-3"
                        onClick={() => navigate("/farmers")}
                      >
                        Cancel
                      </button>
                      {isLoading ? (
                        <button className="btn btn-primary" disabled>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary">
                          Add Farmer
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AddFarmer;
