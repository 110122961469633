import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../axiosConfig/axiosInstance";
import { toast } from "react-toastify";
import EmailPopup from "../../components/EmailPopup";
import { calc } from "antd/es/theme/internal";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isInputAvailable, setIsInputAvailable]=useState(false)

  useEffect(() => {
    setIsInputAvailable(!!email);
  }, [email]);
  const handleInputField = (event) => {
    setEmail(event.target.value);
  };

  const handleFormSubmission = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const response = await axiosInstance.post("/user/forgot-password", {
        email: email
      });
      if (response) {
        // toast.success(response?.data?.message);
        setIsLoading(false);
        setIsEmailSent(true);
        setEmail("");
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong");
      return;
    }
  };
  return (
    <>
      {isEmailSent ? (
        <EmailPopup text="We have sent a password recovery instructions to your email" purpose="forgetPassword" />
      ) : (
        <section className="section-main ms-0 removing-scrolling" >
          <div className="login-signup-section d-flex align-items-center justify-content-center py-4">
            <div className="login-signup-form">
              <form onSubmit={handleFormSubmission}>
                <div className="form-title">Forgot Password?</div>

                <div className="form-group">
                  <div className="title-description">
                    Enter your registered email to get password recovery link
                  </div>
                </div>

                <div className="form-group form-floating">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    value={email}
                    required
                    onChange={handleInputField}
                  />
                  <label>Email</label>
                </div>

                <div className="form-group">
                  {isLoading ? (
                    <button type="submit" className="btn btn-primary" disabled>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </button>
                  ) : (
                    <button type="submit" className="btn btn-primary" disabled={!isInputAvailable}>
                      Send
                    </button>
                  )}
                </div>
              </form>

              <div className="form-group">
                <div className="note main-link mb-0">
                  <Link to={"/login"}>Back to Login</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default ForgetPassword;
