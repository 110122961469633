import React, { useState, useEffect, useContext } from "react";
import uploadUserImg from "../../assets/images/icons/upload-user.svg";
import { toast } from "react-toastify";
import {
  axiosInstance,
  axiosInstanceWithFormData
} from "../../axiosConfig/axiosInstance";
import Loader from "../../components/Loader/Loader";
import { authContext } from "../../components/Context/AuthContext";
import ImageUploadProgress from "../Products/ImageUploadProgress";
import { useNavigate } from "react-router-dom";
import companyLogoPlaceholder from "../../assets/images/logo/companyLogo.svg"

const EditProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [logoPreviewImage, setLogoPreviewImage] = useState(null);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [newImages, setNewImages] = useState([]);
  const [logoNewImage, setLogoNewImage] = useState([]);
  const { state, updateState } = useContext(authContext);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const navigate = useNavigate();
  const [isOldPasswordRequire, setIsOldPasswordRequire] = useState(true);
  const [user] = useState(
    JSON.parse(localStorage.getItem("loggedinUser")) || state?.user
  );
  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    password: "",
    password2: "",
    walletAddress: "",
    oldPassword: ""
  });
  const [file, setFile] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [currentPfp, setCurrentPfp] = useState(null);
  const [currentLogo, setCurrentLogo] = useState(null);

  useEffect(() => {
    setData({
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      companyName: user?.companyName,
      walletAddress: user?.walletAddress,
      password: "",
      password2: "",
      oldPassword: ""
    });
    setCurrentPfp(user?.profilePic);
    setCurrentLogo(user?.companyLogo);
  }, [user]);

  // handle input fields
  const handleInputFields = (event) => {
    const { name, value } = event.target;
    setIsButtonEnabled(true);
    if (name === "firstName" || name === "lastName") {
      setIsOldPasswordRequire(false);
    }
    setData({ ...data, [name]: value });
  };
  // handle upload image logic here
  const handleImageChange = (event, purpose) => {
    setIsButtonEnabled(true);
    setIsOldPasswordRequire(false);
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      if (file) {
        if (purpose === "profile") {
          setFile(file);
        } else {
          setLogoFile(file);
        }
      }
      const { type, size, name } = event.target.files[0];

      if (
        type === "image/jpeg" ||
        type === "image/jpg" ||
        type === "image/png"
      ) {
        if (size <= 1 * 1024 * 1024) {
          // Update the product state with the new media item added to the existing media array
          const reader = new FileReader();
          reader.onload = (e) => {
            const newPreviewImage = {
              image: e.target.result,
              size: `${(size / 1024).toFixed(2)} KB`,
              name: name,
              dbImage: 0
            };
            if (purpose === "profile") {
              setNewImages(() => [newPreviewImage]);
              setPreviewImage(newPreviewImage);
            } else {
              setLogoNewImage(() => [newPreviewImage]);
              setLogoPreviewImage(newPreviewImage);
            }
          };
          reader.readAsDataURL(event.target.files[0]);
          event.target.value = null;
        } else {
          event.target.value = null;
          toast.warn("File size must be less than 1MB");
        }
      } else {
        event.target.value = null;
        toast.warn("Only JPG and PNG files supported");
      }
    }
  };


  // delete, added images
  const handleDeleteImage = (purpose) => {
    setIsButtonEnabled(true);
    if (purpose === "profile") {
      setCurrentPfp(null);
      setFile("remove");
      if (previewImage != null) {
        setPreviewImage(null);
      }
    }
    if (purpose === "logo") {
      setLogoFile("remove")
      setCurrentLogo(null)
      if (logoPreviewImage != null) {
        setLogoPreviewImage(null)
      }
    }
  };

  const handleCancelClick = (e) => {
    if (user?.superAdmin || user?.companyAdmin) {
      navigate("/dashboard");
    } else {
      navigate("/products");
    }
  };

  // handle form submission
  const handleFormSubmission = async (event) => {
    event.preventDefault();
    try {
      if (data?.oldPassword && !data?.password) {
        toast.error("New password required");
        return;
      }
      if (data?.password && data?.password2 && !data?.oldPassword) {
        toast.error("Current password required");
        return;
      }
      if (data?.password || data?.password2) {
        if (data?.password !== data?.password2) {
          toast.error("Password does not match");
          return;
        }
      }
      setIsButtonLoading(true);
      let newUser = null;
      const response = await axiosInstance.patch("/user/edituser", data);
      if (response?.data?.success) {
        newUser = {
          ...user,
          firstName: data.firstName,
          lastName: data.lastName
        };

        localStorage.setItem("loggedinUser", JSON.stringify(newUser));
        updateState({ user: newUser });
        toast.success(response?.data?.message);
        let response1,
          response2 = null;
        if (file != null && response?.data?.success) {
          response1 = await uploadPfp();
          if (response1) {
            if (newUser) {
              newUser = {
                ...newUser,
                profilePic: response1 == "Pfp removed" ? null : response1,
                firstName: data.firstName,
                lastName: data.lastName
              };
            } else {
              newUser = {
                ...user,
                profilePic: response1 == "Pfp removed" ? null : response1,
                firstName: data.firstName,
                lastName: data.lastName
              };
            }
            localStorage.setItem("loggedinUser", JSON.stringify(newUser));
            updateState({ user: newUser });
            setCurrentPfp(response1 == "Pfp removed" ? null : response1);
          }
        }
        if (logoFile != null && response?.data?.success) {
          response2 = await uploadCompanyLogo();
          if (response2?.success) {
            if (newUser) {
              newUser = {
                ...newUser,
                companyLogo: response2?.logoRemoved ? null : response2?.logo,
                firstName: data.firstName,
                lastName: data.lastName
              };
            } else {
              newUser = {
                ...user,
                companyLogo: response2?.logoRemoved ? null : response2?.logo,
                firstName: data.firstName,
                lastName: data.lastName
              };
            }
            localStorage.setItem("loggedinUser", JSON.stringify(newUser));
            updateState({ user: newUser });
            // setCurrentLogo(response2?.logoRemoved? null : response2?.logo);
          }
        }
      }

      setData({
        ...data,
        password: "",
        password2: "",
        oldPassword: ""
      });
      setIsButtonLoading(false);
      return;
    } catch (error) {
      toast.error("Something went wrong");
      setIsButtonLoading(false);
      setIsButtonEnabled(false);
      setData({
        ...data,
        password: "",
        password2: "",
        oldPassword: ""
      });
      return;
    }
  };

  const uploadPfp = async () => {
    try {
      let formdata = new FormData();
      formdata.append("pfp", file);
      const response = await axiosInstanceWithFormData.post(
        "/user/updatepfp",
        formdata
      );
      setData({
        ...data,
        password: "",
        password2: "",
        oldPassword: ""
      });
      if (response?.data?.pfp) {
        return response?.data?.pfp;
      } else {
        return response?.data?.message;
      }
    } catch (error) {
      toast.error("Something went wrong");
      setIsButtonLoading(false);
      setIsButtonEnabled(false);
      return;
    }
  };
  const uploadCompanyLogo = async () => {
    try {
      let formdata = new FormData();
      formdata.append("companyLogo", logoFile);
      const response = await axiosInstanceWithFormData.post(
        "/company/update/logo",
        formdata
      );
      setData({
        ...data,
        password: "",
        password2: "",
        oldPassword: ""
      });
      return response?.data;
    } catch (error) {
      toast.error("Something went wrong");
      setIsButtonLoading(false);
      setIsButtonEnabled(false);
      return;
    }
  };


  return (
    <>
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <section className="section-main">
          <div className="content-sec">
            <div className="row">
              <div className="col-12 d-flex align-items-center justify-content-between">
                <div className="breadcrumb-bar">
                  <ul className="nav align-items-center">
                    <li className="nav-item">Edit Profile</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="form-section">
                  <div className="edit-profile-images">

                  <div className="upload-img">
                    <label>Upload Profile Picture</label>
                    <div className="image-section">
                      <div className="img-placeholder">
                        <div className="user-img-ic">
                          <img src={uploadUserImg} alt="icon" />
                        </div>
                        <div className="img-dimensions mt-1">(Optional)</div>
                        <div className="title mt-2">Upload Picture</div>
                      </div>
                      {previewImage != null || currentPfp != null ? (
                        <>
                          <div
                            className="image-show"
                            style={{
                              display:
                                previewImage != null || currentPfp != null
                                  ? "block"
                                  : "none"
                            }}
                          >
                            <div className="img-details">
                              <ul className="nav">
                                <li className="nav-item">
                                  <div className="uploaded-image">
                                    <img
                                      className=""
                                      id="uploaded-image-preview"
                                      src={
                                        previewImage?.image ||
                                        `${process.env.REACT_APP_SERVER_URL}/uploads/pfp/${currentPfp}`
                                      }
                                      alt="Uploaded Image"
                                    />
                                  </div>
                                </li>
                                <li className="nav-item">
                                  <div
                                    className="product-name"
                                    id="product-name"
                                  >
                                    {previewImage?.name ||
                                      "Current profile picture"}
                                  </div>
                                  <div
                                    className="product-size"
                                    id="product-size"
                                  >
                                    {previewImage?.size}{" "}
                                    {previewImage?.size && "KB"}
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div>
                              <ImageUploadProgress newImages={newImages} />
                            </div>
                            <div
                              className="close-sec"
                              onClick={() => handleDeleteImage("profile")}
                            ></div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <input
                        id="upload-img-pu"
                        type="file"
                        onChange={(event) =>
                          handleImageChange(event, "profile")
                        }
                      />
                    </div>
                  </div>

                  {
                    user?.companyAdmin ?
                  <div className="upload-img">
                    <label>Upload Company Logo</label>
                    <div className="image-section">
                      <div className="img-placeholder">
                        <div className="user-img-ic">
                          <img src={companyLogoPlaceholder} alt="icon" />
                        </div>
                        <div className="img-dimensions mt-1">(Optional)</div>
                        <div className="title mt-2">Upload Company Logo</div>
                      </div>
                      {logoPreviewImage != null || currentLogo != null ? (
                        <>
                          <div
                            className="image-show"
                            style={{
                              display:
                                logoPreviewImage != null || currentLogo != null
                                  ? "block"
                                  : "none"
                            }}
                          >
                            <div className="img-details">
                              <ul className="nav">
                                <li className="nav-item">
                                  <div className="uploaded-image">
                                    <img
                                      className=""
                                      id="uploaded-image-preview"
                                      src={
                                        logoPreviewImage?.image ||
                                        `${process.env.REACT_APP_SERVER_URL}/uploads/companyLogos/${currentLogo}`
                                      }
                                      alt="Uploaded Image"
                                    />
                                  </div>
                                </li>
                                <li className="nav-item">
                                  <div
                                    className="product-name"
                                    id="product-name"
                                  >
                                    {logoPreviewImage?.name ||
                                      "Current company logo"}
                                  </div>
                                  <div
                                    className="product-size"
                                    id="product-size"
                                  >
                                    {logoPreviewImage?.size}
                                    {logoPreviewImage?.size && "KB"}
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div>
                              <ImageUploadProgress newImages={logoNewImage} />
                            </div>
                            <div
                              className="close-sec"
                              onClick={() => handleDeleteImage("logo")}
                            ></div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      <input
                        id="upload-img-pu"
                        type="file"
                        onChange={(event) => handleImageChange(event, "logo")}
                      />
                    </div>
                  </div>:null
                  }

                  </div>

                  <div className="form-area">
                    <form onSubmit={handleFormSubmission}>
                      <div style={{ display: "flex", gap: "2%" }}>
                        <div className="form-group" style={{ width: "50%" }}>
                          <label>First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={data?.firstName}
                            required
                            name="firstName"
                            onChange={handleInputFields}
                          />
                        </div>
                        <div className="form-group" style={{ width: "50%" }}>
                          <label>Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={data?.lastName}
                            required
                            name="lastName"
                            onChange={handleInputFields}
                          />
                        </div>
                      </div>

                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="text"
                          className="form-control"
                          value={data?.email}
                          name="email"
                          readOnly
                          disabled
                        />
                      </div>
                      {!user?.superAdmin && (
                        <div className="form-group">
                          <label>Wallet Address</label>
                          <input
                            type="text"
                            className="form-control"
                            value={data?.walletAddress}
                            name="walletAddress"
                            readOnly
                            disabled
                            onChange={handleInputFields}
                          />
                        </div>
                      )}

                      <div style={{ display: "flex", gap: "2%" }}>
                        {!user?.superAdmin && (
                          <div className="form-group" style={{ width: "50%" }}>
                            <label>Company</label>
                            <input
                              type="text"
                              className="form-control"
                              value={data?.companyName}
                              name="companyName"
                              readOnly
                              disabled
                            />
                          </div>
                        )}

                        <div className="form-group" style={{ width: "50%" }}>
                          <label>Blockchain Network</label>
                          <input
                            type="text"
                            className="form-control"
                            value={user?.setting?.blockchain?.name}
                            name="network"
                            readOnly
                            disabled
                          />
                        </div>
                      </div>

                      <div style={{ display: "flex", gap: "2%" }}>
                        <div className="form-group" style={{ width: "50%" }}>
                          <label>Standard</label>
                          <input
                            type="text"
                            className="form-control"
                            value={user?.setting?.standard?.toUpperCase()}
                            name="standard"
                            readOnly
                            disabled
                          />
                        </div>
                        <div className="form-group" style={{ width: "50%" }}>
                          <label>Default Transactions</label>
                          <input
                            type="text"
                            className="form-control"
                            value={user?.setting?.defaultTransactions?.toUpperCase()}
                            name="defaultTransactions"
                            readOnly
                            disabled
                          />
                        </div>
                      </div>
                      <div
                        className="form-group"
                        style={{
                          width: "100%",
                          background: "#F1F6FF",
                          border: "1px solid #a2d0f1",
                          borderRadius: "5px",
                          padding: "2%",
                          marginBottom: "16px"
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <svg
                            width="23"
                            height="24"
                            viewBox="0 0 23 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ marginRight: "1em" }}
                          >
                            <g clipPath="url(#clip0_350_3724)">
                              <path
                                d="M11.5 0.5C5.14933 0.5 0 5.64933 0 12C0 18.3507 5.14933 23.5 11.5 23.5C17.8507 23.5 23 18.3507 23 12C23 5.64933 17.8507 0.5 11.5 0.5ZM10.6786 6.45536C10.6786 6.34241 10.771 6.25 10.8839 6.25H12.1161C12.229 6.25 12.3214 6.34241 12.3214 6.45536V13.4375C12.3214 13.5504 12.229 13.6429 12.1161 13.6429H10.8839C10.771 13.6429 10.6786 13.5504 10.6786 13.4375V6.45536ZM11.5 17.75C11.1776 17.7434 10.8706 17.6107 10.6449 17.3804C10.4192 17.15 10.2928 16.8404 10.2928 16.5179C10.2928 16.1954 10.4192 15.8857 10.6449 15.6554C10.8706 15.425 11.1776 15.2923 11.5 15.2857C11.8224 15.2923 12.1294 15.425 12.3551 15.6554C12.5808 15.8857 12.7072 16.1954 12.7072 16.5179C12.7072 16.8404 12.5808 17.15 12.3551 17.3804C12.1294 17.6107 11.8224 17.7434 11.5 17.75Z"
                                fill="#0071C1"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_350_3724">
                                <rect
                                  width="23"
                                  height="23"
                                  fill="white"
                                  transform="translate(0 0.5)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                          <p
                            style={{
                              fontSize: "0.8em",
                              marginBottom: "0",
                              fontWeight: "500",
                              lineHeight: "1.8em"
                            }}
                          >
                            If you would like to change only password, type
                            existing and a new password. Otherwise leave this
                            blank
                          </p>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>Current Password</label>
                        <input
                          type={data?.oldPassword ? "password":"text"}
                          className="form-control"
                          value={data?.oldPassword}
                          name="oldPassword"
                          onChange={handleInputFields}
                          autoComplete="off"
                        />
                      </div>
                      <div className="form-group">
                        <label>New Password</label>
                        <input
                          type={data?.password ? "password":"text"}
                          className="form-control"
                          value={data?.password}
                          name="password"
                          onChange={handleInputFields}
                        />
                      </div>
                      <div className="form-group">
                        <label>Repeat Password</label>
                        <input
                          type={data?.password2 ? "password":"text"}
                          className="form-control"
                          value={data?.password2}
                          name="password2"
                          onChange={handleInputFields}
                        />
                      </div>

                      <div className="form-group mt-4 mt-lg-5 row">
                        <div className="col-8 pe-0">
                          {isButtonLoading ? (
                            <button className="btn btn-primary w-100" disabled>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            </button>
                          ) : (
                            <button
                              type="submit"
                              className="btn btn-primary w-100"
                              disabled={!isButtonEnabled}
                            >
                              Update Profile
                            </button>
                          )}
                        </div>
                        <div className="col-4">
                          <button
                            className="btn btn-light me-3 w-100"
                            onClick={handleCancelClick}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};
export default EditProfile;
