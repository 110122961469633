import React, { useEffect, useState } from "react";
import connectIcon from "../../../assets/images/icons/connect-icon.svg";
import {
  axiosInstance,
  axiosInstanceWithFormData
} from "../../../axiosConfig/axiosInstance";
import { toast } from "react-toastify";
import { checkWalletChecksum } from "../../../utils/utills";
import { useNavigate, Link } from "react-router-dom";
import Progressbar from "./Progressbar";
import {
  createWeb3Modal,
  defaultConfig,
  useWeb3ModalAccount,
  useWeb3Modal,
  useDisconnect,
  useWeb3ModalEvents,
  useSwitchNetwork
} from "@web3modal/ethers/react";

const WalletConnect = ({ updateParentState, data, pfp }) => {
  const [networks, setNetworks] = useState([]);
  const [user] = useState(JSON.parse(localStorage?.getItem("loggedinUser")));
  const [selectedNetwork, setSelectedNetwork] = useState(null);
  const [walletAddress, setWalletAddress] = useState(user?.walletAddress);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isSwitch, setIsSwitch] = useState(false);
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const projectId = process.env.REACT_APP_METAMASK_PROJECT_ID;
  const navigate = useNavigate();


  let settings = {};
  if (Object?.keys(user?.farmerSettings)?.length) {
    settings.info = {
      chainId: Number(user?.farmerSettings?.chainId),
      name: user?.farmerSettings?.name,
      currency: user?.farmerSettings?.currency,
      explorerUrl: user?.farmerSettings?.explorerUrl,
      rpcUrl: user?.farmerSettings?.rpcUrl
    };
  } else {
    settings.info = {
      chainId: Number(user?.networkSetting?.chainId),
      name: user?.networkSetting?.name,
      currency: user?.networkSetting?.currency,
      explorerUrl: user?.networkSetting?.explorerUrl,
      rpcUrl: user?.networkSetting?.rpcUrl
    };
  }
  const chains = [
    {
      chainId: settings?.info?.chainId,
      name: settings?.info?.name,
      currency: settings?.info?.currency,
      explorerUrl: settings?.info?.explorerUrl,
      rpcUrl: settings?.info?.rpcUrl
    }
  ];
  const ethersConfig = defaultConfig({
    metadata: {
      name: "Scan and Trace",
      description: "Scan and trace connection established",
      url: "https://web3modal.com",
      icons: [
        "https://drive.google.com/file/d/19BBUCil8jHTf872DXutrg2V4DQsMEjJr/view?usp=sharing"
      ]
    },
    defaultChain: chains[0]
  });

  createWeb3Modal({
    ethersConfig,
    chains,
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    allWallets: "ONLY_MOBILE",
    featuredWalletIds: []
  });
  const { open, close } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { switchNetwork } = useSwitchNetwork();
  const events = useWeb3ModalEvents();

  useEffect(() => {
    if (address) {
      setWalletAddress(address);
    } else {
      setWalletAddress("");
    }

    const fetchData = async () => {
      if (isConnected) {
        try {
          if (chains[0]?.chainId !== chainId) {
            setIsSwitch(true);
            await switchNetwork(chains[0]?.chainId);
            setIsSwitch(false);
            close();
          }
        } catch (error) {
          console.log("error in switch", error);
          setIsSwitch(false);
          close();
          disconnect();
        }
      }
    };
    fetchData();
  }, [address, chainId, isConnected]);

  useEffect(() => {
    if (isSwitch && events?.data?.event === "MODAL_CLOSE") {
      disconnect();
    }
  }, [events?.data?.event]);

  useEffect(() => {
    fetchNetworks();
    window.scrollTo(0, 0);
  }, []);
  const fetchNetworks = async () => {
    try {
      const response = await axiosInstance.get("/general/networks");
      setNetworks(response?.data?.networks);
      if (response?.data?.networks) {
        if (data?.chainId) {
          const selectedNetworks = response?.data?.networks?.filter((item) => {
            return item?.chainId == data?.chainId;
          });
          setSelectedNetwork(selectedNetworks[0]);
        } else {
          setSelectedNetwork(user?.networkSetting);
        }
      }
    } catch (error) {
      toast.error(error?.message);
      return;
    }
  };

  // network selection change
  const handleNetworkSelectionChange = (event) => {
    const blockchainNetwork = JSON.parse(event.target.value);
    setSelectedNetwork(blockchainNetwork);
  };

  const submitProfile = async (event) => {
    event.preventDefault();
    try {
      //  wallet validation
      const isValid = await checkWalletChecksum(walletAddress);
      if (!isValid) {
        toast.error("Error: invalid ethereum address.");
        return;
      }
      const formData = new FormData();
      formData.append("walletAddress", walletAddress);
      if (data?.farmerDescription) {
        formData.append("farmerDescription", data?.farmerDescription);
      }
      if (selectedNetwork) {
        formData.append("chainId", selectedNetwork?.chainId);
      }

      if (data?.firstName) {
        formData.append("firstName", data?.firstName);
      }
      if (pfp) {
        formData.append("pfp", pfp);
      }
      if (data?.lastName) {
        formData.append("lastName", data?.lastName);
      }

      // handle remove pictures
      if (data?.removedPictures?.length > 0) {
        for (let i = 0; i < data?.removedPictures?.length; i++) {
          formData.append("removedPictures", data?.removedPictures[i]);
        }
      }

      //   handle new images
      if (data?.farmPictures?.length > 0) {
        for (let i = 0; i < data?.farmPictures?.length; i++) {
          formData.append("farmPictures", data?.farmPictures[i]);
        }
      }
      setButtonLoading(true);
      const response = await axiosInstanceWithFormData.post(
        "/farmer/create/review-request",
        formData
      );
      if (response) {
        toast.success(response?.data?.message);
        navigate("/farmer/profile");
      }
    } catch (error) {
      toast.error(error?.message);
      return;
    } finally {
      setButtonLoading(false);
    }
  };

  return (
    <section className="section-main ms-0 link-wallet">
      <div className="content-sec">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-12 col-lg-10 col-xl-9 col-xxl-8">
              <Progressbar step1={true} />
              <div className="wallet-sec why_connect">
                <div className="title-main">Why do you need to connect wallet?</div>
                <div className="wallet-description">
                  Connecting your wallet is essential for receiving all your
                  tips directly and securely. If you already have a wallet,
                  manually add it by entering your wallet address If you don’t,
                  simply scan and connect.
                </div>
              </div>

              <div className="wallet-sec">
                <div className="title">Connect Wallet</div>
                <div className="wallet-description">
                  If you don’t remember your wallet address, simply scan and
                  connect your wallet.
                </div>
                {
                  isConnected ? 
                  <div className="wallet-connected my-3">
                        <div className="wallet-details">
                          <div className="c-status">
                            <span></span> Wallet Connected
                          </div>
                          {address?.substring(0, 12)}.....
                          {address?.substring(address?.length - 12)}
                        </div>
                        
                      </div>
                   :
                <Link className="btn btn-secondary" onClick={() => open()}>
                  <img src={connectIcon} alt="icon" /> Connect Wallet
                </Link>
                }
              </div>

              <div className="row">
                <div className="col-12 py-3 py-lg-4 text-center">
                  <div className="optional-title">OR</div>
                </div>
              </div>

              <div className="wallet-sec">
                <div className="title">Add your wallet manually</div>
                <div className="wallet-description">
                  If you already have a wallet, you can add it manually by
                  selecting the network and entering your wallet address.
                </div>

                <form className="mt-3" onSubmit={submitProfile}>
                  <div className="form-group">
                    <label>
                      Select Network
                      <span className="required-asterisk">*</span>
                    </label>
                    <select
                      className="form-select select-options"
                      onChange={handleNetworkSelectionChange}
                    >
                      <option
                        value="Select blockchain network"
                        disabled
                        selected
                      >
                        {selectedNetwork?.name}
                      </option>
                      {networks?.length &&
                        networks?.map((item, index) => {
                          return (
                            <option key={index} value={JSON.stringify(item)}>
                              {item?.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  <div className="form-group">
                    <label>
                      Your Wallet Address
                      <span className="required-asterisk">*</span>
                    </label>
                    <input
                      className="form-control"
                      placeholder="0xB1594BB7131F57a5d1CeaD2CF8b9C3a0120aAF36"
                      value={walletAddress}
                      onChange={(event) => setWalletAddress(event.target.value)}
                      required
                    />
                  </div>

                  <div className="row">
                    <div className="col-12 text-end">
                      <button
                        className="btn btn-light"
                        onClick={() => updateParentState(false)}
                      >
                        Cancel
                      </button>
                      <button className="btn btn-primary mx-2">
                        Link Wallet
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WalletConnect;
