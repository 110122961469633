import { createContext, useState } from "react";

const authContext = createContext();
const AuthProvider = ({ children }) => {
  const [state, setState] = useState({});
  const updateState = newState => {
    setState(newState);
  };
  return (
    <authContext.Provider value={{ state, updateState }}>
      {children}
    </authContext.Provider>
  );
};
export { authContext, AuthProvider };
