import React, { useState, useEffect } from "react";
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Polyline,
  InfoWindow
} from "@react-google-maps/api";
import mapMarker from "../../../assets/images/map/map-marker.svg";

function Map({ productLocations, userLocation }) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API
  });
  const [map, setMap] = useState(null);
  const [productPolyline, setProductPolyline] = useState([]);
  const [activeMarker, setActiveMarker] = useState(null);

  useEffect(() => {
    if (isLoaded && map && productLocations.length > 0 && userLocation) {
      const bounds = getBounds([...productLocations, userLocation]);
      map.fitBounds(bounds);
      // Create polyline for product locations
      const productPath = productLocations.map((loc) => ({
        lat: loc.lat,
        lng: loc.lng
      }));
      setProductPolyline(productPath);
    } else if (
      isLoaded &&
      map &&
      productLocations.length > 0 &&
      !userLocation
    ) {
      const bounds = getBounds([...productLocations]);
      map.fitBounds(bounds);
      // Create polyline for product locations
      const productPath = productLocations.map((loc) => ({
        lat: loc.lat,
        lng: loc.lng
      }));
      setProductPolyline(productPath);
    } else if (
      isLoaded &&
      map &&
      userLocation &&
      productLocations.length == 0
    ) {
      const bounds = getBounds([userLocation]);
      map.fitBounds(bounds);
    } else {
      console.warn("neither user location available nor product");
    }
  }, [map, productLocations, userLocation]);

  const handleMarkerClick = (marker, location) => {
    setActiveMarker({ marker, location });
  };

  const handleCloseClick = () => {
    setActiveMarker(null);
  };

  const handleMapLoad = (mapInstance) => {
    setMap(mapInstance);
    if (mapInstance) {
      let bounds;
      if (productLocations?.length && userLocation) {
        bounds = getBounds([...productLocations, userLocation]);
      } else if (productLocations?.length) {
        bounds = getBounds([...productLocations]);
      } else {
        bounds = getBounds([userLocation]);
      }
      mapInstance.fitBounds(bounds);
    }
  };
  const getBounds = (directions) => {
    const bounds = new window.google.maps.LatLngBounds();
    directions.forEach((point) =>
      bounds.extend(new window.google.maps.LatLng(point.lat, point.lng))
    );
    return bounds;
  };

  const options = {
    zoomControl: true,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: true,
    minZoom: 1
  };

  return (
    <div className="directions-map" style={{ width: "100%", height: "400px" }}>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100%" }}
          zoom={15}
          center={productLocations?.length ? productLocations[0] : userLocation} // Center the map at the first product location
          options={options}
          onLoad={handleMapLoad}
        >
          {map &&
            (productLocations?.length && userLocation ? (
              <>
                {/* Display markers for product locations */}
                {productLocations.map((location, index) => (
                  <Marker
                    key={`product-marker-${index}`}
                    position={{ lat: location.lat, lng: location.lng }}
                    label={(index + 1).toString()}
                    anchorX={0.5}
                    anchorY={0.5}
                    icon={{
                      url: mapMarker,
                      scaledSize: new window.google.maps.Size(48, 48), // Adjust the size as needed
                      labelOrigin: new window.google.maps.Point(24, 18)
                    }}
                    onClick={() => handleMarkerClick(location?.label, location)}
                  />
                ))}

                {/* Display polyline for product locations */}
                <Polyline
                  path={productPolyline}
                  options={{
                    strokeColor: "#007bff",
                    strokeWeight: 4,
                    strokeOpacity: 0.8,
                    geodesic: true
                  }}
                />

                {/* Display marker and polyline for user location */}
                {userLocation && (
                  <>
                    <Marker
                      position={userLocation}
                      label="U"
                      anchorX={0.5}
                      anchorY={0.5}
                      icon={{
                        url: mapMarker,
                        scaledSize: new window.google.maps.Size(48, 48), // Adjust the size as needed
                        labelOrigin: new window.google.maps.Point(24, 18)
                      }}
                      onClick={() =>
                        handleMarkerClick("Your Location", userLocation)
                      }
                    />
                    <Polyline
                      path={[
                        productLocations[productLocations.length - 1],
                        userLocation
                      ]}
                      options={{
                        strokeColor: "#007bff", // Use the same color as product locations
                        strokeWeight: 4,
                        strokeOpacity: 0.8,
                        geodesic: true
                      }}
                    />
                  </>
                )}
                {activeMarker && (
                  <InfoWindow
                    position={activeMarker.location}
                    onCloseClick={handleCloseClick}
                    options={{
                      // disableAutoPan:true,
                      pixelOffset: new window.google.maps.Size(0, -30),
                      // zIndex: 1,
                    }}
                  >
                    <div>{activeMarker.marker}</div>
                  </InfoWindow>
                )}
              </>
            ) : productLocations?.length ? (
              productLocations.map((location, index) => (
                <>
                  <Marker
                    key={`product-marker-${index}`}
                    position={{ lat: location.lat, lng: location.lng }}
                    label={(index + 1).toString()}
                    anchorX={0.5}
                    anchorY={0.5}
                    icon={{
                      url: mapMarker,
                      scaledSize: new window.google.maps.Size(48, 48), // Adjust the size as needed
                      labelOrigin: new window.google.maps.Point(24, 18)
                    }}
                    onClick={() => handleMarkerClick(location?.label, location)}
                  />
                  <Polyline
                    path={productPolyline}
                    options={{
                      strokeColor: "#007bff",
                      strokeWeight: 4,
                      strokeOpacity: 0.8,
                      geodesic: true
                    }}
                  />
                  {activeMarker && (
                    <InfoWindow
                      position={activeMarker.location}
                      onCloseClick={handleCloseClick}
                    >
                      <div>{activeMarker.marker}</div>
                    </InfoWindow>
                  )}
                </>
              ))
            ) : userLocation ? (
              <>
                <Marker
                  position={userLocation}
                  label="U"
                  anchorX={0.5}
                  anchorY={0.5}
                  icon={{
                    url: mapMarker,
                    scaledSize: new window.google.maps.Size(48, 48), // Adjust the size as needed
                    labelOrigin: new window.google.maps.Point(24, 18)
                  }}
                  onClick={() =>
                    handleMarkerClick("Your Location", userLocation)
                  }
                />
                {activeMarker && (
                  <InfoWindow
                    position={activeMarker.location}
                    onCloseClick={handleCloseClick}
                  >
                    <div>{activeMarker.marker}</div>
                  </InfoWindow>
                )}
              </>
            ) : null)}
        </GoogleMap>
      )}
    </div>
  );
}

export default Map;
