const PrivacyPolicy = () => {
  return (
    <>
      <section className="section-main privacy_policy">
        <div className="content-sec p-0">
          <section className="privacy-policy">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <h1>Privacy Policy</h1>
                  <h2>General</h2>
                  <p>
                    Safeguarding the integrity and security of our systems
                    stands as a paramount concern for us. Despite our rigorous
                    measures, the possibility of vulnerabilities persists.{" "}
                    <br />
                    In light of this, we earnestly request your assistance in
                    identifying and reporting any vulnerabilities you may
                    encounter. Your collaboration is invaluable in fortifying
                    the protection of our clients and systems. By promptly
                    notifying us of any potential weaknesses, you enable us to
                    take swift and decisive action to address and rectify them,
                    thereby ensuring the continued safety and reliability of our
                    services.
                  </p>

                  <h2>How we collect personal information</h2>

                  <div className="privacy-listing">
                    <ol>
                      <li>
                        Upon receipt of your report, we commit to providing a
                        response within 3 business days, inclusive of our
                        evaluation and an estimated resolution date.
                      </li>
                      <li>
                        If you have adhered to the aforementioned guidelines, we
                        assure you that no legal action will be taken against
                        you with regard to the report.
                      </li>
                      <li>
                        Your report will be treated with the utmost
                        confidentiality, and your personal details will not be
                        disclosed to third parties without your explicit
                        consent.
                      </li>
                      <li>
                        As a token of appreciation for your assistance, our
                        management team may consider offering a reward for each
                        report of a security problem that was previously unknown
                        to us. The reward amount will be determined based on the
                        severity of the vulnerability and the quality of the
                        report.
                      </li>
                      <li>
                        We are committed to resolving all issues promptly and
                        aim to actively contribute to the publication of the
                        problem once it has been successfully addressed.
                      </li>
                      <li>
                        We will keep you updated on the progress towards
                        resolving the issue, ensuring transparency throughout
                        the process. In any public disclosure related to the
                        reported problem, your name will be credited as the
                        discoverer, unless you prefer otherwise.
                      </li>
                    </ol>
                  </div>

                  <h2>What We Promise</h2>

                  <p>
                    Upon receipt of your report, we commit to providing a
                    response within 3 business days, inclusive of our evaluation
                    and an estimated resolution date. If you have adhered to the
                    aforementioned guidelines, rest assured that no legal action
                    will be taken against you in relation to the report. Your
                    report will be handled with utmost confidentiality, and your
                    personal details will remain undisclosed to third parties
                    without explicit consent. We will keep you informed of the
                    progress towards resolving the issue, ensuring transparency
                    throughout. In any public disclosure related to the reported
                    problem, your name will be credited as the discoverer,
                    unless specified otherwise. As a token of appreciation, our
                    management team may offer a reward for each report of a
                    previously unknown security problem, determined by its
                    severity and the quality of the report. We are dedicated to
                    promptly resolving all issues and aim to actively contribute
                    to the publication of the problem once it is successfully
                    addressed.
                  </p>

                  <h2>Collection and use of data</h2>

                  <div className="privacy-listing">
                    <ol>
                      <li>
                        Upon receipt of your report, we commit to providing a
                        response within 3 business days, inclusive of our
                        evaluation and an estimated resolution date.
                      </li>
                      <li>
                        If you have adhered to the aforementioned guidelines, we
                        assure you that no legal action will be taken against
                        you with regard to the report.
                      </li>
                      <li>
                        Your report will be treated with the utmost
                        confidentiality, and your personal details will not be
                        disclosed to third parties without your explicit
                        consent.
                      </li>
                      <li>
                        As a token of appreciation for your assistance, our
                        management team may consider offering a reward for each
                        report of a security problem that was previously unknown
                        to us. The reward amount will be determined based on the
                        severity of the vulnerability and the quality of the
                        report.
                      </li>
                      <li>
                        We are committed to resolving all issues promptly and
                        aim to actively contribute to the publication of the
                        problem once it has been successfully addressed.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
