import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import breadcrumbImg from "../../assets/images/icons/breadcrumb-icon.svg";
import uploadUserImg from "../../assets/images/icons/upload-user.svg";
import {
  axiosInstanceWithFormData
} from "../../axiosConfig/axiosInstance";
import { toast } from "react-toastify";
import countriesListing from "countries-cities";
import { checkWalletChecksum } from "../../utils/utills";



const AddUser = () => {
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    isEditor: false,
    isViewer: false,
    walletAddress: "",
    country: "",
    city: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [selectedRole, setSelectedRole] = useState("");
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
 
  const [countryPlaceholder, setCountryPlaceholder] =
    useState("Select Country");

  useEffect(() => {
    getCountries();
  }, []);

  const getCountries = () => {
    setCountries(countriesListing.getCountries());
    setCities(countriesListing.getCities(countriesListing.getCountries()[0]));
  };

  // countries input changing
  const onCountryChange = async (e) => {
    setCities(countriesListing.getCities(countries[e.target.value]));
    setUser({
      ...user,
      country: countries[e.target.value],
      city: countriesListing.getCities(countries[e.target.value])[0]
    });
    setCountryPlaceholder(countries[e.target.value]);
  };

  // cities input
  const onCityChange = async (e) => {
    setUser({ ...user, city: cities[e.target.value] });
  };

  // handle Input changes
  const handleInputChanges = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };
  // handle role change
  const handleRoleChange = (event) => {
    const role = event.target.value;
    setSelectedRole(role);
    setUser({
      ...user,
      isViewer: role === "viewer",
      isEditor: role === "editor",
    });
  };

  
  // handle Image changes
  const handleImageChange = (event) => {
    const { type, size, name } = event.target.files[0];
    if (type === "image/png" || type === "image/jpg" || type === "image/jpeg") {
      // Size must be equal to or less then 4 MBs
      if (size <= 4 * 1024 * 1024) {
        setImage(event.target.files[0]);
        event.target.value = null;
        return;
      } else {
        event.target.value = null;
        toast.warn("File Size must be less than 4MBs");
        return;
      }
    } else {
      event.target.value = null;
      toast.warn("Only JPG, JPEG and PNG extension supported");
      return;
    }
  };
  const handleDeleteImage = () => {
    setImage(null);
  };

  // handle form submission
  const handleFormSubmission = async (event) => {
    event.preventDefault();
    try {
      const formData = new FormData();
      if (user?.isEditor || user?.isFarmer) {
        const isValid = await checkWalletChecksum(user?.walletAddress);
        if (!isValid) {
          toast.error("Error: invalid ethereum address.");
          return;
        }
        formData.append("walletAddress", user?.walletAddress)

      }
      if (!user?.country || !user?.city) {
        toast.warn("User country and city is required");
        return;
      }
      
      formData.append("firstName", user?.firstName);
      formData.append("lastName", user?.lastName);
      formData.append("email",user?.email);
      formData.append("password", user?.password);
      formData.append("isEditor", user?.isEditor);
      formData.append("isViewer", user?.isViewer);
      formData.append("country", user?.country);
      formData.append("city", user?.city)
      setIsLoading(true);
      const response = await axiosInstanceWithFormData.post("/user/register", formData);
      if (image) {
        const formData = new FormData();
        formData.append("pfp", image);
        formData.append("userId", response?.data?.userId);
        await axiosInstanceWithFormData.post("/user/updatepfp", formData);
      }
      toast.success(response?.data?.message);
      if (response) {
        navigate("/users");
      }
      setUser({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        isEditor: false,
        isViewer: false,
        walletAddress: "",
        country: "",
        city: ""
      });
      setSelectedRole("");
      setImage(null);
      return;
    } catch (error) {
      toast.error("Something went wrong");
      return;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <section className="section-main">
        <div className="content-sec">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-between">
              <div className="breadcrumb-bar">
                <ul className="nav align-items-center">
                  <li className="nav-item">
                    <Link to={"/users"}>Users</Link>
                  </li>
                  <li className="nav-item">
                    <img src={breadcrumbImg} alt="icon" />
                  </li>
                  <li className="nav-item">Add User</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <div className="form-section  user">
                <div className="edit-profile-images">
                  <div className="upload-img">
                    <div className="image-section">
                      <div className="img-placeholder">
                        <div className="user-img-ic">
                          <img src={uploadUserImg} alt="icon" />
                        </div>
                        <div className="optional-txt">(Optional)</div>
                        <div className="title">
                          {image ? "Uploaded Picture" : "Upload Picture"}
                        </div>
                      </div>
                      {image ? (
                        <div
                          className="image-show"
                          style={{ display: image != null ? "block" : "none" }}
                        >
                          <div className="img-details">
                            <div className="uploaded-image">
                              <img
                                id="uploaded-image-preview"
                                src={URL.createObjectURL(image)}
                                alt="Uploaded Image"
                              />
                            </div>
                          </div>
                          <div
                            className="close-sec"
                            onClick={() => handleDeleteImage()}
                          ></div>
                        </div>
                      ) : null}
                      <input
                        id="upload-img-pu"
                        type="file"
                        onChange={handleImageChange}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="img-dimensions">
                      <div className="title">Picture Dimensions</div>
                      300 X 300 (JPG or PNG)
                    </div>
                  </div>
                 
                </div>
                <div className="form-area">
                  <form onSubmit={handleFormSubmission}>
                    <div className="form-group">
                      <label>First Name</label>
                      <span className="required-asterisk">*</span>
                      <div className="input-area">
                        <input
                          type="text"
                          name="firstName"
                          onChange={handleInputChanges}
                          required
                          value={user?.firstName}
                          className="form-control"
                          autoComplete="new-password"
                        />
                        <span className="focus-bg"></span>
                      </div>
                    </div>
                    <div className="form-group">
                      <label>Last Name</label>
                      <span className="required-asterisk">*</span>
                      <input
                        type="text"
                        name="lastName"
                        onChange={handleInputChanges}
                        value={user?.lastName}
                        required
                        className="form-control"
                        autoComplete="new-password"
                      />
                    </div>
                    <div className="form-group">
                      <label>Email</label>
                      <span className="required-asterisk">*</span>
                      <input
                        type="email"
                        name="email"
                        onChange={handleInputChanges}
                        required
                        value={user?.email}
                        className="form-control"
                        autoComplete="new-password"
                      />
                    </div>

                    <div className="form-group">
                      <label>Password</label>
                      <span className="required-asterisk">*</span>
                      <input
                        type="password"
                        name="password"
                        onChange={handleInputChanges}
                        required
                        value={user?.password}
                        className="form-control"
                        autoComplete="new-password"
                      />
                    </div>

                    <div className="form-group">
                      <label>Country</label>
                      <span className="required-asterisk">*</span>
                      <select
                        name=""
                        required
                        className="form-select select-options"
                        onChange={onCountryChange}
                        value={countryPlaceholder}
                      >
                        <option value={countryPlaceholder}>
                          {countryPlaceholder}
                        </option>
                        {countries.map((item, index) => (
                          <option key={index} value={index}>
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>

                    {user?.country && (
                      <div className="form-group mt-3">
                        <label>City</label>
                        <span className="required-asterisk">*</span>
                        <select
                          name=""
                          required
                          className="form-select select-options"
                          onChange={onCityChange}
                        >
                          {cities.map((item, index) => (
                            <option key={index} value={index}>
                              {item}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                    <div className="form-group mt-3">
                      <label>Designation</label>
                      <span className="required-asterisk">*</span>
                      <select
                        name="" // Change 'role' to the appropriate field name
                        onChange={handleRoleChange}
                        required
                        value={selectedRole}
                        className="form-select select-options"
                      >
                        <option value="" disabled>
                          Select a role
                        </option>
                        <option value="editor">Editor</option>
                        <option value="viewer">Viewer</option>
                      </select>
                    </div>
                    {user?.isEditor && (
                      <div className="form-group mt-3">
                        <label>Wallet Address</label>
                        <span className="required-asterisk">*</span>
                        <input
                          type="text"
                          name="walletAddress"
                          onChange={handleInputChanges}
                          required
                          value={user?.walletAddress}
                          className="form-control"
                        />
                      </div>
                    )}

                    
                    <div className="form-group mt-4 mt-lg-5 text-end">
                      <button
                        className="btn btn-light me-3"
                        onClick={() => navigate("/users")}
                      >
                        Cancel
                      </button>
                      {isLoading ? (
                        <button className="btn btn-primary" disabled>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary">
                          Add User
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default AddUser;
