import React from "react";
import { Modal } from "react-bootstrap";

const FullImage = ({image, resetFullPicture}) => {
    const handleClose = ()=>{
        resetFullPicture(null)
    }
  return (
    
    <Modal
      show={true}
      onHide={handleClose}
      dialogClassName="fullscreen border-0 bg-transparent position-relative"
      size="lg"
      centered
      >
      <button
        type="button"
        className="btn-close"
        aria-label="Close"
        data-bs-dismiss="modal"
        onClick={handleClose}
        style={{
          position: "absolute",
          right: "10px",
          top: "10px",
          zIndex: "9999",
          padding: "8px",
          backgroundColor: "white",
          borderRadius: "5px"
        }}
        ></button>
      <Modal.Body className="p-0">
        <img
          src={image}
          id="fullscreenImage"
          className="w-100"
          alt="Fullscreen Image" style = {{maxHeight: '80vh', objectFit:'cover',}}
          />
      </Modal.Body>
    </Modal>
  );
};

export default FullImage;
