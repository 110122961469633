import React from "react";
import Modal from "react-bootstrap/Modal";
import deleteModal from "../../assets/images/icons/delet-modal.svg";
import Button from "react-bootstrap/Button";

const DeleteRequestModal = ({ updateModalStatus, show, handleClose }) => {
  const handleButtonClick = () => {
    updateModalStatus(false);
  };
  const handleCloseButton = () => {
    handleClose(false);
  };
  return (
    <Modal
      show={show}
      onHide={handleCloseButton}
      backdrop="static"
      keyboard={false}
      centered
      className="modal fade deleteModal"
    >
      <Modal.Body className="Modal-body text-center">
        <div className="modal-header-icon">
          <img
            className="mx-auto"
            src={deleteModal}
            alt="email-icon"
            style={{
              width: "84px",
              height: "auto",
              marginBottom: "35px"
            }}
          />
        </div>

        <div className="Modal-title ">
          Do you really want to delete this review request?
        </div>
        <ul className="nav justify-content-center align-items-center">
          <li className="nav-item">
            <Button
              variant="delete"
              onClick={handleButtonClick}
              className="btn btn-delete"
            >
              Yes, Delete it
            </Button>
          </li>
          <li className="nav-item">
            <Button
              variant="danger"
              onClick={handleCloseButton}
              className="btn btn-dngr"
            >
              No
            </Button>
          </li>
        </ul>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteRequestModal;
