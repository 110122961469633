import React, { useState, useEffect } from "react";
import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api";
import customIcon from "../assets/images/reporting/map-indications.svg";

function MultipleLocations({ scanLocations }) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API
  });
  const [map, setMap] = useState(null);
  const [locationsLoaded, setLocationsLoaded] = useState(false);
  
  useEffect(() => {
    if (map && scanLocations.length > 0) {
      const bounds = getBounds(scanLocations);
      map.fitBounds(bounds);
      setLocationsLoaded(true); // Set locations as loaded once bounds are set
    }
  }, [map, scanLocations]);

  const handleMapLoad = (mapInstance) => {
    setMap(mapInstance);
  };

  const getBounds = (locations) => {
    const bounds = new window.google.maps.LatLngBounds();
    locations.forEach((location) =>
      bounds.extend(new window.google.maps.LatLng(location.latitude, location.longitude))
    );
    return bounds;
  };

  const options = {
    zoomControl: true,
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: true,
    minZoom:8
  };

  return (
    <div className="directions-map" style={{ width: "100%", height: "370px" }}>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100%" }}
          // zoom={15}
          center={scanLocations.length > 0 ? { lat: scanLocations[0].latitude, lng: scanLocations[0].longitude } : { lat: 0, lng: 0 }}
          options={options}
          onLoad={handleMapLoad}
        >
          {locationsLoaded && scanLocations.map((location, index) => (
            <Marker
              key={index}
              position={{ lat: location.latitude, lng: location.longitude }}
              // label={(index + 1).toString()}
              anchorX={0.5}
              anchorY={0.5}
              icon={{
                // url: `https://maps.google.com/mapfiles/ms/icons/${getColor(index)}.png`,
                url: customIcon,
                scaledSize: new window.google.maps.Size(50, 50)
              }}
            />
          ))}
        </GoogleMap>
      )}
    </div>
  );
}

// Function to determine marker color based on index
function getColor(index) {
  const colors = ["red", "blue", "green", "yellow", "purple","orange","pink","black"]; // Add more colors if needed
  return colors[index % colors.length];
}

export default MultipleLocations;
