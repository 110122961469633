import React from "react";
import "../../assets/css/components-style/login-signup.css";
import "../../assets/css/components-style/header.css";
// import logo from "../../assets/images/logo/scane-trace-logo.svg";
import { Link } from "react-router-dom";
import logo from '../../assets/images/icons/logo.svg'
const LoginSignupHeader = (props) => {
  return (
    <>
      <section className="header-top">
        <div className="container-fluid px-0 px-md-3">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between">
                <Link to={"#"}>
                  <div className="side-bar-header p-0 mb-0">
                    {
                      props?.type === "verifyPage"?
                      <img src={`${process.env.REACT_APP_SERVER_URL}/uploads/companyLogos/${props?.companyLogo}`} alt="logo" />:
                      <img src={logo} alt="logo" />
                    }
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default LoginSignupHeader;
