import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import declineIcon from "../../assets/images/farmer/declined-modal-icon.svg"

const DeclineModal = ({show, updateDeclineReasonModalStatus, reason}) => {
    const handleButtonClick=()=>{
        updateDeclineReasonModalStatus(false)
    }
  return (
    <Modal
      show={show}
      backdrop="static"
      keyboard={false}
      centered
      className="modal fade deleteModal"
    >
      <Modal.Body className="Modal-body text-center">
      <div className="modal-header-icon">
          <img
            className="mx-auto"
            src={declineIcon}
            alt="email-icon"
            style={{
              width: "84px",
              height: "auto",
              marginBottom: "35px"
            }}
          />
        </div>
        <div className="Modal-title mb-3">Profile Declined</div>
        <div>
          <p>
           {reason ? reason :"N/A"}
          </p>
        </div>
        <ul className="nav justify-content-center align-items-center">
          <li className="nav-item">
            <Button
              variant="approve"
              onClick={handleButtonClick}
              className="btn btn-primary"
              style={{width:"fit-content"}}
            >
              Back to your Profile
            </Button>
          </li>
        </ul>
      </Modal.Body>
    </Modal>
  );
};

export default DeclineModal;
