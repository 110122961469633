import { useEffect, useState } from "react";
import { axiosInstance } from "../../../axiosConfig/axiosInstance";
import { useSearchParams } from "react-router-dom";
import successLogo from "../../../assets/images/icons/success.svg";
import { useNavigate } from "react-router-dom";
const VerifyReview = () => {
  const [searchParams] = useSearchParams();
  const [productId, setProductId] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const verifyToken = async () => {
      if (searchParams.get("token")) {
        setProductId(searchParams.get("productId"));
        try {
          await axiosInstance.post(
            `product/verify/review/${searchParams.get("token")}`
          );
          return;
        } catch (error) {
          console.log(
            "error occur while showing review verified thank you message",
            error
          );
          return;
        }
      }
    };
    verifyToken();
  }, [searchParams.get("token")]);
  return (
    <>
      <section className="verify-success-review min-vh-50 d-flex jusitfy-content-center remove-scroll-bar">
        <div className="container text-center ">
          <div className="row">
            <div className="col-12 px-0">
              <div className="verify-img mb-3 mb-md-4">
                <img src={successLogo} alt="success-icon" />
              </div>
              <h2>Thank you for sharing your feedback :)</h2>
              <p>
                Your feedback will help us to improve our product and provide a
                better experience
              </p>
            </div>
            <button
              type="submit"
              className="btn btn-primary mt-3"
              onClick={() => navigate(`/product/getProduct/scan/${productId}`)}
              style={{ padding: "10px", fontSize:"15px" }}
            >
              Done
            </button>
          </div>
        </div>

        <div className="review-footer">
          POWERED BY <span>DMCC</span>
        </div>
      </section>
    </>
  );
};

export default VerifyReview;
